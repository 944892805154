import mqtt from 'mqtt/dist/mqtt';

// connect to broker and return client
export function connectHost(host, mqttOptions) {
  let client = mqtt.connect(host, mqttOptions);
  return client;
}

export function disconnectHost(client) {
  if (client) {
    client.end(() => {});
  }
}

export function onConnect(client, connectHandler) {
  client.on('connect', connectHandler);
}

export function onError(client) {
  client.on('error', (err) => {
    client.end();
  });
}

export function onMessage(client, msgHandler) {
  client.on('message', (topic, msg) => {
    const payload = { topic, msg: msg.toString() };
    msgHandler(payload);
  });
}

export function topicSubscribe(client, topic, qos, topicHandler) {
  if (client) {
    let status;
    client.subscribe(topic, qos, (err, granted) => {
      if (err) {
        console.log(err);
        status = false;
        topicHandler(status);
      } else {
        console.log(`was subscribed`);
        status = true;
        topicHandler(status);
      }
    });
  }
}

export function topicSubscribeMultiple(client, topics, topicHandler) {
  if (client && topics) {
    client.subscribe(topics, (err, granted) => {
      if (err) {
        console.log(err);
        topicHandler(false);
      } else {
        console.log('was subscribed');
        if (granted.length) topicHandler(true);
      }
    });
  }
}

export function topicUnsubscribeMultiple(client, topics, subHandler) {
  if (client && topics) {
    client.unsubscribe(topics, (err) => {
      if (err) {
        console.log(err);
      } else {
        console.log('was unsubscribed');
        subHandler(false);
      }
    });
  }
}

export function topicUnsubscribe(client, topic, subHandler) {
  if (client) {
    client.unsubscribe(topic, (error) => {
      if (error) {
        console.log(error);
      } else {
        subHandler(false);
      }
    });
  }
}

export function topicPublish(client, contextInfo) {
  return new Promise((resolve, reject) => {
    let { topic, qos, payload } = contextInfo;
    if (client) {
      let message;
      let object;
      client.publish(topic, payload, { qos }, (error) => {
        if (error) {
          message = `Publish error: ${error}`;
          object = { status: 'success', message };
          reject(object);
        } else {
          message = 'Publish success';
          object = { status: 'success', message };
          resolve(object);
        }
      });
    } else {
      const message = 'Client is not defined';
      const object = { status: 'error', message };
      reject(object);
    }
  });
}
