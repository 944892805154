import { connect } from "react-redux";

import { getTasksDepth, getTasksCount } from "core/slices/metrics";
import {
  tasksDepth,
  loadingTasksDepth,
  tasksCount,
  loadingTasksCount,
  navigationOptions,
} from "core/selectors";

import MetricsInfo from "../../../../components/pipelineMonitoring/monitoringGraph/InfoDrawer/Edge/MetricsInfo";

export default connect(
  (state) => ({
    tasksDepth: tasksDepth(state),
    loadingTasksDepth: loadingTasksDepth(state),
    tasksCount: tasksCount(state),
    loadingTasksCount: loadingTasksCount(state),
    navigationOptions: navigationOptions(state),
  }),
  {
    getTasksDepth,
    getTasksCount,
  }
)(MetricsInfo);
