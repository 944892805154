import { useEffect, useRef, useState } from 'react';

import {
  Box,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  Skeleton,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import TabPanel from '../tools/TabPanel';
import { a11yProps } from '../Utils';
import AlertBox from '../tools/AlertBox';

const DEFAULT_LOGS_LINES = 200;

export function SessionLogsWidget(props) {
  const {
    client,
    topics,
    monitoringLogs,
    isSessionLogsLoading,
    setIsSessionLogsLoading,
  } = props;

  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [filteredLogs, setFilteredLogs] = useState('');
  const [lastTimestamp, setLastTimestamp] = useState('');
  const [firstTimestamp, setFirstTimestamp] = useState('');
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [isAutoReload, setIsAutoReload] = useState(true);
  const [isViewAll, setIsViewAll] = useState(false);
  const logsTimeInterval = useRef(null);

  // handlers
  const handleTabChange = (event, newValue) => {
    setIsAutoReload(true);
    if (newValue === 0) {
      if (!monitoringLogs?.session) {
        handleGetLogs('session', DEFAULT_LOGS_LINES);
      } else {
        searchAmongLogs(searchValue, monitoringLogs?.session);
      }
    } else if (newValue === 1) {
      if (!monitoringLogs?.navigation) {
        handleGetLogs('navigation', DEFAULT_LOGS_LINES);
      } else {
        searchAmongLogs(searchValue, monitoringLogs?.navigation);
      }
    }
    setTabIndex(newValue);
  };

  const handleGetLogs = (type, linesCant) => {
    if (topics) {
      setIsSessionLogsLoading(true);

      const logObj = {
        log_type: type,
      };
      if (linesCant) {
        logObj['lines'] = linesCant;
      }
      client.publish(
        topics.monitoring_logs.publishTopic,
        JSON.stringify(logObj),
        {
          qos: topics.monitoring_logs.publishQos,
        }
      );
    }
  };

  const createLogInterval = (type, linesCant) => {
    if (logsTimeInterval.current) {
      clearInterval(logsTimeInterval.current);
    }
    const newLogsTimeInterval = setInterval(() => {
      handleGetLogs(type, linesCant);
    }, 60000);
    logsTimeInterval.current = newLogsTimeInterval;
  };

  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(debounceTimer);
      const timer = setTimeout(() => {
        func(...args);
      }, delay);
      setDebounceTimer(timer);
    };
  };

  const searchAmongLogs = (targetValue, logs) => {
    if (targetValue) {
      const filteredLogs = logs
        .split('\n')
        .filter((log) => log.toLowerCase().includes(targetValue.toLowerCase()));
      setFilteredLogs(filteredLogs.join('\n'));
    } else {
      setFilteredLogs(logs?.trim());
    }
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    debounce(searchAmongLogs, 100)(
      event.target.value,
      tabIndex === 0 ? monitoringLogs?.session : monitoringLogs?.navigation
    );
  };

  const handleSwitchChange = (switchType) => (event) => {
    let logLines = DEFAULT_LOGS_LINES;
    let isNewInterval = true;

    if (switchType === 'viewAll') {
      setIsViewAll(event.target.checked);
      logLines = event.target.checked ? 0 : DEFAULT_LOGS_LINES;
      isNewInterval = isAutoReload;
      handleGetLogs(tabIndex === 0 ? 'session' : 'navigation', logLines);
    } else if (switchType === 'autoReload') {
      setIsAutoReload(event.target.checked);
      logLines = isViewAll ? 0 : DEFAULT_LOGS_LINES;
      isNewInterval = event.target.checked;
    }

    if (isNewInterval) {
      createLogInterval(tabIndex === 0 ? 'session' : 'navigation', logLines);
    } else {
      clearInterval(logsTimeInterval.current);
    }
  };

  // hooks
  useEffect(() => {
    return () => {
      setIsSessionLogsLoading(false);
      clearInterval(logsTimeInterval.current);
    };
  }, []);

  useEffect(() => {
    if (client) {
      createLogInterval(
        tabIndex === 0 ? 'session' : 'navigation',
        DEFAULT_LOGS_LINES
      );
    }
  }, [client, tabIndex]);

  useEffect(() => {
    if (monitoringLogs) {
      let newMonitoringLogs = '';
      if (tabIndex === 0) {
        newMonitoringLogs = monitoringLogs.session?.trim();
      } else if (tabIndex === 1) {
        newMonitoringLogs = monitoringLogs.navigation?.trim();
      }

      searchAmongLogs(searchValue, newMonitoringLogs);
      setLastTimestamp(
        newMonitoringLogs
          ?.split('\n')
          .pop()
          ?.split(' ', 3)
          .slice(1, 3)
          .join(' ')
          .split(',', 1)[0]
      );
      setFirstTimestamp(
        newMonitoringLogs
          ?.split('\n')
          .shift()
          ?.split(' ', 3)
          .slice(1, 3)
          .join(' ')
          .split(',', 1)[0]
      );
    } else {
      handleGetLogs(
        tabIndex === 0 ? 'session' : 'navigation',
        DEFAULT_LOGS_LINES
      );
    }
  }, [monitoringLogs]);

  useEffect(() => {
    if (!isSessionLogsLoading && client) {
      setTimeout(() => {
        handleGetLogs(
          tabIndex === 0 ? 'session' : 'navigation',
          DEFAULT_LOGS_LINES
        );
      }, 1000);
    }
  }, [client]);

  return (
    <Card
      sx={{
        p: 0,
        minHeight: 350,
        height: '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={t('overseer_app.widget.Session_logs', 'Session Logs')}
            disabled={isSessionLogsLoading}
            {...a11yProps(0, { textTransform: 'initial' })}
          />
          <Tab
            label={t('overseer_app.widget.Nav_logs', 'Nav Logs')}
            disabled={isSessionLogsLoading}
            {...a11yProps(1, { textTransform: 'initial' })}
          />
        </Tabs>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            pr: 2,
            alignItems: 'center',
          }}
        >
          <Switch
            color="info"
            size="small"
            checked={isViewAll}
            onChange={handleSwitchChange('viewAll')}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography
            variant="small"
            sx={{
              fontSize: 'small',
              fontWeight: isViewAll ? 'bold' : 'normal',
              color: (theme) =>
                isViewAll ? theme.palette.info.main : theme.palette.slate.main,
            }}
          >
            {t('overseer_app.widget.view_all', 'View All')}
          </Typography>

          <Switch
            color="info"
            size="small"
            checked={isAutoReload}
            onChange={handleSwitchChange('autoReload')}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography
            variant="small"
            sx={{
              fontSize: 'small',
              fontWeight: isAutoReload ? 'bold' : 'normal',
              color: (theme) =>
                isAutoReload
                  ? theme.palette.info.main
                  : theme.palette.slate.main,
            }}
          >
            {t('overseer_app.widget.auto_reload', 'Auto Reload')}
          </Typography>

          <FormControl
            sx={{ ml: 3 }}
            variant="standard"
            disabled={isSessionLogsLoading}
          >
            <Input
              placeholder={t('overseer_app.widget.search', 'Search')}
              id="input-search-session-logs"
              startAdornment={
                <InputAdornment position="start">
                  <IconComponent iconName="search" color="slate" />
                </InputAdornment>
              }
              value={searchValue}
              onChange={handleSearchChange}
            />
          </FormControl>
        </Box>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        {isSessionLogsLoading ? (
          <Skeleton variant="rectangular" height={350} />
        ) : (
          <Grid container>
            <Grid
              id="logs-view-session"
              item
              xs={12}
              sx={{
                overflowY: 'auto',
                maxHeight: 350,
                p: 2,
                display: 'flex',
                flexDirection: 'column-reverse',
              }}
            >
              {filteredLogs ? (
                <Typography
                  component={'pre'}
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {filteredLogs}
                </Typography>
              ) : (
                <AlertBox
                  severity="info"
                  content={t(
                    'overseer_app.widget.no_session_logs',
                    'No session logs'
                  )}
                />
              )}
            </Grid>
          </Grid>
        )}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        {isSessionLogsLoading ? (
          <Skeleton variant="rectangular" height={350} />
        ) : (
          <Grid container>
            <Grid
              id="logs-view-navigation"
              item
              xs={12}
              sx={{
                overflowY: 'auto',
                maxHeight: 350,
                p: 2,
                display: 'flex',
                flexDirection: 'column-reverse',
              }}
            >
              {filteredLogs ? (
                <Typography
                  component={'pre'}
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {filteredLogs}
                </Typography>
              ) : (
                <AlertBox
                  severity="info"
                  content={t(
                    'overseer_app.widget.no_navigation_logs',
                    'No navigation logs'
                  )}
                />
              )}
            </Grid>
          </Grid>
        )}
      </TabPanel>
      <Box
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: 0,
          maxHeight: '1em',
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}
      >
        <Typography variant="caption" sx={{ fontSize: 'x-small', pr: '2px' }}>
          {t('overseer_app.widget.first_log', 'First log')}:
        </Typography>
        <Typography
          variant="caption"
          sx={{ fontSize: 'x-small', pr: 2, fontWeight: 'bold' }}
        >
          {isSessionLogsLoading ? (
            <CircularProgress size={10} sx={{ color: 'slate', ml: 5 }} />
          ) : (
            firstTimestamp || '-'
          )}
        </Typography>
        <Typography variant="caption" sx={{ fontSize: 'x-small', pr: '2px' }}>
          {t('overseer_app.widget.last_log', 'Last log')}:
        </Typography>
        <Typography
          variant="caption"
          sx={{ fontSize: 'x-small', pr: 2, fontWeight: 'bold' }}
        >
          {isSessionLogsLoading ? (
            <CircularProgress size={10} sx={{ color: 'slate', ml: 5 }} />
          ) : (
            lastTimestamp || '-'
          )}
        </Typography>
        <Typography variant="caption" sx={{ fontSize: 'x-small', pr: '2px' }}>
          {t('overseer_app.widget.cant_lines', 'Lines')}:
        </Typography>
        <Typography
          variant="caption"
          sx={{ fontSize: 'x-small', pr: 2, fontWeight: 'bold' }}
        >
          {isSessionLogsLoading ? (
            <CircularProgress size={10} sx={{ color: 'slate', ml: 5 }} />
          ) : (
            filteredLogs?.split('\n').length || 0
          )}
        </Typography>
      </Box>
    </Card>
  );
}
