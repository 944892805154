import { Button, Card, Grid, Stack, Tab, Tabs } from '@mui/material';
import { isEmptyOrUndefined } from 'core/utils/validators';
import { React, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommandForm from '../../redux/containers/widgets/CommandForm';
import MultiSelector from '../tools/MultiSelector';
import RobotBlocking from '../tools/RobotBlocking';
import TabPanel from '../tools/TabPanel';
import { a11yProps } from '../Utils';
import { ButtonsWrapper } from './ActionWidget';
import ConfirmDialog from './Components/ConfirmDialog';

export default function SessionPlanWidget(props) {
  const {
    title,
    store,
    robotCode,
    isLoadingRobotBlocking,
    isLoadingLeaveRobot,
    rawRobotStatus,
    postUserActions,
    controlledBy,
    isLoadingTakeRobot,
    setOpenKick,
    takeSelectedRobot,
    publishMessageCommand,
    iHaveRobotTaken,
    getRobotsTaken,
    robotsTakenList,
    featurePermission,
    checkFeaturePermission,
    postActionReason,
  } = props;

  const { t } = useTranslation();

  const [selectedAisles, setSelectedAisles] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState('');
  const [confirmMsg, setConfirmMsg] = useState('');
  const [cmdObj, setCmdObj] = useState(null);
  const [hasForm, setHasForm] = useState(false);
  const modalForm = useRef({});

  const checkRobotBlocking = () => {
    return (
      <>
        {!iHaveRobotTaken && (
          <RobotBlocking
            featurePermission={featurePermission}
            checkFeaturePermission={checkFeaturePermission}
            controlledBy={controlledBy}
            isLoadingTakeRobot={isLoadingTakeRobot}
            setOpenKick={setOpenKick}
            takeSelectedRobot={takeSelectedRobot}
            getRobotsTaken={getRobotsTaken}
            minutesLeft={robotsTakenList[robotCode]?.minutes_left}
            minutesKickLeft={robotsTakenList[robotCode]?.minutes_kick_left}
          />
        )}
      </>
    );
  };

  const getAisles = () => {
    if (rawRobotStatus && rawRobotStatus['aisles_to_capture'] !== undefined) {
      let result = [];
      if (rawRobotStatus['aisles_to_faces'] !== undefined) {
        rawRobotStatus['aisles_to_capture'].forEach((x) => {
          Object.entries(rawRobotStatus['aisles_to_faces']).forEach(
            ([key, value]) => {
              if (value.includes(x)) {
                if (!result.some((item) => item.aisle === key)) {
                  result.push({
                    aisle: key,
                    linears: value.join(', '),
                    aisle_display: `${key} (${value.join(', ')})`,
                  });
                }
              }
            }
          );
        });
      } else {
        rawRobotStatus['aisles_to_capture'].forEach((item) => {
          let key = item.split('-')[0];
          if (!result.some((i) => i.aisle === key)) {
            result.push({
              aisle: key,
              linears: '',
              aisle_display: key,
            });
          }
        });
      }

      return result;
    }
    return [];
  };

  const handlePlanAisles = (planType) => {
    let aisleInfo = [];
    let aux, cmdObj, confirmTitle, confirmMsg;
    selectedAisles.forEach((aisle) => {
      aux = rawRobotStatus['aisles_to_faces'][aisle].join(', ');
      aisleInfo.push(`${aisle} (${aux})`);
    });
    setHasForm(false);
    if (planType === 'remove') {
      setHasForm(true);
      confirmTitle = `${t(
        'overseer_app.widget.remove_title',
        'Remove aisles from plan'
      )}`;
      confirmMsg = `${t(
        'overseer_app.widget.remove_aisle_confirm',
        'The following aisles will be removed from the session plan'
      )}: \n${aisleInfo.join(', ')}.\n${t(
        'overseer_app.widget.confirm',
        'Do you confirm?'
      )}`;
      cmdObj = { name: 'omit-aisles', command: 'omit' };
    } else if (planType === 'replan') {
      confirmTitle = `${t(
        'overseer_app.widget.replan_title',
        'Replan aisles from plan'
      )}`;
      confirmMsg = `${t(
        'overseer_app.widget.replan_aisle_confirm',
        'The following aisles will be placed at the end of the session plan queue'
      )}: \n${aisleInfo.join(', ')}.\n${t(
        'overseer_app.widget.confirm',
        'Do you confirm?'
      )}`;
      cmdObj = { name: 'replan-aisles', command: 'replan' };
    }
    setConfirmTitle(confirmTitle);
    setConfirmMsg(confirmMsg);
    setCmdObj(cmdObj);
    setOpenConfirm(true);
  };

  const confirmPlanAisles = () => {
    let extraInfo = { aisles: selectedAisles };
    publishMessageCommand(cmdObj, extraInfo);
    postUserActions(cmdObj['name'], robotCode, extraInfo);
    setSelectedAisles([]);
    if (!isEmptyOrUndefined(modalForm.current ?? {}, 'dict')) postActionReason({
      store,
      robot: robotCode,
      aisles: JSON.stringify(selectedAisles),
      ...modalForm.current,
    });
  };

  return (
    <Card
      sx={{
        p: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Grid container>
        <Grid xs={12} sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={0}
            aria-label={'robot-schedule-tab-0'}
          >
            <Tab
              label={title}
              {...a11yProps(0, { textTransform: 'initial' })}
            />
          </Tabs>
          <TabPanel value={0} index={0} sx={{ p: 2 }}>
            <ButtonsWrapper container spacing={1}>
              {checkRobotBlocking()}
              <Grid container>
                <Grid item xs={12}>
                  <MultiSelector
                    canSelectAll={false}
                    options={getAisles()}
                    inputSelected={selectedAisles}
                    setInputSelectedOptions={setSelectedAisles}
                    inputLabel={'Aisles'}
                    objectName={'aisle_display'}
                    objectId={'aisle'}
                    isDisabled={
                      !iHaveRobotTaken ||
                      isLoadingLeaveRobot ||
                      isLoadingRobotBlocking
                    }
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Stack spacing={2} direction="column">
                    <Button
                      variant="contained"
                      onClick={() => handlePlanAisles('remove')}
                      disabled={
                        !iHaveRobotTaken ||
                        isLoadingLeaveRobot ||
                        isLoadingRobotBlocking ||
                        selectedAisles.length === 0
                      }
                    >
                      {t(
                        'overseer_app.widget.remove_aisle_plan',
                        'Remove aisles from plan'
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handlePlanAisles('replan')}
                      disabled={
                        !iHaveRobotTaken ||
                        isLoadingLeaveRobot ||
                        isLoadingRobotBlocking ||
                        selectedAisles.length === 0
                      }
                    >
                      {t('overseer_app.widget.replan_aisle', 'Replan aisles')}
                    </Button>
                  </Stack>
                </Grid>
                <ConfirmDialog
                  open={openConfirm}
                  setOpen={setOpenConfirm}
                  confirmTitle={confirmTitle}
                  confirmMsg={confirmMsg}
                  cmdObj={cmdObj}
                  confirmPlanAisles={confirmPlanAisles}
                >
                  {
                    hasForm &&
                    <CommandForm
                      command={'remove_aisles'}
                      modalForm={modalForm}
                    />
                  }
                </ConfirmDialog>
              </Grid>
            </ButtonsWrapper>
          </TabPanel>
        </Grid>
      </Grid>
    </Card>
  );
}
