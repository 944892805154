import { connect } from 'react-redux';

import { getTasksProgress } from 'core/slices/pipelineMonitoring';
import { tasksProgress, loadingTasksProgress } from 'core/selectors';

import ProgressInfo from '../../../../components/pipelineMonitoring/monitoringGraph/InfoDrawer/Edge/ProgressInfo';

export default connect(
  state => ({
    tasksProgress: tasksProgress(state),
    loadingTasksProgress: loadingTasksProgress(state),
  }),
  {
    getTasksProgress,
  },
)(ProgressInfo);
