import React, { useEffect, useState } from 'react';

import {
  Typography,
  Box,
  MenuItem,
  Select,
  Button,
  Slider,
  Input,
} from '@mui/material';
import moment from 'moment';
import { checkViewOnlyPermision } from '../../../Utils'


function RevisionMetadata(props) {
  const { revision, projectId, serviceName, updateServiceMetadata, navigationOptions } = props;
  const viewId = 'Pipeline'
  const [memory, setMemory] = useState(revision.metadata.memory);
  const [cpu, setCpu] = useState(revision.metadata.cpu);
  const [concurrency, setConcurrency] = useState(revision.metadata.concurrency);
  const [maxInstances, setMaxInstances] = useState(
    revision.metadata['max-instances'],
  );

  useEffect(() => {}, [revision]);

  const handleUpdateRevision = () => {
    updateServiceMetadata({
      service_name: serviceName,
      project_id: projectId,
      changes: {
        concurrency: concurrency,
        'max-instances': maxInstances,
        cpu: cpu.slice(0, 1),
        memory: memory,
      },
      revision_id: revision.revision_id,
    });
  };

  return (
    <Box
      sx={{
        margin: 2,
      }}>
      <Box
        sx={{
          marginY: 3,
          backgroundColor: '#e9e9f1',
          padding: 2,
          borderRadius: 1,
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 2,
          }}>
          <Typography>Traffic: {revision.metadata.traffic}%</Typography>
          <Typography sx={{ color: 'gray' }}>
            {moment(revision.creation).format('MMMM Do YYYY, h:mm:ss')}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            marginY: 2,
          }}>
          <Typography> RAM Memory Limit:</Typography>
          <Select
            disabled={checkViewOnlyPermision(navigationOptions, viewId)}
            sx={{ width: '150px', height: '30px' }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={memory}
            onChange={e => setMemory(e.target.value)}>
            <MenuItem value={'128Mi'}>128 MiB</MenuItem>
            <MenuItem value={'256Mi'}>256 MiB</MenuItem>
            <MenuItem value={'512Mi'}>512 MiB</MenuItem>
            <MenuItem value={'1Gi'}>1 GiB</MenuItem>
            <MenuItem value={'2Gi'}>2 GiB</MenuItem>
            <MenuItem value={'4Gi'}>4 GiB</MenuItem>
            <MenuItem value={'8Gi'}>8 GiB</MenuItem>
            <MenuItem value={'16Gi'}>16 GiB</MenuItem>
          </Select>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            marginY: 2,
          }}>
          <Typography> CPU Limit:</Typography>
          <Select
            disabled={checkViewOnlyPermision(navigationOptions, viewId)}
            sx={{ width: '150px', height: '30px' }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={cpu}
            onChange={e => setCpu(e.target.value)}>
            <MenuItem value={'1000m'}>1 CPU</MenuItem>
            <MenuItem value={'2000m'}>2 CPU</MenuItem>
            <MenuItem value={'4000m'}>4 CPU</MenuItem>
            <MenuItem value={'6000m'}>6 CPU</MenuItem>
            <MenuItem value={'8000m'}>8 CPU</MenuItem>
          </Select>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 3,
            alignItems: 'center',
            marginRight: 2,
            marginY: 2,
          }}>
          <Typography sx={{ width: 'fit-content' }}> Max instances:</Typography>
          <Input
            disabled={checkViewOnlyPermision(navigationOptions, viewId)}
            value={maxInstances}
            onChange={e => setMaxInstances(parseInt(e.target.value))}
            sx={{ width: '60px' }}
            inputProps={{
              step: 1,
              min: 1,
              max: 1000,
              type: 'number',
            }}
          />
          <Slider
            disabled={checkViewOnlyPermision(navigationOptions, viewId)}
            sx={{ width: '200px' }}
            value={maxInstances}
            min={1}
            max={1000}
            onChange={(e, newValue) => setMaxInstances(parseInt(newValue))}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: 3,
            alignItems: 'center',
            marginRight: 2,
            marginY: 2,
            justifyContent: 'space-between',
          }}>
          <Typography>Concurrency:</Typography>
          <Input
            disabled={checkViewOnlyPermision(navigationOptions, viewId)}
            value={concurrency}
            onChange={e => setConcurrency(parseInt(e.target.value))}
            sx={{ width: '60px' }}
            inputProps={{
              step: 1,
              min: 1,
              max: 100,
              type: 'number',
            }}
          />
          <Slider
            disabled={checkViewOnlyPermision(navigationOptions, viewId)}
            sx={{ width: '200px' }}
            value={concurrency}
            min={1}
            max={100}
            onChange={(e, newValue) => setConcurrency(parseInt(newValue))}
          />
        </Box>
        <Button
          disabled={checkViewOnlyPermision(navigationOptions, viewId)}
          variant="contained"
          sx={{ height: '30px', width: '100%' }}
          onClick={() =>
            window.confirm(
              `Are you sure you want to update ${serviceName} metadata?
              \ Concurrency: ${concurrency} 
              \ Max-instances: ${maxInstances}  
              \ Memory: ${memory} 
              \ CPU: ${cpu} `,
            )
              ? handleUpdateRevision()
              : null
          }>
          Update service
        </Button>
      </Box>
    </Box>
  );
}

export default RevisionMetadata;
