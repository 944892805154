import { sortArray } from "core/utils/parsers";

export const NAVIGATION_STATUS = {
  NAVIGATING: "NAVIGATING",
  PROCESSING: "PROCESSING",
  PLANNED: "PLANNED",
  FINISHED: "FINISHED",
  CANCELLED: "CANCELLED",
};

export function prettyName(name) {
  if (name) {
    return titleCase(name.replace(/_/g, " "));
  }
  return name;
}

export function formatDate(date) {
  const currentMonth = date.getMonth() + 1;
  const currentDay = date.getDate();
  const month = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
  const day = currentDay + 1 < 10 ? `0${currentDay}` : currentDay;
  return (
    `${date.getFullYear()}-${month}-${day}`
  );
}

/**
 * Orders a DAG topologically, that is, parents always go before their children in the order.
 * @param {Object} graph A dictionary with a list of keys under each key. The graph has to be a DAG. Example: {a:  ['b','c', 2], b:[], c:[2], 2:[b]}
 * @returns {Array} The keys of the graph sorted in topological order
 */
export function topoSort(graph) {
  const sortedGraph = [];
  const visited = {};
  for (const node of Object.keys(graph)) {
    visited[node] = false;
  }
  for (const node of Object.keys(graph)) {
    if (!visited[node]) {
      topoSortUtil(graph, node, visited, sortedGraph);
    }
  }
  return sortedGraph.reverse();
}

/**
 * This function orders the keys in the sortedGraph list
 * @param {Object} graph A dictionary with a list of keys under each keys. The graph has to be a DAG. Example: {a:  ['b','c', 2], b:[], c:[2], 2:[b]}
 * @param {str,  number} node A key in  the dict
 * @param {Object} visited A Dictionary of booleans, that marks which keys were already visited
 * @param {Array} sortedGraph A list to keep the order within recursion, is empty on first call
 */
function topoSortUtil(graph, node, visited, sortedGraph) {
  visited[node] = true;
  if (graph[node] !== undefined) {
    for (const child of graph[node]) {
      if (!visited[child]) {
        topoSortUtil(graph, child, visited, sortedGraph);
      }
    }
  }
  sortedGraph.push(parseInt(node));
}

/**
 * Gets the sorted process flow for an object id
 * @param {int} objectId the id of an object
 * @param {dict} processesData
 * @returns {array} An array with the process keys (ids) for the requested object id, sorted in topological order
 */
export function getSortedFlowForObjectId(objectId, processesData) {
  const objectIdFlow = {};
  if (Object.keys(processesData).length === 0) {
    return [];
  }
  // make a list
  for (const [processId, process] of Object.entries(processesData)) {
    if (
      process["object_id"] === objectId &&
      process["granularity"] !== "session"
    ) {
      objectIdFlow[processId] = [];
      for (const toProcess of process.children) {
        const toProcessId = toProcess.process_id;
        if (
          processesData[toProcessId]["object_id"] === objectId &&
          processesData[toProcessId]["granularity"] !== "session"
        ) {
          objectIdFlow[processId].push(toProcessId);
        }
      }
    }
  }

  return topoSort(objectIdFlow);
}

/**
 *
 * @param {dict} processesData
 * @param {int} objectId
 * @returns {int or string} The root key of the flow
 */
export function getRootsForObjectId(processesData, objectId) {
  const objectProcessesData = Object.fromEntries(
    Object.entries(processesData).filter(([processId, process]) => {
      return process["object_id"] === objectId;
    })
  );
  const roots = new Set(
    Object.keys(objectProcessesData).map((key) => parseInt(key))
  );
  for (const [, process] of Object.entries(objectProcessesData)) {
    for (const childProcess of process.children) {
      roots.delete(childProcess.process_id);
    }
  }
  return Array.from(roots);
}

/**
 *
 * @param {str} str
 * @returns {str} the string with all its first letters capitalize.
 * Example titleCase('helLo how ARE you') => 'Hello How Are You'
 */
export function titleCase(str) {
  let splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

export function getAllChainsFromStoreData(storeData) {
  const allChains = new Set();
  for (const [storeId, storeRow] of Object.entries(storeData)) {
    allChains.add(storeRow.chain_name);
  }
  return sortArray(Array.from(allChains));
}

export function checkViewOnlyPermision(navigationOptions, viewId) {
  let view = navigationOptions.find(
    (element) => element.description === viewId
  );
  if (view) return view.view_only;
  return true;
}
