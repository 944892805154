import { connect } from 'react-redux';
import ActionWidget from '../../../components/widgets/ActionWidget';

import { getActionHistory, isLoadingActionHistory, isStoreMapInfoLoading, getFeaturePermission } from 'core/selectors';
import { getActionsHistory } from 'core/slices/actionWidget';
import { checkFeaturePermission } from 'core/slices/backend';

export default connect(state => ({
  isStoreMapInfoLoading: isStoreMapInfoLoading(state),
  isLoadingActionHistory: isLoadingActionHistory(state),
  actionsHistory: getActionHistory(state),
  featurePermission: getFeaturePermission(state),
}),
  {
    checkFeaturePermission,
    getActionsHistory,
  },)(ActionWidget);
