import { connect } from "react-redux";

import { addAlertPolicy } from "core/slices/alertPolicies";

import {} from "core/selectors";

import Revisions from "../../../components/pipelineMonitoring/monitoringGraph/InfoDrawer/Revisions";

export default connect((state) => ({}), {
  addAlertPolicy,
})(Revisions);
