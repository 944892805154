import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  CircularProgress,
  Input,
  Slider,
  Divider,
} from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { checkViewOnlyPermision } from '../../../Utils'

function TrafficInfo(props) {
  const {
    node,
    getServiceMetadata,
    updateServiceTraffic,
    getVertexMetadata,
    updateVertexTraffic,
    navigationOptions
  } = props;
  const viewId = 'Pipeline'
  const { session, date } = useParams();
  const [traffic, setTraffic] = useState([]);
  const [loadingTraffic, setLoadingTraffic] = useState(false);

  useEffect(() => {
    getTraffic();
    return () => {
      setTraffic([]);
      setLoadingTraffic(false);
    };
  }, []);

  const getTraffic = async () => {
    try {
      // Vertex Services
      if (node.is_vertex) {
        setLoadingTraffic(true);
        const metadata = await getVertexMetadata({
          process_id: node.process_id,
          chain: session.split('-')[1].replace(/[^a-zA-Z]+/g, ''),
        });

        const aux_traffic = [];
        metadata.deployedModels.forEach(model => {
          aux_traffic.push({
            id: model.id,
            traffic: metadata.trafficSplit[model.id],
            creation: model.createTime,
            metadata: model.dedicatedResources,
          });
        });
        aux_traffic.sort((a, b) => new Date(b.creation) - new Date(a.creation));
        setTraffic(aux_traffic);
        setLoadingTraffic(false);
      } else {
        // Cloud run Services
        setLoadingTraffic(true);
        const metadata = await getServiceMetadata({
          service_name: node.service_name,
          project_id: node.project_id,
        });
        const aux_traffic = [];
        metadata.revisions.forEach(rev =>
          aux_traffic.push({
            id: rev.revision_id,
            traffic: rev.metadata.traffic,
            creation: rev.creation,
            metadata: rev.metadata,
          }),
        );
        setTraffic(aux_traffic);
        setLoadingTraffic(false);
      }
    } catch (error) {
      setLoadingTraffic(false);
      setTraffic(null);
    }
  };

  const handleTrafficChange = (revision_id, new_value) => {
    const new_traffic = traffic.map(rev => {
      if (rev.id === revision_id) {
        rev.traffic = new_value;
        return rev;
      }
      return rev;
    });
    setTraffic(new_traffic);
  };

  const handleUpdateTraffic = async () => {
    if (node.is_vertex) {
      // Update Vertex Traffic
      setLoadingTraffic(true);
      const new_traffic = {};
      traffic.forEach(model => {
        if (model.traffic > 0) {
          new_traffic[model.id.toString()] = model.traffic;
        }
      });
      const res = await updateVertexTraffic({
        process_id: node.process_id,
        chain: session.split('-')[1].replace(/[^a-zA-Z]+/g, ''),
        traffic: new_traffic,
      });
      const aux_traffic = [];

      res.deployedModels.forEach(model => {
        aux_traffic.push({
          id: model.id,
          traffic: res.trafficSplit[model.id],
          creation: model.createTime,
          metadata: model.dedicatedResources,
        });
      });
      aux_traffic.sort((a, b) => new Date(b.creation) - new Date(a.creation));
      setTraffic(aux_traffic);
      setLoadingTraffic(false);
    } else {
      // Update Cloud Run Traffic
      setLoadingTraffic(true);
      const new_traffic = traffic.map(rev => {
        return {
          revision_id: rev.id,
          percent: rev.traffic,
          metadata: rev.metadata,
        };
      });

      const res = await updateServiceTraffic({
        service_name: node.service_name,
        project_id: node.project_id,
        traffic: new_traffic,
      });
      const aux_traffic = [];
      res.revisions.forEach(rev =>
        aux_traffic.push({
          id: rev.revision_id,
          traffic: rev.metadata.traffic,
          creation: rev.creation,
          metadata: rev.metadata,
        }),
      );
      setTraffic(aux_traffic);
      setLoadingTraffic(false);
    }
  };

  const checkPercentage = () => {
    let count = 0;
    traffic.forEach(rev => {
      count += rev.traffic;
    });
    if (count === 100) {
      return true;
    }
    return false;
  };

  if (!traffic) {
    return (
      <Box>
        <Typography
          sx={{
            margin: 1,
            color: 'white',
            fontWeight: 'bold',
            fontSize: 24,
            marginX: 3,
          }}>
          {node.service_name}
        </Typography>

        <Typography
          sx={{
            color: 'lightgrey',
            fontSize: 16,
            marginX: 3,
            marginBottom: 1,
          }}>
          {node.display_name}
          {node.description ? ` - ${node.description}` : null}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 1,
            paddingY: 2,
          }}>
          <Typography
            sx={{
              color: '#7c909b',
              fontSize: 18,
              fontWidth: 'bold',
            }}>
            No traffic information available for this service...
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      <Typography
        sx={{
          margin: 1,
          color: 'white',
          fontWeight: 'bold',
          fontSize: 24,
          marginX: 3,
        }}>
        {node.service_name}
      </Typography>

      <Typography
        sx={{ color: 'lightgrey', fontSize: 16, marginX: 3, marginBottom: 1 }}>
        {node.display_name}
        {node.description ? ` - ${node.description}` : null}
      </Typography>
      {loadingTraffic ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 1,
            paddingY: 2,
          }}>
          <CircularProgress sx={{ color: '#7c909b' }} />
          <Typography
            sx={{ color: '#7c909b', fontSize: 18, fontWidth: 'bold' }}>
            Loading data...
          </Typography>
        </Box>
      ) : node.is_vertex === false ||
        node.is_vertex === null ||
        node.is_vertex === undefined ? (
        <Box
          sx={{
            padding: 2,
            borderRadius: 1,
            margin: 2,
            backgroundColor: '#e9e9f1',
          }}>
          {traffic?.map((rev, idx) => {
            return (
              <Box key={idx} sx={{ marginY: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {rev.id.split('-').slice(-2).join('-')}
                  </Typography>
                  <Typography sx={{ color: 'gray' }}>
                    {' '}
                    {moment(rev.creation).format('MMMM Do YYYY, h:mm:ss')}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                  }}>
                  <Input
                    disabled={checkViewOnlyPermision(navigationOptions, viewId)}
                    value={rev.traffic}
                    onChange={e =>
                      handleTrafficChange(rev.id, parseInt(e.target.value))
                    }
                    sx={{ width: '60px' }}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 100,
                      type: 'number',
                    }}
                  />
                  <Slider
                    disabled={checkViewOnlyPermision(navigationOptions, viewId)}
                    sx={{ width: '300px' }}
                    value={rev.traffic}
                    min={0}
                    max={100}
                    onChange={(e, newValue) =>
                      handleTrafficChange(rev.id, parseInt(newValue))
                    }
                  />
                </Box>
                <Box sx={{ marginY: 1, marginX: 4 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Typography sx={{ color: 'gray', fontSize: 12 }}>
                      RAM: {rev.metadata.memory}
                    </Typography>
                    <Typography sx={{ color: 'gray', fontSize: 12 }}>
                      Concurrency: {rev.metadata.concurrency}
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Typography sx={{ color: 'gray', fontSize: 12 }}>
                      CPU: {rev.metadata.cpu}
                    </Typography>
                    <Typography sx={{ color: 'gray', fontSize: 12 }}>
                      Max instances: {rev.metadata['max-instances']}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ marginTop: 2 }} />
              </Box>
            );
          })}
          <Button
            disabled={!checkPercentage() || checkViewOnlyPermision(navigationOptions, viewId)}
            variant="contained"
            sx={{ height: '30px', width: '100%' }}
            onClick={() =>
              window.confirm(
                `Are you sure you want to update ${node.service_name} traffic?
              `,
              )
                ? handleUpdateTraffic()
                : null
            }>
            Update Traffic
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            padding: 2,
            borderRadius: 1,
            margin: 2,
            backgroundColor: '#e9e9f1',
          }}>
          {traffic?.map((model, idx) => {
            return (
              <Box key={idx} sx={{ marginY: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {model.id}
                  </Typography>
                  <Typography sx={{ color: 'gray' }}>
                    {' '}
                    {moment(model.creation).format('MMMM Do YYYY, h:mm:ss')}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                  }}>
                  <Input
                    value={model.traffic}
                    onChange={e =>
                      handleTrafficChange(model.id, parseInt(e.target.value))
                    }
                    sx={{ width: '60px' }}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 100,
                      type: 'number',
                    }}
                  />
                  <Slider
                    sx={{ width: '300px' }}
                    value={model.traffic}
                    min={0}
                    max={100}
                    onChange={(e, newValue) =>
                      handleTrafficChange(model.id, parseInt(newValue))
                    }
                  />
                </Box>
                <Box sx={{ marginY: 1, marginX: 4 }}>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ color: 'gray', fontSize: 12 }}>
                      Machine Type: {model.metadata.machineSpec.machineType}
                    </Typography>

                    <Typography sx={{ color: 'gray', fontSize: 12 }}>
                      Min Replica Count: {model.metadata.minReplicaCount}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ color: 'gray', fontSize: 12 }}>
                      GPU: {model.metadata.machineSpec?.acceleratorType}
                    </Typography>
                    <Typography sx={{ color: 'gray', fontSize: 12 }}>
                      Max Replica Count: {model.metadata.maxReplicaCount}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ marginTop: 2 }} />
              </Box>
            );
          })}
          <Button
            disabled={!checkPercentage() || checkViewOnlyPermision(navigationOptions, viewId)}
            variant="contained"
            sx={{ height: '30px', width: '100%' }}
            onClick={() =>
              window.confirm(
                `Are you sure you want to update ${node.service_name} traffic?
              `,
              )
                ? handleUpdateTraffic()
                : null
            }>
            Update Traffic
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default TrafficInfo;
