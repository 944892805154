import { Badge, Box, IconButton, LinearProgress, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Suspense, useContext, useEffect, useState } from "react";
import { RobotViewContext } from '../../context/RobotView';
import { transformPixels2Robot } from '../Utils';
import { handleCanvasClickDefault, handleCanvasHoverDefault, clearCanvas, drawVirtualBorder, drawRobotPose, drawLaserHits, rotateAroundRobot, drawPath } from '../utils/canvasFunctions'
import useCanvas from './useCanvas';

import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { useTranslation } from 'react-i18next';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const getMousePos = (canvas, event) => {
  let rect = canvas.getBoundingClientRect();
  return {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top
  }
}


export default function StoreCanvas(props) {
  const {
    info,
    width = 600, height = 600,
    handleCanvasClick = handleCanvasClickDefault, handleCanvasHover = handleCanvasHoverDefault,
    navInfo,
    isFollowingRobot,
    createNavPath,
    pathPoints,
    setPathPoints,
    cleanPath,
    cleanCanvas,
    setCleanCanvas,
    waitingResponse,
    relocatingAngle,
    setRelocatingAngle,
    isRelocating,
    relocatingPose,
    setRelocatingPose,
    isAutonomousMapping,
    setAutonomousMappingForm,
    isRelocateOpen
  } = props;

  const { t } = useTranslation();

  // Context
  const {
    virtualBorders,
    setVirtualBorders,
    isLinesLoading,
  } = useContext(RobotViewContext);

  // States
  const canvasWidth = useState(width)[0];
  const canvasHeight = useState(height)[0];

  const [relocatingLaserHits, setRelocatingLaserHits] = useState([]);
  const [initiate, setInitiate] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [isAngling, setIsAngling] = useState(false);
  const [isRemoveLineActive, setIsRemoveLineActive] = useState(false);
  const [deletedVirtualBordersId, setDeletedVirtualBordersId] = useState([]);

  // Hooks const
  const [canvasRef, canvasDraw] = useCanvas();
  const [canvasLaserRef, canvasLaserDraw] = useCanvas();
  const [canvasLocalNavRef, canvasLocalNavDraw] = useCanvas();
  const [canvasGlobalNavRef, canvasGlobalNavDraw] = useCanvas();
  const [canvasCustomPathRef, canvasCustomPathDraw] = useCanvas();
  const [canvasAutonomousMappingRef, canvasAutonomousMappingDraw] = useCanvas();
  const [canvasAutonomousMappingAnimationRef, canvasAutonomousMappingAnimationDraw] = useCanvas();


  useEffect(() => {
    if (!isRelocateOpen) {
      setIsDragging(false);
      setIsAngling(false);
    }
  }, [isRelocateOpen])
  
  // Handlers
  function scaleMousePosition(canvas, evt) {
    const rect = canvas.getBoundingClientRect(); // Abs. size of element
    const scaleX = canvas.width / rect.width;    // Relationship bitmap vs. element for x
    const scaleY = canvas.height / rect.height;  // Relationship bitmap vs. element for y

    return {
      x: (evt.clientX - rect.left) * scaleX,   // Scale mouse coordinates after they have
      y: (evt.clientY - rect.top) * scaleY     // been adjusted to be relative to element
    }
  }

  const handleAutonomousMappingForm = (virtualBorders) => {
    const newVirtualBorders = virtualBorders.map(border => {
      const newInit = transformPixels2Robot(border.pxInit.x, border.pxInit.y, info.origin, info.resolution, info.height);
      const newEnd = transformPixels2Robot(border.pxEnd.x, border.pxEnd.y, info.origin, info.resolution, info.height);
      return { "cmd": "add", "line": [newInit, newEnd] };
    });
    const deletedVirtualBorders = deletedVirtualBordersId.map(id => { return { "cmd": "rm", "id": id }; });
    setAutonomousMappingForm({
      newVirtualBorders,
      deletedVirtualBorders
    });
  }

  const isTouchingLine = (point, line) => {
    const x1 = line[0].x;
    const y1 = line[0].y;
    const x2 = line[1].x;
    const y2 = line[1].y;
    const m = (y2 - y1) / (x2 - x1);
    const b = y1 - m * x1;
    const distance = Math.abs(m * point.x - point.y + b) / Math.sqrt(m * m + 1);

    // check if the point is inside the line segment
    const minX = Math.min(x1, x2);
    const maxX = Math.max(x1, x2);
    const minY = Math.min(y1, y2);
    const maxY = Math.max(y1, y2);
    const isInside = (point.x >= minX && point.x <= maxX && point.y >= minY && point.y <= maxY);
    return isInside && distance < 5;
  }

  const mouseDown = (event) => {
    if (isAutonomousMapping) {
      if (isRemoveLineActive) {
        // remove line from virtualBorders by checking if the mouse is over the line
        const coords = scaleMousePosition(canvasAutonomousMappingRef.current, event);
        const newVirtualBorders = [...virtualBorders];
        for (let i = 0; i < newVirtualBorders.length; i++) {
          const border = newVirtualBorders[i];
          const pxInit = border.pxInit;
          const pxEnd = border.pxEnd;
          if (pxEnd) {
            const line = [pxInit, pxEnd];
            if (isTouchingLine(coords, line)) {
              const targetLine = newVirtualBorders.splice(i, 1)[0];
              if (targetLine?.id) setDeletedVirtualBordersId([...deletedVirtualBordersId, targetLine.id]);
              setVirtualBorders(newVirtualBorders);
              handleAutonomousMappingForm(newVirtualBorders);
              canvasAutonomousMappingAnimationDraw(clearCanvas, [canvasWidth, canvasHeight]);
              break;
            }
          }
        }
      } else {
        if (virtualBorders.length === 0) {
          const coords = scaleMousePosition(canvasAutonomousMappingRef.current, event);
          canvasAutonomousMappingAnimationDraw(drawVirtualBorder, [coords, canvasWidth, canvasHeight, 'point']);
          setVirtualBorders([{ pxInit: coords }]);
        } else {
          const pxEnd = virtualBorders[virtualBorders.length - 1]?.pxEnd;
          if (pxEnd) {
            const coords = scaleMousePosition(canvasAutonomousMappingRef.current, event);
            setVirtualBorders([...virtualBorders, { pxInit: coords }]);
          } else {
            const coords = scaleMousePosition(canvasAutonomousMappingRef.current, event);
            const newVirtualBorders = [...virtualBorders];
            newVirtualBorders[newVirtualBorders.length - 1]['pxEnd'] = coords;
            setVirtualBorders(newVirtualBorders);
            handleAutonomousMappingForm(newVirtualBorders);
            canvasAutonomousMappingAnimationDraw(drawVirtualBorder, [coords, canvasWidth, canvasHeight, 'point']);
          }
        }
      }
    } else {
      if (!isRelocating) return;
      if (isDragging) {
        setIsDragging(false);
        setIsAngling(true);
        return;
      }
      if (isAngling) {
        setIsAngling(false);
        return;
      }
      setIsDragging(true);
    }
  }

  const handleUndoLastLine = () => {
    if (virtualBorders.length > 0) {
      canvasAutonomousMappingAnimationDraw(clearCanvas, [canvasWidth, canvasHeight])
      setVirtualBorders(virtualBorders.slice(0, -1));
    }
  }

  const handleRemoveLine = () => {
    setIsRemoveLineActive(!isRemoveLineActive);
  }

  const handleAutonomousMappingMove = (event) => {
    if (isAutonomousMapping) {
      const pxInit = virtualBorders[virtualBorders.length - 1]?.pxInit;
      if (pxInit) {
        const coords = { 'pxEnd': scaleMousePosition(canvasAutonomousMappingRef.current, event), 'pxInit': pxInit }
        canvasAutonomousMappingAnimationDraw(drawVirtualBorder, [coords, canvasWidth, canvasHeight, 'dashedLine']);
      }
    }
  }

  const mouseMove = (canvasRef) => (event) => {
    if (isAutonomousMapping) {
      // if last border is not closed, draw line
      if (virtualBorders?.length > 0 && !virtualBorders[virtualBorders.length - 1]?.pxEnd) {
        handleAutonomousMappingMove(event);
      }
    } else {
      if (!isRelocating) return;
      if (isDragging) {
        let mousePos = getMousePos(canvasRef.current, event);
        let newPose = transformPixels2Robot(mousePos.x, mousePos.y, info.origin, info.resolution, info.height);
        newPose[2] = relocatingAngle;
        setRelocatingPose(newPose);
        // get movement offset
        let offset = [
          newPose[0] - navInfo.pose[0],
          newPose[1] - navInfo.pose[1]
        ];
        setRelocatingLaserHits(navInfo.laser_hits.map(position => [
          position[0] + offset[0],
          position[1] + offset[1],
        ]));
        canvasDraw(drawRobotPose, [newPose, info, isFollowingRobot, 'responsive-canvas']);
        let relAngle = 0;
        if (relocatingAngle !== 0) relAngle = relocatingAngle - navInfo.pose[2];
        
        canvasLaserDraw(drawLaserHits, [relocatingLaserHits.map(pos => rotateAroundRobot(pos, relAngle, newPose)), info]);
      }
      else if (isAngling) {
        let mousePos = getMousePos(canvasRef.current, event);
        let realMousePos = transformPixels2Robot(mousePos.x, mousePos.y, info.origin, info.resolution, info.height);
        let angle = Math.atan2(
          realMousePos[1] - relocatingPose[1],
          realMousePos[0] - relocatingPose[0]
        );
        setRelocatingAngle(angle);
      }
    }
  }

  const handleDrawAutonomousMapping = () => {
    canvasAutonomousMappingDraw(clearCanvas, [canvasWidth, canvasHeight])
    virtualBorders.forEach((border) => {
      if (border.pxEnd) {
        canvasAutonomousMappingDraw(drawVirtualBorder, [{ ...border.pxInit }, canvasWidth, canvasHeight, 'point']);
        canvasAutonomousMappingDraw(drawVirtualBorder, [border, canvasWidth, canvasHeight, 'line']);
        canvasAutonomousMappingDraw(drawVirtualBorder, [{ ...border.pxEnd }, canvasWidth, canvasHeight, 'point']);
      } else if (border.pxInit) {
        canvasAutonomousMappingDraw(drawVirtualBorder, [{ ...border.pxInit }, canvasWidth, canvasHeight, 'point']);
      }
    })
  }

  useEffect(() => {
    if (virtualBorders?.length > 0) {
      if (isAutonomousMapping) handleDrawAutonomousMapping();
    } else {
      canvasAutonomousMappingDraw(clearCanvas, [canvasWidth, canvasHeight])
    }
  }, [virtualBorders, canvasWidth, canvasHeight])

  useEffect(() => {
    const canvas = document.getElementById('responsive-canvas');
    const laserCanvas = document.getElementById('canvas-laser');
    const localNavCanvas = document.getElementById('canvas-local-nav');
    const globalNavCanvas = document.getElementById('canvas-global-nav');
    const pathCanvas = document.getElementById('canvas-custom-path');
    const autonomousMappingCanvas = document.getElementById('canvas-autonomous-mapping');
    const autonomousMappingAnimationCanvas = document.getElementById('canvas-autonomous-mapping-animation');
    const canvasArray = [canvas, laserCanvas, localNavCanvas, pathCanvas, globalNavCanvas, autonomousMappingCanvas, autonomousMappingAnimationCanvas];
    canvasArray.forEach(cv => {
      if (cv) {
        cv.width = canvasWidth;
        cv.height = canvasHeight;
      }
    })
  }, [canvasWidth, canvasHeight])

  useEffect(() => {
    if (cleanCanvas.canvasList) {
      cleanCanvas.canvasList.forEach(item => {
        let canvas = document.getElementById(item);
        canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
      })
      setCleanCanvas({});
    }
  }, [cleanCanvas])

  useEffect(() => {
    if (createNavPath) {
      let pathCanvas = document.getElementById('canvas-custom-path');
      pathCanvas.width = canvasWidth;
      pathCanvas.height = canvasHeight;
    }
  }, [createNavPath])

  useEffect(() => {
    if (info.img && canvasRef?.current && navInfo.pose) {
      if (isRelocating && initiate) {
        setRelocatingPose(navInfo.pose);
        setRelocatingLaserHits(navInfo.laser_hits);
        setInitiate(false);
      }
      if (!isRelocating) {
        canvasDraw(drawRobotPose, [navInfo.pose, info, isFollowingRobot, 'responsive-canvas']);
        canvasLaserDraw(drawLaserHits, [navInfo.laser_hits, info]);
      }
      canvasGlobalNavDraw(drawPath, [navInfo.global_nav_points, info, "blue"]);
      canvasLocalNavDraw(drawPath, [navInfo.local_nav_points, info, "green"]);
    }
  }, [info, navInfo])

  useEffect(() => {
    if (isRelocating) {
      let updatedPose = [...relocatingPose];
      updatedPose[2] = relocatingAngle;
      setRelocatingPose(updatedPose);
    }
  }, [relocatingAngle])

  useEffect(() => {
    canvasDraw(drawRobotPose, [relocatingPose, info, isFollowingRobot, 'responsive-canvas']);

    canvasLaserDraw(drawLaserHits, [relocatingLaserHits.map(pos => rotateAroundRobot(pos, relocatingAngle - navInfo.pose[2], relocatingPose)), info])
  }, [relocatingPose]);

  useEffect(() => {
    if (!isAutonomousMapping) {
      handleDrawAutonomousMapping();
      canvasAutonomousMappingAnimationDraw(clearCanvas, [canvasWidth, canvasHeight]);
    }
  }, [isAutonomousMapping])

  const choosePoint = (event, canvasRef) => {
    let path_points = pathPoints;
    if (!waitingResponse) {
      let pos = getMousePos(canvasRef.current, event);
      canvasRef.current.getContext("2d").fillStyle = "#000000";
      canvasRef.current.getContext("2d").beginPath();
      canvasRef.current
        .getContext("2d")
        .arc(pos.x, pos.y, 5, 0, 2 * Math.PI);
      canvasRef.current.getContext("2d").fill();
      // Add to array
      path_points.push(pos);
      setPathPoints(path_points);
    }
  }


  return (
    <>
      <Suspense fallback={<LinearProgress sx={{ width: '98%', mx: 'auto' }} color="secondary" />}>
        <canvas id="responsive-canvas" ref={canvasRef} tabIndex="1" onClick={handleCanvasClick} onMouseMove={mouseMove(canvasRef)} onMouseDown={mouseDown} style={{ borderRadius: "5px", "outline": "none", width: 'auto', position: 'absolute', left: 0, top: 0, zIndex: 2 }} />
        <canvas id="canvas-laser" ref={canvasLaserRef} tabIndex="1" style={{ borderRadius: "5px", "outline": "none", width: 'auto', position: 'absolute', left: 0, top: 0, zIndex: 1 }} />
        <canvas id="canvas-local-nav" ref={canvasLocalNavRef} tabIndex="1" style={{ borderRadius: "5px", "outline": "none", width: 'auto', position: 'absolute', left: 0, top: 0, zIndex: 1 }} />
        <canvas id="canvas-global-nav" ref={canvasGlobalNavRef} tabIndex="1" style={{ borderRadius: "5px", "outline": "none", width: 'auto', position: 'absolute', left: 0, top: 0, zIndex: 1 }} />
        <canvas id="canvas-custom-path" ref={canvasCustomPathRef} onClick={(event) => choosePoint(event, canvasCustomPathRef)} tabIndex="1" style={{ borderRadius: "5px", "outline": "none", width: 'auto', position: 'absolute', left: 0, top: 0, zIndex: createNavPath ? 3 : 1 }} />
        <canvas id="canvas-autonomous-mapping-animation" ref={canvasAutonomousMappingAnimationRef} tabIndex="1" style={{ borderRadius: "5px", "outline": "none", width: 'auto', position: 'absolute', left: 0, top: 0, zIndex: isAutonomousMapping ? 4 : 1 }} onMouseDown={mouseDown} onMouseMove={mouseMove(canvasRef)} />
        <canvas id="canvas-autonomous-mapping" ref={canvasAutonomousMappingRef} tabIndex="1" style={{ borderRadius: "5px", "outline": "none", width: 'auto', position: 'absolute', left: 0, top: 0, zIndex: 1 }} />
      </Suspense>
      {(!isLinesLoading && isAutonomousMapping) ?
        <Box sx={{ display: 'flex', zIndex: 10, bottom: 5, left: 5, top: 5, position: 'sticky' }}>
          <Tooltip title={t('overseer_app.widgets.map_view.remove_last_line', 'Remove last line')}>
            <Badge badgeContent={virtualBorders.length} color="warning"
              sx={{ fontSize: '20px', color: 'white' }}
            >
              <IconButton
                onClick={handleUndoLastLine}
                sx={{ fontSize: '20px', boxShadow: 2, backgroundColor: '#ffffffed', color: theme => theme.palette.primary.main }}
              >
                <IconComponent
                  iconName={"arrow-undo-circle-outline"}
                  style={{ fontSize: '20px' }}
                />
              </IconButton>
            </Badge>
          </Tooltip>

          <StyledToggleButtonGroup
            size="small"
            value={isRemoveLineActive}
            onChange={handleRemoveLine}
            exclusive={true} aria-label="Small sizes"
            sx={{ ml: 2, background: "#ffffffed" }}
          >
            <ToggleButton value={true} key="left">
              <Tooltip title={t('overseer_app.widgets.map_view.remove_line', 'Remove line')}>
                <Box sx={{ height: '20px' }}>
                  <IconComponent
                    iconName={"close-circle-outline"}
                    style={{ fontSize: '20px' }}
                  />
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={false} key="right">
              <Tooltip title={t('overseer_app.widgets.map_view.add_line', 'Add line')}>
                <Box sx={{ height: '20px' }}>
                  <IconComponent
                    iconName={"add-circle-outline"}
                    style={{ fontSize: '20px' }}
                  />
                </Box>
              </Tooltip>
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Box>
        : isLinesLoading ?
          <Box sx={{ display: 'flex', zIndex: 10, bottom: 5, left: 5, position: 'sticky' }}>
            <LinearProgress sx={{ width: '100%', mx: 1, borderRadius: '5px' }} color="warning" />
          </Box>
          : null
      }
    </>
  );
}
