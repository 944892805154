import { connect } from 'react-redux';

import {
	getServicingHistory,
	getChangeMotives,
} from 'core/slices/servicingHistoryWidget';
import {
	loadingServicingHistory,
	servicingHistory,
	loadingChangeMotives,
	changeMotives,
	patchChangeReasonsResponse,
} from 'core/selectors';

import ServicingHistoryWidget from '../../../components/widgets/ServicingHistoryWidget';

export default connect(
	(state) => ({
		servicingHistory: servicingHistory(state),
		loadingServicingHistory: loadingServicingHistory(state),
		changeMotives: changeMotives(state),
		loadingChangeMotives: loadingChangeMotives(state),
		patchChangeReasonsResponse: patchChangeReasonsResponse(state),
	}),
	{
		getServicingHistory,
		getChangeMotives,
	}
)(ServicingHistoryWidget);
