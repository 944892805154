import React from "react";

import {
  Typography,
  Box,
  Divider,
  Modal,
  CircularProgress,
  Stack,
  Chip,
} from "@mui/material";

function NoMetricAvailable(props) {
  const { root } = props;

  return (
    <Box>
      <Typography
        sx={{
          margin: 1,
          color: "white",
          fontWeight: "bold",
          fontSize: 24,
          marginX: 3,
        }}
      >
        {root.data.displayName}
      </Typography>

      <Typography
        sx={{
          color: "lightgrey",
          fontSize: 16,
          marginX: 3,
          marginBottom: 1,
        }}
      >
        {root.data.descriptionLines.length > 0
          ? `${root.data.descriptionLines[0]}`
          : null}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 1,
          paddingY: 2,
        }}
      >
        <Typography
          sx={{
            color: "#7c909b",
            fontSize: 18,
            fontWidth: "bold",
          }}
        >
          No metrics available...
        </Typography>
      </Box>
    </Box>
  );
}

export default NoMetricAvailable;
