import { createReducer } from './utils';
import {
  SNACK_SET,
  PARAMETERS_REQUEST,
  PARAMETERS_AVAILABLE,
  PARAMETERS_UNAVAILABLE,
} from './constants';

const defaultState = {
  loadingParametersRobots: false,
  loadingAllParamsRobot: false,
  loadingUpdateParameters: false,
  parametersRobots: [],
  allParamsRobot: [],
};

export const reducer = createReducer(defaultState, {
  [PARAMETERS_REQUEST]: handleCustomRequest,
  [PARAMETERS_AVAILABLE]: handleCustomAvailable,
  [PARAMETERS_UNAVAILABLE]: handleCustomUnavailable,
});

function handleCustomRequest(state, { payload: { loadingName } }) {
  return {
    ...state,
    [loadingName]: true,
  };
}

function handleCustomAvailable(
  state,
  { payload: { keyState, loadingName, requestInfo, aditionalStates = {} } }
) {
  const x = {
    ...state,
    [loadingName]: false,
    [keyState]: requestInfo,
    ...aditionalStates,
  };
  return x;
}

function handleCustomUnavailable(
  state,
  { payload: { keyState, loadingName } }
) {
  return {
    ...state,
    [keyState]: [],
    [loadingName]: false,
  };
}

export function getParameters() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingParameters';
    const keyState = 'parametersRobots';
    dispatch({ type: PARAMETERS_REQUEST, payload: { loadingName } });
    try {
      const parametersResponse = await dataSource.getParameters();
      const parametersRobots = parametersResponse ? parametersResponse : [];
      dispatch({
        type: PARAMETERS_AVAILABLE,
        payload: { keyState, loadingName, requestInfo: parametersRobots },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: PARAMETERS_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getRobotParametersValues(robotsList) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingParametersValue';
    const keyState = 'parametersValues';
    dispatch({ type: PARAMETERS_REQUEST, payload: { loadingName } });
    try {
      const allParametersResponse = await dataSource.getParameterValues(
        JSON.stringify(robotsList)
      );
      const allParamsRobot = allParametersResponse ? allParametersResponse : [];
      dispatch({
        type: PARAMETERS_AVAILABLE,
        payload: { keyState, loadingName, requestInfo: allParamsRobot },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: PARAMETERS_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getRobotParametersByVersion(robot_uuid, version) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingParametersValue';
    const keyState = 'parametersValues';
    dispatch({ type: PARAMETERS_REQUEST, payload: { loadingName } });
    try {
      const parametersRobotResponse =
        await dataSource.getParametersRobotByVersion(robot_uuid, version);
      const parametersRobot = parametersRobotResponse
        ? parametersRobotResponse
        : [];
      dispatch({
        type: PARAMETERS_AVAILABLE,
        payload: { keyState, loadingName, requestInfo: parametersRobot },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: PARAMETERS_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function postParameterUpdates(parametersObject, newValue) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingUpdateParameters';
    const keyState = 'updateMessageResponse';
    let snack;
    dispatch({ type: PARAMETERS_REQUEST, payload: { loadingName } });
    try {
      const updateResponse = await dataSource.postParameters(
        parametersObject,
        newValue
      );
      const update = updateResponse ? updateResponse : {};
      dispatch({
        type: PARAMETERS_AVAILABLE,
        payload: { requestInfo: update, keyState, loadingName },
      });
      snack = {
        open: true,
        message: 'Robot parameter was updated',
        severity: 'success',
      };
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: PARAMETERS_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      snack = {
        open: true,
        message: 'There was a problem updating the parameter',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}
