import React, { useEffect } from "react";
import { Typography, Box, CircularProgress } from "@mui/material";
import moment from "moment";
import LogRow from "./LogRow";

function LogsInfo(props) {
  const { getServiceLogs, serviceLogs, loadingServiceLogs, node } = props;
  useEffect(() => {
    getServiceLogs(node.project_id, node.service_name, 20);
  }, []);

  return (
    <Box>
      <Typography
        sx={{
          margin: 1,
          color: "white",
          fontWeight: "bold",
          fontSize: 24,
          marginX: 3,
        }}
      >
        {node.service_name}
      </Typography>

      <Typography
        sx={{ color: "lightgrey", fontSize: 16, marginX: 3, marginBottom: 1 }}
      >
        {node.display_name}
        {node.description ? ` - ${node.description}` : null}
      </Typography>
      {loadingServiceLogs ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 1,
            paddingY: 2,
          }}
        >
          <CircularProgress sx={{ color: "#7c909b" }} />
          <Typography
            sx={{ color: "#7c909b", fontSize: 18, fontWidth: "bold" }}
          >
            Loading data...
          </Typography>
        </Box>
      ) : (
        <Box>
          {serviceLogs.logs ? (
            serviceLogs.logs.map((log, idx) => {
              if (log.severity) {
                return <LogRow log={log} />;
              }
            })
          ) : (
            <Typography>No logs to show</Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

export default LogsInfo;
