import { createReducer } from './utils';
import {
  handleRequest,
  handleAvailable,
  handleUnavailable,
} from '../reducerHandlers';
import {
  HARDWARE_ALERTS_REQUEST,
  HARDWARE_ALERTS_AVAILABLE,
  HARDWARE_ALERTS_UNAVAILABLE,
} from './constants';

const defaultState = {
  activeHardwareAlerts: [],
  historicHardwareAlerts: [],
  isLoadingHardwareAlerts: false,
  isLoadingHistoricHardwareAlerts: false,
};

export const reducer = createReducer(defaultState, {
  [HARDWARE_ALERTS_REQUEST]: handleRequest,
  [HARDWARE_ALERTS_AVAILABLE]: handleAvailable,
  [HARDWARE_ALERTS_UNAVAILABLE]: handleUnavailable,
});

export function getActiveHardwareAlerts(robot_id) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingHardwareAlerts';
    const keyState = 'activeHardwareAlerts';
    dispatch({ type: HARDWARE_ALERTS_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getActiveHardwareAlerts(robot_id);
      dispatch({
        type: HARDWARE_ALERTS_AVAILABLE,
        payload: { keyState, loadingName, data: response },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: HARDWARE_ALERTS_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getHistoricHardwareAlerts(robot_id) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingHistoricHardwareAlerts';
    const keyState = 'historicHardwareAlerts';
    dispatch({ type: HARDWARE_ALERTS_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getHardwareAlertsHistory(robot_id);
      dispatch({
        type: HARDWARE_ALERTS_AVAILABLE,
        payload: { keyState, loadingName, data: response },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: HARDWARE_ALERTS_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}
