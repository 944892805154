import { connect } from 'react-redux';
import AlertsTabs from '../../../components/alert/AlertsTabs';

import {
    getAllCreatedAlerts,
    getAvailableAlerts,
    getGroupAlertsRelation,
    getSeverities,
    createNewAlert,
    updateAlert,
    deleteAlert,
    getChartData,
    getCMIpolicies
} from 'core/slices/alertManager';

import {
    getGroupsData,
} from 'core/slices/group';

import {
    getCLIFilters,
} from 'core/slices/cloudLoggingInterface';

import {
    getAlertsCreated,
    isLoadingAlertsCreated,
    getSeverities as getSeveritiesSelector,
    getCLIData,
    isLoadingCLIData,
    getChartDataSelector,
    isLoadingChartDataSelector,
    getCMIPolicy as getCMIPolicySelector,
    isLoadingCMIData as isLoadingCMIDataSelector,

} from 'core/selectors';


export default connect(
    state => ({
        alertsCreated: getAlertsCreated(state),
        isLoadingAlertsCreated: isLoadingAlertsCreated(state),
        severities: getSeveritiesSelector(state),
        CLIData: getCLIData(state),
        isLoadingCLIData: isLoadingCLIData(state),
        dataChart: getChartDataSelector(state),
        isLoadingChartData: isLoadingChartDataSelector(state),
        CMIData : getCMIPolicySelector(state),
        isLoadingCMIData : isLoadingCMIDataSelector(state)
    }),
    {
        getAllCreatedAlerts,
        getAvailableAlerts,
        getGroupAlertsRelation,
        getSeverities,
        getGroupsData,
        createNewAlert,
        updateAlert,
        deleteAlert,
        getCLIFilters,
        getChartData,
        getCMIpolicies
    }
)(AlertsTabs);
