import { connect } from 'react-redux';
import DashboardView from '../../../components/home/DashboardView';
import { checkFeaturePermission, getNavigationStatus } from 'core/slices/backend';
import {
  getMetricsOptionsList,
  getSpecificMetricInfo,
  getChainsList,
  isLoadingMetricData,
  navigationOptions,
  getRealTimeData,
  getEventsList,
  getActiveEventsList,
  getClosedEventsList,
  getFeaturePermission,
  getMonitorMetricsData,
  isLoadingMonitorMetricsData,
  isLoadingNavigationStats,
  navigationStats,
  navigationStatuses,
  isLoadingNavigationStatuses
} from 'core/selectors';
import { getChains } from 'core/slices/backend';
import {
  getMetricsOptions,
  getSpecificMetric,
  getRealTimeMetrics,
  getMonitorMetrics,
  getNavigationStats
} from 'core/slices/dashboardMetrics';
import { getEvents } from 'core/slices/event';

export default connect(
  (state) => ({
    chainsList: getChainsList(state),
    metricsOptions: getMetricsOptionsList(state),
    specificMetricData: getSpecificMetricInfo(state),
    isLoadingMetricData: isLoadingMetricData(state),
    navOptions: navigationOptions(state),
    realTimeData: getRealTimeData(state),
    eventsList: getEventsList(state),
    activeEventsList: getActiveEventsList(state),
    closedEventsList: getClosedEventsList(state),
    featurePermissionResponse: getFeaturePermission(state),
    monitorMetricsData: getMonitorMetricsData(state),
    isLoadingMonitorMetricsData: isLoadingMonitorMetricsData(state),
    isLoadingNavigationStats: isLoadingNavigationStats(state),
    navigationStats: navigationStats(state),
    navigationStatuses: navigationStatuses(state),
    isLoadingNavigationStatuses: isLoadingNavigationStatuses(state),
  }),
  {
    getChains,
    getSpecificMetric,
    getMetricsOptions,
    getRealTimeMetrics,
    getEvents,
    checkFeaturePermission,
    getMonitorMetrics,
    getNavigationStats,
    getNavigationStatus,
  }
)(DashboardView);
