import { connect } from 'react-redux';

import {
  getActiveHardwareAlerts,
  getHistoricHardwareAlerts,
} from 'core/slices/hardwareAlertsWidget';
import {
  getActiveHardwareAlertsList,
  isLoadingHardwareAlertsList,
  getHistoricHardwareAlertsList,
  isLoadingHistoricHardwareAlertsList,
} from 'core/selectors';

import HardwareAlertsWidget from '../../../components/widgets/HardwareAlertsWidget';

export default connect(
  (state) => ({
    hardwareActiveAlerts: getActiveHardwareAlertsList(state),
    loadingHardwareActiveAlerts: isLoadingHardwareAlertsList(state),
    historicHardwareAlerts: getHistoricHardwareAlertsList(state),
    loadingHistoricHardwareAlerts: isLoadingHistoricHardwareAlertsList(state),
  }),
  {
    getActiveHardwareAlerts,
    getHistoricHardwareAlerts,
  }
)(HardwareAlertsWidget);
