import { Typography, Container } from '@material-ui/core';


export default function NotAllowedView() {
  return (
    <Container maxWidth="sm" style={{ marginTop: '20px' }}>
      <Typography variant="h4" component="h1" align="center" color="textSecondary">
        Access denied
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary">
        Sorry, you do not have permission to access this page.
      </Typography>
    </Container>
  );
}