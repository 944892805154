import { createReducer } from './utils';
import {
  handleRequest,
  handleAvailable,
  handleUnavailable,
} from '../reducerHandlers';
import {
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  LOG_OUT_REQUEST,
  LOG_OUT_SUCCESS,
  LOG_OUT_FAILURE,
  SESSION_REQUEST,
  SESSION_AVAILABLE,
  SESSION_UNAVAILABLE,
  SNACK_SET,
} from './constants';

const defaultState = {
  loading: true,
  loadingLogOut: false,
  loggedIn: false,
  errorMessage: null,
  userInfo: {},
  workshiftInfo: [],
};

// reducer

export const reducer = createReducer(defaultState, {
  [SIGN_IN_REQUEST]: handleSignInRequest,
  [SIGN_IN_SUCCESS]: handleSignInSuccess,
  [SIGN_IN_FAILURE]: handleSignInFailure,
  [LOG_OUT_REQUEST]: handleLogOutRequest,
  [LOG_OUT_SUCCESS]: handleLogOutSuccess,
  [LOG_OUT_FAILURE]: handleLogOutFailure,
  [SESSION_REQUEST]: handleRequest,
  [SESSION_AVAILABLE]: handleAvailable,
  [SESSION_UNAVAILABLE]: handleUnavailable,
});

function handleSignInRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleSignInSuccess(state, { payload: { userInfo } }) {
  return {
    ...state,
    userInfo,
    loading: false,
    loggedIn: true,
    errorMessage: null,
  };
}

function handleSignInFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    loggedIn: false,
    errorMessage: error,
    userInfo: {},
  };
}

function handleLogOutRequest(state) {
  return {
    ...state,
    loadingLogOut: true,
  };
}

function handleLogOutSuccess(state) {
  return {
    ...state,
    loading: false,
    loadingLogOut: false,
    loggedIn: false,
    errorMessage: null,
    userInfo: {},
  };
}

function handleLogOutFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingLogOut: false,
    errorMessage: error,
  };
}

// actions

export function checkSession() {
  return async (dispatch, getState, { services: { authSource } }) => {
    dispatch({ type: SIGN_IN_REQUEST });
    try {
      const userInfo = await authSource.checkAuthenticated();
      dispatch({
        type: SIGN_IN_SUCCESS,
        payload: { userInfo },
      });
    } catch (error) {
      dispatch({
        type: SIGN_IN_FAILURE,
        payload: { error },
      });
    }
  };
}

export function logOut() {
  return async (dispatch, getState, { services: { authSource } }) => {
    dispatch({ type: LOG_OUT_REQUEST });
    try {
      await authSource.logOut();
      dispatch({
        type: LOG_OUT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: LOG_OUT_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getUserInfo(username) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    const loadingName = 'loadingGetUserInfo';
    const keyState = 'detailedUserInfo';
    dispatch({ type: SESSION_REQUEST, payload: { loadingName } });
    try {
      const detailedUserInfo = await dataSource.getUserInfo(username);
      dispatch({
        type: SESSION_AVAILABLE,
        payload: { keyState, loadingName, data: detailedUserInfo },
      });
    } catch (error) {
      dispatch({
        type: SESSION_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function finishSession({ session, object_type }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      const response = await dataSource.finishSession({ session, object_type });
      return response;
    } catch (error) {
      console.log(error);
    }
  };
}

export function getWorkshiftInfo(userId) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingWorkshift';
    const keyState = 'workshiftInfo';
    dispatch({ type: SESSION_REQUEST, payload: { loadingName } });
    try {
      const workshiftInfo = await dataSource.getWorkshiftInfo(userId);
      dispatch({
        type: SESSION_AVAILABLE,
        payload: { keyState, loadingName, data: workshiftInfo },
      });
    } catch (error) {
      dispatch({
        type: SESSION_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function postWorkshift(userId, action, events_list = []) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingPostWorkshift';
    const keyState = 'workshiftResponse';
    let snack;
    dispatch({ type: SESSION_REQUEST, payload: { loadingName } });
    try {
      const creationResponse = await dataSource.postWorkshift(
        userId,
        action,
        JSON.stringify(events_list)
      );
      dispatch({
        type: SESSION_AVAILABLE,
        payload: { data: creationResponse, keyState, loadingName },
      });
      snack = {
        open: true,
        message: creationResponse,
      };
      if (creationResponse) {
        snack['severity'] = 'success';
      } else {
        snack['severity'] = 'error';
      }
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: SESSION_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      snack = {
        open: true,
        message: 'Error',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}

export function postMonitorActivity(
  user_id,
  events_list,
  workshiftId,
  activity_type
) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingPostMonitorActivity';
    const keyState = 'monitorActivityResponse';
    dispatch({ type: SESSION_REQUEST, payload: { loadingName } });
    try {
      const creationResponse = await dataSource.postMonitorActivity(
        user_id,
        JSON.stringify(events_list),
        workshiftId,
        activity_type
      );
      dispatch({
        type: SESSION_AVAILABLE,
        payload: { data: creationResponse, keyState, loadingName },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: SESSION_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
    }
  };
}

export function postMonitorStatus(user_id, active_status) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingPostMonitorStatus';
    const keyState = 'monitorStatusResponse';
    let snack;
    dispatch({ type: SESSION_REQUEST, payload: { loadingName } });
    try {
      const creationResponse = await dataSource.postMonitorStatus(
        user_id,
        active_status
      );
      dispatch({
        type: SESSION_AVAILABLE,
        payload: { data: creationResponse, keyState, loadingName },
      });
      snack = {
        open: true,
        message: creationResponse,
      };
      if (creationResponse) {
        snack['severity'] = 'success';
      } else {
        snack['severity'] = 'error';
      }
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: SESSION_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      snack = {
        open: true,
        message: 'Error',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}
