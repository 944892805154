import { Box, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';

//My components
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

export default function Icon(props) {
  const {
    color = 'teal',
    variant = 'filled',
    tooltip = '',
    iconName,
    sx,
    fontSize = '24px',
  } = props;

  const [boxSize, setBoxSize] = React.useState('auto');

  useEffect(() => {
    if (fontSize) {
      const INCREMENT_BOX_FACTOR = 7 / 4;
      const sizeList = fontSize.split('px');
      setBoxSize(
        sizeList.length > 1
          ? `${INCREMENT_BOX_FACTOR * parseInt(sizeList[0])}px`
          : 'auto'
      );
    }
  }, [fontSize]);

  return (
    <Tooltip title={tooltip}>
      <Box
        sx={{
          mx: 1,
          p: 1,
          display: 'flex',
          justifyContent: 'center',
          width: boxSize,
          border: variant === 'outlined' ? '1px solid #e9e9f1' : 'none',
          borderRadius: '5px',
          backgroundColor: (theme) =>
            variant === 'filled'
              ? `${theme.palette[color]?.main}33`
              : 'transparent',
          color: (theme) => theme.palette[color]?.main,
          fontSize: fontSize,
          ...sx,
        }}
      >
        <IconComponent iconName={iconName} />
      </Box>
    </Tooltip>
  );
}
