import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDialog(props) {
  const { open, setOpen, confirmTitle, confirmMsg, confirmPlanAisles, children: childrenForm } = props;

  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    confirmPlanAisles();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{confirmTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {confirmMsg}
        </DialogContentText>
        <Divider sx={{ my: 2 }} />
        {childrenForm}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {t('overseer_app.general.Cancel', 'Cancel')}
        </Button>
        <Button onClick={handleConfirm}>
          {t('overseer_app.general.confirm', 'Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
