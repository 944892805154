import { format } from 'date-fns';

export function hasOnlyOneValueForThisKey(array, keyToCheck) {
  const val = [];

  for (const obj of array) {
    if (Object.prototype.hasOwnProperty.call(obj, keyToCheck)) {
      val.push(obj[keyToCheck]);
      if (val.length > 1) {
        return false;
      }
    }
  }

  return true;
}

export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
}

export function convertToUserTimezone(dateString, userTimezone, targetFormat = 'yyyy-MM-dd HH:mm:ss') {
  if (dateString) {
    // Convert the date string to a Date object
    const date = new Date(dateString.includes('Z') ? dateString : `${dateString}Z`)

    const formattedDate = format(date, targetFormat)

    return formattedDate;
  }
  return null;
}

export function getUserTimezone() {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return userTimezone;
}
