import * as React from 'react';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import InputAdornment from '@mui/material/InputAdornment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { BootstrapDialogTitle } from '../Utils';

import {
  Typography,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

const monthMap = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

export default function AddAisles(props) {
  const {
    open,
    dateCellSelected,
    handleClose,
    selectedDate,
    sessionSelected,
    handleUpdateSessions,
    isLoading,
  } = props;

  const [time, setTime] = useState('');
  const [startAt, setStartAt] = useState('');
  const [aisles, setAisles] = useState('');
  const [dayToScheduler, setDayToScheduler] = useState('');
  const [sessionType, setSessionType] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [weekDayIndex, setWeekDayIndex] = useState(null);

  useEffect(() => {
    if (sessionSelected && sessionSelected.aisles) {
      setAisles(sessionSelected.aisles);
    }
    let aux_date = new Date();
    let split_date = dateCellSelected.date.split('-');

    let startAtSession =
      sessionSelected && sessionSelected.start_local_time
        ? sessionSelected.start_local_time
        : '00:00AM';

    if (startAtSession) {
      const [time, period] = startAtSession.toLowerCase().split(' ');
      const [hours, minutes] = time.split(':').map(Number);

      let adjustedHours = hours;
      if (period === 'pm' && hours !== 12) {
        adjustedHours += 12;
      } else if (period === 'am' && hours === 12) {
        adjustedHours = 0;
      }

      aux_date.setFullYear(split_date[0]);
      aux_date.setMonth(split_date[1] - 1);
      aux_date.setDate(split_date[2]);
      aux_date.setHours(adjustedHours);
      aux_date.setMinutes(minutes);
      setStartAt(aux_date);
    } else {
      setStartAt(new Date());
    }
  }, [sessionSelected]);

  const handleTimeChange = (date) => {
    const options = {
      timeZone: 'UTC', // Use GMT 00:00 timezone
      hour12: false, // Use 24-hour format
      hour: 'numeric', // Display hours
      minute: 'numeric', // Display minutes
    };
    const timeString = date.toLocaleString('en-US', options);
    setTime(timeString);
  };

  const handleAislesChange = (event) => {
    setAisles(event.target.value);
  };

  const handleClick = () => {
    let weekDay =
      typeof weekDayIndex === 'number' ? [weekDayIndex] : weekDayIndex;
    let parsedAisles = aisles.split(',').map((item) => item.replace(/\s/g, ''));
    handleUpdateSessions(
      [sessionSelected.id],
      null,
      null,
      startDate,
      parsedAisles,
      weekDay
    );
  };

  const handleSelectDayToScheduler = (event) => {
    if (event.target.value === 'Periodic') {
      setSessionType(event.target.value);
      setStartDate(dateCellSelected.date);
      setWeekDayIndex(dateCellSelected.index);
    } else {
      setSessionType('Unique');
      setStartDate(event.target.value);
      setWeekDayIndex(null);
    }
    setDayToScheduler(event.target.value);
  };

  const checkSessionType = () => {
    if (sessionSelected && sessionSelected.session_type === 'Exception')
      return true;
    else return false;
  };

  return (
    <div>
      <Dialog open={open} maxWidth={'md'} fullWidth onClose={handleClose}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Change Layout
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              alignItems={'center'}
              display={'flex'}
              justifyContent={'start'}
            >
              <Typography style={{ fontSize: '1.25rrem' }}>
                {dateCellSelected.day + ', ' + dateCellSelected.date}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'end'}
              flexDirection={'column'}
              marginY={'1rem'}
            >
              <Typography sx={{ marginTop: '1rem', color: '#1B242B' }}>
                Store Local Time
              </Typography>
              <MobileTimePicker
                sx={{ width: '75%' }}
                width={'50%%'}
                label={'Start at: '}
                views={['hours', 'minutes']}
                defaultValue={startAt}
                disabled
                date={time}
                onChange={(date) => handleTimeChange(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    helperText="With error"
                    error
                  />
                )}
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <AccessTimeIcon />
                        </InputAdornment>
                      ),
                    },
                    mobilePaper: {
                      sx: {
                        bgcolor: 'paper.main',
                      },
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid
                item
                xs={12}
                md={12}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
              >
                <FormControl fullWidth>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Enter layout "
                    type="text"
                    variant="standard"
                    value={aisles}
                    onChange={handleAislesChange}
                    sx={{ width: '100%' }}
                  />
                </FormControl>
                <Typography sx={{ marginTop: '1rem', color: 'text.primary' }}>
                  Enter the information in the following format: A1, A2, A3,
                  ..., AN Each element should be separated by a comma. For
                  example: B5, C12, D3. This will help us process the data
                  correctly. Thank you!
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} marginTop={'2rem'}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Only for</InputLabel>
                <Select
                  value={dayToScheduler}
                  label="Only for"
                  onChange={handleSelectDayToScheduler}
                >
                  <MenuItem
                    sx={{ bgcolor: 'background.default' }}
                    value={dateCellSelected.date}
                  >
                    {`Only for ${
                      dateCellSelected.day + ', ' + dateCellSelected.date
                    }`}
                  </MenuItem>
                  <MenuItem
                    sx={{ bgcolor: 'background.default' }}
                    disabled={checkSessionType()}
                    value={'Periodic'}
                  >
                    {`Created for every ${dateCellSelected.day}`}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid
              item
              xs={12}
              display={'flex'}
              justifyContent={'end'}
              alignItems={'center'}
            >
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={handleClick}
                sx={{
                  width: '10rem',
                  mb: 3,
                  mr: 3,
                  display: 'flex',
                  alignItems: 'center',
                  background: '#1E76E8',
                  color: 'white',
                  '&:hover': {
                    background: '#1565C0',
                  },
                }}
              >
                Save
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
