import { createReducer } from './utils';
import {
  GET_HISTORIC_ALERTS,
  GET_HISTORIC_ALERTS_SUCCESS,
  GET_HISTORIC_ALERTS_FAILURE,
  GET_ACTIVE_ALERTS,
  GET_ACTIVE_ALERTS_SUCCESS,
  GET_ACTIVE_ALERTS_FAILURE,
} from './constants';

const defaultState = {
  loadingHistoricAlerts: false,
  historicAlerts: [],
  historicAlertsErrorMessage: null,

  loadingActiveAlerts: false,
  activeAlerts: [],
  activeAlertsErrorMessage: null,
};

export const reducer = createReducer(defaultState, {
  [GET_HISTORIC_ALERTS]: handleGetHistoricAlerts,
  [GET_HISTORIC_ALERTS_SUCCESS]: handleGetHistoricAlertsSuccess,
  [GET_HISTORIC_ALERTS_FAILURE]: handleGetHistoricAlertsFailure,

  [GET_ACTIVE_ALERTS]: handleGetActiveAlerts,
  [GET_ACTIVE_ALERTS_SUCCESS]: handleGetActiveAlertsSuccess,
  [GET_ACTIVE_ALERTS_FAILURE]: handleGetActiveAlertsFailure,
});

function handleGetHistoricAlerts(state) {
  return {
    ...state,
    loadingHistoricAlerts: true,
    historicAlertsErrorMessage: null,
  };
}

function handleGetHistoricAlertsSuccess(
  state,
  { payload: { historicAlerts } }
) {
  return {
    ...state,
    historicAlerts,
    loadingHistoricAlerts: false,
    historicAlertsErrorMessage: null,
  };
}

function handleGetHistoricAlertsFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingHistoricAlerts: false,
    historicAlertsErrorMessage: error,
  };
}

export function getHistoricAlerts({ robot_id }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_HISTORIC_ALERTS });
    try {
      const historicAlerts = await dataSource.getHistoricAlerts({
        robot_id,
      });

      dispatch({
        type: GET_HISTORIC_ALERTS_SUCCESS,
        payload: { historicAlerts },
      });
    } catch (error) {
      dispatch({
        type: GET_HISTORIC_ALERTS_FAILURE,
        payload: { error },
      });
    }
  };
}

function handleGetActiveAlerts(state) {
  return {
    ...state,
    loadingActiveAlerts: true,
    activeAlertsErrorMessage: null,
  };
}

function handleGetActiveAlertsSuccess(state, { payload: { activeAlerts } }) {
  return {
    ...state,
    activeAlerts,
    loadingActiveAlerts: false,
    activeAlertsErrorMessage: null,
  };
}

function handleGetActiveAlertsFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingActiveAlerts: false,
    activeAlertsErrorMessage: error,
  };
}

export function getActiveAlerts({ robot_id }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_ACTIVE_ALERTS });
    try {
      const activeAlerts = await dataSource.getActiveAlerts({
        robot_id,
      });

      dispatch({
        type: GET_ACTIVE_ALERTS_SUCCESS,
        payload: { activeAlerts },
      });
    } catch (error) {
      dispatch({
        type: GET_ACTIVE_ALERTS_FAILURE,
        payload: { error },
      });
    }
  };
}
