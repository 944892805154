import React, { useState, useEffect } from 'react';
import { Typography, Grid, Paper, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

// Components
import AlertMonitoring from './AlertMonitoring';

// Icons
import { IconComponent } from "@zippeditoolsjs/zippedi-icons";

export function searchSubstringsInArray(mainString) {
    //Lorens metrics
    const custom_metric = ['capture_time', 'navigation_time', 'upload_time']
    for (const searchString of custom_metric) {
        if (mainString.includes(searchString)) {
            return true;
        }
    }
    return false;
}

export default function AlertCreation(props) {
    const {
        JSONRobotStatus,
        alertsCreated,
        isLoadingAlertsCreated,
        getCLIFilters,
        CLIData,
        isLoadingCLIData,
        getChartData,
        dataChart,
        isLoadingChartData,
        CMIData,
        isLoadingCMIData,
        getCMIpolicies
    } = props;

    //Form to create a new alert
    const [nameAlert, setNameAlert] = useState('');
    const [titleAlert, setTitleAlert] = useState('');
    const [iconAlert, setIconAlert] = useState('');
    const [descriptionAlert, setDescriptionAlert] = useState('');
    //state to store the name of the alert
    const [alert, setAlert] = useState(null);
    const [lastSelectedOption, setLastSelectedOption] = useState(null); //index of the last selected option
    //boolean to show or hide the form to create a new alert
    const [showFormAlert, setShowFormAlert] = useState(false);
    //JSON status
    const [json, setJson] = useState(JSONRobotStatus);
    //info data display chart
    const [infoData, setInfoData] = useState("");
    //robot id
    const [robot_id, setRobotId] = useState("");
    //time ranges
    const [timeFrame, setTimeFrame] = useState({ delta_time: 1, alignment_period: "300s", custom_period: "120s" });

    //array to save the threshold forms
    //const [thresholdForms, setThresholdForms] = useState([]);

    useEffect(() => {
        if (alert) {
            let nameAlert_aux = alert.name ? alert.name : ""
            let descriptionAlert_aux = alert.description ? alert.description : ""
            let titleAlert_aux = alert.title_name ? alert.title_name : ""
            let titleIcon_aux = alert.icon ? alert.icon : ""
            let gcp_project_aux = alert.gcp_project ? alert.gcp_project : ""
            let metric_name_aux = alert.metric_name ? alert.metric_name : ""

            setNameAlert(nameAlert_aux)
            setTitleAlert(titleAlert_aux)
            setDescriptionAlert(descriptionAlert_aux)
            setIconAlert(titleIcon_aux)
            setRobotId(null)
            setTimeFrame({ delta_time: 1, alignment_period: "300s", custom_period: "120s" })

            // Request CLI
            let metric_name_split = alert.metric_name ? alert.metric_name.split('/') : ""
            // Check if is a custom metric
            let isCustomMetric = searchSubstringsInArray(metric_name_aux);
            if (isCustomMetric) {
                getCLIFilters(gcp_project_aux, metric_name_aux)
                getChartData(
                    alert.metric_name,
                    alert.gcp_project,
                    "", // No valueType for custom metrics, so we pass an empty string
                    timeFrame.delta_time,
                    timeFrame.custom_period
                );
                getCMIpolicies(gcp_project_aux, nameAlert_aux)
            }
            else {
                if (metric_name_split.length > 1) {
                    getCLIFilters(gcp_project_aux, metric_name_split[1])
                    getCMIpolicies(gcp_project_aux, nameAlert_aux)
                } else {
                    getCLIFilters(gcp_project_aux, metric_name_aux)
                    getCMIpolicies(gcp_project_aux, nameAlert_aux)
                }
            }
        }
    }, [alert])

    useEffect(() => {
        if (alert && CLIData) {
            if (alert.gcp_project && alert.metric_name && (CLIData.valueType || searchSubstringsInArray(alert.metric_name))) { //case to add custom metrics´
                getChartData(
                    alert.metric_name,
                    alert.gcp_project,
                    CLIData.valueType,
                    timeFrame.delta_time,
                    searchSubstringsInArray(alert.metric_name) ? timeFrame.custom_period : timeFrame.alignment_period
                );
                setInfoData("");
            }
            else {
                setInfoData("No data to display")
            }
        }
    }, [CLIData])

    // Reload on range change
    useEffect(() => {
        if (alert && CLIData) {
            if (alert.gcp_project && alert.metric_name && (CLIData.valueType || searchSubstringsInArray(alert.metric_name))) {
                getChartData(
                    alert.metric_name,
                    alert.gcp_project,
                    CLIData.valueType,
                    timeFrame.delta_time,
                    searchSubstringsInArray(alert.metric_name) ? timeFrame.custom_period : timeFrame.alignment_period
                );
            }
        }
    }, [timeFrame]);

    const handleChangeAlert = (event) => {
        const selectedOption = event.target.value;
        setLastSelectedOption(selectedOption);
    };

    const handleChangeNameAlert = (event) => {
        const value = event.target.value;
        // Remove any leading or trailing whitespace from the input value
        const trimmedValue = value.trim();
        // Update the state only if it doesn't contain empty spaces
        setNameAlert(trimmedValue);
    };

    return (
        <StyledPaper elevation={0} sx={{ p: 3, paddingBottom: '20rem' }}>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Alerts Manager
                    </Typography>

                    <Grid container>
                        <FormControl fullWidth sx={{ marginTop: "1rem" }}>
                            <InputLabel id="demo-simple-select-label">Choose alert</InputLabel>
                            <Select
                                labelId="select-label-alert"
                                id="select-alert"
                                value={lastSelectedOption ? lastSelectedOption : ""}
                                label="Choose alert"
                                onChange={handleChangeAlert}
                                disabled={isLoadingAlertsCreated}
                            >
                                {alertsCreated ?
                                    alertsCreated.sort((a, b) => a.name.localeCompare(b.name))
                                        .map((obj, index) => (
                                            <MenuItem
                                                key={index}
                                                value={index}
                                                onClick={() => {
                                                    if (lastSelectedOption === index) {
                                                        setLastSelectedOption(null);
                                                        setNameAlert('');
                                                        setTitleAlert('');
                                                        setDescriptionAlert('');
                                                        setIconAlert('');
                                                        setRobotId('');
                                                    }
                                                    else {
                                                        setAlert(obj);
                                                    }
                                                }}
                                            >
                                                {obj.name}
                                            </MenuItem>
                                        ))
                                    : null
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    {(showFormAlert || lastSelectedOption) && (
                        <Grid>
                            <Typography
                                variant="h5"
                                sx={{ fontWeight: "bold", marginTop: "1rem", mb: 2 }}
                            >
                                {showFormAlert ? "Create new alert" : "Modify Alert"}
                            </Typography>

                            <Grid
                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        id="alert-name"
                                        name="alert-name"
                                        label="Name"
                                        helperText="Alert name. No whitespace allowed, only '-'"
                                        fullWidth
                                        autoComplete="given-name"
                                        margin="dense"
                                        value={nameAlert}
                                        onChange={handleChangeNameAlert}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        id="title-name"
                                        name="title-name"
                                        label="Title"
                                        helperText="Alert title, to be shown on the first column in the robots' matrix"
                                        fullWidth
                                        margin="dense"
                                        value={titleAlert}
                                        onChange={(event) => setTitleAlert(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        id="description-name"
                                        name="description-name"
                                        label="Description"
                                        helperText={" "}
                                        fullWidth
                                        margin="dense"
                                        value={descriptionAlert}
                                        onChange={(event) => setDescriptionAlert(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            id="icon-name"
                                            name="icon-name"
                                            label="Icon"
                                            helperText={
                                                <span>
                                                    Code of an icon at{" "}
                                                    <Link
                                                        href="https://ionic.io/ionicons"
                                                        target="_blank"
                                                        rel="noopener"
                                                    >
                                                        ionicons
                                                    </Link>{" "}
                                                    to symbolize the alert. Example: " filter "
                                                </span>
                                            }
                                            fullWidth
                                            margin="dense"
                                            value={iconAlert}
                                            onChange={(event) => setIconAlert(event.target.value)}
                                        />
                                    </Grid>

                                    <Grid container item xs={6} sx={{ mt: 3 }} direction={'row'}>
                                        <Grid item xs={4} >
                                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold', mr: 2 }}>
                                                Previsualization:
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={6}>
                                            {iconAlert ? (
                                                <>
                                                    <Grid item sx={{ mr: 2 }}>
                                                        <IconComponent
                                                            iconName={iconAlert}
                                                            style={{ fontSize: "28px", color: "#fe2d38", marginRight: 3 }}
                                                        />
                                                    </Grid>

                                                    <Grid item>
                                                        <IconComponent
                                                            iconName={iconAlert}
                                                            style={{ fontSize: "28px", color: "#ffa400" }}
                                                        />
                                                    </Grid>
                                                </>
                                            ) : (null)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                <Grid item xs={12}>
                    {(showFormAlert || lastSelectedOption) ? (
                        <AlertMonitoring
                            CLIData={CLIData}
                            isLoadingCLIData={isLoadingCLIData}
                            infoData={infoData}
                            dataChart={dataChart}
                            isLoadingChartData={isLoadingChartData}
                            robot_id={robot_id}
                            setRobotId={setRobotId}
                            timeFrame={timeFrame}
                            setTimeFrame={setTimeFrame}
                            CMIData={CMIData}
                            isLoadingCMIData={isLoadingCMIData}
                            isUploadMetric={alert.metric_name === "upload_time"}
                        />
                    ) : null
                    }
                </Grid>
            </Grid >
        </StyledPaper >
    );
}


const StyledPaper = styled(props => {
    return <Paper elevation={1} {...props} />;
})(({ theme }) => {
    return {
        width: '100%',
        background:
            theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
        '& .MuiDataGrid-columnHeaders': {
            backgroundColor:
                theme.palette.type === 'light'
                    ? theme.palette.primary.main
                    : theme.palette.paper.main,
            color: 'white',
        },
        '& .MuiDataGrid-cell': {
            background:
                theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
            color: theme.palette.type === 'light' ? 'black' : 'white',
        },
        '& .MuiDataGrid-columnsPanel': {
            background:
                theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
            color: theme.palette.type === 'light' ? 'black' : 'white',
        },
        '& .MuiDataGrid-menuIconButton': {
            opacity: 1,
            color: 'white',
        },
        '& .MuiDataGrid-sortIcon': {
            color: 'white',
        },
    };
});

/* for future use
    const handleAddThresholdForm = () => {
        setThresholdForms([...thresholdForms, { isOr: false, dataKey: '', storeSelector: '', severity: '', thresholdType: '', thresholdValue: '' }]);
    };

    const removeThreshold = (index) => {
        const updatedForms = [...thresholdForms];
        updatedForms.splice(index, 1);
        setThresholdForms(updatedForms);
    };

    const handleCreateAlert = async () => {
        if (nameAlert !== "" && titleAlert !== "" &&
            iconAlert !== "" && descriptionAlert !== "") {
            await createNewAlert({
                name: nameAlert,
                title: titleAlert,
                icon: iconAlert,
                description: descriptionAlert,
            })
            setNewAlert(false)
            getAllCreatedAlerts()
        }
    }

    const handleUpdateAlert = async () => {
        if (nameAlert !== "" && titleAlert !== "" &&
            iconAlert !== "" && descriptionAlert !== "" && alert?.name !== "") {
            await updateAlert({
                name: nameAlert,
                title: titleAlert,
                icon: iconAlert,
                description: descriptionAlert,
                old_name: alert.name
            })
            setNewAlert(false)
            getAllCreatedAlerts()
        }
    }

    const handleDeleteAlert = async () => {
        if (alert !== null) {
            await deleteAlert(alert.name)
            getAllCreatedAlerts()
        }
    }

    */
