import { createReducer } from './utils';
import {
    handleRequest,
    handleAvailable,
    handleUnavailable,
} from '../reducerHandlers';

import {
    GROUPS_REQUEST,
    GROUPS_AVAILABLE,
    GROUPS_UNAVAILABLE,
    SNACK_SET
} from './constants';

const defaultState = {
    groupData: [],

    loading_groupData: false,
    loading_group: false
};

export const reducer = createReducer(defaultState, {
    [GROUPS_REQUEST]: handleRequest,
    [GROUPS_AVAILABLE]: handleAvailable,
    [GROUPS_UNAVAILABLE]: handleUnavailable,
});

export function getGroupsData() {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'groupData';
        const loadingName = 'loading_groupData';
        dispatch({
            type: GROUPS_REQUEST,
            payload: { loadingName: loadingName },
        });

        try {
            const response = await dataSource.getGroupsData();
            dispatch({
                type: GROUPS_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName,
                },
            });

        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: GROUPS_UNAVAILABLE,
                payload: {
                    keyState: keyState,
                    loadingName: loadingName,
                    defaultState: [],
                },
            });
            const snack = {
                open: true,
                message: 'There was an error getting groups data.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function createGroup(group_name) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: GROUPS_REQUEST, payload: { loadingName: 'loading_group' } });

        try {
            await dataSource.postGroupsData(group_name);
            const snack = {
                open: true,
                message: 'group created',
                severity: 'success',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: GROUPS_UNAVAILABLE,
                payload: { error, loadingName: 'loading_group' },
            });
            const snack = {
                open: true,
                message: 'Error in creating group',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

export function deleteGroup(group_id) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: GROUPS_REQUEST, payload: { loadingName: 'loading_group' } });

        try {
            await dataSource.deleteGroup(group_id);
            const snack = {
                open: true,
                message: 'group deleted',
                severity: 'success',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: GROUPS_UNAVAILABLE,
                payload: { error, loadingName: 'loading_group' },
            });
            const snack = {
                open: true,
                message: 'Error in deleting group',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

export function updateGroup(group_id, group_name) {
    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: GROUPS_REQUEST, payload: { loadingName: 'loading_group' } });

        try {
            await dataSource.updateGroup(group_id, group_name);
            const snack = {
                open: true,
                message: 'group updated',
                severity: 'success',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: GROUPS_UNAVAILABLE,
                payload: { error, loadingName: 'loading_group' },
            });
            const snack = {
                open: true,
                message: 'Error in updating group',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}