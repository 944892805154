import { Box, Grid } from '@mui/material';
import { Legend, DonutChart, Text, Title } from '@zippeditoolsjs/dashboards';

export default function PieChart(props) {
  const {
    chartData,
    category,
    inputCategories,
    inputColors,
    inputIndex,
    size,
    subtitle,
    textOrientation,
    title,
    valueFormatter,
    variant = 'pie',
  } = props;

  return (
    <Grid container item xs={size} padding={2}>
      <Grid item xs={12}>
        <Box className={textOrientation}>
          <Title className="mt-2">{title}</Title>
          <Text className={textOrientation}>{subtitle}</Text>
        </Box>
      </Grid>
      {chartData?.length ? (
        <Grid container>
          <Grid item xs={6}>
            <DonutChart
              data={chartData}
              category={category}
              index={inputIndex}
              valueFormatter={valueFormatter}
              colors={inputColors}
              className="mt-4 h-80"
              variant={variant}
              showLabel={true}
            />
          </Grid>
          <Grid item xs={6}>
            <Legend
              categories={inputCategories}
              colors={inputColors}
              className="max-w-xs"
            />
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Title>No data</Title>
        </Box>
      )}
    </Grid>
  );
}
