import { useEffect, useState, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from '@mui/material';

export default function EventDialog(props) {
  const { openModal, handleCloseModal, selectedEvent } = props;

  const formatElapsedTime = (elapsedTime) => {
    let totalMinutes = elapsedTime.split(' ')[0];
    if (!isNaN(totalMinutes)) totalMinutes = parseInt(totalMinutes);
    else return elapsedTime;
    let hours = Math.trunc(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  return (
    <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
      <DialogTitle>
        {selectedEvent && (
          <Grid container>
            <Grid item xs={4}>
              {selectedEvent && selectedEvent.robot_id}
            </Grid>
            <Grid item container xs={8} spacing={2}>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  <b>Priority:</b> {selectedEvent.priority}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  <b>Status:</b> {selectedEvent.event_status}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  <b>Elapsed Time:</b>{' '}
                  {formatElapsedTime(selectedEvent.elapsed_time)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent>
        {selectedEvent && (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>Creation Time:</b> {selectedEvent.created_at}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>Assigned Monitor:</b>{' '}
                {selectedEvent.assigned_monitor_username}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>Number of Alerts:</b> {selectedEvent.alerts_count}
              </Typography>
            </Grid>
            <TableContainer style={{ maxHeight: '20rem' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Alert ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Severity</TableCell>
                    <TableCell>Created At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedEvent.alerts.map((alert, index) => (
                    <TableRow key={index}>
                      <TableCell>{alert.id}</TableCell>
                      <TableCell>{alert.alert_id}</TableCell>
                      <TableCell>
                        {alert.is_alert_active ? 'active' : 'closed'}
                      </TableCell>
                      <TableCell>{alert.severity_id}</TableCell>
                      <TableCell>{alert.created_at}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}
