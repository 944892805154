import { createReducer } from './utils';
import {
    handleRequest,
    handleAvailable,
    handleUnavailable,
} from '../reducerHandlers';

import {
    CLI_REQUEST,
    CLI_AVAILABLE,
    CLI_UNAVAILABLE,
} from './constants';

const defaultState = {
    cliData: {},
    loading_cliData: false,
};

export const reducer = createReducer(defaultState, {
    [CLI_REQUEST]: handleRequest,
    [CLI_AVAILABLE]: handleAvailable,
    [CLI_UNAVAILABLE]: handleUnavailable,
});

export function getCLIFilters(project_name, metric_name) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'cliData';
        const loadingName = 'loading_cliData';

        dispatch({
            type: CLI_REQUEST,
            payload: { loadingName: loadingName },
        });

        // Default metric for overseer alerts
        const metric_kind = "robot_status";

        try {
            const response = await dataSource.getCLIFilters({
                project_name,
                metric_kind,
                metric_name
            });
            dispatch({
                type: CLI_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName,
                },
            });

        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: CLI_UNAVAILABLE,
                payload: {
                    keyState: keyState,
                    loadingName: loadingName,
                    defaultState: [],
                },
            });
        }
    };
}


