import { connect } from 'react-redux';

import { getRobotStatusHistory } from 'core/slices/robotMonitoring';
import {
  getIsLoadingRobotStatusHistory,
  getRobotStatusHistoryInfo,
} from 'core/selectors';

import NavigationWidget from '../../../components/widgets/NavigationWidget';

export default connect(
  (state) => ({
    isLoadingRobotStatusHistory: getIsLoadingRobotStatusHistory(state),
    robotStatusHistory: getRobotStatusHistoryInfo(state),
  }),
  {
    getRobotStatusHistory,
  }
)(NavigationWidget);
