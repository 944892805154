import {connect} from 'react-redux';
import Scheduler from '../../../components/fleetManagement/Scheduler';
import { getChains, getRobots } from 'core/slices/backend';
import { getRobotsBaldur, postNewSessions, postSessionUpdates } from 'core/slices/fleetControl';
import { getSessionsScheduler, deleteSessions} from 'core/slices/scheduler';
import {
  getChainsList,
  getRobotsList,
  getRobotListBaldur,
  getNewSessionsResponse,
  isLoadingChains,
  isLoadingRobots,
  isLoadingRobotListBaldur,
  getSessions,
  isLoadingSessions,
  isLoadingNewSessions,
  navigationOptions,
  isLoadingSessionUpdate,
  getSessionUpdateResponse,
  getDeleteSessionsResponse,
  isDeletingSessions,
} from 'core/selectors';

export default connect(state => ({
  chainsList: getChainsList(state),
  robotsList: getRobotsList(state),
  robotsListBaldur: getRobotListBaldur(state),
  sessionsList: getSessions(state),
  isLoadingSessions: isLoadingSessions(state),
  newSessionsResponse: getNewSessionsResponse(state),
  isLoadingChains: isLoadingChains(state),
  isLoadingRobots: isLoadingRobots(state),
  isLoadingRobotListBaldur: isLoadingRobotListBaldur(state),
  isLoadingNewSessions: isLoadingNewSessions(state),
  navOptions: navigationOptions(state),
  isLoadingSessionUpdate: isLoadingSessionUpdate(state),
  updateSessionResponse: getSessionUpdateResponse(state),
  isLoadingDeleteSessions: isDeletingSessions(state),
  deleteSessionsResponse: getDeleteSessionsResponse(state),
}),
{
  getChains,
  getRobots,
  getRobotsBaldur,
  getSessionsScheduler,
  postNewSessions,
  postSessionUpdates,
  deleteSessions
},)(Scheduler);