import { useEffect, useState } from 'react';
import { DataGrid } from '@zippeditoolsjs/table';

export default function UserTable(props) {
  const TABLE_COLUMNS = [
    { field: 'active_status', headerName: 'Status', width: 90 },
    {
      field: 'username',
      headerName: 'Monitor',
      width: 200,
    },
    {
      field: 'working_on_it',
      headerName: 'Working on',
      width: 150,
    },
    {
      field: 'active_events',
      headerName: 'Events',
      width: 90,
    },
    {
      field: 'escalation_counts',
      headerName: 'Escalations',
      width: 110,
    },
    {
      field: 'effectiveness',
      headerName: 'Effectiveness',
      width: 110,
      renderCell: (params) => <div>{params.value}%</div>,
    },
    {
      field: 'avg_response_time',
      headerName: 'Avg Response',
      width: 110,
      renderCell: (params) => (
        <div>
          {params.value} {responseUnit}
        </div>
      ),
    },
    {
      field: 'avg_resolution_time',
      headerName: 'Avg Resolution',
      width: 110,
      renderCell: (params) => (
        <div>
          {params.value} {resolutionUnit}
        </div>
      ),
    },
    {
      field: 'avg_close_time',
      headerName: 'Avg Close',
      width: 110,
      renderCell: (params) => (
        <div>
          {params.value} {closeUnit}
        </div>
      ),
    },
  ];
  const { data } = props;

  const [monitorRows, setMonitorRows] = useState([]);
  const [responseUnit, setResponseUnit] = useState(null);
  const [resolutionUnit, setResolutionUnit] = useState(null);
  const [closeUnit, setCloseUnit] = useState(null);

  useEffect(() => {
    if (data?.all_monitors) {
      setMonitorRows(data.all_monitors);
    }
    if (data?.response_time?.unit_of_measurement) {
      setResponseUnit(data.response_time.unit_of_measurement[0]);
    }
    if (data?.resolution_time?.unit_of_measurement) {
      setResolutionUnit(data.resolution_time.unit_of_measurement[0]);
    }
    if (data?.close_time?.unit_of_measurement) {
      setCloseUnit(data.close_time.unit_of_measurement[0]);
    }
  }, [data]);

  return (
    <>
      <DataGrid
        getRowId={(row) => row.user_id}
        rows={monitorRows}
        columns={TABLE_COLUMNS}
        disableRowSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
      />
    </>
  );
}
