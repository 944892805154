import { Box, Card, CardContent, Divider } from '@mui/material';
import { Legend, LineChart, Text, Title } from '@zippeditoolsjs/dashboards';
import ChipGroup from './ChipGroup';

export default function LineChartCard(props) {
  const {
    sx,
    title = 'Cycles',
    subtitle = 'Analysis of the behavior of the cycles',
    lineData,
    inputIndex = 'index',
    inputCategories = ['movements'],
    inputColors = ['teal'],
    filterVariant = 'chip',
    isLoading = false,
    timeFrame,
    setTimeFrame,
  } = props;

  return (
    <Card sx={{ mt: 2, ...sx }}>
      <CardContent>
        <Box className="text-center">
          <Title className="mt-2">{title}</Title>
          <Text className="text-center">{subtitle}</Text>
        </Box>

        <Divider sx={{ my: 2 }} />
        {filterVariant === 'chip' ? (
          <ChipGroup
            disabled={isLoading}
            timeFrame={timeFrame}
            setTimeFrame={setTimeFrame}
            optionTimes={[
              { label: '1 hours', deltaTime: 1 },
              { label: '3 hours', deltaTime: 3 },
              { label: '6 hours', deltaTime: 6 },
              { label: '12 hours', deltaTime: 12 },
              { label: '1 day', deltaTime: 24 },
            ]}
            color="info"
            size="small"
          />
        ) : null}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Legend categories={inputCategories} colors={inputColors} />
        </Box>
        {lineData?.length ? (
          <LineChart
            className="mt-4 h-80"
            data={lineData}
            index={inputIndex}
            categories={inputCategories}
            colors={inputColors}
            showLegend={false}
            yAxisWidth={48}
          />
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Title>No data</Title>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
