import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MultiSelector from '../tools/MultiSelector';
import { TimeField } from '@mui/x-date-pickers/TimeField';

export default function FootageDialog(props) {
  const {
    open,
    handleClose,
    resolutionOptions,
    cameraOptions,
    speedOptions,
    isSpeedOptionsLoading,
    isResolutionOptionsLoading,
  } = props;
  const { t } = useTranslation();
  const handleConfirm = () => {
    const footageForm = {
      date: selectedDate,
      start_time: initTime,
      end_time: endTime,
      speed,
      resolution,
    };
    handleClose(footageForm);
  };
  const minDate = new Date(new Date().setDate(new Date().getDate() - 90));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [initTime, setInitTime] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [endTime, setEndTime] = useState(
    new Date(new Date().setHours(23, 59, 59, 999))
  );
  const [speed, setSpeed] = useState(speedOptions[0]?.name ?? '');
  const [resolution, setResolution] = useState(
    resolutionOptions[0]?.name ?? ''
  );

  // Handlers
  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
    }
  };

  const handleSelectorChange = (setValue) => (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('overseer_app.widget.footageTitle', 'Camera Footage')}
        <Divider />
        <Typography sx={{ fontSize: 'small', pt: 1 }}>
          {t(
            'overseer_app.widget.footageSubTitle',
            'Video rendering is only available while robot is not processing sessions. You will be notified by email when your footage is ready. It might take a few minutes or hours depending on robot’s status.'
          )}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          {/* Date */}
          <Grid xs={6}>
            <Typography sx={{ fontSize: 'small' }}>Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  label={''}
                  value={selectedDate}
                  onChange={handleDateChange}
                  disableFuture
                  minDate={minDate}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          {/* Speed */}
          <Grid xs={6} sx={{ pl: 1 }}>
            <Typography sx={{ fontSize: 'small', pb: 1 }}>
              {t('overseer_app.widget.Speed', 'Speed')}
            </Typography>
            <FormControl fullWidth>
              {isSpeedOptionsLoading ? (
                <LinearProgress />
              ) : (
                <Select
                  labelId="speed-select-label"
                  id="speed-select-label"
                  value={speed}
                  label=""
                  onChange={handleSelectorChange(setSpeed)}
                >
                  {speedOptions.map((option, index) => (
                    <MenuItem key={index} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Grid>
          {/* Start Time */}
          <Grid xs={6} sx={{ pt: 2 }}>
            <Typography sx={{ fontSize: 'small' }}>Start Time</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={['TimeField']}>
                <TimeField
                  value={initTime}
                  onChange={(newValue) => setInitTime(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          {/* Resolution */}
          <Grid xs={6} sx={{ pl: 1, pt: 2 }}>
            <Typography sx={{ fontSize: 'small', pb: 1 }}>
              {t('overseer_app.widget.Resolution', 'Resolution')}
            </Typography>

            <FormControl fullWidth>
              {isResolutionOptionsLoading ? (
                <LinearProgress />
              ) : (
                <Select
                  labelId="resolution-select-label"
                  id="resolution-select-label"
                  value={resolution}
                  label=""
                  onChange={handleSelectorChange(setResolution)}
                >
                  {resolutionOptions.map((option, index) => (
                    <MenuItem key={index} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Grid>
          {/* End Time */}
          <Grid xs={6} sx={{ pt: 2 }}>
            <Typography sx={{ fontSize: 'small' }}>End Time</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={['TimeField']}>
                <TimeField
                  value={endTime}
                  onChange={(newValue) => setEndTime(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          {/* Camera Choice */}
          <Grid xs={6} sx={{ pl: 1, pt: 2 }}>
            <Typography sx={{ fontSize: 'small', pb: 1 }}>
              {t('overseer_app.widget.Cameras', 'Cameras')}
            </Typography>
            <MultiSelector
              options={cameraOptions}
              setInputSelectedOptions={() => {}}
              inputLabel={'All '}
              isDisabled={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose}>Cancel</Button> */}
        <Button onClick={handleConfirm} autoFocus variant="contained">
          {t('overseer_app.general.send', 'Send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
