import { connect } from 'react-redux';

import { getSessionProgress } from 'core/slices/pipelineMonitoring';
import { loadingSessionProgress, sessionProgress } from 'core/selectors';

import ProgressInfo from '../../../../components/pipelineMonitoring/monitoringGraph/InfoDrawer/Node/ProgressInfo';

export default connect(
  state => ({
    sessionProgress: sessionProgress(state),
    loadingSessionProgress: loadingSessionProgress(state),
  }),
  {
    getSessionProgress,
  },
)(ProgressInfo);
