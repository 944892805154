import { Typography } from '@mui/material';
import {useState, useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';

export default function LastRefresh(props) {
  const { lastUpdateTime, timeInterval } = props;

  const [elapsedTime, setElapsedTime] = useState('Just now');

  const { t } = useTranslation();
  
  let lastUpdateTimeRef = useRef();
  
  useEffect(() => {
    lastUpdateTimeRef.current = lastUpdateTime
  }, [lastUpdateTime])

  useEffect(() => {
    const timer = setInterval(() => {
        calculateElapsedTime(lastUpdateTimeRef.current);
    }, timeInterval);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const calculateElapsedTime = (lastUpdateTimeParameter) => {
    let now = new Date();
    let time, response;
    let timeElapsed = Math.floor((now - lastUpdateTimeParameter) / 1000);
    if (timeElapsed > 3600) {
      timeElapsed = Math.floor(timeElapsed / 60);
      time = `${timeElapsed} ${t('overseer_app.general.hours', 'hours')}`;
      response = t('overseer_app.widget.elapsed_time', time, {
        elapsedTime: time,
      });
    } else if (timeElapsed > 60) {
      timeElapsed = Math.floor(timeElapsed / 60);
      time = `${timeElapsed} ${t('overseer_app.general.minutes', 'minutes')}`;
      response = t('overseer_app.widget.elapsed_time', time, {
        elapsedTime: time,
      });
    } else {
      if (timeElapsed < 3)
        response = t('overseer_app.general.just_now', 'Just now');
      else {
        time = `${timeElapsed} ${t('overseer_app.general.seconds', 'seconds')}`;
        response = t('overseer_app.widget.elapsed_time', time, {
          elapsedTime: time,
        });
      }
    }
    
    setElapsedTime(response);
  };

  return (
    <Typography variant="caption">
      {`${t(
        'overseer_app.widget.last_refresh',
        'Last refresh:'
      )} ${elapsedTime}`}
    </Typography>
  );
}
