/**
 * Sort an array of strings
 * @param   {array}   array   The array to sort
 * @returns {array}           The sorted array
 */
export function sortArray(array) {
  return array.sort((a, b) => {
    return a.localeCompare(b);
  });
}

/**
 * Capitalizes the first letter of a given string.
 *
 * @param   {string}    string   The input string to be capitalized.
 * @returns {string}             A new string with the first letter capitalized.
 */
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}