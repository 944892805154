import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function EscalationClickUpDialog(props) {
  const {
    open,
    setOpen,
    comments,
    setComments,
    handleEscalate,
    notification,
    isLoading,
    clickupTeamsList,
  } = props;

  const { t } = useTranslation();

  const [destination, setDestination] = useState(clickupTeamsList[0].name);
  const [mainAlert, setMainAlert] = useState('');

  const handleChange = (event) => {
    setDestination(event.target.value);
  };

  const handleConfirm = () => {
    const payload = {
      robotEventId: notification.robot_event_id,
      destinationTeam: destination,
      comment: comments,
      storeCode: notification.store_code,
      storeName: notification.store_name,
      chainName: notification.chain_name,
      alerts: notification.alerts,
      robotCode: notification.robot_id,
      mainAlert: mainAlert
    };
    handleEscalate(payload);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMainAlertChange = (event) => {
    setMainAlert(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("overseer_app.navbar_notifications.escalate_to_clickup_title", "Escalate Issue to ClickUp")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(
            "overseer_app.navbar_notifications.escalate_to_clickup_description",
            "You are about to escalate an incident to ClickUp. If you are sure, select which team it will be assigned to and attach a message with the problem in question. Once the form is completed, press the \"Escalate\" button to finish."
          )}
        </DialogContentText>
        <Stack spacing={1}>
          <FormControl>
            <RadioGroup row value={destination} onChange={handleChange}>
              {clickupTeamsList.map((item) => (
                <FormControlLabel
                  value={item.name}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="subject-label">
              {t(
                "overseer_app.general.Alert",
                "Alert"
              )}
            </InputLabel>
            <Select
              labelId="subject-label"
              id="subject-select"
              value={mainAlert}
              label={t(
                "overseer_app.general.Alert",
                "Alert"
              )}
              onChange={handleMainAlertChange}
            >
              {notification?.alerts.map(alert => (
                <MenuItem value={alert}>{alert['description_alert']}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="task-comment"
            label="Comments"
            fullWidth
            multiline
            rows={2}
            value={comments}
            onChange={(event) => {
              setComments(event.target.value);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          {t('overseer_app.general.cancel', 'Cancel')}
        </Button>
        <Button onClick={handleConfirm} autoFocus disabled={isLoading}>
          {t('overseer_app.navbar_notifications.escalate', 'Escalate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
