import React, { useState } from 'react';

import { Alert, Box, Link, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';

function SessionAlert(props) {
  const { alertInfo } = props;

  const [detail, setDetail] = useState(false);

  const { t } = useTranslation();

  return (
    <Alert
      sx={{ display: 'flex', alignItems: 'center', marginY: 2 }}
      severity='warning'
      action={
        detail ? (
          <ExpandLessIcon onClick={() => setDetail(!detail)} />
        ) : (
          <ExpandMoreIcon onClick={() => setDetail(!detail)} />
        )
      }
    >
      <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
        {alertInfo.incident.condition.displayName} Alert at {alertInfo.incident.metric.labels.session_id}
      </Typography>
      {detail ? (
        <Box>
          <Typography sx={{ fontSize: 13 }}>
            {`${alertInfo.incident.metric.displayName} at ${alertInfo.incident.metric.labels.session_id} 
        of store ${alertInfo.incident.metric.labels.store_code} (ID: ${alertInfo.incident.metric.labels.store_id}) is above the threshold ${alertInfo.incident.threshold_value} 
        with a value of ${alertInfo.incident.observed_value}`}
          </Typography>
          <Link href={alertInfo.incident.url} underline='always'>
            <Typography sx={{ fontSize: 13 }}>{t('overseer_app.widget.go_incident', 'Go to incident')}</Typography>
          </Link>
        </Box>
      ) : null}
    </Alert>
  );
}

export default SessionAlert;
