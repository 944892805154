import { connect } from 'react-redux';

import { getHistoricAlerts, getActiveAlerts } from 'core/slices/alertsWidget';
import {
  loadingHistoricAlerts,
  historicAlerts,
  activeAlerts,
  loadingActiveAlerts,
} from 'core/selectors';

import AlertsWidget from '../../../components/widgets/AlertsWidget';

export default connect(
  (state) => ({
    historicAlerts: historicAlerts(state),
    loadingHistoricAlerts: loadingHistoricAlerts(state),
    activeAlerts: activeAlerts(state),
    loadingActiveAlerts: loadingActiveAlerts(state),
  }),
  {
    getHistoricAlerts,
    getActiveAlerts,
  }
)(AlertsWidget);
