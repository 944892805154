import React, { useState } from 'react';
import { Button, Grid, TextField, Typography, Paper, InputAdornment, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';

// Components
import GroupTable from './GroupTable';
import CircularLoader from '../../tools/CircularLoader';
import AlertMonitoring from '../AlertMonitoring';

//Icons
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';


export default function CreateGroup(props) {
    const { getGroupsData, createGroup, deleteGroup, updateGroup, groupsDataList, isLoadingGroupData } = props

    const { t } = useTranslation();
    const [groupName, setGroupName] = useState("")

    const createGroupHandle = async (groupName) => {
        await createGroup(groupName)
        getGroupsData()
    }

    return (
        <StyledPaper elevation={0} sx={{ p: 3 }}>
            <Grid container >
                <Grid container direction={"column"} spacing={4}>
                    <Grid container item xs direction={"column"} spacing={2}>
                        <Grid item xs>
                            <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
                                Create Group
                            </Typography>
                        </Grid>

                        <Grid container item xs spacing={2} alignItems="center">
                            <Grid item xs={6}>
                                <TextField fullWidth size="small" label="Group name" variant="outlined"
                                    value={groupName}
                                    onChange={(e) => {
                                        setGroupName(e.target.value);
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setGroupName("")}
                                                    edge="end"
                                                >
                                                    <IconComponent iconName={'close-outline'} style={{ fontSize: 24 }} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs alignSelf="flex-end">
                                <Button fullWidth variant="contained"
                                    justifyContent="center"
                                    alignItems="center"
                                    disabled={groupName === ""}
                                    sx={{ textTransform: 'none', maxWidth: 100, p: 1 }}
                                    startIcon={<IconComponent iconName={'save-outline'} style={{ fontSize: 24 }} />}
                                    onClick={() => [createGroupHandle(groupName), setGroupName("")]}
                                >
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container xs direction={"column"} spacing={2}>
                        <Grid item>
                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                Manage Groups
                            </Typography>
                        </Grid>
                        {isLoadingGroupData ? (
                            <Grid
                                item container
                                direction="column"
                                justifyContent="center"
                                alignItems="center">
                                <CircularLoader size="2em" />
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    {"Loading..."}
                                </Typography>
                            </Grid>
                        ) : (
                            <GroupTable
                                t={t}
                                groupsDataList={groupsDataList}
                                isLoadingGroupData={isLoadingGroupData}
                                getGroupsData={getGroupsData}
                                deleteGroup={deleteGroup}
                                updateGroup={updateGroup}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </StyledPaper>
    )
}

const StyledPaper = styled(props => {
    return <Paper elevation={1} {...props} />;
})(({ theme }) => {
    return {
        width: '100%',
        background:
            theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
        '& .MuiDataGrid-columnHeaders': {
            backgroundColor:
                theme.palette.type === 'light'
                    ? theme.palette.primary.main
                    : theme.palette.paper.main,
            color: 'white',
        },
        '& .MuiDataGrid-cell': {
            background:
                theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
            color: theme.palette.type === 'light' ? 'black' : 'white',
        },
        '& .MuiDataGrid-columnsPanel': {
            background:
                theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
            color: theme.palette.type === 'light' ? 'black' : 'white',
        },
        '& .MuiDataGrid-menuIconButton': {
            opacity: 1,
            color: 'white',
        },
        '& .MuiDataGrid-sortIcon': {
            color: 'white',
        },
    };
});

