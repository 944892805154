import { createReducer } from "./utils";
import {
  GET_REVISIONS,
  GET_REVISIONS_SUCCESS,
  GET_REVISIONS_FAILURE,
  GET_REQUEST_COUNTS,
  GET_REQUEST_COUNTS_SUCCESS,
  GET_REQUEST_COUNTS_FAILURE,
  GET_CONTAINER_INSTANCES,
  GET_CONTAINER_INSTANCES_SUCCESS,
  GET_CONTAINER_INSTANCES_FAILURE,
  GET_REQUEST_LATENCIES,
  GET_REQUEST_LATENCIES_SUCCESS,
  GET_REQUEST_LATENCIES_FAILURE,
  GET_CPU_UTILIZATION,
  GET_CPU_UTILIZATION_SUCCESS,
  GET_CPU_UTILIZATION_FAILURE,
  GET_MEMORY_UTILIZATION,
  GET_MEMORY_UTILIZATION_SUCCESS,
  GET_MEMORY_UTILIZATION_FAILURE,
  GET_VERTEX_RESPONSE_COUNT,
  GET_VERTEX_RESPONSE_COUNT_SUCCESS,
  GET_VERTEX_RESPONSE_COUNT_FAILURE,
  GET_VERTEX_PREDICTION_COUNT,
  GET_VERTEX_PREDICTION_COUNT_SUCCESS,
  GET_VERTEX_PREDICTION_COUNT_FAILURE,
  GET_VERTEX_CPU_UTILIZATION,
  GET_VERTEX_CPU_UTILIZATION_SUCCESS,
  GET_VERTEX_CPU_UTILIZATION_FAILURE,
  GET_VERTEX_LATENCIES,
  GET_VERTEX_LATENCIES_SUCCESS,
  GET_VERTEX_LATENCIES_FAILURE,
  GET_VERTEX_REPLICAS_COUNT,
  GET_VERTEX_REPLICAS_COUNT_SUCCESS,
  GET_VERTEX_REPLICAS_COUNT_FAILURE,
  GET_TASKS_DEPTH,
  GET_TASKS_DEPTH_SUCCESS,
  GET_TASKS_DEPTH_FAILURE,
  GET_TASKS_COUNT,
  GET_TASKS_COUNT_SUCCESS,
  GET_TASKS_COUNT_FAILURE,
  GET_ALERT_POLICIES,
  GET_ALERT_POLICIES_FAILURE,
  GET_ALERT_POLICIES_SUCCESS,
  GET_BATCH_LATENCY,
  GET_BATCH_LATENCY_SUCCESS,
  GET_BATCH_LATENCY_FAILURE,
  GET_JOBS_LATENCIES,
  GET_JOBS_LATENCIES_SUCCESS,
  GET_JOBS_LATENCIES_FAILURE,
} from "./constants";

const defaultState = {
  loadingRevisions: false,
  revisions: {},
  revisionsErrorMessage: null,

  loadingRequestCounts: false,
  requestCounts: {},
  requestCountsErrorMessage: null,

  loadingContainerInstances: false,
  containerInstances: {},
  containerInstancesErrorMessage: null,

  loadingRequestLatencies: false,
  requestLatencies: {},
  requestLatenciesErrorMessage: null,

  loadingCpuUtilization: false,
  cpuUtilization: {},
  cpuUtilizationErrorMessage: null,

  loadingMemoryUtilization: false,
  memoryUtilization: {},
  memoryUtilizationErrorMessage: null,

  loadingVertexResponseCount: false,
  vertexResponseCount: {},
  vertexResponseErrorMessage: null,

  loadingVertexPredictionCount: false,
  vertexPredictionCount: {},
  vertexPredictionErrorMessage: null,

  loadingVertexReplicasCount: false,
  vertexReplicasCount: {},
  vertexReplicasCountErrorMessage: null,

  loadingVertexLatencies: false,
  vertexLatencies: {},
  vertexLatenciesErrorMessage: null,

  loadingVertexCpuUtilization: false,
  vertexCpuUtilization: {},
  vertexCpuUtilizationErrorMessage: null,

  loadingTasksDepth: false,
  tasksDepth: {},
  tasksDepthErrorMessage: null,

  loadingTasksCount: false,
  tasksCount: {},
  tasksCountErrorMessage: null,

  loadingBatchLatency: false,
  batchLatency: {},
  batchLatencyErrorMessage: null,

  loadingJobsLatencies: false,
  jobsLatencies: {},
  jobsLatenciesErrorMessage: null,

  // loadingAlertPolicies: false,
  // alertPolicies: {},
  // alertPoliciesErrorMessage: null,
};

export const reducer = createReducer(defaultState, {
  [GET_REVISIONS]: handleRevisions,
  [GET_REVISIONS_SUCCESS]: handleRevisionsSuccess,
  [GET_REVISIONS_FAILURE]: handleRevisionsFailure,

  [GET_REQUEST_COUNTS]: handleRequestCounts,
  [GET_REQUEST_COUNTS_SUCCESS]: handleRequestCountsSuccess,
  [GET_REQUEST_COUNTS_FAILURE]: handleRequestCountsFailure,

  [GET_CONTAINER_INSTANCES]: handleContainerInstances,
  [GET_CONTAINER_INSTANCES_SUCCESS]: handleContainerInstancesSuccess,
  [GET_CONTAINER_INSTANCES_FAILURE]: handleContainerInstancesFailure,

  [GET_REQUEST_LATENCIES]: handleRequestLatencies,
  [GET_REQUEST_LATENCIES_SUCCESS]: handleRequestLatenciesSuccess,
  [GET_REQUEST_LATENCIES_FAILURE]: handleRequestLatenciesFailure,

  [GET_CPU_UTILIZATION]: handleCpuUtilization,
  [GET_CPU_UTILIZATION_SUCCESS]: handleCpuUtilizationSuccess,
  [GET_CPU_UTILIZATION_FAILURE]: handleCpuUtilizationFailure,

  [GET_MEMORY_UTILIZATION]: handleMemoryUtilization,
  [GET_MEMORY_UTILIZATION_SUCCESS]: handleMemoryUtilizationSuccess,
  [GET_MEMORY_UTILIZATION_FAILURE]: handleMemoryUtilizationFailure,

  [GET_VERTEX_RESPONSE_COUNT]: handleVertexResponseCount,
  [GET_VERTEX_RESPONSE_COUNT_SUCCESS]: handleVertexResponseCountSuccess,
  [GET_VERTEX_RESPONSE_COUNT_FAILURE]: handleVertexResponseCountFailure,

  [GET_VERTEX_PREDICTION_COUNT]: handleVertexPredictionCount,
  [GET_VERTEX_PREDICTION_COUNT_SUCCESS]: handleVertexPredictionCountSuccess,
  [GET_VERTEX_PREDICTION_COUNT_FAILURE]: handleVertexPredictionCountFailure,

  [GET_VERTEX_REPLICAS_COUNT]: handleVertexReplicasCount,
  [GET_VERTEX_REPLICAS_COUNT_SUCCESS]: handleVertexReplicasCountSuccess,
  [GET_VERTEX_REPLICAS_COUNT_FAILURE]: handleVertexReplicasCountFailure,

  [GET_VERTEX_LATENCIES]: handleVertexLatencies,
  [GET_VERTEX_LATENCIES_SUCCESS]: handleVertexLatenciesSuccess,
  [GET_VERTEX_LATENCIES_FAILURE]: handleVertexLatenciesFailure,

  [GET_VERTEX_CPU_UTILIZATION]: handleVertexCpuUtilization,
  [GET_VERTEX_CPU_UTILIZATION_SUCCESS]: handleVertexCpuUtilizationSuccess,
  [GET_VERTEX_CPU_UTILIZATION_FAILURE]: handleVertexCpuUtilizationFailure,

  [GET_TASKS_DEPTH]: handleTasksDepth,
  [GET_TASKS_DEPTH_SUCCESS]: handleTasksDepthSuccess,
  [GET_TASKS_DEPTH_FAILURE]: handleTasksDepthFailure,

  [GET_TASKS_COUNT]: handleTasksCount,
  [GET_TASKS_COUNT_SUCCESS]: handleTasksCountSuccess,
  [GET_TASKS_COUNT_FAILURE]: handleTasksCountFailure,

  // [GET_ALERT_POLICIES]: handleGetAlertPolicies,
  // [GET_ALERT_POLICIES_SUCCESS]: handleGetAlertPoliciesSuccess,
  // [GET_ALERT_POLICIES_FAILURE]: handleGetAlertPoliciesFailure,

  [GET_BATCH_LATENCY]: handleGetBatchLatency,
  [GET_BATCH_LATENCY_SUCCESS]: handleGetBatchLatencySuccess,
  [GET_BATCH_LATENCY_FAILURE]: handleGetBatchLatencyFailure,

  [GET_JOBS_LATENCIES]: handleGetJobsLatencies,
  [GET_JOBS_LATENCIES_SUCCESS]: handleGetJobsLatenciesSuccess,
  [GET_JOBS_LATENCIES_FAILURE]: handleGetJobsLatenciesFailure,
});

function handleRevisionsSuccess(state, { payload: { revisions } }) {
  return {
    ...state,
    revisions: revisions.metrics,
    loadingRevisions: false,
    revisionsErrorMessage: null,
  };
}

function handleRevisionsFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingRevisions: false,
    revisionsErrorMessage: error,
  };
}

function handleRevisions(state) {
  return {
    ...state,
    loadingRevisions: true,
    revisionsErrorMessage: null,
  };
}

function handleRequestCountsSuccess(
  state,
  { payload: { requestCounts, alertPolicies } }
) {
  return {
    ...state,
    requestCounts: { metrics: requestCounts.metrics, policies: alertPolicies },
    loadingRequestCounts: false,
    requestCountsErrorMessage: null,
  };
}

function handleRequestCountsFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingRequestCounts: false,
    requestCountsErrorMessage: error,
  };
}

function handleRequestCounts(state) {
  return {
    ...state,
    loadingRequestCounts: true,
    requestCountsErrorMessage: null,
  };
}

function handleContainerInstancesSuccess(
  state,
  { payload: { containerInstances, alertPolicies } }
) {
  return {
    ...state,
    containerInstances: {
      metrics: containerInstances.metrics,
      policies: alertPolicies,
    },
    loadingContainerInstances: false,
    containerInstancesErrorMessage: null,
  };
}

function handleContainerInstancesFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingContainerInstances: false,
    containerInstancesErrorMessage: error,
  };
}

function handleContainerInstances(state) {
  return {
    ...state,
    loadingContainerInstances: true,
    containerInstancesErrorMessage: null,
  };
}

function handleRequestLatenciesSuccess(
  state,
  { payload: { requestLatencies, alertPolicies } }
) {
  return {
    ...state,
    requestLatencies: {
      metrics: requestLatencies.metrics,
      policies: alertPolicies,
    },
    loadingRequestLatencies: false,
    requestLatenciesErrorMessage: null,
  };
}

function handleRequestLatenciesFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingRequestLatencies: false,
    requestLatenciesErrorMessage: error,
  };
}

function handleRequestLatencies(state) {
  return {
    ...state,
    loadingRequestLatencies: true,
    requestLatenciesErrorMessage: null,
  };
}

function handleCpuUtilizationSuccess(
  state,
  { payload: { cpuUtilization, alertPolicies } }
) {
  return {
    ...state,
    cpuUtilization: {
      metrics: cpuUtilization.metrics,
      policies: alertPolicies,
    },
    loadingCpuUtilization: false,
    cpuUtilizationErrorMessage: null,
  };
}

function handleCpuUtilizationFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingCpuUtilization: false,
    cpuUtilizationErrorMessage: error,
  };
}

function handleCpuUtilization(state) {
  return {
    ...state,
    loadingCpuUtilization: true,
    cpuUtilizationErrorMessage: null,
  };
}

function handleMemoryUtilizationSuccess(
  state,
  { payload: { memoryUtilization, alertPolicies } }
) {
  return {
    ...state,
    memoryUtilization: {
      metrics: memoryUtilization.metrics,
      policies: alertPolicies,
    },
    loadingMemoryUtilization: false,
    memoryUtilizationErrorMessage: null,
  };
}

function handleMemoryUtilizationFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingMemoryUtilization: false,
    memoryUtilizationErrorMessage: error,
  };
}

function handleMemoryUtilization(state) {
  return {
    ...state,
    loadingMemoryUtilization: true,
    memoryUtilizationErrorMessage: null,
  };
}

function handleVertexResponseCountSuccess(
  state,
  { payload: { vertexResponseCount, alertPolicies } }
) {
  return {
    ...state,
    vertexResponseCount: {
      metrics: vertexResponseCount.metrics,
      policies: alertPolicies,
    },
    loadingVertexResponseCount: false,
    vertexResponseCountErrorMessage: null,
  };
}

function handleVertexResponseCountFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingVertexResponseCount: false,
    vertexResponseCountErrorMessage: error,
  };
}

function handleVertexResponseCount(state) {
  return {
    ...state,
    loadingVertexResponseCount: true,
    vertexResponseCountErrorMessage: null,
  };
}

function handleVertexPredictionCountSuccess(
  state,
  { payload: { vertexPredictionCount, alertPolicies } }
) {
  return {
    ...state,
    vertexPredictionCount: {
      metrics: vertexPredictionCount.metrics,
      policies: alertPolicies,
    },
    loadingVertexPredictionCount: false,
    vertexPredictionCountErrorMessage: null,
  };
}

function handleVertexPredictionCountFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingVertexPredictionCount: false,
    vertexPredictionCountErrorMessage: error,
  };
}

function handleVertexPredictionCount(state) {
  return {
    ...state,
    loadingVertexPredictionCount: true,
    vertexPredictionCountErrorMessage: null,
  };
}

function handleVertexReplicasCountSuccess(
  state,
  { payload: { vertexReplicasCount, alertPolicies } }
) {
  return {
    ...state,
    vertexReplicasCount: {
      metrics: vertexReplicasCount.metrics,
      policies: alertPolicies,
    },
    loadingVertexReplicasCount: false,
    vertexReplicasCountErrorMessage: null,
  };
}

function handleVertexReplicasCountFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingVertexReplicasCount: false,
    vertexReplicasCountErrorMessage: error,
  };
}

function handleVertexReplicasCount(state) {
  return {
    ...state,
    loadingVertexReplicasCount: true,
    vertexReplicasCountErrorMessage: null,
  };
}

function handleVertexLatenciesSuccess(
  state,
  { payload: { vertexLatencies, alertPolicies } }
) {
  return {
    ...state,
    vertexLatencies: {
      metrics: vertexLatencies.metrics,
      policies: alertPolicies,
    },
    loadingVertexLatencies: false,
    vertexLatenciesErrorMessage: null,
  };
}

function handleVertexLatenciesFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingVertexLatencies: false,
    vertexLatenciesErrorMessage: error,
  };
}

function handleVertexLatencies(state) {
  return {
    ...state,
    loadingVertexLatencies: true,
    vertexLatenciesErrorMessage: null,
  };
}

function handleVertexCpuUtilizationSuccess(
  state,
  { payload: { vertexCpuUtilization, alertPolicies } }
) {
  return {
    ...state,
    vertexCpuUtilization: {
      metrics: vertexCpuUtilization.metrics,
      policies: alertPolicies,
    },
    loadingVertexCpuUtilization: false,
    vertexCpuUtilizationErrorMessage: null,
  };
}

function handleVertexCpuUtilizationFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingVertexCpuUtilization: false,
    vertexCpuUtilizationErrorMessage: error,
  };
}

function handleVertexCpuUtilization(state) {
  return {
    ...state,
    loadingVertexCpuUtilization: true,
    vertexCpuUtilizationErrorMessage: null,
  };
}

function handleTasksDepthSuccess(
  state,
  { payload: { tasksDepth, alertPolicies } }
) {
  return {
    ...state,
    tasksDepth: { metrics: tasksDepth.metrics, policies: alertPolicies },
    loadingTasksDepth: false,
    tasksDepthErrorMessage: null,
  };
}

function handleTasksDepthFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingTasksDepth: false,
    tasksDepthErrorMessage: error,
  };
}

function handleTasksDepth(state) {
  return {
    ...state,
    loadingTasksDepth: true,
    tasksDepthErrorMessage: null,
  };
}

function handleTasksCountSuccess(
  state,
  { payload: { tasksCount, alertPolicies } }
) {
  return {
    ...state,
    tasksCount: { metrics: tasksCount.metrics, policies: alertPolicies },
    loadingTasksCount: false,
    tasksCountErrorMessage: null,
  };
}

function handleTasksCountFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingTasksCount: false,
    tasksCountErrorMessage: error,
  };
}

function handleTasksCount(state) {
  return {
    ...state,
    loadingTasksCount: true,
    tasksCountErrorMessage: null,
  };
}

function handleGetBatchLatencySuccess(state, { payload: { batchLatency } }) {
  return {
    ...state,
    batchLatency: { metrics: batchLatency.metrics, policies: [] },
    loadingBatchLatency: false,
    batchLatencyErrorMessage: null,
  };
}

function handleGetBatchLatencyFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingBatchLatency: false,
    batchLatencyErrorMessage: error,
  };
}

function handleGetBatchLatency(state) {
  return {
    ...state,
    loadingBatchLatency: true,
    batchLatencyErrorMessage: null,
  };
}

function handleGetJobsLatenciesSuccess(state, { payload: { jobsLatencies } }) {
  return {
    ...state,
    jobsLatencies: { metrics: jobsLatencies.metrics, policies: [] },
    loadingJobsLatencies: false,
    jobsLatenciesErrorMessage: null,
  };
}

function handleGetJobsLatenciesFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingJobsLatencies: false,
    jobsLatenciesErrorMessage: error,
  };
}

function handleGetJobsLatencies(state) {
  return {
    ...state,
    loadingJobsLatencies: true,
    jobsLatenciesErrorMessage: null,
  };
}

export function getRevisions({
  metrics_type,
  hours = 2,
  is_vertex,
  service_name,
  project_id,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_REVISIONS });
    try {
      const revisions = await dataSource.getServiceMetrics(
        metrics_type,
        hours,
        is_vertex,
        service_name,
        project_id
      );

      dispatch({
        type: GET_REVISIONS_SUCCESS,
        payload: { revisions },
      });
      return revisions["metrics"];
    } catch (error) {
      dispatch({
        type: GET_REVISIONS_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getRequestCounts({
  metrics_type,
  hours = 2,
  is_vertex,
  service_name,
  project_id,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_REQUEST_COUNTS });
    try {
      const requestCounts = await dataSource.getServiceMetrics(
        metrics_type,
        hours,
        is_vertex,
        service_name,
        project_id
      );

      const alertPolicies = await dataSource.getAlertPolicies({
        project_id,
        service_name,
        metrics_type,
      });

      dispatch({
        type: GET_REQUEST_COUNTS_SUCCESS,
        payload: { requestCounts, alertPolicies },
      });
    } catch (error) {
      dispatch({
        type: GET_REQUEST_COUNTS_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getContainerInstances({
  metrics_type,
  hours = 2,
  is_vertex,
  service_name,
  project_id,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_CONTAINER_INSTANCES });
    try {
      const containerInstances = await dataSource.getServiceMetrics(
        metrics_type,
        hours,
        is_vertex,
        service_name,
        project_id
      );
      const alertPolicies = await dataSource.getAlertPolicies({
        project_id,
        service_name,
        metrics_type,
      });

      dispatch({
        type: GET_CONTAINER_INSTANCES_SUCCESS,
        payload: { containerInstances, alertPolicies },
      });
    } catch (error) {
      dispatch({
        type: GET_CONTAINER_INSTANCES_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getRequestLatencies({
  metrics_type,
  hours = 2,
  is_vertex,
  service_name,
  project_id,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_REQUEST_LATENCIES });
    try {
      const requestLatencies = await dataSource.getServiceMetrics(
        metrics_type,
        hours,
        is_vertex,
        service_name,
        project_id
      );
      const alertPolicies = await dataSource.getAlertPolicies({
        project_id,
        service_name,
        metrics_type,
      });

      dispatch({
        type: GET_REQUEST_LATENCIES_SUCCESS,
        payload: { requestLatencies, alertPolicies },
      });
    } catch (error) {
      dispatch({
        type: GET_REQUEST_LATENCIES_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getCpuUtilization({
  metrics_type,
  hours = 2,
  is_vertex,
  service_name,
  project_id,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_CPU_UTILIZATION });
    try {
      const cpuUtilization = await dataSource.getServiceMetrics(
        metrics_type,
        hours,
        is_vertex,
        service_name,
        project_id
      );
      const alertPolicies = await dataSource.getAlertPolicies({
        project_id,
        service_name,
        metrics_type,
      });

      dispatch({
        type: GET_CPU_UTILIZATION_SUCCESS,
        payload: { cpuUtilization, alertPolicies },
      });
    } catch (error) {
      dispatch({
        type: GET_CPU_UTILIZATION_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getMemoryUtilization({
  metrics_type,
  hours = 2,
  is_vertex,
  service_name,
  project_id,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_MEMORY_UTILIZATION });
    try {
      const memoryUtilization = await dataSource.getServiceMetrics(
        metrics_type,
        hours,
        is_vertex,
        service_name,
        project_id
      );
      const alertPolicies = await dataSource.getAlertPolicies({
        project_id,
        service_name,
        metrics_type,
      });

      dispatch({
        type: GET_MEMORY_UTILIZATION_SUCCESS,
        payload: { memoryUtilization, alertPolicies },
      });
    } catch (error) {
      dispatch({
        type: GET_MEMORY_UTILIZATION_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getVertexResponseCount({
  metrics_type,
  hours = 2,
  is_vertex,
  service_name,
  project_id,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_VERTEX_RESPONSE_COUNT });
    try {
      const vertexResponseCount = await dataSource.getServiceMetrics(
        metrics_type,
        hours,
        is_vertex,
        service_name,
        project_id
      );

      const alertPolicies = await dataSource.getAlertPolicies({
        project_id,
        service_name,
        metrics_type,
      });
      dispatch({
        type: GET_VERTEX_RESPONSE_COUNT_SUCCESS,
        payload: { vertexResponseCount, alertPolicies },
      });
    } catch (error) {
      dispatch({
        type: GET_VERTEX_RESPONSE_COUNT_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getVertexPredictionCount({
  metrics_type,
  hours = 2,
  is_vertex,
  service_name,
  project_id,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_VERTEX_PREDICTION_COUNT });
    try {
      const vertexPredictionCount = await dataSource.getServiceMetrics(
        metrics_type,
        hours,
        is_vertex,
        service_name,
        project_id
      );

      const alertPolicies = await dataSource.getAlertPolicies({
        project_id,
        service_name,
        metrics_type,
      });

      dispatch({
        type: GET_VERTEX_PREDICTION_COUNT_SUCCESS,
        payload: { vertexPredictionCount, alertPolicies },
      });
    } catch (error) {
      dispatch({
        type: GET_VERTEX_PREDICTION_COUNT_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getVertexReplicasCount({
  metrics_type,
  hours = 2,
  is_vertex,
  service_name,
  project_id,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_VERTEX_REPLICAS_COUNT });
    try {
      const vertexReplicasCount = await dataSource.getServiceMetrics(
        metrics_type,
        hours,
        is_vertex,
        service_name,
        project_id
      );
      const alertPolicies = await dataSource.getAlertPolicies({
        project_id,
        service_name,
        metrics_type,
      });

      dispatch({
        type: GET_VERTEX_REPLICAS_COUNT_SUCCESS,
        payload: { vertexReplicasCount, alertPolicies },
      });
    } catch (error) {
      dispatch({
        type: GET_VERTEX_REPLICAS_COUNT_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getVertexLatencies({
  metrics_type,
  hours = 2,
  is_vertex,
  service_name,
  project_id,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_VERTEX_LATENCIES });
    try {
      const vertexLatencies = await dataSource.getServiceMetrics(
        metrics_type,
        hours,
        is_vertex,
        service_name,
        project_id
      );

      const alertPolicies = await dataSource.getAlertPolicies({
        project_id,
        service_name,
        metrics_type,
      });

      dispatch({
        type: GET_VERTEX_LATENCIES_SUCCESS,
        payload: { vertexLatencies, alertPolicies },
      });
    } catch (error) {
      dispatch({
        type: GET_VERTEX_LATENCIES_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getVertexCpuUtilization({
  metrics_type,
  hours = 2,
  is_vertex,
  service_name,
  project_id,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_VERTEX_CPU_UTILIZATION });
    try {
      const vertexCpuUtilization = await dataSource.getServiceMetrics(
        metrics_type,
        hours,
        is_vertex,
        service_name,
        project_id
      );

      const alertPolicies = await dataSource.getAlertPolicies({
        project_id,
        service_name,
        metrics_type,
      });

      dispatch({
        type: GET_VERTEX_CPU_UTILIZATION_SUCCESS,
        payload: { vertexCpuUtilization, alertPolicies },
      });
    } catch (error) {
      dispatch({
        type: GET_VERTEX_CPU_UTILIZATION_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getTasksDepth({
  metrics_type,
  hours = 2,
  is_vertex,
  service_name,
  project_id,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_TASKS_DEPTH });
    try {
      const tasksDepth = await dataSource.getServiceMetrics(
        metrics_type,
        hours,
        is_vertex,
        service_name,
        project_id
      );

      const alertPolicies = await dataSource.getAlertPolicies({
        project_id,
        service_name,
        metrics_type,
      });

      dispatch({
        type: GET_TASKS_DEPTH_SUCCESS,
        payload: { tasksDepth, alertPolicies },
      });
    } catch (error) {
      dispatch({
        type: GET_TASKS_DEPTH_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getTasksCount({
  metrics_type,
  hours = 2,
  is_vertex,
  service_name,
  project_id,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_TASKS_COUNT });
    try {
      const tasksCount = await dataSource.getServiceMetrics(
        metrics_type,
        hours,
        is_vertex,
        service_name,
        project_id
      );

      const alertPolicies = await dataSource.getAlertPolicies({
        project_id,
        service_name,
        metrics_type,
      });

      dispatch({
        type: GET_TASKS_COUNT_SUCCESS,
        payload: { tasksCount, alertPolicies },
      });
    } catch (error) {
      dispatch({
        type: GET_TASKS_COUNT_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getBatchLatency({ project_id, object_id, hours, session }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_BATCH_LATENCY });
    try {
      const batchLatency = await dataSource.getBatchLatency({
        project_id,
        object_id,
        hours,
        session,
      });

      dispatch({
        type: GET_BATCH_LATENCY_SUCCESS,
        payload: { batchLatency },
      });
    } catch (error) {
      dispatch({
        type: GET_BATCH_LATENCY_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getJobsLatencies({
  project_id,
  processes_list,
  hours,
  session,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_JOBS_LATENCIES });
    try {
      const jobsLatencies = await dataSource.getJobsLatencies({
        project_id,
        processes_list,
        hours,
        session,
      });

      dispatch({
        type: GET_JOBS_LATENCIES_SUCCESS,
        payload: { jobsLatencies },
      });
    } catch (error) {
      dispatch({
        type: GET_JOBS_LATENCIES_FAILURE,
        payload: { error },
      });
    }
  };
}
