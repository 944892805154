import React from "react";

import {
  Typography,
  Box,
  Divider,
  Modal,
  CircularProgress,
  Stack,
  Chip,
} from "@mui/material";
import Select from "react-select";

import LineGraph from "../../LineGraph";

function MetricGraph(props) {
  const {
    title,
    data,
    loadingData,
    graphType,
    setSelectedGraph,
    processes,
    setSelectedProcesses,
    selectedProcesses,
  } = props;

  return (
    <Box
      sx={{
        backgroundColor: "#e9e9f1",
        padding: 1,
        borderRadius: 1,
      }}
    >
      <Typography sx={{ fontWeight: "bold", marginY: 1 }}>{title}</Typography>

      {processes ? (
        <Select
          value={selectedProcesses}
          isClearable
          isMulti
          onChange={(e) => setSelectedProcesses(e)}
          name="Processes"
          options={processes}
          getOptionLabel={(option) => `${option.value}: ${option.label}`}
        />
      ) : null}

      <Box
        sx={{ width: "450px", height: "300px" }}
        onClick={() =>
          setSelectedGraph({
            title: title,
            data: data.metrics,
            type: graphType,
          })
        }
      >
        {loadingData ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 1,
              paddingY: 2,
            }}
          >
            <CircularProgress />
            <Typography
              sx={{ color: "#29373f", fontSize: 18, fontWidth: "bold" }}
            >
              Loading metrics...
            </Typography>
          </Box>
        ) : (
          <>
            <LineGraph
              data={data.metrics}
              size="sm"
              graphType={graphType}
              legendSeparation="sm"
            />
          </>
        )}
      </Box>
    </Box>
  );
}

export default MetricGraph;
