import { connect } from 'react-redux';

import {
  getServiceMetadata,
  updateServiceTraffic,
  getVertexMetadata,
  updateVertexTraffic,
} from 'core/slices/serviceMetadata';
import { loadingServiceMetadata, serviceMetadata, navigationOptions } from 'core/selectors';

import TrafficInfo from '../../../../components/pipelineMonitoring/monitoringGraph/InfoDrawer/Node/TrafficInfo';

export default connect(
  state => ({
    serviceMetadata: serviceMetadata(state),
    loadingServiceMetadata: loadingServiceMetadata(state),
    navigationOptions: navigationOptions(state)
  }),
  {
    getServiceMetadata,
    updateServiceTraffic,
    getVertexMetadata,
    updateVertexTraffic,
  },
)(TrafficInfo);
