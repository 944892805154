import { DataBar, Legend, Metric, Text } from '@zippeditoolsjs/dashboards';
import { useContext, useEffect, useState } from 'react';

import { Box, Card, Divider, Grid, Tabs, Tab, Typography } from '@mui/material';
import TabPanel from '../tools/TabPanel';
import { useTranslation } from 'react-i18next';
import { RobotViewContext } from '../../context/RobotView';
import AlertBox from '../tools/AlertBox';
import { a11yProps } from '../Utils';

export default function AislesProgressWidget(props) {
  const { leftTitle = 'Aisle progress percentage', title } = props;
  const { rawRobotStatus } = useContext(RobotViewContext);
  const { t } = useTranslation();

  const [aisleProgresses, setAisleProgresses] = useState(null);
  const [aisleProgress, setAisleProgress] = useState(null);
  const [hasValues, setHasValues] = useState(false);
  const [markerValue, setMarkerValue] = useState(0);
  const [tooltip, setTooltip] = useState('');

  // hooks

  useEffect(() => {
    if (rawRobotStatus) {
      const aisleToProcess = rawRobotStatus?.aisles_to_process?.length || 0;
      const aisleProcessed = rawRobotStatus?.aisles_processed?.length || 0;
      const aisleToCapture = rawRobotStatus?.aisles_to_capture?.length || 0;
      const aislesOmitted = rawRobotStatus?.aisles_omitted?.length || 0;
      const divider =
        aisleToProcess + aisleProcessed + aisleToCapture + aislesOmitted;
      const statusAislesProcessed = [
        {
          title: 'Captured but not processed',
          value: aisleToProcess || 0,
          percentage: aisleToProcess
            ? Math.round(100 * (aisleToProcess / divider))
            : 0,
          color: 'yellow',
          tooltipColor: 'yellow',
        },
        {
          title: 'Processed',
          value: aisleProcessed || 0,
          percentage: aisleProcessed
            ? Math.round(100 * (aisleProcessed / divider))
            : 0,
          color: 'emerald',
          tooltipColor: 'emerald',
        },
        {
          title: 'Not captured',
          value: aisleToCapture || 0,
          percentage: aisleToCapture
            ? Math.round(100 * (aisleToCapture / divider))
            : 0,
          color: 'stone',
        },
        {
          title: 'Omitted',
          value: aislesOmitted,
          percentage: aislesOmitted
            ? Math.round(100 * (aislesOmitted / divider))
            : 0,
          color: 'slate',
        },
      ];

      setHasValues(
        aisleToProcess + aisleProcessed + aisleToCapture + aislesOmitted > 0
      );
      setTooltip(
        statusAislesProcessed.map((item, index) => (
          <Box
            key={`typography-tooltip-aisles-progress-${item.title}-${index}`}
            sx={{ display: 'flex' }}
          >
            <Typography
              sx={{
                color: (theme) => {
                  return theme.palette[item.tooltipColor]?.main;
                },
              }}
            >
              {item.title}:
            </Typography>
            <Typography sx={{ ml: 1 }}>
              {item.percentage} % ({item.value})
            </Typography>
          </Box>
        ))
      );
      setAisleProgresses(statusAislesProcessed);
      setAisleProgress(statusAislesProcessed.map((item) => item.percentage));
      setMarkerValue(
        statusAislesProcessed[0].percentage +
        statusAislesProcessed[1].percentage
      );
    } else {
      setAisleProgress(null);
      setAisleProgresses(null);
      setMarkerValue(0);
      setHasValues(false);
    }
  }, [rawRobotStatus]);

  return (
    <Card
      sx={{
        p: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Grid container>
        <Grid xs={12}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={0}
            aria-label={'robot-schedule-tab-0'}
          >
            <Tab
              label={title}
              {...a11yProps(0, { textTransform: 'initial' })}
            />
          </Tabs>
          <TabPanel value={0} index={0} sx={{ p: 2 }}>
            {hasValues && aisleProgress?.length ? (
              <Grid container>
                {aisleProgresses.map((aisleProgressInfo, index) => {
                  return (
                    <Grid
                      item
                      xs={3}
                      sx={{ textAlign: 'center' }}
                      key={`grid-kpi-aisle-progress-${index}`}
                    >
                      <Metric color={aisleProgressInfo.color}>
                        {aisleProgressInfo.value}
                      </Metric>
                      <Text>{aisleProgressInfo.title}</Text>
                    </Grid>
                  );
                })}

                <Grid item xs={12}>
                  <Divider sx={{ mt: 1, mb: 2 }} />
                  <DataBar
                    markerValue={markerValue}
                    className="mt-3"
                    type="category"
                    showLabels={false}
                    values={aisleProgress}
                    colors={aisleProgresses.map(
                      (aisleProgressInfo) => aisleProgressInfo.color
                    )}
                    leftTitle={leftTitle}
                    tooltip={tooltip}
                  />

                  <Box
                    sx={{ display: 'flex', justifyContent: 'flex-end', m: 1 }}
                  >
                    <Legend
                      categories={aisleProgresses.map(
                        (aisleProgressInfo) => aisleProgressInfo.title
                      )}
                      colors={aisleProgresses.map(
                        (aisleProgressInfo) => aisleProgressInfo.color
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <AlertBox
                severity="info"
                content={t(
                  'overseer_app.widget.no_aisle_progress_data',
                  'No Aisle Progress Data'
                )}
              />
            )}
          </TabPanel>
        </Grid>
      </Grid>
    </Card>
  );
}
