import React, { useState, useEffect } from 'react';
import { Grid, Typography, Paper, FormControl, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import BarChart from './charts/Bar';
import LineChart from './charts/Line';

import WarningIcon from '@mui/icons-material/Warning';
import { styled } from '@mui/system';

// Components
import CircularLoader from '../tools/CircularLoader';
import AlertChips from './AlertChips';

function filterById(arr, id, CLIData, setStoreKeys) {
    if (CLIData.valueType === 'DISTRIBUTION' || CLIData.valueType === '') {
        return arr.filter((item) => item.id === id);
    } else {
        //CLIData.valueType === 'INT64'
        if (id === null) {
            const uniqueKeys = [...new Set(arr.flatMap(obj => Object.keys(obj).filter(key => key !== "time")))];
            uniqueKeys.push("")
            setStoreKeys(uniqueKeys);
            return arr;
        }
        const filteredData = arr.map((item) => {
            const filteredItem = { time: item.time };

            for (const key in item) {
                if (key === 'time' || key === id) {
                    filteredItem[key] = item[key];
                }
            }
            return filteredItem;
        });
        setStoreKeys([id])
        return filteredData;
    }
}

const NoDataContainer = styled(Paper)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
});

const NoDataMessage = styled(Typography)({
    marginLeft: 10,
});

export default function AlertMonitoring(props) {

    const {
        dataChart,
        isLoadingChartData,
        infoData,
        CLIData,
        isLoadingCLIData,
        robot_id,
        setRobotId,
        timeFrame,
        setTimeFrame,
        CMIData,
        isLoadingCMIData,
        isUploadMetric
    } = props

    const [storeKeys, setStoreKeys] = useState(null);
    const [LineCharData, setLineCharData] = useState([]);
    const [BarChartData, setBarChartData] = useState([]);

    useEffect(() => {
        if (dataChart) {
            const resultFilter = dataChart ? filterById(dataChart, robot_id, CLIData, setStoreKeys) : null;
            CLIData.valueType === 'INT64' ? setBarChartData(resultFilter) : setLineCharData(resultFilter);
        }
    }, [robot_id, dataChart]);

    //Keys to bar graph
    useEffect(() => {
        if (dataChart !== null) {
            const uniqueKeys = [...new Set(dataChart.flatMap(obj => Object.keys(obj).filter(key => key !== "time")))];
            uniqueKeys.push("")
            setStoreKeys(uniqueKeys);
        }
    }, [dataChart])

    return (
        <Grid container flexDirection={'column'} spacing={0}>
            <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    Alert Monitoring
                </Typography>
            </Grid>

            <Grid container item direction={'row'} sx={{ my: 2 }}>
                {/* Form Data */}
                <Grid item xs={3}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        sx={{ minWidth: 120, marginBottom: 0 }}
                        disabled={isLoadingCLIData || isLoadingChartData}
                    >
                        <Autocomplete
                            id="robot-id"
                            defaultValue={'all'}
                            options={
                                CLIData.valueType === 'INT64' && storeKeys !== null
                                    ? storeKeys.map((item) => item)
                                    : dataChart.map((item) => item.id)
                            }
                            value={robot_id}
                            disabled={isLoadingCLIData || isLoadingChartData}
                            onChange={(_, newValue) => setRobotId(newValue)}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderInput={(params) => (
                                <TextField {...params} label={isUploadMetric ? "Session ID" : "Robot ID"} variant="outlined" />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item container xs={8} alignSelf={'center'} sx={{ mx: 4 }}>
                    <AlertChips
                        timeFrame={timeFrame}
                        setTimeFrame={setTimeFrame}
                        disabled={isLoadingCLIData || isLoadingChartData}
                    />
                </Grid>
            </Grid>

            {isLoadingCLIData || isLoadingChartData || isLoadingCMIData ? (
                <Grid
                    item
                    container
                    direction={'column'}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mt: 5 }}
                >
                    <CircularLoader size="2em" />
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>
                        {'Loading...'}
                    </Typography>
                </Grid>
            ) : (
                <Grid container item direction={'column'}>
                    {Object.keys(CLIData).length > 0 ? (
                        <Grid item container direction={'column'} sx={{ my: 2 }}>
                            {/* Metric Data */}
                            <Grid item sx={{ bgcolor: '#cccccc', borderRadius: 2, p: 3 }}>
                                <Typography
                                    sx={{ textAlign: 'justify', fontWeight: 'bold', pb: 2 }}
                                >
                                    {'Metric'}
                                </Typography>

                                <Typography sx={{ textAlign: 'justify' }}>
                                    {CLIData.filter}
                                </Typography>
                            </Grid>

                            <Grid container flexDirection={'column'} spacing={0}>
                                <Grid container item direction={'column'} sx={{ my: 2 }}>
                                    {/* Threshold Data */}
                                    <Grid
                                        item
                                        sx={{ bgcolor: '#f2f2f2', borderRadius: 2, p: 3 }}
                                    >
                                        <Typography
                                            sx={{ textAlign: 'justify', fontWeight: 'bold', pb: 2 }}
                                        >
                                            {'Threshold Information'}
                                        </Typography>

                                        <Typography sx={{ textAlign: 'justify', pb: 2 }}>
                                            {'Comparison: '}
                                            <span style={{ fontWeight: 'bold' }}>
                                                {CMIData.comparison === 'COMPARISON_GT'
                                                    ? 'Greater Than'
                                                    : 'Less Than'}
                                            </span>
                                        </Typography>

                                        <Typography sx={{ textAlign: 'justify' }}>
                                            {'Threshold: '}
                                            <span style={{ fontWeight: 'bold' }}>
                                                {CMIData.threshold}
                                            </span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={4} alignSelf={'center'}>
                            <Typography
                                sx={{
                                    bgcolor: '#cccccc',
                                    borderRadius: 2,
                                    p: 3,
                                    textAlign: 'justify',
                                }}
                            >
                                {'No metrics have been found'}
                            </Typography>
                        </Grid>
                    )}

                    {/* Metric Graph */}
                    <Grid item alignSelf={'center'}>
                        {CLIData.valueType === 'INT64' ? (
                            <Grid item xs={12} alignSelf={'center'}>
                                <>
                                    {infoData ? (
                                        <NoDataContainer>
                                            <WarningIcon color="secondary" />
                                            <NoDataMessage variant="h6">{infoData}</NoDataMessage>
                                        </NoDataContainer>
                                    ) : (
                                        <>
                                            {/* Bar Chart*/}
                                            {isLoadingChartData ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    display={'flex'}
                                                    direction={'column'}
                                                    alignItems={'center'}
                                                >
                                                    <CircularLoader size="2em" />
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ fontWeight: 'bold', mt: 2 }}
                                                    >
                                                        {'Loading...'}
                                                    </Typography>
                                                </Grid>
                                            ) : (
                                                dataChart.length > 0 ?
                                                    <BarChart
                                                        data={
                                                            BarChartData.length > 0 ? BarChartData : dataChart
                                                        }
                                                        keys={storeKeys}
                                                    />
                                                    :
                                                    <NoDataContainer>
                                                        <WarningIcon color="secondary" />
                                                        <NoDataMessage variant="h6">{"No Data to this interval"}</NoDataMessage>
                                                    </NoDataContainer>
                                            )}
                                        </>
                                    )}
                                </>
                            </Grid>
                        ) : (
                            <Grid item alignSelf={'center'}>
                                <Grid
                                    sx={{ height: 500, width: '100%', bgcolor: 'transparent' }}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <>
                                        {infoData ? (
                                            <NoDataContainer>
                                                <WarningIcon color="secondary" />
                                                <NoDataMessage variant="h6">{infoData}</NoDataMessage>
                                            </NoDataContainer>
                                        ) : (
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{ height: '500px', bgcolor: 'transparent' }}
                                            >
                                                {/* Linear Chart*/}
                                                {robot_id === null ? (
                                                    <Grid
                                                        item
                                                        container
                                                        direction={'column'}
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        xs={12}
                                                        sx={{ mt: 5 }}
                                                    >
                                                        <Typography
                                                            variant="h6"
                                                            sx={{ fontWeight: 'bold', mt: 2 }}
                                                        >
                                                            {'There is no data or you must choose a robot'}
                                                        </Typography>
                                                    </Grid>
                                                ) : (
                                                    <LineChart data={LineCharData} CLIData={CLIData} />
                                                )}
                                            </Grid>
                                        )}
                                    </>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

