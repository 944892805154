import { Box, LinearProgress, Tooltip, Typography } from '@mui/material';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { Suspense, useEffect, useRef } from 'react';
import useCanvas from '../tools/useCanvas';
import { transformRobot2Pixels } from '../Utils';
import { drawRectangleOnCanvas, writeAisleOnCanvas } from '../utils/canvasFunctions';

export default function LayoutMap(props) {
  const {
    mapImg,
    layout,
    id = '',
    width = 600,
    mapMetadata = {},
    highlightedBoxes = [],
    layersDisplay = ['aisles'],
  } = props;

  const mapImgRef = useRef(null);
  const [canvasRef, canvasDraw] = useCanvas();

  // Hooks

  useEffect(() => {
    if (mapImgRef.current?.complete) {
      handleCanvasSize();
    }
  }, [mapImgRef]);


  useEffect(() => {
    if (mapMetadata?.resolution && layout && mapImgRef.current && canvasRef.current) {
      handleCanvasSize();
      handleLayers();
    }
  }, [mapMetadata, layout, highlightedBoxes, layersDisplay]);


  // Handlers
  const handleCanvasSize = () => {
    canvasRef.current.height = mapImgRef.current.height;
    canvasRef.current.width = mapImgRef.current.width;
  };

  const handleLayers = () => {

    highlightedBoxes.forEach((box) => {
      parseDataToDraw(box);
      const colorFill = box.color ? `${box.color}AA` : box.excluded ? 'rgba(170,170,170,0.53)' : 'rgba(255, 164, 0, 0.7)'
      const { px, py, width, height, strokeStyle } = parseDataToDraw(box)
      canvasDraw(drawRectangleOnCanvas, [px, py, width, height, colorFill, strokeStyle]);
    });

    layersDisplay.forEach((layer) => {
      if (layer === 'aisles') {
        const capture = layout?.capture ?? {};
        // replacing key by client_name if it exists and parsing list of entries
        handleDefaultDraw(Object.entries(capture));
      } else if (layer === 'nav_excluded') {
        const navExcluded = layout?.nav_excluded ?? {};
        const navExcludedEntries = Object.entries(navExcluded);
        handleDefaultDraw(navExcludedEntries);
      } else if (layer === 'zones') {
        const zones = layout?.zones ?? {};
        const zonesEntries = Object.entries(zones);
        handleDefaultDraw(zonesEntries);
      }
    });

  };

  const handleDefaultDraw = (captureEntries) => {
    for (const [key, value] of captureEntries) {
      const { px, py, width, height, colorFill, strokeStyle, font } = parseDataToDraw(value)
      canvasDraw(drawRectangleOnCanvas, [px, py, width, height, colorFill, strokeStyle]);
      canvasDraw(writeAisleOnCanvas, [key, [px, py], [px + width, py + height], true, font]);
    };
  }

  const parseDataToDraw = (data) => {
    const imgHeight = mapImgRef.current.height;
    const imgWidth = mapImgRef.current.width;
    const bbox = data.bbox;
    const origin = mapMetadata.origin;
    const resolution = mapMetadata.resolution;
    const originalHeight = mapMetadata.height;
    const originalWidth = mapMetadata.width;
    const scaleX = imgHeight / originalHeight;
    const scaleY = imgWidth / originalWidth;

    const upperLeft = transformRobot2Pixels(
      bbox[0].x,
      bbox[0].y,
      0,
      origin,
      resolution,
      originalHeight
    );
    const lowerRight = transformRobot2Pixels(
      bbox[1].x,
      bbox[1].y,
      0,
      origin,
      resolution,
      originalHeight
    );

    const px = upperLeft[0] * scaleX;
    const py = upperLeft[1] * scaleY;
    const width = (lowerRight[0] * scaleX - px);
    const height = (lowerRight[1] * scaleY - py);
    const colorFill = data.color ? `${data.color}55` : data.excluded ? 'rgba(170,170,170,0.53)' : 'rgba(255, 164, 0, 0.3)'
    const strokeStyle = data.color ? data.color : "#212529EE"
    const fontFactor = 13 * imgWidth / 600;
    const font = `bold ${fontFactor}px verdana, sans-serif`;

    return { px, py, width, height, colorFill, strokeStyle, font }
  }

  return (
    <Box id="layout-map-box" style={{ position: 'relative', width: 'fit-content' }}>
      <Suspense
        fallback={
          <LinearProgress sx={{ width: '98%', mx: 'auto' }} color="secondary" />
        }
      >
        {!mapImg ?
          <Box sx={{ width: width, minHeight: '400px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#F9F9F9', border: '1px solid #DDD', borderRadius: '5px' }}>
            <Typography variant="h6" sx={{ color: 'grey' }}>No map available</Typography>
          </Box>
          :
          <img
            ref={mapImgRef}
            width={width}
            style={{
              maxWidth: 'inherit',
              borderRadius: '5px',
              maxHeight: '100%',
              objectFit: 'contain',
              zIndex: 0,
            }}
            src={`data:image/jpeg;base64,${mapImg}`}
            alt="layoutMap"
          />
        }
        <canvas
          id={`layout-aisles-canvas-${id}`}
          ref={canvasRef}
          style={{
            borderRadius: '5px',
            outline: 'none',
            width: 'auto',
            height: 'auto',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
          }}
        />
        <Tooltip title={
          mapImg ?
            <>
              <Typography color="inherit">Version</Typography>
              <ul>
                <li>Map: <b>{mapMetadata.map_url}</b></li>
                <li>Map date: <b>{mapMetadata.map_created_at}</b></li>
                <li>Layout date : <b>{mapMetadata.version_created_at}</b></li>
              </ul>
            </>
            :
            <Typography color="inherit">No map available</Typography>
        } placement="auto" >
          <Box
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              zIndex: 3,
            }}>
            <IconComponent
              iconName={'information-circle'}
              style={{ fontSize: "28px", color: "rgb(97, 97, 97, 0.92)", marginRight: 3, }}
            />
          </Box>
        </Tooltip>
      </Suspense>
    </Box>
  );
}
