import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { reducer as snackAlertReducer } from './slices/snackAlert';
import { reducer as sessionReducer } from './slices/session';
import { reducer as pipelineMonitoringReducer } from './slices/pipelineMonitoring';
import { reducer as serviceMonitoringReducer } from './slices/serviceMonitoring';
import { reducer as navigationReducer } from './slices/navigation';
import { reducer as metricsReducer } from './slices/metrics';
import { reducer as serviceMetadataReducer } from './slices/serviceMetadata';
import { reducer as alertPoliciesReducer } from './slices/alertPolicies';
import { reducer as backendReducer } from './slices/backend';
import { reducer as parametersReducer } from './slices/parameters';
import { reducer as fleetControlReducer } from './slices/fleetControl';
import { reducer as schedulerReducer } from './slices/scheduler';
import { reducer as actionWidget } from './slices/actionWidget';
import { reducer as alertsWidgetReducer } from './slices/alertsWidget';
import { reducer as HardwareAlertsWidgetReducer } from './slices/hardwareAlertsWidget';
import { reducer as hardwareComponentsWidgetReducer } from './slices/HardwareComponentsWidget';
import { reducer as robotMonitoringReducer } from './slices/robotMonitoring';
import { reducer as robotServicingReducer } from './slices/robotServicing';
import { reducer as servicingHistoryWidgetReducer } from './slices/servicingHistoryWidget';
import { reducer as groupReducer } from './slices/group';
import { reducer as alertsReducer } from './slices/alertManager';
import { reducer as cameraWidgetsReducer } from './slices/cameraWidgets';
import { reducer as cloudLoggingInterfaceReducer } from './slices/cloudLoggingInterface';
import { reducer as robotConfigurationReducer } from './slices/robotConfiguration';
import { reducer as eventsReducer } from './slices/event';
import { reducer as timelineTraceReducer } from './slices/timelineTrace';
import { reducer as dashboardMetricsReducer } from './slices/dashboardMetrics';
import { reducer as layoutsReducer } from './slices/layouts';

export default function _createStore(services) {
  return createStore(
    combineReducers({
      snackAlert: snackAlertReducer,
      session: sessionReducer,
      pipelineMonitoring: pipelineMonitoringReducer,
      serviceMonitoring: serviceMonitoringReducer,
      navigation: navigationReducer,
      metrics: metricsReducer,
      serviceMetadata: serviceMetadataReducer,
      alertPolicies: alertPoliciesReducer,
      backend: backendReducer,
      parameters: parametersReducer,
      fleetControl: fleetControlReducer,
      scheduler: schedulerReducer,
      alertsWidget: alertsWidgetReducer,
      hardwareAlertsWidget: HardwareAlertsWidgetReducer,
      hardwareComponentsWidget: hardwareComponentsWidgetReducer,
      robotMonitoring: robotMonitoringReducer,
      servicingHistoryWidget: servicingHistoryWidgetReducer,
      actionWidget: actionWidget,
      robotServicing: robotServicingReducer,
      cameraWidgets: cameraWidgetsReducer,
      group: groupReducer,
      alerts: alertsReducer,
      cli: cloudLoggingInterfaceReducer,
      robotConfiguration: robotConfigurationReducer,
      events: eventsReducer,
      timelineTrace: timelineTraceReducer,
      dashboardMetrics: dashboardMetricsReducer,
      layouts: layoutsReducer,
    }),
    applyMiddleware(thunk.withExtraArgument({ services }))
  );
}
