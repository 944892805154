import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    Checkbox,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';

// Extra funcitons
function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function TransferList(props) {
    const {
        left,
        right,
        newAlerts,
        removedAlerts,
        setLeft,
        setRight,
        setNewAlerts,
        setRemovedAlerts,
        selectedGroup
    } = props

    const [checked, setChecked] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setNewAlerts(newAlerts.concat(leftChecked));
        setRight(right.concat(leftChecked).sort());
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setRemovedAlerts(removedAlerts.concat(rightChecked))
        setLeft(left.concat(rightChecked).sort());
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    useEffect(() => {
        if (selectedGroup !== "") {
            setChecked([])
        }
    }, [selectedGroup]);

    const customList = (title, items) => (
        <Card sx={{ width: 400, border: "1px solid rgba(0, 0, 0, .5)" }} >
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={
                    <Typography sx={{ fontSize: '18px', fontWeight: "bold" }}>
                        {title}
                    </Typography>
                }
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider sx={{ bgcolor: "rgba(0, 0, 0, .5)" }} />
            <List
                sx={{
                    width: "100%",
                    height: 400,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
            >
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value}-label`;
                    return (
                        <ListItem
                            key={value}
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} sx={{ fontSize: '16px' }}
                                primary={
                                    <Typography sx={{ fontSize: '16px', }}>
                                        {`${value}`}
                                    </Typography>
                                }
                            >
                            </ListItemText>
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    );

    return (
        <Grid container spacing={3} alignItems="center">
            <Grid item>{customList('Alerts available', left)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 1 }}
                        variant="outlined"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 1 }}
                        variant="outlined"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList(`Alerts in ${selectedGroup}`, right)}</Grid>
        </Grid>

    );
}
