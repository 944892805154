import React, { useEffect, useState } from 'react';

import {
  Typography,
  Box,
  Divider,
  Modal,
  CircularProgress,
  Stack,
  Chip,
} from '@mui/material';

import { useParams } from 'react-router-dom';

import LineGraph from '../../LineGraph';
import NoMetricAvailable from './NoMetricAvailable';
import MetricGraph from './MetricGraph';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  zIndex: 100,
};

function MetricsInfo(props) {
  const {
    root,
    batchLatency,
    loadingBatchLatency,
    getBatchLatency,
    jobsLatencies,
    loadingJobsLatencies,
    getJobsLatencies,
  } = props;
  const { session, date } = useParams();

  const [selectedGraph, setSelectedGraph] = useState(false);
  const [timeFrame, setTimeFrame] = useState(2);
  const [selectedProcesses, setSelectedProcesses] = useState(
    root.children.map((child) => ({
      value: child.process_id,
      label: child.display_name,
    }))
  );

  useEffect(() => {
    getBatchLatency({
      project_id: 'zippedi-software-dev',
      object_id: root.objectId,
      hours: timeFrame,
      session,
    });

    getJobsLatencies({
      project_id: 'zippedi-software-dev',
      session: session,
      processes_list: selectedProcesses.map((process) => process.value),
      hours: timeFrame,
    });
  }, [timeFrame, selectedProcesses]);

  if (
    jobsLatencies === {} &&
    !loadingJobsLatencies &&
    batchLatency === {} &&
    !loadingBatchLatency
  ) {
    return <NoMetricAvailable root={root} />;
  }
  return (
    <Box>
      <Typography
        sx={{
          margin: 1,
          color: 'white',
          fontWeight: 'bold',
          fontSize: 24,
          marginX: 3,
        }}
      >
        {root.data.displayName}
      </Typography>

      <Typography
        sx={{ color: 'lightgrey', fontSize: 16, marginX: 3, marginBottom: 1 }}
      >
        {root.data.descriptionLines.length > 0
          ? `${root.data.descriptionLines[0]}`
          : null}
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={1}
        sx={{ marginRight: 2 }}
      >
        <Chip
          label="2 hours"
          variant={timeFrame === 2 ? 'filled' : 'outlined'}
          color="secondary"
          size="small"
          onClick={() => setTimeFrame(2)}
        />
        <Chip
          label="6 hours"
          variant={timeFrame === 6 ? 'filled' : 'outlined'}
          color="secondary"
          size="small"
          onClick={() => setTimeFrame(6)}
        />
        <Chip
          label="12 hours"
          variant={timeFrame === 12 ? 'filled' : 'outlined'}
          size="small"
          color="secondary"
          onClick={() => setTimeFrame(12)}
        />
        <Chip
          label="1 day"
          variant={timeFrame === 24 ? 'filled' : 'outlined'}
          size="small"
          color="secondary"
          onClick={() => setTimeFrame(24)}
        />
        <Chip
          label="7 days"
          variant={timeFrame === 7 * 24 ? 'filled' : 'outlined'}
          size="small"
          color="secondary"
          onClick={() => setTimeFrame(7 * 24)}
        />
      </Stack>
      <Modal
        style={{ backdropFilter: 'blur(5px)' }}
        open={selectedGraph !== false}
        onClose={() => setSelectedGraph(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontWeight: 'bold' }}
          >
            {selectedGraph ? selectedGraph.title : null}
          </Typography>
          <Box sx={{ width: '800px', height: '500px' }}>
            <LineGraph
              data={selectedGraph.data}
              size="lg"
              graphType={selectedGraph.type}
            />
          </Box>
        </Box>
      </Modal>
      <Box sx={{ margin: 2, backgroundColor: '#e9e9f1', borderRadius: 1 }}>
        <MetricGraph
          title="Batch Latency (secs.)"
          data={batchLatency}
          loadingData={loadingBatchLatency}
          graphType="numerical"
          setSelectedGraph={setSelectedGraph}
        />
        <Divider sx={{ borderBottomWidth: 2 }} />

        <MetricGraph
          title="Job Latencies (secs.)"
          processes={root.children.map((child) => ({
            value: child.process_id,
            label: child.display_name,
          }))}
          selectedProcesses={selectedProcesses}
          setSelectedProcesses={setSelectedProcesses}
          data={jobsLatencies}
          loadingData={loadingJobsLatencies}
          graphType="numerical"
          setSelectedGraph={setSelectedGraph}
        />
      </Box>
    </Box>
  );
}

export default MetricsInfo;
