import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import LinearChart from '../metrics/LinearChart';

export default function MonitorCharts(props) {
  const {
    size = 12,
    monitorsData,
    isLoading,
    selectedMonitor,
    selectedMetric,
  } = props;

  const valueFormatter = function (number) {
    return `${Intl.NumberFormat('us').format(number).toString()} ${
      unitMeasurement[0]
    }`;
  };

  const { t } = useTranslation();

  const [selectedLinearData, setSelectedLinearData] = useState(null);
  const [metricCategory, setMetricCategory] = useState(null);
  const [unitMeasurement, setUnitMeasurement] = useState('minutes');

  useEffect(() => {
    if (monitorsData[selectedMetric]) {
      let data = monitorsData[selectedMetric];
      if (selectedMonitor) {
        setSelectedLinearData(
          data.general_by_monitor.data.filter(
            (item) => item.user_id === selectedMonitor.user_id
          )
        );
      } else {
        setSelectedLinearData(data.general);
      }
      setUnitMeasurement(data.unit_of_measurement);
      setMetricCategory(data.category);
    }
  }, [selectedMetric, selectedMonitor, monitorsData]);

  return (
    <Grid container item xs={size}>
      <LinearChart
        size={12}
        inputIndex={'time_window'}
        inputCategories={metricCategory ? [metricCategory] : []}
        lineData={selectedLinearData}
        isLoading={isLoading}
        inputColors={['indigo', 'rose', 'teal']}
        showLegend={true}
        yAxisWidth={48}
        textOrientation={'text-left'}
        valueFormatter={valueFormatter}
      />
    </Grid>
  );
}
