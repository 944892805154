import { Alert, Box, Typography } from '@mui/material';
import React from 'react';

export default function AlertBox(props) {
  const { severity = 'info', content, sx } = props;

  return (
    <Box sx={{ px: 2, pt: 2, overflowY: 'auto', width: '100%', ...sx }}>
      <Alert severity={severity}>
        <Typography>{content}</Typography>
      </Alert>
    </Box>
  );
}
