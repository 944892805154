import { connect } from "react-redux";

import { getServiceLogs } from "core/slices/serviceMonitoring";
import { loadingServiceLogs, serviceLogs } from "core/selectors";

import LogsInfo from "../../../../components/pipelineMonitoring/monitoringGraph/InfoDrawer/Node/LogsInfo";

export default connect(
  (state) => ({
    serviceLogs: serviceLogs(state),
    loadingServiceLogs: loadingServiceLogs(state),
  }),
  {
    getServiceLogs,
  }
)(LogsInfo);
