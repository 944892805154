import { connect } from 'react-redux';

import { getSessionsScheduler } from 'core/slices/scheduler';
import { getRobotsBaldur, getRobotUuid } from 'core/slices/fleetControl';
import { getSessionsRobot } from 'core/slices/scheduler';
import {
  getSessions,
  isLoadingSessions,
  getRobotListBaldur,
  getRobotUuidValue,
  getRobotSessionsList,
  isLoadingRobotSessions
} from 'core/selectors';

import ScheduleWidget from '../../../components/widgets/ScheduleWidget';

export default connect(
  (state) => ({
    robotsListBaldur: getRobotListBaldur(state),
    robotUuid: getRobotUuidValue(state),
    sessionsList: getSessions(state),
    isLoadingSessions: isLoadingSessions(state),
    robotSessionsList: getRobotSessionsList(state),
    isLoadingRobotSessions: isLoadingRobotSessions(state)
  }),
  {
    getSessionsScheduler,
    getRobotsBaldur,
    getRobotUuid,
    getSessionsRobot
  }
)(ScheduleWidget);
