import { createReducer } from './utils';
import {
  SNACK_SET,
  FLEET_CONTROL_REQUEST,
  FLEET_CONTROL_AVAILABLE,
  FLEET_CONTROL_UNAVAILABLE,
} from './constants';

const defaultState = {
  robotListBaldur: [],
  robotVersions: [],
  newSessionsResponse: {},
  isLoadingRobotVersions: false,
  isLoadingRobotListBaldur: false,
  loadingUpdateSessions: false,
};

export const reducer = createReducer(defaultState, {
  [FLEET_CONTROL_REQUEST]: handleCustomRequest,
  [FLEET_CONTROL_AVAILABLE]: handleCustomAvailable,
  [FLEET_CONTROL_UNAVAILABLE]: handleCustomUnavailable,
});

function handleCustomRequest(state, { payload: { loadingName } }) {
  return {
    ...state,
    [loadingName]: true,
  };
}

function handleCustomAvailable(
  state,
  { payload: { keyState, loadingName, requestInfo, aditionalStates = {} } }
) {
  const x = {
    ...state,
    [loadingName]: false,
    [keyState]: requestInfo,
    ...aditionalStates,
  };
  return x;
}

function handleCustomUnavailable(
  state,
  { payload: { keyState, loadingName } }
) {
  return {
    ...state,
    [keyState]: [],
    [loadingName]: false,
  };
}

export function getRobotsBaldur(chains_list) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingRobotListBaldur';
    const keyState = 'robotListBaldur';
    dispatch({ type: FLEET_CONTROL_REQUEST, payload: { loadingName } });
    try {
      const robotsResponse = await dataSource.getRobotsBaldur(
        JSON.stringify(chains_list)
      );
      const robotsList = robotsResponse ? robotsResponse : [];
      dispatch({
        type: FLEET_CONTROL_AVAILABLE,
        payload: { keyState, loadingName, requestInfo: robotsList },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: FLEET_CONTROL_UNAVAILABLE,
        payload: { error, keyState, loadingName },
      });
    }
  };
}

export function getRobotUuid(robot_code) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = "isLoadingRobotUuid";
    const keyState = "robotUuid";
    dispatch({ type: FLEET_CONTROL_REQUEST, payload: { loadingName } });
    try {
      const robotResponse = await dataSource.getRobotUuid(robot_code);
      const robotsUuid = robotResponse ? robotResponse.data : null;
      dispatch({
        type: FLEET_CONTROL_AVAILABLE,
        payload: { keyState, loadingName, requestInfo: robotsUuid },
      });
    } catch (error) {
      console.log("error: ", error);
      dispatch({
        type: FLEET_CONTROL_UNAVAILABLE,
        payload: { error, keyState, loadingName },
      });
    }
  };
}

export function getAllVersionsRobot({
  robot_uuid,
  store,
  robot,
  can_return_latest_active,
}) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingRobotVersions';
    const keyState = 'robotVersions';
    dispatch({ type: FLEET_CONTROL_REQUEST, payload: { loadingName } });
    try {
      const versionResponse = await dataSource.getVersionsRobot({
        robot_uuid,
        store,
        robot,
        can_return_latest_active,
      });
      const versionRobot = versionResponse ? versionResponse : [];
      dispatch({
        type: FLEET_CONTROL_AVAILABLE,
        payload: { keyState, loadingName, requestInfo: versionRobot },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: FLEET_CONTROL_UNAVAILABLE,
        payload: { error, keyState, loadingName },
      });
    }
  };
}

export function postNewSessions(
  robots_uuid,
  session_name,
  oh_mode,
  start_local_time,
  aisles = [],
  active = true,
  start_date = null,
  type_session = 'Unique',
  week_days = []
) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingNewSessions';
    const keyState = 'newSessionsResponse';
    let snack;
    dispatch({ type: FLEET_CONTROL_REQUEST, payload: { loadingName } });
    try {
      const creationResponse = await dataSource.postSession(
        JSON.stringify(robots_uuid),
        session_name,
        oh_mode,
        start_local_time,
        JSON.stringify(aisles),
        active,
        start_date,
        type_session,
        JSON.stringify(week_days)
      );
      const creation = creationResponse ? creationResponse : {};
      dispatch({
        type: FLEET_CONTROL_AVAILABLE,
        payload: { requestInfo: creation, keyState, loadingName },
      });
      snack = {
        open: true,
        message: 'Session was created',
        severity: 'success',
      };
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: FLEET_CONTROL_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      snack = {
        open: true,
        message: 'There was a problem creating the session',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}

export function postSessionUpdates(
  session_ids,
  oh_mode,
  start_local_time,
  start_date,
  aisles,
  week_days
) {
  let aisleList = aisles ? JSON.stringify(aisles) : aisles;
  let weekDays = week_days ? JSON.stringify(week_days) : week_days;
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingUpdateSessions';
    const keyState = 'updateSessionMessageResponse';
    let snack;
    dispatch({ type: FLEET_CONTROL_REQUEST, payload: { loadingName } });
    try {
      const updateResponse = await dataSource.updateSession(
        JSON.stringify(session_ids),
        oh_mode,
        start_local_time,
        start_date,
        aisleList,
        weekDays
      );
      const update = updateResponse ? updateResponse : {};
      dispatch({
        type: FLEET_CONTROL_AVAILABLE,
        payload: { requestInfo: update, keyState, loadingName },
      });
      snack = {
        open: true,
        message: 'Session(s) was updated',
        severity: 'success',
      };
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: FLEET_CONTROL_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      snack = {
        open: true,
        message: 'There was a problem updating the session',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}
