import { connect } from 'react-redux';

import {
  alerts,
  batchesReceived,
  getIsSessionProgressTableLoading,
  getSessionProgressTableData,
  getSignedBlob,
  isLoadingSignedBlob,
  loadingAlerts,
  loadingNavigationData,
  navigationOptions,
  objectNames,
  processFlow
} from 'core/selectors';
import {
  getAllMetadata,
  getSessionProgressTable,
  signBlob
} from 'core/slices/pipelineMonitoring';
import { getAlerts } from 'core/slices/serviceMonitoring';

import PipelineMonitoringTable from '../../../components/pipelineMonitoring/PipelineMonitoringTable';

export default connect(
  (state) => ({
    processFlow: processFlow(state),
    objectNames: objectNames(state),
    batchesReceived: batchesReceived(state),
    navigationOptions: navigationOptions(state),
    alerts: alerts(state),
    loadingAlerts: loadingAlerts(state),
    loadingNavigationData: loadingNavigationData(state),
    signedBlob: getSignedBlob(state),
    isLoadingSignedBlob: isLoadingSignedBlob(state),
    sessionProgressTableData: getSessionProgressTableData(state),
    isSessionProgressTableLoading: getIsSessionProgressTableLoading(state),
  }),
  {
    signBlob,
    getAlerts,
    getAllMetadata,
    getSessionProgressTable,
  }
)(PipelineMonitoringTable);
