import { findIconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconComponent } from '@zippeditoolsjs/zippedi-icons'
import React from 'react'

export default function CustomIcon(props) {
  const { icon } = props;
  library.add(fas);

  return (
    <>
      {findIconDefinition({ iconName: icon.slice(3) }) ?
        <FontAwesomeIcon
          icon={icon}
        />
        :
        <IconComponent
          iconName={'warning'}
          style={{
            fontSize: '23px',
          }}
        />
      }
    </>
  )
}
