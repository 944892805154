import { connect } from 'react-redux';

import {
  getTimelineTrace,
} from 'core/slices/timelineTrace';
import {
  isLoadingTimelineTrace,
  getTimelineTraceInfo,
} from 'core/selectors';

import TimelineWidget from '../../../components/widgets/TimelineWidget';

export default connect(
  (state) => ({
    isLoading: isLoadingTimelineTrace(state),
    timelineTrace: getTimelineTraceInfo(state),
  }),
  {
    getTimelineTrace,
  }
)(TimelineWidget);
