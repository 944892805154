import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { BootstrapDialogTitle } from "../Utils";


export default function DeleteSession(props) {
  const {
    open,
    dateCellSelected,
    handleClose,
    sessionSelected,
    dialogType,
    selectedRobots,
    isLoading,
    handleOpenConfirmation
    } = props;

    const [time, setTime] = React.useState("");
    const [startAt, setStartAt] = React.useState("")


    React.useEffect(() => {
      let aux_date = new Date()
      let split_date = dateCellSelected.date.split('-')

      let startAtSession = sessionSelected && sessionSelected.start_local_time ?  sessionSelected.start_local_time : '00:00AM';

      if (startAtSession) {
        const [time, period] = startAtSession.toLowerCase().split(/(?<=^\d+)(am|pm)?$/);
        const [hours, minutes] = time.split(':')//.map(Number);
        let minutesStart = minutes.split(' ')[0];

        let adjustedHours = hours;
        if (period === 'pm' && hours !== 12) {
          adjustedHours = hours + 12;
        } else if (period === 'am' && hours === 12) {
          adjustedHours = 0;
        }

        aux_date.setFullYear(split_date[0]);
        aux_date.setMonth(split_date[1]);
        aux_date.setDate(split_date[2]);
        aux_date.setHours(adjustedHours);
        aux_date.setMinutes(minutesStart);

        setStartAt(aux_date);
      }
      else {
        setStartAt(new Date());
      }
    }, [sessionSelected])

    const handleTimeChange = (date) => {
        const options = {
          hour12: false, // Use 24-hour format
          hour: 'numeric', // Display hours
          minute: 'numeric' // Display minutes
        };
        const timeString = date.toLocaleString('en-US', options);
        setTime(timeString);
    };


  return (
    <div>
      <Dialog open={open} maxWidth={dialogType === "deleteMultiSessions" ? "md" : "sm"} fullWidth onClose={handleClose}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {dialogType === "deleteMultiSessions" ? "Delete sessions " : "Delete session"}
        </BootstrapDialogTitle>
          <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={dialogType === "deleteMultiSessions" ? 6 : 12}
              alignItems={"center"}
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"column"}
            >
              <Grid
                item
                xs={12}
                md={12}
                p={2}
                width={"100%"}
                display={"flex"}
                justifyContent={"space-around"}
                alignItems={"center"}
                flexDirection={"row"}
              >
                <Typography style={{ fontSize: "1.25rrem" }}>
                  {dateCellSelected.day + ", " + dateCellSelected.date}
                </Typography>
                {dialogType === "deleteMultiSessions" ? null : (
                  <Grid display={'flex'} justifyContent={"center"} alignItems={"end"} flexDirection={"column"}>
                    <Typography sx={{ marginTop: "1rem",color:"#1B242B" }}>
                      Store Local Time
                    </Typography>
                    <MobileTimePicker
                      sx={{ width: "75%" }}
                      width={"75%%"}
                      label={"Start at: "}
                      views={["hours", "minutes"]}
                      defaultValue={startAt}
                      disabled
                      date={time}
                      onChange={(date) => handleTimeChange(date)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          helperText="With error"
                          error
                        />
                      )}
                      slotProps={{
                        textField: {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccessTimeIcon/>
                              </InputAdornment>
                            ),
                          },
                        },
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}
              display={"flex"}
              justifyContent={"center"}
              sx={{backgroundColor: dialogType === "deleteMultiSessions" ? "#F4F3F3" :"None"}}
            >
              {dialogType === "deleteMultiSessions" ? (
                <Grid item xs={12} md={6} >
                  <List style={{ height: "10rem", overflow: "auto" }}>
                    {selectedRobots.map((item) => (
                      <ListItem>
                        <ListItemText sx={{textAlign:"center"}} primary={item.robot_code} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              ) : " "}
            </Grid>
            {/* <Grid item xs={12} md={7} marginTop={"2rem"}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Delete</InputLabel>
                <Select
                  value={dayToScheduler}
                  label="Delete"
                  onChange={handleSelectDayToScheduler}
                >
                  <MenuItem value={0}>{`Delete ${
                    dateCellSelected.day + ", " + dateCellSelected.date
                  }`}</MenuItem>
                  <MenuItem value={1}>{`Delete all ${
                    dateCellSelected.day + " of " + monthMap[selectedDate.$M]
                  }`}</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={() => handleOpenConfirmation()}
                sx={{
                  width: "10rem",
                  mb: 3,
                  mr: 3,
                  display: "flex",
                  alignItems: "center",
                  background: "#1E76E8",
                  color: "white",
                  "&:hover": {
                    background: "#1565C0",
                  },
                }}
              >
                Delete
              </Button>
              { isLoading && (<CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />) }
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
