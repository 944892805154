import { connect } from "react-redux";

import { getBatchLatency, getJobsLatencies } from "core/slices/metrics";
import {
  batchLatency,
  loadingBatchLatency,
  jobsLatencies,
  loadingJobsLatencies,
} from "core/selectors";

import MetricsInfo from "../../../../components/pipelineMonitoring/monitoringGraph/InfoDrawer/Root/MetricsInfo";

export default connect(
  (state) => ({
    batchLatency: batchLatency(state),
    loadingBatchLatency: loadingBatchLatency(state),
    jobsLatencies: jobsLatencies(state),
    loadingJobsLatencies: loadingJobsLatencies(state),
  }),
  { getBatchLatency, getJobsLatencies }
)(MetricsInfo);
