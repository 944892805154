import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";

import { CircularProgress, List, ListItem, ListItemText } from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Typography } from "@mui/material";

import { BootstrapDialogTitle } from "../Utils";
import { useTranslation } from "react-i18next";

const monthMap = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

const dayOfWeekMap = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export default function NewSessionDialog(props) {
  const {
    open,
    dateCellSelected,
    handleClose,
    selectedDate,
    dialogType,
    sessionSelected,
    handleNewSessions,
    actualRowClicked,
    isLoading,
    selectedRobots,
    handleUpdateSessions,
    selectedSessions,
  } = props;
  const { t } = useTranslation();

  const [time, setTime] = useState("");
  const [checkedOH, setCheckedOH] = useState(false);
  const [ohMode, setOhMode] = useState("OFF");
  const [sessionKey, setSessionKey] = useState("");
  const [dayToScheduler, setDayToScheduler] = useState("");
  const [sessionType, setSessionType] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [weekDayIndex, setWeekDayIndex] = useState(null);

  useEffect(() => {
    if (sessionSelected && "oh_mode" in sessionSelected) {
      setOhMode(sessionSelected.oh_mode);
    }
  }, [sessionSelected]);

  const checkSessionType = () => {
    if (sessionSelected && sessionSelected.session_type === "Exception")
      return true;
    else return false;
  };

  const handleSessionKeyChange = (event) => {
    setSessionKey(event.target.value);
  };

  const handleTimeChange = (date) => {
    const options = {
      hour: "numeric", // Display hours
      minute: "numeric", // Display minutes
      second: "numeric", // Display seconds
    };
    const timeString = date.toLocaleString("en-US-u-hc-h23", options);
    setTime(timeString);
  };

  const handleChangeSwitchOH = (event) => {
    setCheckedOH(event.target.checked);
  };

  const handleChangeSelectScanMode = (event) => {
    setOhMode(event.target.value);
  };

  const handleSelectDayToScheduler = (event) => {
    if (event.target.value === "Periodic") {
      setSessionType(event.target.value);
      setStartDate(dateCellSelected.date);
      setWeekDayIndex(dateCellSelected.index);
    } else {
      setSessionType("Unique");
      setStartDate(event.target.value);
      setWeekDayIndex(null);
    }
    setDayToScheduler(event.target.value);
  };

  const handleClick = () => {
    let weekDay = typeof weekDayIndex === 'number' ? [weekDayIndex] : weekDayIndex;
    let robot_uuids;
    if (dialogType && dialogType === "editSession") {
      handleUpdateSessions(
        [sessionSelected.id],
        ohMode,
        time,
        startDate,
        undefined,
        weekDay
      );
    } else if (dialogType && dialogType === "editMultiSessions") {
      robot_uuids = selectedRobots.map((robot) => robot.robot_uuid);
      handleUpdateSessions(
        selectedSessions,
        ohMode,
        time,
        startDate,
        undefined,
        weekDay
      );
    } else {
      if (dialogType && dialogType === "addMultiSessions") {
        robot_uuids = selectedRobots.map((robot) => robot.robot_uuid);
      } else if (dialogType && dialogType === "addSession") {
        robot_uuids = [actualRowClicked.robot_uuid];
      }
      handleNewSessions(
        robot_uuids,
        sessionKey,
        ohMode,
        time,
        undefined,
        undefined,
        startDate,
        sessionType,
        weekDay
      );
    }
  };

  return (
    <div>
      <Dialog open={open} maxWidth={"md"} fullWidth onClose={handleClose}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {["addSession", "addMultiSessions"].includes(dialogType)
            ? "Create new session"
            : " "}
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid container display={"flex"} justifyContent={"space-around"}>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  paddingLeft: "1rem",
                }}
              >
                {["addSession", "addMultiSessions"].includes(dialogType) ? (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Enter session Key"
                    type="text"
                    variant="standard"
                    value={sessionKey}
                    onChange={handleSessionKeyChange}
                    sx={{ width: "100%" }}
                  />
                ) : (
                  <Typography
                    m={2}
                    variant="h5"
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    Edit session
                  </Typography>
                )}
              </Grid>
              <Grid container item xs={12} md={6}>
                <FormControl
                  sx={{
                    width: "35%",
                    marginTop: "2rem",
                    marginLeft: "auto",
                    marginBottom: "2rem",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <InputLabel id="oh_mode-select-label">{t('overseer_app.scheduler.OH_Mode', 'OH Mode')}</InputLabel>
                  <Select
                    labelId="oh_mode-select-label"
                    id="oh_mode-select-label"
                    value={ohMode}
                    label="OH Mode"
                    onChange={handleChangeSelectScanMode}
                  >
                    <MenuItem value={"ON"}>ON</MenuItem>
                    <MenuItem value={"OFF"}>OFF</MenuItem>
                    <MenuItem value={"PRIORITY"}>PRIORITY</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              alignItems={"center"}
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
            >
              <Typography style={{ fontSize: "1.25rrem" }}>
                {dateCellSelected.day + ", " + dateCellSelected.date}
              </Typography>
              {sessionSelected &&
                ["editSession", "editMultiSession"].includes(dialogType) &&
                sessionSelected.start_local_time ? (
                <>
                  <Typography>{sessionSelected.start_local_time}</Typography>
                  <Typography>Type: {sessionSelected.type}</Typography>
                </>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display={"flex"}
              justifyContent={"center"}
            >
              <Grid
                item
                xs={12}
                md={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"end"}
                flexDirection={"column"}
              >
                <Typography sx={{ marginTop: "1rem",color:"#1B242B" }}>
                  Store Local Time
                </Typography>
                <MobileTimePicker
                  sx={{ width: "75%" }}
                  width={"75%"}
                  label={"Start at: "}
                  views={["hours", "minutes"]}
                  date={time}
                  onChange={(date) => handleTimeChange(date)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      helperText="With error"
                      error
                    />
                  )}
                  slotProps={{
                    textField: {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <AccessTimeIcon />
                          </InputAdornment>
                        ),
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} marginTop={"2rem"}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Only for</InputLabel>
                <Select
                  value={dayToScheduler}
                  label="Only for"
                  onChange={handleSelectDayToScheduler}
                >
                  <MenuItem value={dateCellSelected.date}>{`Only for ${dateCellSelected.day + ", " + dateCellSelected.date
                    }`}</MenuItem>
                  <MenuItem
                    disabled={checkSessionType()}
                    value={"Periodic"}
                  >{`Created for every ${dateCellSelected.day + " of " + monthMap[selectedDate.$M]
                    }`}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {dialogType === "addMultiSessions" ? (
              <Grid item xs={12} md={6} marginTop={"2rem"}>
                <List style={{ height: "10rem", overflow: "auto" }}>
                  {selectedRobots.map((item) => (
                    <ListItem>
                      <ListItemText
                        sx={{ textAlign: "center" }}
                        primary={item.robot_code}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={handleClick}
                sx={{
                  width: "10rem",
                  mb: 3,
                  mr: 3,
                  display: "flex",
                  alignItems: "center",
                  background: "#1E76E8",
                  color: "white",
                  "&:hover": {
                    background: "#1565C0",
                  },
                }}
              >
                Save
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
