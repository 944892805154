import { createReducer } from './utils';
import {
  handleRequest,
  handleAvailable,
  handleUnavailable,
} from '../reducerHandlers';
import {
  ACTION_WIDGET_REQUEST,
  ACTION_WIDGET_AVAILABLE,
  ACTION_WIDGET_UNAVAILABLE,
  SNACK_SET,
} from './constants';

// Reducer

const defaultState = {
  isActionHistoryLoading: false,
  actionsHistory: [],

  storeMapInfo: {},
  isStoreMapInfoLoading: false,
};

export const reducer = createReducer(defaultState, {
  [ACTION_WIDGET_REQUEST]: handleRequest,
  [ACTION_WIDGET_AVAILABLE]: handleAvailable,
  [ACTION_WIDGET_UNAVAILABLE]: handleUnavailable,
});

// Actions

export function getActionsHistory(store, robotCode) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'actionsHistory';
    const loadingName = 'isActionHistoryLoading';
    dispatch({
      type: ACTION_WIDGET_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getActionsHistory(store, robotCode);
      dispatch({
        type: ACTION_WIDGET_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.actions,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ACTION_WIDGET_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the action history.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getStoreMap({ robot_code }) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'storeMapInfo';
    const loadingName = 'isStoreMapInfoLoading';
    dispatch({
      type: ACTION_WIDGET_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      let response = await dataSource.getStoreMap({ robot_code });
      dispatch({
        type: ACTION_WIDGET_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ACTION_WIDGET_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the store map.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}


export function postNewMap({ fileForm }) {
  return async (dispatch, _, { services: { dataSource } }) => {
    try {
      let response = await dataSource.postNewMap(fileForm);
    } catch (error) {
      console.error('error: ', error);
      const snack = {
        open: true,
        message: 'There was an error posting the new store map.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
