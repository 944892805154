import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Tab,
  Tabs,
  Grid,
  Typography,
  Card,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TabPanel from '../tools/TabPanel';

import { a11yProps } from '../Utils';
import AlertBox from '../tools/AlertBox';

function HardwareComponentsWidget(props) {
  const {
    getHardwareComponents,
    hardwareComponents,
    loadingHardwareComponents,

    getDefaultHardwareComponents,
    defaultHardwareComponents,
    loadingDefaultHardwareComponents,
    store,
    robot,
  } = props;

  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    getHardwareComponents({
      robot_id: `${store}-${robot}`,
      is_factory_hardware: false,
    });
    getDefaultHardwareComponents({
      robot_id: `${store}-${robot}`,
      is_factory_hardware: true,
    });
  }, [store, robot]);

  return loadingHardwareComponents || loadingDefaultHardwareComponents ? (
    <Box
      variant="rectangular"
      minHeight={250}
      height={'100%'}
      sx={{
        m: 2,
        backgroundColor: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
      }}
    >
      <CircularProgress />
      <Typography>Loading Hardware Components...</Typography>
    </Box>
  ) : (
    <Card
      sx={{
        m: 2,
        minHeight: 250,
        height: '100%',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={t(
              'overseer_app.widget.hardware_components',
              'Hardware Components'
            )}
            {...a11yProps(0, { textTransform: 'initial' })}
          />
          <Tab
            label={t('overseer_app.widget.factory_default', 'Factory Default')}
            {...a11yProps(1, { textTransform: 'initial' })}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <Grid container>
          <Grid item xs={12} sx={{ overflowY: 'auto', maxHeight: 200 }}>
            {hardwareComponents?.length > 0 ? (
              hardwareComponents.map((component, idx) => (
                <Box
                  key={idx}
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',

                    gap: 1,
                    p: 1,
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                      {component.component_name} - {component.component_model}
                    </Typography>
                    <Typography sx={{ fontSize: '12px' }}>
                      {component.component_serial_number}
                      {component.metadata?.units
                        ? ` - ${component.metadata?.units} Units`
                        : null}{' '}
                    </Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <AlertBox
                severity="info"
                content={t(
                  'overseer_app.widget.no_hardware_components',
                  'No Hardware components available'
                )}
              />
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Grid container>
          <Grid item xs={12} sx={{ overflowY: 'auto', maxHeight: 200 }}>
            {defaultHardwareComponents?.length > 0 ? (
              defaultHardwareComponents.map((component, idx) => (
                <Box
                  key={idx}
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',

                    gap: 1,
                    p: 1,
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                      {component.component_name} - {component.component_model}
                    </Typography>
                    <Typography sx={{ fontSize: '12px' }}>
                      {component.component_serial_number}
                      {component.metadata?.units
                        ? ` - ${component.metadata?.units} Units`
                        : null}{' '}
                    </Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <AlertBox
                severity="info"
                content={t(
                  'overseer_app.widget.no_factory_hardware_components',
                  'No Factory Hardware components available...'
                )}
              />
            )}
          </Grid>
        </Grid>
      </TabPanel>
    </Card>
  );
}

export default HardwareComponentsWidget;
