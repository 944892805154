import { connect } from 'react-redux';

import {
  getJobsCompleted,
  getAllMetadata,
  getBatchesByAisle,
  signBlob,
} from "core/slices/pipelineMonitoring";
import { getAlerts } from "core/slices/serviceMonitoring";
import {
  processFlow,
  jobsCompleted,
  processesData,
  totalAisles,
  objectNames,
  batchesReceived,
  loadingMonitoringData,
  storeData,
  navigationData,
  navigationOptions,
  loadingAlerts,
  alerts,
  loadingBatchesByAisle,
  batchesByAisle,
  loadingNavigationData,
  getSignedBlob,
  isLoadingSignedBlob,
} from "core/selectors";

import PipelineMonitoring from '../../../components/pipelineMonitoring/PipelineMonitoring';

export default connect(
  (state) => ({
    processFlow: processFlow(state),
    jobsCompleted: jobsCompleted(state),
    processesData: processesData(state),
    totalAisles: totalAisles(state),
    objectNames: objectNames(state),
    batchesReceived: batchesReceived(state),
    loadingMonitoringData: loadingMonitoringData(state),
    storeData: storeData(state),
    navigationData: navigationData(state),
    navigationOptions: navigationOptions(state),
    alerts: alerts(state),
    loadingAlerts: loadingAlerts(state),
    loadingBatchesByAisle: loadingBatchesByAisle(state),
    batchesByAisle: batchesByAisle(state),
    loadingNavigationData: loadingNavigationData(state),
    signedblob: getSignedBlob(state),
    isLoadingSignedBlob: isLoadingSignedBlob(state),
  }),
  {
    getJobsCompleted,
    getAllMetadata,
    getAlerts,
    getBatchesByAisle,
    signBlob,
  }
)(PipelineMonitoring);
