import { useState } from 'react';
import { Box, Grid, Pagination } from '@mui/material';
import { Text, Title } from '@zippeditoolsjs/dashboards';
import { slice } from 'lodash';
import { BarList } from '@tremor/react';

export default function BarListChart(props) {
  const { chartData, size, subtitle, textOrientation, title, valueFormatter } =
    props;
  const ItemsPerPage = 10;

  const [page, setPage] = useState(1);
  const startIndex = (page - 1) * ItemsPerPage;
  const endIndex = page * ItemsPerPage;

  const slicedData = slice(chartData, startIndex, endIndex);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Grid container item xs={size} padding={2}>
      <Grid item xs={12}>
        <Box className={textOrientation}>
          <Title className="mt-2">{title}</Title>
          <Text className={textOrientation}>{subtitle}</Text>
        </Box>
      </Grid>
      {chartData?.length ? (
        <>
          <Grid item xs={12} container alignItems="center">
            <BarList
              index={'robot'}
              category={'time'}
              data={slicedData}
              style={{ width: '100%' }}
              valueFormatter={valueFormatter}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Pagination
                count={Math.ceil(chartData.length / ItemsPerPage)}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          </Grid>
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Title>No data</Title>
        </Box>
      )}
    </Grid>
  );
}
