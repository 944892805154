export const TIER_COLOR = Object.freeze({
  0: Symbol('pastelYellow'),
  1: Symbol('pastelEmerald'),
  2: Symbol('pastelSky'),
  3: Symbol('pastelViolet'),
  4: Symbol('teal'),
  5: Symbol('pink'),
});

export const getTierColor = (tier) => {
  let tierColor = TIER_COLOR[tier];
  tierColor = tierColor ? tierColor.description : 'slate';
  return tierColor;
};

/**
 * Transforms a hex color into a pastel color.
 * @param {string} hex - The hex color to transform.
 * @returns {string} - The pastel color in hex format.
 */
export function hexToPastel(hex) {
  // Ensure the hex is valid
  if (!/^#([0-9A-F]{3}){1,2}$/i.test(hex)) {
    throw new Error('Invalid hex color');
  }

  // Convert the hex to RGB
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  // Mix the color with white
  r = Math.floor((r + 255) / 2);
  g = Math.floor((g + 255) / 2);
  b = Math.floor((b + 255) / 2);

  // Convert the pastel color back to hex
  const pastelHex = `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;

  return pastelHex;
}
