import { Box, Card, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AlertBox from '../tools/AlertBox';
import Icon from '../utils/Icon';

export default function TimelineInfoWidget(props) {
  const { selectedDotInfo } = props;
  const { t } = useTranslation();

  const [parsedDotInfo, setParsedDotInfo] = React.useState([]);
  const [infoType, setInfoType] = React.useState('');

  useEffect(() => {
    if (selectedDotInfo) {
      let parsedDotInfo = [];
      if (selectedDotInfo.Action) {
        setInfoType('action');
        parsedDotInfo = selectedDotInfo.username?.map((user, index) => {
          return {
            name: user,
            robot_id: selectedDotInfo.robot_id,
            description: selectedDotInfo.z[index],
            timestamp: selectedDotInfo.x,
            icon: 'person-circle',
            color: 'indigo',
            robotEventId: selectedDotInfo.robot_event_id?.[index] ?? null,
            metadata: selectedDotInfo.metadata?.[index] ?? null,
          }
        }) ?? [];
      }
      else if (selectedDotInfo.alert_id) {
        setInfoType('incident');
        const nRaisedIncident = selectedDotInfo.categoryClicked.split('incident_event_')[1];
        const timestamp = selectedDotInfo.x;
        const suppressedAt = selectedDotInfo.supressed_at;
        parsedDotInfo = selectedDotInfo.alert_id?.map((incident, index) => {
          return {
            name: incident,
            robot_id: selectedDotInfo.robot_id,
            description: suppressedAt ? 'Type: Suppressed Incident' : 'Type: New Incident',
            nRaisedIncident: nRaisedIncident ? Number(nRaisedIncident) + 1 : 1,
            suppressedAt: suppressedAt,
            timestamp: timestamp,
            icon: suppressedAt ? 'checkmark-circle' : 'alert-circle',
            color: suppressedAt ? 'gray' : 'amber',
            robotEventId: selectedDotInfo.robot_event_id?.[index],
          }
        }) ?? [];
      } else if (selectedDotInfo.metadata) {
        setInfoType('metadata');
        parsedDotInfo = selectedDotInfo.metadata?.map((metadata, index) => {
          return {
            name: selectedDotInfo.z,
            robot_id: selectedDotInfo.robot_id,
            description: '',
            timestamp: selectedDotInfo.x,
            icon: 'information-circle',
            color: 'red',
            robotEventId: selectedDotInfo.robot_event_id?.[index],
            metadata: metadata,
          }
        }) ?? [];
      }
      if (selectedDotInfo.line_dead_time) {
        parsedDotInfo.push({
          name: selectedDotInfo.z,
          robot_id: selectedDotInfo.robot_id,
          description: JSON.parse(selectedDotInfo.metadata_dead_time).description,
          timestamp: selectedDotInfo.x,
          icon: 'time',
          color: 'slate',
          robotEventId: selectedDotInfo.robot_event_id,
          metadata: JSON.parse(selectedDotInfo.metadata_dead_time),
        });
      }
      setParsedDotInfo(parsedDotInfo);
    }
  }, [selectedDotInfo]);


  return (
    <Card
      sx={{
        p: 0,
        pb: 1,
        width: '100%',
        height: '100%',
      }}
    >
      <Typography sx={{ pl: 3, pt: 3 }}>Timeline Info</Typography>
      <Divider />
      {
        !parsedDotInfo.length ?
          <AlertBox severity="info" content={t('overseer_app.general.nothing_selected', 'Nothing selected')} />
          :
          <List
            sx={{
              width: "100%",
              maxHeight: 500,
              bgcolor: 'background.paper',
              overflow: 'auto',
            }}
            dense
            component="div">
            {
              parsedDotInfo.map((incident, index) => {
                return (
                  <>
                    <ListItem key={`TimeLineListItem${index}`}>
                      <ListItemAvatar>
                        <Icon
                          iconName={incident.icon}
                          sx={{ alignSelf: 'center' }}
                          color={incident.color}
                        />
                      </ListItemAvatar>
                      <ListItemText sx={{ fontSize: '16px' }}
                        primary={
                          <>
                            <Typography variant='h6'> {incident.name} </Typography>
                            <Divider sx={{ width: '70%' }} />
                          </>
                        }
                        secondary={
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {incident.description}
                            </Typography>
                            <Typography variant='small' color='textSecondary'> Created At: {incident.timestamp} </Typography>
                            {
                              infoType === 'incident' ?
                                <>
                                  <Typography variant='small' color='textSecondary'> Incident Number: {incident.nRaisedIncident} </Typography>
                                  {
                                    incident.suppressedAt &&
                                    <Typography variant='small' color='textSecondary'> Suppressed At: {incident.suppressedAt} </Typography>
                                  }
                                </> : null
                            }
                            <Typography variant='small' color='textSecondary'> Event id: {incident.robotEventId ?? '-'} </Typography>
                            <Typography variant='small' color='textSecondary'> Robot id: {incident.robot_id ?? '-'} </Typography>
                            {
                              incident.metadata &&
                              <Typography variant='small' color='textSecondary'> Metadata: <pre> {JSON.stringify(incident.metadata, null, 2)} </pre></Typography>
                            }
                          </Box>
                        }
                      />
                    </ListItem>
                    <Divider sx={{ mx: 3 }} />
                  </>
                )
              })
            }
          </List>
      }
    </Card>
  );
}
