import { connect } from 'react-redux';
import CreateGroup from '../../../../components/alert/group/CreateGroup';

import {
    getGroupsData,
    createGroup,
    deleteGroup,
    updateGroup
} from 'core/slices/group';

import {
    getGroupData,
    isLoadingGroupData
} from 'core/selectors';

export default connect(
    state => ({
        groupsDataList: getGroupData(state),
        isLoadingGroupData: isLoadingGroupData(state)
    }),
    {
        getGroupsData,
        createGroup,
        deleteGroup,
        updateGroup
    }
)(CreateGroup);
