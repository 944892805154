import React from 'react'
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Typography,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  CircularProgress,
  Switch,
  FormGroup,
  FormControlLabel,
  Chip,
  Autocomplete
} from "@mui/material";


export default function UpdateDialog(props) {
  const {
    open,
    handleDialog,
    parameterUpdate,
    paramToEdit,
    updatePost,
    loading
  } = props;

  const [newValue, setNewValue] = useState("");
  const [checked, setChecked] = React.useState(true);
  const [chips, setChips] = React.useState([]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && event.target.value.trim() !== '') {
      const newChip = event.target.value.trim();
      setChips((prevChips) => [...prevChips, newChip]);
      event.target.value = '';
    }
  };

  const handleDeleteChip = (chipToDelete) => {
    setChips((prevChips) => prevChips.filter((chip) => chip !== chipToDelete));
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setNewValue(event.target.checked)
  };

  const updateParameters = () => {
    let value = newValue;
    if (typeof paramToEdit.values[0].value === "object") {
      value = JSON.stringify(chips);
    }
    updatePost(paramToEdit, value);
    setNewValue("");
    setChips([]);
  };

  return (
    <>
      <Dialog open={open} onClose={handleDialog} maxWidth={"md"} fullWidth>
        <DialogTitle>{`Edit parameter: ${parameterUpdate}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Paper>
              <Grid container spacing={3} p={1}>
                {paramToEdit ? paramToEdit.values.map((row, index) => (
                  <Grid item xs key={index} sx={{ overflowWrap: 'break-word', hyphens: 'auto' }}>
                    {`${row.robot_code} : ${row.value}`}
                  </Grid>
                  ))
                : null}
              </Grid>
            </Paper>
          </DialogContentText>
        </DialogContent>
        <Grid container spacing={2} alignItems="center" justifyContent="center" padding={1}>
          <Grid item xs={4}>
            <Typography style={{ display: "flex", justifyContent: "center" }}>
              {`${parameterUpdate} New value: `}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            { paramToEdit && typeof paramToEdit.values[0].value === "boolean" ?
              <FormGroup>
                <FormControlLabel label={newValue.toString()} control={
                  <Switch
                    checked={newValue}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                />
              </FormGroup>
              :
              <>
              { paramToEdit && typeof paramToEdit.values[0].value === "object" ? 
              <>
                <Autocomplete
                  multiple
                  freeSolo
                  value={chips}
                  options={[]}
                  onChange={(event, newValue) => {
                    setChips(newValue);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        label={option}
                        onDelete={() => handleDeleteChip(option)}
                        {...getTagProps({ index })}
                        style={{ marginRight: 5, marginBottom: 5 }}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter value"
                      onKeyPress={handleKeyPress}
                    />
                  )}
                />
                <Typography variant="subtitle2">This value is an array, so to enter values, you must type and press enter to store the new string of values</Typography>
                </>
                :
                <TextField
                  label="Enter new Value"
                  value={newValue}
                  onChange={(event) => {
                    setNewValue(event.target.value);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "60%",
                  }}
                />
              }
              </>
            }
          </Grid>
          <Grid item xs={4} style={{ position: 'relative' }}>
            <Button
              disabled={loading}
              fullWidth
              variant="contained"
              color="primary"
              onClick={updateParameters}
            >
              Change Value
            </Button>
            { loading && (<CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />) }
          </Grid>
        </Grid>
    </Dialog>
  </>
  )
}