import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Divider,
  CircularProgress,
  Input,
  Slider,
  Button,
} from "@mui/material";

import { checkViewOnlyPermision } from "../../../Utils";

function MetadataInfo(props) {
  const { edge, getTasksMetadata, updateTasksMetadata, navigationOptions } =
    props;
  const [maxAttempts, setMaxAttempts] = useState(null);
  const [maxConcurrentDispatches, setMaxConcurrentDispatches] = useState(null);
  const [maxDispatches, setMaxDispatches] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metadata, setMetadata] = useState({});

  const getMetadata = async () => {
    try {
      setLoading(true);
      const metadata = await getTasksMetadata({
        service_name: edge.data.child.service_name,
        project_id: edge.data.child.queue_project_id,
        task_name: edge.data.child.cloud_tasks_queue,
      });
      setMaxAttempts(metadata.max_attempts);
      setMaxConcurrentDispatches(metadata.max_concurrent_dispatches);
      setMaxDispatches(metadata.max_dispatches);
      setMetadata(metadata);
      setLoading(false);
    } catch (error) {
      setMetadata(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    getMetadata();
  }, []);

  const handleUpdateTasksMetadata = async () => {
    setLoading(true);
    const metadata = await updateTasksMetadata({
      service_name: edge.data.child.service_name,
      project_id: edge.data.child.queue_project_id,
      task_name: edge.data.child.cloud_tasks_queue,
      changes: {
        max_dispatches: maxDispatches,
        max_concurrent_dispatches: maxConcurrentDispatches,
        max_attempts: maxAttempts,
      },
    });

    setMaxAttempts(metadata.max_attempts);
    setMaxConcurrentDispatches(metadata.max_concurrent_dispatches);
    setMaxDispatches(metadata.max_dispatches);
    setMetadata(metadata);
    setLoading(false);
  };
  if (!metadata) {
    return (
      <Box>
        <Typography
          sx={{
            margin: 1,
            color: "white",
            fontWeight: "bold",
            fontSize: 24,
            marginX: 3,
          }}
        >
          {edge.data.child.cloud_tasks_queue}
        </Typography>

        <Typography
          sx={{
            color: "lightgrey",
            fontSize: 16,
            marginX: 3,
            marginBottom: 1,
          }}
        >
          {edge.data.child.display_name}
          {edge.data.child.description
            ? ` - ${edge.data.child.description}`
            : null}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 1,
            paddingY: 2,
          }}
        >
          <Typography
            sx={{
              color: "#7c909b",
              fontSize: 18,
              fontWidth: "bold",
            }}
          >
            No metadata available for this service...
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Typography
        sx={{
          margin: 1,
          color: "white",
          fontWeight: "bold",
          fontSize: 24,
          marginX: 3,
        }}
      >
        {edge.data.child.cloud_tasks_queue}
      </Typography>

      <Typography
        sx={{ color: "lightgrey", fontSize: 16, marginX: 3, marginBottom: 1 }}
      >
        {edge.data.child.display_name}
        {edge.data.child.description
          ? ` - ${edge.data.child.description}`
          : null}
      </Typography>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 1,
            paddingY: 2,
          }}
        >
          <CircularProgress sx={{ color: "#7c909b" }} />
          <Typography
            sx={{ color: "#7c909b", fontSize: 18, fontWidth: "bold" }}
          >
            Loading data...
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            margin: 2,
          }}
        >
          <Box
            sx={{
              marginY: 3,
              backgroundColor: "#e9e9f1",
              padding: 2,
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 2,
              }}
            >
              <Typography>Status: {metadata.state}</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 3,
                alignItems: "center",
                marginRight: 2,
                marginY: 2,
              }}
            >
              <Typography sx={{ width: "fit-content" }}>
                {" "}
                Max Attempts:
              </Typography>
              <Input
                value={maxAttempts}
                onChange={(e) => setMaxAttempts(parseInt(e.target.value))}
                disabled={checkViewOnlyPermision(navigationOptions, "Pipeline")}
                sx={{ width: "70px" }}
                inputProps={{
                  step: 1,
                  min: 1,
                  max: 100,
                  type: "number",
                }}
              />
              <Slider
                sx={{ width: "200px" }}
                disabled={checkViewOnlyPermision(navigationOptions, "Pipeline")}
                value={maxAttempts}
                min={1}
                max={100}
                onChange={(e, newValue) => setMaxAttempts(parseInt(newValue))}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                marginRight: 2,
                marginY: 2,
                justifyContent: "space-between",
              }}
            >
              <Typography>Max Concurrent Dispatches:</Typography>
              <Input
                value={maxConcurrentDispatches}
                disabled={checkViewOnlyPermision(navigationOptions, "Pipeline")}
                onChange={(e) =>
                  setMaxConcurrentDispatches(parseInt(e.target.value))
                }
                sx={{ width: "70px" }}
                inputProps={{
                  step: 1,
                  min: 1,
                  max: 1000,
                  type: "number",
                }}
              />
              <Slider
                sx={{ width: "200px" }}
                value={maxConcurrentDispatches}
                disabled={checkViewOnlyPermision(navigationOptions, "Pipeline")}
                min={1}
                max={1000}
                onChange={(e, newValue) =>
                  setMaxConcurrentDispatches(parseInt(newValue))
                }
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 3,
                alignItems: "center",
                marginRight: 2,
                marginY: 2,
                justifyContent: "space-between",
              }}
            >
              <Typography>Max Dispatches:</Typography>
              <Input
                value={maxDispatches}
                disabled={checkViewOnlyPermision(navigationOptions, "Pipeline")}
                onChange={(e) => setMaxDispatches(parseInt(e.target.value))}
                sx={{ width: "70px" }}
                inputProps={{
                  step: 1,
                  min: 1,
                  max: 1000,
                  type: "number",
                }}
              />
              <Slider
                sx={{ width: "200px" }}
                disabled={checkViewOnlyPermision(navigationOptions, "Pipeline")}
                value={maxDispatches}
                min={1}
                max={1000}
                onChange={(e, newValue) => setMaxDispatches(parseInt(newValue))}
              />
            </Box>
            <Button
              variant="contained"
              disabled={checkViewOnlyPermision(navigationOptions, "Pipeline")}
              sx={{ height: "30px", width: "100%" }}
              onClick={() =>
                window.confirm(
                  `Are you sure you want to update ${edge.data.child.cloud_tasks_queue} metadata?
              \ Max Attemps: ${maxAttempts} 
              \ Max Concurrent Dispatches: ${maxConcurrentDispatches}  
              \ Max Dispatches: ${maxDispatches} 
             `
                )
                  ? handleUpdateTasksMetadata()
                  : null
              }
            >
              Update service
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default MetadataInfo;
