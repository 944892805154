import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  Modal,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Button,
} from '@mui/material';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: '#E8E8E8',
  borderRadius: '10px',
  boxShadow: 24,
  padding: 2,
};

function ChangeReasonsModal(props) {
  const {
    component,
    open,
    onClose,
    changeMotives,
    patchChangeReasons,
    loadingPatchChangeReasons,
  } = props;
  const [motive, setMotive] = useState(
    changeMotives
      .map((motive) => motive.description)
      .includes(component.change_motives)
      ? component.change_motives
      : !component.change_motives
      ? ''
      : 'Other'
  );
  const [comment, setComment] = useState(component.change_reasons);
  const [otherMotive, setOtherMotive] = useState(
    changeMotives
      .map((motive) => motive.description)
      .includes(component.change_motives)
      ? ''
      : component.change_motives
  );

  const sendComments = () => {
    const data = {
      servicing_id: component.id,
      change_motives: motive === 'Other' ? otherMotive : motive,
      change_reasons: comment,
    };
    patchChangeReasons(data);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      {loadingPatchChangeReasons ? (
        <Box>Loading...</Box>
      ) : (
        <Box sx={style}>
          <Box>
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              [{component.component_name}]
            </Typography>
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              {component.replacing_by?.component_model} was replaced for{' '}
              {component.component_model}
            </Typography>
          </Box>

          <Box sx={{ width: '100%', fontSize: '13px', marginY: 2 }}>
            <Typography sx={{ marginLeft: 1 }}>Motive</Typography>
            <Select
              fullWidth
              value={motive}
              onChange={(e) => setMotive(e.target.value)}
              sx={{ backgroundColor: 'white' }}
              size="small"
            >
              {changeMotives?.map((m) => {
                return (
                  <MenuItem value={m.description}>{m.description}</MenuItem>
                );
              })}
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
          </Box>

          {motive === 'Other' ? (
            <Box sx={{ width: '100%', fontSize: '13px', marginY: 2 }}>
              <Typography sx={{ marginLeft: 1 }}>Other Motive</Typography>
              <TextField
                value={otherMotive}
                size="small"
                fullWidth
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => setOtherMotive(e.target.value)}
              />
            </Box>
          ) : null}

          <Box sx={{ width: '100%', fontSize: '13px', marginY: 2 }}>
            <Typography sx={{ marginLeft: 1 }}>Reasons/Comments</Typography>
            <TextField
              value={comment}
              size="small"
              multiline
              minRows={3}
              maxRows={5}
              fullWidth
              sx={{ backgroundColor: 'white' }}
              onChange={(e) => setComment(e.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              disabled={
                (motive === 'Other' && otherMotive === '') ||
                motive === '' ||
                comment === ''
              }
              variant="contained"
              onClick={() => sendComments()}
            >
              Send
            </Button>
          </Box>
        </Box>
      )}
    </Modal>
  );
}

export default ChangeReasonsModal;
