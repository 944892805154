import { createReducer } from './utils';
import {
  GET_HARDWARE_COMPONENTS,
  GET_HARDWARE_COMPONENTS_SUCCESS,
  GET_HARDWARE_COMPONENTS_FAILURE,
  GET_DEFAULT_HARDWARE_COMPONENTS,
  GET_DEFAULT_HARDWARE_COMPONENTS_SUCCESS,
  GET_DEFAULT_HARDWARE_COMPONENTS_FAILURE,
} from './constants';

const defaultState = {
  loadingHardwareComponents: false,
  hardwareComponents: [],
  hardwareComponentsErrorMessage: null,

  loadingDefaultHardwareComponents: false,
  defaultHardwareComponents: [],
  defaultHardwareComponentsErrorMessage: null,
};

export const reducer = createReducer(defaultState, {
  [GET_HARDWARE_COMPONENTS]: handleGetHardwareComponents,
  [GET_HARDWARE_COMPONENTS_SUCCESS]: handleGetHardwareComponentsSuccess,
  [GET_HARDWARE_COMPONENTS_FAILURE]: handleGetHardwareComponentsFailure,

  [GET_DEFAULT_HARDWARE_COMPONENTS]: handleGetDefaultHardwareComponents,
  [GET_DEFAULT_HARDWARE_COMPONENTS_SUCCESS]:
    handleGetDefaultHardwareComponentsSuccess,
  [GET_DEFAULT_HARDWARE_COMPONENTS_FAILURE]:
    handleGetDefaultHardwareComponentsFailure,
});

function handleGetHardwareComponents(state) {
  return {
    ...state,
    loadingHardwareComponents: true,
    hardwareComponentsErrorMessage: null,
  };
}

function handleGetHardwareComponentsSuccess(
  state,
  { payload: { hardwareComponents } }
) {
  return {
    ...state,
    hardwareComponents,
    loadingHardwareComponents: false,
    hardwareComponentsErrorMessage: null,
  };
}

function handleGetHardwareComponentsFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingHardwareComponents: false,
    hardwareComponentsErrorMessage: error,
  };
}

export function getHardwareComponents({ robot_id }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_HARDWARE_COMPONENTS });
    try {
      const hardwareComponents = await dataSource.getHardwareComponents({
        robot_id,
      });

      dispatch({
        type: GET_HARDWARE_COMPONENTS_SUCCESS,
        payload: { hardwareComponents },
      });
    } catch (error) {
      dispatch({
        type: GET_HARDWARE_COMPONENTS_FAILURE,
        payload: { error },
      });
    }
  };
}

function handleGetDefaultHardwareComponents(state) {
  return {
    ...state,
    loadingDefaultHardwareComponents: true,
    defaultHardwareComponentsErrorMessage: null,
  };
}

function handleGetDefaultHardwareComponentsSuccess(
  state,
  { payload: { defaultHardwareComponents } }
) {
  return {
    ...state,
    defaultHardwareComponents,
    loadingDefaultHardwareComponents: false,
    defaultHardwareComponentsErrorMessage: null,
  };
}

function handleGetDefaultHardwareComponentsFailure(
  state,
  { payload: { error } }
) {
  return {
    ...state,
    loadingDefaultHardwareComponents: false,
    defaultHardwareComponentsErrorMessage: error,
  };
}

export function getDefaultHardwareComponents({
  robot_id,
  is_factory_hardware,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_DEFAULT_HARDWARE_COMPONENTS });
    try {
      const defaultHardwareComponents =
        await dataSource.getDefaultHardwareComponents({
          robot_id,
          is_factory_hardware,
        });

      dispatch({
        type: GET_DEFAULT_HARDWARE_COMPONENTS_SUCCESS,
        payload: { defaultHardwareComponents },
      });
    } catch (error) {
      dispatch({
        type: GET_DEFAULT_HARDWARE_COMPONENTS_FAILURE,
        payload: { error },
      });
    }
  };
}
