import { connect } from 'react-redux';

import {
  getStoredFootageBucket,
  getStoredResolutionOptions,
  getStoredSpeedOptions,
  isFootageBucketLoading,
  isResolutionOptionsLoading,
  isSpeedOptionsLoading,
  userInfo,
} from 'core/selectors';
import {
  getFootageBucket,
  getResolutionOptions,
  getSpeedOptions,
} from 'core/slices/cameraWidgets';
import { setSnackAlert } from 'core/slices/snackAlert';
import { postUserActions } from 'core/slices/robotMonitoring';

import CameraOnDemandWidget from '../../../components/widgets/CameraOnDemandWidget';

export default connect(
  (state) => ({
    userInfo: userInfo(state),
    footageBucket: getStoredFootageBucket(state),
    speedOptions: getStoredSpeedOptions(state),
    resolutionOptions: getStoredResolutionOptions(state),
    isSpeedOptionsLoading: isSpeedOptionsLoading(state),
    isResolutionOptionsLoading: isResolutionOptionsLoading(state),
    isFootageBucketLoading: isFootageBucketLoading(state),
  }),
  {
    setSnackAlert,
    getSpeedOptions,
    postUserActions,
    getFootageBucket,
    getResolutionOptions,
  }
)(CameraOnDemandWidget);
