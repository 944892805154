import React, { useState } from 'react';

import { Box, Typography, Tooltip, IconButton, Modal } from '@mui/material';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import EditIcon from '@mui/icons-material/Edit';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChangeReasonsModal from '../../../redux/containers/widgets/Components/ChangeReasonsModal';

function ServicingRow(props) {
	const { component, changeMotives } = props;
	const [openDetail, setOpenDetail] = useState(false);
	const [openModal, setOpenModal] = useState(false);

	return (
		<Box
			sx={{
				borderBottom: 1,
				borderColor: 'divider',
				gap: 1,
				p: 1,
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<ChangeReasonsModal
				component={component}
				changeMotives={changeMotives}
				open={openModal}
				onClose={() => setOpenModal(false)}
			/>

			<IconButton color="primary" onClick={() => setOpenDetail(!openDetail)}>
				{openDetail ? <ExpandMoreIcon /> : <ChevronRightIcon />}
			</IconButton>

			<Box
				sx={{
					cursor: 'pointer',
					display: 'flex',
					alignItems: 'start',
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<Box onClick={() => setOpenDetail(!openDetail)}>
					<Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
						{component.component_name} -{' '}
						{component.replacing_by?.component_model} was replaced for{' '}
						{component.component_model}
					</Typography>
					<Typography sx={{ fontSize: '12px' }}>
						{component.created_at}{' '}
					</Typography>

					{openDetail ? (
						<Box>
							<Typography sx={{ fontSize: '12px' }}>
								{component.is_manual ? '[MANUAL SERVICING]' : null}
							</Typography>

							{component.author ? (
								<Typography sx={{ fontSize: '12px' }}>
									{component.author}: {component.change_reasons}
								</Typography>
							) : (
								<Typography sx={{ fontSize: '12px' }}>
									No change reasons provided...
								</Typography>
							)}
						</Box>
					) : null}
				</Box>
				<Tooltip title="Add/Edit Change Reasons ">
					<IconButton color="primary" onClick={() => setOpenModal(true)}>
						<EditIcon />
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
	);
}

export default ServicingRow;
