import React, { useState } from 'react';

import { Alert, Typography, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';

function ServiceAlert(props) {
  const { alertInfo } = props;

  const [detail, setDetail] = useState(false);

  const { t } = useTranslation();

  return (
    <Alert
      sx={{ display: 'flex', alignItems: 'center', marginY: 2 }}
      severity="error"
      action={
        detail ? (
          <ExpandLessIcon onClick={() => setDetail(!detail)} />
        ) : (
          <ExpandMoreIcon onClick={() => setDetail(!detail)} />
        )
      }
    >
      <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
        Error at {alertInfo.incident.resource.labels.project_id}/
        {alertInfo.incident.resource.type === 'cloud_tasks_queue'
          ? alertInfo.incident.resource.labels.queue_id
          : alertInfo.incident.resource.type ===
            'aiplatform.googleapis.com/Endpoint'
          ? alertInfo.incident.resource.labels.endpoint_id
          : alertInfo.incident.resource.labels.service_name}{' '}
        (policy name: {alertInfo.incident.policy_name})
      </Typography>
      {detail ? (
        <>
          <Typography sx={{ fontSize: 13 }}>
            {alertInfo.incident.condition_name} at [
            {alertInfo.incident.resource.labels.project_id}/
            {alertInfo.incident.resource.type === 'cloud_tasks_queue'
              ? alertInfo.incident.resource.labels.queue_id
              : alertInfo.incident.resource.type ===
                'aiplatform.googleapis.com/Endpoint'
              ? alertInfo.incident.resource.labels.endpoint_id
              : alertInfo.incident.resource.labels.revision_name}
            ] is above the threshold of{' '}
            {parseFloat(alertInfo.incident.threshold_value)} with a value of{' '}
            {parseFloat(alertInfo.incident.observed_value)}.
          </Typography>
        </>
      ) : null}
    </Alert>
  );
}

export default ServiceAlert;
