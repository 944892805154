import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Divider,
  Modal,
  CircularProgress,
  Stack,
  Chip,
} from '@mui/material';
import LineGraph from '../../LineGraph';
import Revisions from '../../../../../redux/containers/pipelineMonitoring/Revisions';
import ServiceAlert from '../../../ServiceAlert';
import { useParams } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  zIndex: 100,
};

function MetricsInfo(props) {
  const {
    node,
    getRequestCounts,
    requestCounts,
    loadingRequestCounts,
    getContainerInstances,
    containerInstances,
    loadingContainerInstances,
    getMemoryUtilization,
    memoryUtilization,
    loadingMemoryUtilization,
    getCpuUtilization,
    cpuUtilization,
    loadingCpuUtilization,
    getRequestLatencies,
    requestLatencies,
    loadingRequestLatencies,
    getRevisions,
    getVertexResponseCount,
    vertexResponseCount,
    loadingVertexResponseCount,
    getVertexPredictionCount,
    loadingVertexPredictionCount,
    vertexPredictionCount,
    getVertexReplicasCount,
    loadingVertexReplicasCount,
    vertexReplicasCount,
    getVertexLatencies,
    loadingVertexLatencies,
    vertexLatencies,
    getVertexCpuUtilization,
    loadingVertexCpuUtilization,
    vertexCpuUtilization,
    navigationOptions,
  } = props;
  const { session, date } = useParams();
  const [timeFrame, setTimeFrame] = useState(2);

  const [selectedGraph, setSelectedGraph] = useState(false);
  const [selectedRevision, setSelectedRevision] = useState('');
  const [revisions, setRevisions] = useState([]);
  const [loadingRevisions, setLoadingRevisions] = useState(false);
  const [newPolicy, setNewPolicy] = useState(false);

  const getRevisionsData = async () => {
    setLoadingRevisions(true);
    const revs = await getRevisions({
      metrics_type: 'revisions',
      hours: timeFrame,
      is_vertex: node.is_vertex,
      service_name: node.service_name,
      project_id: node.project_id,
    });
    setRevisions(revs);
    setSelectedRevision(revs[0]);
    setLoadingRevisions(false);
  };

  useEffect(() => {
    if (
      node.is_vertex === false ||
      node.is_vertex === null ||
      node.is_vertex === undefined
    ) {
      getRevisionsData();

      getRequestCounts({
        metrics_type: 'request_counts',
        hours: timeFrame,
        is_vertex: node.is_vertex,
        service_name: node.service_name,
        project_id: node.project_id,
      });

      getContainerInstances({
        metrics_type: 'container_instances',
        hours: timeFrame,
        is_vertex: node.is_vertex,
        service_name: node.service_name,
        project_id: node.project_id,
      });

      getMemoryUtilization({
        metrics_type: 'memory_utilization',
        hours: timeFrame,
        is_vertex: node.is_vertex,
        service_name: node.service_name,
        project_id: node.project_id,
      });

      getCpuUtilization({
        metrics_type: 'cpu_utilization',
        hours: timeFrame,
        is_vertex: node.is_vertex,
        service_name: node.service_name,
        project_id: node.project_id,
      });

      getRequestLatencies({
        metrics_type: 'request_latencies',
        hours: timeFrame,
        is_vertex: node.is_vertex,
        service_name: node.service_name,
        project_id: node.project_id,
      });
    } else {
      let endpoint = node.endpoint.split(':');
      endpoint = endpoint[0];
      endpoint = endpoint.slice(1);

      getVertexResponseCount({
        metrics_type: 'vertex_response_count',
        hours: timeFrame,
        is_vertex: node.is_vertex,
        service_name: endpoint,
        project_id: node.project_id,
      });

      getVertexPredictionCount({
        metrics_type: 'vertex_prediction_count',
        hours: timeFrame,
        is_vertex: node.is_vertex,
        service_name: endpoint,
        project_id: node.project_id,
      });

      getVertexReplicasCount({
        metrics_type: 'vertex_replicas_count',
        hours: timeFrame,
        is_vertex: node.is_vertex,
        service_name: endpoint,
        project_id: node.project_id,
      });

      getVertexLatencies({
        metrics_type: 'vertex_latencies',
        hours: timeFrame,
        is_vertex: node.is_vertex,
        service_name: endpoint,
        project_id: node.project_id,
      });

      getVertexCpuUtilization({
        metrics_type: 'vertex_cpu_utilization',
        hours: timeFrame,
        is_vertex: node.is_vertex,
        service_name: endpoint,
        project_id: node.project_id,
      });
    }
  }, [timeFrame, newPolicy]);
  return (
    <Box>
      {node.alerts?.map((alertInfo) => {
        return (
          <Box sx={{ marginX: 1 }}>
            <ServiceAlert alertInfo={alertInfo} />
          </Box>
        );
      })}
      <Typography
        sx={{
          margin: 1,
          color: 'white',
          fontWeight: 'bold',
          fontSize: 24,
          marginX: 3,
        }}
      >
        {node.service_name}
      </Typography>

      <Typography
        sx={{ color: 'lightgrey', fontSize: 16, marginX: 3, marginBottom: 1 }}
      >
        {node.display_name}
        {node.description ? ` - ${node.description}` : null}
      </Typography>

      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={1}
        sx={{ marginRight: 2 }}
      >
        <Chip
          label="2 hours"
          variant={timeFrame === 2 ? 'filled' : 'outlined'}
          color="secondary"
          size="small"
          onClick={() => setTimeFrame(2)}
        />
        <Chip
          label="6 hours"
          variant={timeFrame === 6 ? 'filled' : 'outlined'}
          color="secondary"
          size="small"
          onClick={() => setTimeFrame(6)}
        />
        <Chip
          label="12 hours"
          variant={timeFrame === 12 ? 'filled' : 'outlined'}
          size="small"
          color="secondary"
          onClick={() => setTimeFrame(12)}
        />
        <Chip
          label="1 day"
          variant={timeFrame === 24 ? 'filled' : 'outlined'}
          size="small"
          color="secondary"
          onClick={() => setTimeFrame(24)}
        />
        <Chip
          label="7 days"
          variant={timeFrame === 7 * 24 ? 'filled' : 'outlined'}
          size="small"
          color="secondary"
          onClick={() => setTimeFrame(7 * 24)}
        />
      </Stack>

      <Modal
        style={{ backdropFilter: 'blur(5px)' }}
        open={selectedGraph !== false}
        onClose={() => setSelectedGraph(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontWeight: 'bold' }}
          >
            {selectedGraph ? selectedGraph.title : null}
          </Typography>
          <Box sx={{ width: '800px', height: '500px' }}>
            <LineGraph
              data={selectedGraph.data}
              size="lg"
              graphType={selectedGraph.type}
            />
          </Box>
        </Box>
      </Modal>

      {node.is_vertex === true ? (
        <Box sx={{ margin: 2, backgroundColor: '#e9e9f1', borderRadius: 1 }}>
          {loadingVertexResponseCount ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 1,
                paddingY: 2,
              }}
            >
              <CircularProgress />
              <Typography
                sx={{ color: '#29373f', fontSize: 18, fontWidth: 'bold' }}
              >
                Loading data...
              </Typography>
            </Box>
          ) : (
            <Box>
              <Revisions
                data={vertexPredictionCount}
                loadingData={loadingVertexPredictionCount}
                setSelectedGraph={setSelectedGraph}
                title="Predictions/Second"
                selectedRevision={'Vertex AI'}
                graphType={'numerical'}
                setNewPolicy={setNewPolicy}
                navOptions={navigationOptions}
                metric={{
                  project_id: node.project_id,
                  resource_type: 'aiplatform.googleapis.com/Endpoint',
                  metric_type:
                    'aiplatform.googleapis.com/prediction/online/prediction_count',
                  resource_label_key: 'endpoint_id',
                  resource_label_value: node.endpoint.split(':')[0].slice(1),
                }}
              />
              <Divider sx={{ borderBottomWidth: 2 }} />
              <Revisions
                data={vertexResponseCount}
                loadingData={loadingVertexResponseCount}
                setSelectedGraph={setSelectedGraph}
                title="Response Count by Code"
                selectedRevision={'Vertex AI'}
                graphType={'numerical'}
                setNewPolicy={setNewPolicy}
                navOptions={navigationOptions}
                metric={{
                  project_id: node.project_id,
                  resource_type: 'aiplatform.googleapis.com/Endpoint',
                  metric_type:
                    'aiplatform.googleapis.com/prediction/online/response_count',
                  resource_label_key: 'endpoint_id',
                  resource_label_value: node.endpoint.split(':')[0].slice(1),
                }}
              />
              <Divider sx={{ borderBottomWidth: 2 }} />
              <Revisions
                data={vertexReplicasCount}
                loadingData={loadingVertexReplicasCount}
                setSelectedGraph={setSelectedGraph}
                title="Replicas Count"
                selectedRevision={'Vertex AI'}
                graphType={'numerical'}
                setNewPolicy={setNewPolicy}
                navOptions={navigationOptions}
                metric={{
                  project_id: node.project_id,
                  resource_type: 'aiplatform.googleapis.com/Endpoint',
                  metric_type:
                    'aiplatform.googleapis.com/prediction/online/replicas',
                  resource_label_key: 'endpoint_id',
                  resource_label_value: node.endpoint.split(':')[0].slice(1),
                }}
              />
              <Divider sx={{ borderBottomWidth: 2 }} />
              <Revisions
                data={vertexLatencies}
                loadingData={loadingVertexLatencies}
                setSelectedGraph={setSelectedGraph}
                title="Prediction Latencies (secs)"
                selectedRevision={'Vertex AI'}
                graphType={'numerical'}
                setNewPolicy={setNewPolicy}
                navOptions={navigationOptions}
                metric={{
                  project_id: node.project_id,
                  resource_type: 'aiplatform.googleapis.com/Endpoint',
                  metric_type:
                    'aiplatform.googleapis.com/prediction/online/prediction_latencies',
                  resource_label_key: 'endpoint_id',
                  resource_label_value: node.endpoint.split(':')[0].slice(1),
                }}
              />

              <Divider sx={{ borderBottomWidth: 2 }} />
              <Revisions
                data={vertexCpuUtilization}
                loadingData={loadingVertexCpuUtilization}
                setSelectedGraph={setSelectedGraph}
                title="CPU Utilization (%)"
                selectedRevision={'Vertex AI'}
                graphType={'numerical'}
                setNewPolicy={setNewPolicy}
                navOptions={navigationOptions}
                metric={{
                  project_id: node.project_id,
                  resource_type: 'aiplatform.googleapis.com/Endpoint',
                  metric_type:
                    'aiplatform.googleapis.com/prediction/online/cpu/utilization',
                  resource_label_key: 'endpoint_id',
                  resource_label_value: node.endpoint.split(':')[0].slice(1),
                }}
              />
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ margin: 2, backgroundColor: '#e9e9f1', borderRadius: 1 }}>
          {loadingRevisions ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 1,
                paddingY: 2,
              }}
            >
              <CircularProgress />
              <Typography
                sx={{ color: '#29373f', fontSize: 18, fontWidth: 'bold' }}
              >
                Loading data...
              </Typography>
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: 1,
                }}
              >
                {revisions !== false ? (
                  revisions.map((revision, idx) => {
                    return (
                      <Box
                        key={idx}
                        onClick={() => setSelectedRevision(revision)}
                        sx={{
                          color:
                            selectedRevision === revision
                              ? '#29373f'
                              : '#e9e9f1',
                          paddingX: 5,
                          flexGrow: 1,
                          paddingTop: 1,
                          textAlign: 'center',
                          cursor: 'pointer',
                          backgroundColor:
                            selectedRevision === revision ? null : '#29373f',
                        }}
                      >
                        {revision}
                        {selectedRevision === revision ? (
                          <hr
                            style={{
                              width: '70%',
                              color: '#e9e9f1',
                            }}
                          />
                        ) : null}
                      </Box>
                    );
                  })
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      gap: 1,
                      paddingY: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#29373f',
                        fontSize: 18,
                        fontWidth: 'bold',
                      }}
                    >
                      No metrics available for this service
                    </Typography>
                  </Box>
                )}
              </Box>
              {revisions !== false ? (
                <Box>
                  <Revisions
                    data={requestCounts}
                    loadingData={loadingRequestCounts}
                    setSelectedGraph={setSelectedGraph}
                    title="Request Count"
                    selectedRevision={selectedRevision}
                    graphType={'numerical'}
                    setNewPolicy={setNewPolicy}
                    navOptions={navigationOptions}
                    metric={{
                      project_id: node.project_id,
                      resource_type: 'cloud_run_revision',
                      metric_type: 'run.googleapis.com/request_count',
                      resource_label_key: 'service_name',
                      resource_label_value: node.service_name,
                    }}
                  />

                  <Divider sx={{ borderBottomWidth: 2 }} />
                  <Revisions
                    data={containerInstances}
                    loadingData={loadingContainerInstances}
                    setSelectedGraph={setSelectedGraph}
                    title="Container Instance Count"
                    selectedRevision={selectedRevision}
                    graphType={'numerical'}
                    setNewPolicy={setNewPolicy}
                    navOptions={navigationOptions}
                    metric={{
                      project_id: node.project_id,
                      resource_type: 'cloud_run_revision',
                      metric_type:
                        'run.googleapis.com/container/instance_count',
                      resource_label_key: 'service_name',
                      resource_label_value: node.service_name,
                    }}
                  />

                  <Divider sx={{ borderBottomWidth: 2 }} />

                  <Revisions
                    data={cpuUtilization}
                    loadingData={loadingCpuUtilization}
                    setSelectedGraph={setSelectedGraph}
                    title="Container CPU Utilization (%)"
                    selectedRevision={selectedRevision}
                    graphType={'percentage'}
                    setNewPolicy={setNewPolicy}
                    navOptions={navigationOptions}
                    metric={{
                      project_id: node.project_id,
                      resource_type: 'cloud_run_revision',
                      metric_type:
                        'run.googleapis.com/container/cpu/utilizations',
                      resource_label_key: 'service_name',
                      resource_label_value: node.service_name,
                    }}
                  />

                  <Divider sx={{ borderBottomWidth: 2 }} />

                  <Revisions
                    data={memoryUtilization}
                    loadingData={loadingMemoryUtilization}
                    setSelectedGraph={setSelectedGraph}
                    title="Container memory RAM utilization (%)"
                    selectedRevision={selectedRevision}
                    graphType={'percentage'}
                    setNewPolicy={setNewPolicy}
                    navOptions={navigationOptions}
                    metric={{
                      project_id: node.project_id,
                      resource_type: 'cloud_run_revision',
                      metric_type:
                        'run.googleapis.com/container/memory/utilizations',
                      resource_label_key: 'service_name',
                      resource_label_value: node.service_name,
                    }}
                  />

                  <Divider sx={{ borderBottomWidth: 2 }} />

                  <Revisions
                    data={requestLatencies}
                    loadingData={loadingRequestLatencies}
                    setSelectedGraph={setSelectedGraph}
                    title="Requests latencies (secs.)"
                    selectedRevision={selectedRevision}
                    graphType={'numerical'}
                    setNewPolicy={setNewPolicy}
                    navOptions={navigationOptions}
                    metric={{
                      project_id: node.project_id,
                      resource_type: 'cloud_run_revision',
                      metric_type: 'run.googleapis.com/request_latencies',
                      resource_label_key: 'service_name',
                      resource_label_value: node.service_name,
                    }}
                  />
                </Box>
              ) : null}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default MetricsInfo;
