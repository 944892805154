import { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

const CAMERA_WAITING_TIME = 30;

export default function CameraOnDemandDialog(props) {
  const {
    open = false,
    dialogName,
    handleClose,
    setSnackAlert,
    onDemandCameras,
    handleGetCameras,
  } = props;
  const { t } = useTranslation();
  const [principalCamera, setPrincipalCamera] = useState(null);
  const [cameraStack, setCameraStack] = useState([]);
  const [imageProgress, setImageProgress] = useState(0);
  const [imageProgressInterval, setImageProgressInterval] = useState(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomedCamera, setZoomedCamera] = useState(null);
  const [isCameraLoading, setIsCameraLoading] = useState(false);
  const cameraTimeout = useRef(null);

  // Handlers
  const handlePrincipalCamera = (cameraIndex) => () => {
    setPrincipalCamera(cameraIndex);
  };

  const handleNoDataReceivedAfterXSeconds = (waitingTime) => {
    if (cameraTimeout.current) {
      clearTimeout(cameraTimeout.current);
    }
    const newTimeOut = setTimeout(() => {
      if (!onDemandCameras?.length && open) {
        clearInterval(imageProgressInterval);
        setImageProgress(100);
        setIsCameraLoading(false);
        setSnackAlert({
          open: true,
          message: t(
            'overseer_app.widget.camera_on_demand_no_data_received',
            `No data received from the robot after ${waitingTime} seconds for the ${dialogName} Camera.`,
            { waitingTime, dialogName }
          ),
          severity: 'error',
        });
      }
    }, waitingTime * 1000);
    cameraTimeout.current = newTimeOut;
  };

  const handleImageLoader = (maxTime) => {
    const interval = setInterval(() => {
      setImageProgress((prevProgress) => {
        const newProgress = prevProgress + 100 / maxTime;
        if (newProgress >= 100) {
          clearInterval(interval);
        }
        return newProgress >= 100 ? 100 : newProgress;
      });
    }, 100); // Intervalo de 100 ms
    setImageProgressInterval(interval);
  };

  const handleClickPosition = () => {
    const doc = document.getElementById('principal_camera');
    const bounds = doc.getBoundingClientRect();
    const width = doc.clientWidth;
    const height = doc.clientHeight;
    const left = bounds.left;
    const top = bounds.top;
    const x = window.event.pageX - left - window.scrollX;
    const y = window.event.pageY - top - window.scrollY;
    return [x, y, width, height];
  };

  const handleGetingCameras = ({ dialogName, msg, maxTime = 75 }) => {
    setIsZoomed(false);
    setIsCameraLoading(true);
    setImageProgress(0);
    clearInterval(imageProgressInterval);
    handleImageLoader(maxTime);
    handleGetCameras({ dialogName, msg });
  };

  const handleZoomImg = () => {
    if (!isCameraLoading) {
      if (isZoomed) {
        setIsZoomed(false);
        setZoomedCamera(null);
        handleGetingCameras({ dialogName: dialogName });
      } else {
        // if already zoomed then return the previous image
        const selectedCamera = cameraStack[principalCamera];
        const [x, y, img_width, img_height] = handleClickPosition();

        const imageObject = new Image();
        imageObject.src = `data:image/jpeg;base64,${selectedCamera.img}`;
        const org_width = imageObject.width;
        const org_height = imageObject.height;

        // percentages
        const percent_x = x / img_width;
        const percent_y = y / img_height;
        // positions on original size image
        const real_x = org_width * percent_x;
        const real_y = org_height * percent_y;
        const real_position = [real_x, real_y];
        const obj = { [selectedCamera.name]: real_position };
        handleGetingCameras({ dialogName: dialogName, msg: obj, maxTime: 40 });
        setIsZoomed(true);
      }
    }
  };

  // Hooks
  useEffect(() => {
    if (open && onDemandCameras && Object.keys(onDemandCameras).length) {
      if (cameraTimeout) clearTimeout(cameraTimeout.current);
      setIsCameraLoading(false);
      if (isZoomed) {
        const newZoomedCamera = Object.entries(onDemandCameras).map(
          (cameraInfo) => {
            const cameraName = cameraInfo[0];
            return {
              name: cameraName,
              parsedName: `${cameraName?.split('_').join(' ')} Zoomed`,
              img: cameraInfo[1],
            };
          }
        );
        setZoomedCamera(newZoomedCamera[0]);
      } else {
        const newCameraStack = Object.entries(onDemandCameras)
          .map((cameraInfo) => {
            const cameraName = cameraInfo[0];
            return {
              name: cameraName,
              parsedName: cameraName?.split('_').join(' '),
              img: cameraInfo[1],
            };
          })
          .sort((a, b) => a.name.localeCompare(b.name));
        setCameraStack(newCameraStack);
        if (principalCamera == null) {
          setPrincipalCamera(0);
        }
      }
    } else if (!open) {
      if (cameraTimeout) clearTimeout(cameraTimeout.current);
      clearInterval(imageProgressInterval);
      setImageProgress(100);
      setPrincipalCamera(null);
      setCameraStack([]);
      setIsZoomed(false);
      setZoomedCamera(null);
      setIsCameraLoading(false);
    }
  }, [open, onDemandCameras]);

  useEffect(() => {
    if (open && principalCamera == null) {
      handleNoDataReceivedAfterXSeconds(CAMERA_WAITING_TIME);
      handleGetingCameras({ dialogName: dialogName });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="camera-on-demand-dialog-title"
      aria-describedby="camera-on-demand-dialog-description"
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">
            {t(`overseer_app.widget.${dialogName}`, dialogName)}
          </Typography>
          <Box>
            <Button
              onClick={() => handleGetingCameras({ dialogName: dialogName })}
              disabled={isCameraLoading}
              size="small"
              variant="outlined"
              startIcon={<IconComponent iconName="refresh" color="slate" />}
              sx={{ mb: 1 }}
            >
              <Typography variant="small" sx={{ fontSize: 'x-small' }}>
                {t('overseer_app.widget.refresh', 'Refresh')}
              </Typography>
            </Button>
            <Button
              onClick={handleClose}
              size="small"
              variant="outlined"
              startIcon={<IconComponent iconName="close" color="slate" />}
              sx={{ mb: 1, ml: 1 }}
            >
              <Typography variant="small" sx={{ fontSize: 'x-small' }}>
                {t('overseer_app.widget.close', 'Close')}
              </Typography>
            </Button>
          </Box>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mr: 0, mt: 0 }}>
          {cameraStack?.length ? (
            <Grid
              item
              xs={3}
              sx={{
                bgcolor: 'grey.300',
                borderRadius: '5px',
                px: '5px!important',
              }}
            >
              <Stack
                spacing={2}
                sx={{ overflowY: 'auto' }}
                justifyContent="center"
              >
                {cameraStack.map((camera, index) => (
                  <Box
                    sx={{ position: 'relative' }}
                    key={`camera-on-demand-dialog-stack-${index}`}
                  >
                    <img
                      key={`camera-on-demand-left-${index}`}
                      className={
                        isCameraLoading
                          ? 'img-loading camera-on-demand-left'
                          : 'camera-on-demand-left'
                      }
                      alt="collector1_camera1"
                      onClick={handlePrincipalCamera(index)}
                      style={{
                        borderRadius: '5px',
                        border:
                          camera.name === cameraStack[principalCamera]?.name
                            ? '2px solid #0070B7'
                            : 'none',
                        height: 'auto',
                        width: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                        cursor: 'pointer',
                      }}
                      src={`data:image/jpeg;base64,${camera.img}`}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        zIndex: 1,
                        font: 'small-caption',
                        top: 0,
                        left: 0,
                        bgcolor: '#e0e0e08a',
                        maxHeight: '2em',
                        maxWidth: '7em',
                        borderRadius: '0 0 4px 0',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <Tooltip title={camera.parsedName}>
                        <Typography variant="small">
                          {camera.parsedName}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                ))}
              </Stack>
            </Grid>
          ) : null}
          <Grid item xs={cameraStack?.length ? 9 : 12}>
            {cameraStack?.[principalCamera]?.img ? (
              <>
                <Box sx={{ position: 'relative' }}>
                  <img
                    id="principal_camera"
                    className={isCameraLoading ? 'img-loading' : ''}
                    alt="collector1_camera1"
                    style={{
                      borderRadius: '5px',
                      height: 'auto',
                      width: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                      cursor: isCameraLoading
                        ? 'progress'
                        : isZoomed
                        ? 'zoom-out'
                        : 'zoom-in',
                    }}
                    onClick={handleZoomImg}
                    src={`data:image/jpeg;base64,${
                      isZoomed &&
                      zoomedCamera?.img &&
                      zoomedCamera?.name === cameraStack[principalCamera]?.name
                        ? zoomedCamera.img
                        : cameraStack[principalCamera].img
                    }`}
                  />

                  <Box
                    sx={{
                      position: 'absolute',
                      zIndex: 1,
                      font: 'small-caption',
                      top: 0,
                      left: 0,
                      bgcolor: '#e0e0e08a',
                      maxHeight: '2em',
                      maxWidth: '10em',
                      borderRadius: '0 0 4px 0',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <Typography variant="small">
                      {cameraStack[principalCamera]?.name?.split('_').join(' ')}
                    </Typography>
                  </Box>
                </Box>
                {imageProgress === 100 ? null : (
                  <LinearProgress variant="determinate" value={imageProgress} />
                )}
              </>
            ) : (
              <Skeleton variant="rectangular" height={260} width={380} />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
