import { useEffect, useState } from 'react';

import { Badge, Box, Card, Skeleton, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PipelineMonitoringTable from '../../redux/containers/pipelineMonitoring/PipelineMonitoringTable';

import PipelineAlerts from '../pipelineMonitoring/PipelineAlerts';
import TabPanel from '../tools/TabPanel';
import { a11yProps } from '../Utils';
import AislesProgressWidget from './AislesProgressWidget';

export default function PipelineProgressWidget(props) {
  const { store, robot } = props;
  const { t } = useTranslation();

  const [alertResume, setAlertResume] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // card with widget info
  return (
    <Card
      sx={{
        p: 0,
        height: '100%',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={t(
              'overseer_app.robot_view.pipeline_progress',
              'Pipeline Progress'
            )}
            {...a11yProps(0, { textTransform: 'initial' })}
          />
          <Tab
            label={
              <Badge
                badgeContent={alertResume?.length || 0}
                color="error"
                sx={{ zIndex: 2 }}
              >
                <Box sx={{ pr: '9px' }}>
                  {t('overseer_app.robot_view.Alerts', 'Alerts')}
                </Box>
              </Badge>
            }
            {...a11yProps(1, { textTransform: 'initial' })}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        {store && robot ? (
          <PipelineMonitoringTable
            cardElevation={0}
            store={store}
            robot={robot}
            showTestSessions={true}
            setAlertResume={setAlertResume}
          />
        ) : (
          <Skeleton variant="rectangular" height={350} />
        )}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <PipelineAlerts
          isLoading={!(store && robot)}
          alerts={alertResume}
          sx={{ maxHeight: '11em' }}
        />
      </TabPanel>
    </Card>
  );
}
