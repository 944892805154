import { useContext } from 'react';
import { Autocomplete, Grid, LinearProgress, TextField } from '@mui/material';
import { DashboardViewContext } from '../../context/DashboardView';

export default function MetricSelector(props) {
  const {
    metricsList,
    isLoadingMetrics,
    chainsList,
    isLoadingChains,
    timeRangeList,
    isLoadingTimeRange,
    size,
  } = props;

  const {
    selectedTimeRange,
    setSelectedTimeRange,
    selectedMetric,
    setSelectedMetric,
    setSelectedChain,
  } = useContext(DashboardViewContext);

  const handleTimeRangeChange = (event, value) => {
    const selectedValue = value ? value.value : null;
    setSelectedTimeRange(selectedValue);
  };

  const handleMetricChange = (event, value) => {
    const selectedValue = value ? value.name : null;
    setSelectedMetric(selectedValue);
  };

  return (
    <Grid container item xs={size} spacing={2} padding={2}>
      <Grid item xs={4}>
        <Autocomplete
          fullWidth
          defaultValue={
            selectedTimeRange
              ? timeRangeList.find(
                  (option) => option.value === selectedTimeRange
                )
              : null
          }
          options={timeRangeList}
          getOptionLabel={(option) => option.name}
          onChange={handleTimeRangeChange}
          renderInput={(params) => <TextField {...params} label="Time range" />}
        />
        {isLoadingTimeRange && (
          <LinearProgress
            sx={{ width: '100%', mx: 'auto', top: '-5px' }}
            color="secondary"
          />
        )}
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          fullWidth
          defaultValue={
            selectedMetric
              ? metricsList.find((option) => option.name === selectedMetric)
              : null
          }
          options={metricsList}
          getOptionLabel={(option) => option.title}
          getOptionDisabled={(option) => option.available === false}
          onChange={handleMetricChange}
          renderInput={(params) => <TextField {...params} label="Metrics" />}
        />
        {isLoadingMetrics && (
          <LinearProgress
            sx={{ width: '100%', mx: 'auto', top: '-5px' }}
            color="secondary"
          />
        )}
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          fullWidth
          options={
            chainsList ? chainsList.map((chain) => chain.name) : chainsList
          }
          onChange={(e, value) => setSelectedChain(value)}
          renderInput={(params) => <TextField {...params} label="Chains" />}
        />
        {isLoadingChains && (
          <LinearProgress
            sx={{ width: '100%', mx: 'auto', top: '-5px' }}
            color="secondary"
          />
        )}
      </Grid>
    </Grid>
  );
}
