import { connect } from 'react-redux';
import Navbar from '../../components/navbar/Navbar';
import { getUserInfo, getWorkshiftInfo } from 'core/slices/session';
import {
  getEvents,
  updateEventStatus,
  postClickUpTask,
  getClickUpTeams,
} from 'core/slices/event';
import { setSnackAlert } from 'core/slices/snackAlert';
import { getNavigationOptions } from 'core/slices/navigation';
import { checkFeaturePermission } from 'core/slices/backend';
import {
  userInfo,
  detailedUserInfo,
  workshiftInfo,
  getWorkshiftResponse,
  isloadingPostWorkshift,
  getEventsList,
  updatingEventStatus,
  isLoadingUpdateEventStatus,
  getFeaturePermission,
  isLoadingNewClickUpTask,
  getNewClickUpTaskResponse,
  getClickupTeamsResponse,
} from 'core/selectors';

export default connect(
  (state) => ({
    userInfo: userInfo(state),
    detailedUserInfo: detailedUserInfo(state),
    workshiftInfo: workshiftInfo(state),
    workshiftResponse: getWorkshiftResponse(state),
    isloadingPostWorkshift: isloadingPostWorkshift(state),
    eventsList: getEventsList(state),
    updatingEventStatus: updatingEventStatus(state),
    isLoadingUpdateEventStatus: isLoadingUpdateEventStatus(state),
    featurePermissionResponse: getFeaturePermission(state),
    isLoadingClickUpTask: isLoadingNewClickUpTask(state),
    newClickUpTaskResponse: getNewClickUpTaskResponse(state),
    clickupTeamsList: getClickupTeamsResponse(state),
  }),
  {
    getWorkshiftInfo,
    getUserInfo,
    getNavigationOptions,
    getEvents,
    updateEventStatus,
    checkFeaturePermission,
    postClickUpTask,
    getClickUpTeams,
    setSnackAlert,
  }
)(Navbar);
