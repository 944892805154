import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Divider,
  LinearProgress,
  CircularProgress,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Paper,
  TableBody,
} from "@mui/material";
import { useParams } from "react-router-dom";
import moment from "moment";

function ProgressInfo(props) {
  const { node, getSessionProgress, sessionProgress, loadingSessionProgress } =
    props;
  const { session, date } = useParams();
  const [progress, setProgress] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [noData, setNoData] = useState(false);

  const getProgress = async () => {
    setLoadingProgress(true);
    const data = await getSessionProgress({
      session,
      process_id: node.process_id,
    });
    if (data.length === 0) {
      setNoData(true);
      setLoadingProgress(false);
    } else {
      const total = data.length;
      const completed = data.filter((task) => task.completed === true).length;
      setProgress(data);
      setPercentage(Math.round((completed / total) * 100));
      setLoadingProgress(false);
    }
  };

  useEffect(() => {
    getProgress();
  }, [session]);
  return (
    <Box>
      <Typography
        sx={{
          margin: 1,
          color: "white",
          fontWeight: "bold",
          fontSize: 24,
          marginX: 3,
        }}
      >
        {node.service_name}
      </Typography>

      <Typography
        sx={{ color: "lightgrey", fontSize: 16, marginX: 3, marginBottom: 1 }}
      >
        {node.display_name}
        {node.description ? ` - ${node.description}` : null}
      </Typography>
      {loadingProgress ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 1,
            paddingY: 2,
          }}
        >
          <CircularProgress sx={{ color: "#7c909b" }} />
          <Typography
            sx={{ color: "#7c909b", fontSize: 18, fontWidth: "bold" }}
          >
            Loading data...
          </Typography>
        </Box>
      ) : (
        <Box>
          {noData ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingY: 2,
              }}
            >
              <Typography
                sx={{ color: "#7c909b", fontSize: 18, fontWidth: "bold" }}
              >
                No tasks linked to this service on this session...
              </Typography>{" "}
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                  marginY: 2,
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={percentage}
                  color={percentage !== 100 ? "secondary" : "success"}
                  sx={{ height: "10px", borderRadius: 1, width: "70%" }}
                />
                <Typography sx={{ color: "lightgrey", fontSize: 16 }}>
                  {percentage}%
                </Typography>
              </Box>
              <Box sx={{ marginBottom: 2, overflow: "hidden" }}>
                <Typography
                  sx={{
                    marginX: 2,
                    color: "#e9e9f1",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  Pending Tasks
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    backgroundColor: "#e9e9f1",
                    display: "flex",
                    justifyContent: "center",
                    marginX: 1,
                    width: "auto",
                    maxHeight: 400,
                  }}
                >
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Aisle</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Source
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Started At
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Processing Time
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {progress.map((row) =>
                        !row.completed ? (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>{row.aisle}</TableCell>
                            <TableCell>{row.source}</TableCell>
                            <TableCell>{row.started_at}</TableCell>
                            <TableCell>
                              {moment(row.started_at).fromNow()}
                            </TableCell>
                          </TableRow>
                        ) : null
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{ marginBottom: 2, overflow: "hidden" }}>
                <Typography
                  sx={{
                    marginX: 2,
                    color: "#e9e9f1",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  Completed Tasks
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    backgroundColor: "#e9e9f1",
                    display: "flex",
                    justifyContent: "center",
                    marginX: 1,
                    width: "auto",
                    maxHeight: 400,
                  }}
                >
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Aisle</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Source
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Started At
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Finished At
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {progress.map((row) =>
                        row.completed ? (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>{row.aisle}</TableCell>
                            <TableCell>{row.source}</TableCell>
                            <TableCell>{row.started_at}</TableCell>
                            <TableCell>{row.finished_at}</TableCell>
                          </TableRow>
                        ) : null
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default ProgressInfo;
