import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  TextareaAutosize,
  Grid,
  IconButton,
  Divider,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Selector from '../tools/Selector';

export default function NewServicingDialog(props) {
  const {
    open,
    handleClose,
    postNewServicing,
    isLoadingNewServicing,
    robotCode,
    changeMotivesList,
    robotComponentsList,
    resetForm,
    setResetForm,
  } = props;

  const { t } = useTranslation();

  const [hardwareComponent, setHardwareComponent] = useState('');
  const [componentId, setComponentId] = useState('');
  const [componentModel, setComponentModel] = useState('');
  const [componentSerialNumber, setComponentSerialNumber] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMotive, setSelectedMotive] = useState('');
  const [otherMotive, setOtherMotive] = useState('');
  const [metadataValuesList, setMetadataValuesList] = useState([]);
  const [comments, setComments] = useState('');

  useEffect(() => {
    if (resetForm) {
      setHardwareComponent('');
      setComponentId('');
      setComponentModel('');
      setComponentSerialNumber('');
      setSelectedDate(null);
      setSelectedMotive('');
      setOtherMotive('');
      setComments('');
      setResetForm(false);
      setMetadataValuesList([]);
    }
  }, [resetForm]);

  const handleChange = (index, field, value) => {
    const updatedMetadata = [...metadataValuesList];
    updatedMetadata[index][field] = value;
    setMetadataValuesList(updatedMetadata);
  };

  const handleAddField = () => {
    setMetadataValuesList([...metadataValuesList, { key: '', value: '' }]);
  };

  const handleRemoveField = (index) => {
    setMetadataValuesList((prevMetadataValues) => {
      const updatedList = [...prevMetadataValues];
      updatedList.splice(index, 1);
      return updatedList;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (hardwareComponent === null || selectedDate === null) {
      console.log('Por favor, completa todos los campos requeridos');
      return;
    }
    // transform metadata array in object
    let metadata = {};
    metadataValuesList.forEach((item) => (metadata[item.key] = item.value));
    await postNewServicing(
      hardwareComponent.component_name,
      componentId,
      componentModel,
      componentSerialNumber,
      robotCode,
      selectedDate,
      selectedMotive,
      metadata,
      comments
    );
  };

  const showTextArea = selectedMotive === 'Other';

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Create New Servicing'}
        <Divider />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Selector
                options={robotComponentsList}
                selectedElement={hardwareComponent}
                setSelectedElement={setHardwareComponent}
                labelInput={'Hardware Component'}
                optionLabel={'component_name'}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={['DatePicker']}
                  style={{ padding: 3 }}
                >
                  <DatePicker
                    style={{ padding: 3 }}
                    inputFormat="dd/MM/yyyy"
                    disableFuture
                    renderInput={(params) => <TextField {...params} />}
                    value={dayjs(selectedDate)}
                    onChange={(newDate) => {
                      setSelectedDate(newDate.format('YYYY-MM-DDTHH:MM:ss'));
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Component ID"
                value={componentId}
                name="component_id"
                onChange={(e) => {
                  setComponentId(e.target.value);
                }}
                fullWidth
                required
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Component Model"
                value={componentModel}
                name="component_model"
                onChange={(e) => {
                  setComponentModel(e.target.value);
                }}
                fullWidth
                required
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Component Serial Number"
                value={componentSerialNumber}
                name="component_serial_number"
                onChange={(e) => {
                  setComponentSerialNumber(e.target.value);
                }}
                fullWidth
                required
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="motivo-label">
                  {t('overseer_app.robot_view.motive', 'Motive')}
                </InputLabel>
                <Select
                  labelId="motivo-label"
                  id="motivo"
                  value={selectedMotive}
                  onChange={(e) => {
                    setSelectedMotive(e.target.value);
                  }}
                  required
                >
                  {changeMotivesList?.map((item) => (
                    <MenuItem value={item.description}>
                      {item.description}
                    </MenuItem>
                  ))}
                  <MenuItem value="Other">
                    {t('overseer_app.robot_view.other', 'Other')}
                  </MenuItem>
                </Select>
              </FormControl>
              {showTextArea && (
                <FormControl fullWidth margin="normal">
                  <Typography variant="body1" gutterBottom>
                    {t(
                      'overseer_app.robot_view.specify_motive',
                      'Specify motive'
                    )}
                    :
                  </Typography>
                  <TextField
                    value={otherMotive}
                    name="component_name"
                    onChange={(e) => {
                      setOtherMotive(e.target.value);
                    }}
                    fullWidth
                    required
                    margin="normal"
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                {t('overseer_app.robot_view.metadata', 'Metadata')} (
                {t('overseer_app.general.optional', 'optional')}):
              </Typography>
              <Grid container spacing={2} alignItems={'center'}>
                {metadataValuesList.map((data, index) => (
                  <>
                    <Grid item xs={5} key={index}>
                      <TextField
                        label="Key"
                        value={data.key}
                        onChange={(e) =>
                          handleChange(index, 'key', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        label="Value"
                        value={data.value}
                        onChange={(e) =>
                          handleChange(index, 'value', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        color="primary"
                        aria-label="add to shopping cart"
                        onClick={() => handleRemoveField(index)}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                  </>
                ))}
              </Grid>
              <IconButton
                color="primary"
                aria-label="add to shopping cart"
                onClick={handleAddField}
              >
                <AddIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                {t('overseer_app.robot_view.comments', 'Comments')} (
                {t('overseer_app.general.optional', 'optional')}):
              </Typography>
              <TextareaAutosize
                aria-label="Comentarios"
                value={comments}
                onChange={(e) => {
                  setComments(e.target.value);
                }}
                minRows={3}
                style={{ width: '100%', marginTop: '1rem', padding: '0.5rem' }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          disabled={isLoadingNewServicing}
          color="primary"
        >
          {t('overseer_app.robot_view.send', 'Send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
