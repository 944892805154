import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SchedulerViewContext } from '../../../context/SchedulerView';
import TabPanel from '../../tools/TabPanel';
import { a11yProps } from '../../Utils';
import ScanningForm from './ScanningForm';
import ScheduleForm from './ScheduleForm';


export default function AddPlanForm(props) {
  const [openModal, setOpenModal] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();

  const {
    selectedChain,
    selectedFloor,
    selectedStore,
  } = useContext(SchedulerViewContext);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const formatElapsedTime = (elapsedTime) => {
    let totalMinutes = elapsedTime.split(' ')[0];
    if (!isNaN(totalMinutes)) totalMinutes = parseInt(totalMinutes);
    else return elapsedTime;
    let hours = Math.trunc(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  return (
    <>
      <Button
        variant='contained'
        onClick={() => { setOpenModal(true) }}
      >{t('overseer_app.scheduler.add_plan', 'Add plan')}</Button>

      <Dialog open={openModal} onClose={() => { setOpenModal(false) }} maxWidth="lg">
        <DialogTitle>
          <Typography variant="subtitle1">
            Create new navigation plan
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ height: `calc(${document.getElementById('layout-map-box')?.clientHeight ?? 400}px + 7em)`, overflowY: 'auto', maxHeight: '45em', overflow: 'hidden', pb: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant="subtitle2">Chain</Typography>
                    <Typography variant="subtitle2" sx={{ ml: 1 }}>{selectedChain?.name}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle2">Store</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle2">Robot</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={t('overseer_app.schedule.schedule', 'Schedule')}
                    {...a11yProps(0, { textTransform: 'initial' })}
                  />
                  <Tab
                    label={t('overseer_app.schedule.scanning', 'Scanning')}
                    {...a11yProps(1, { textTransform: 'initial' })}
                  />
                </Tabs>
              </Box>
              <TabPanel value={tabIndex} index={0}>
                <ScheduleForm />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <ScanningForm />
              </TabPanel>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {/* <Divider sx={{ with: '80%!important', mb: 1 }} /> */}
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button variant='outlined' onClick={() => { }}>Cancel</Button>
              <Button variant='contained' onClick={() => { }}>Save</Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>

  );
}
