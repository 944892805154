import { LinearProgress } from '@mui/material';
import { Suspense, useEffect, useState } from 'react';
import useCanvas from './useCanvas';
import {
  drawRobotPose,
  drawLaserHits,
  drawPath,
  drawHeatmap,
} from '../utils/canvasFunctions';

export default function MapCanvas(props) {
  const {
    id,
    info,
    width = 600,
    height = 600,
    navInfo,
    isFollowingRobot,
    layersDisplay,
    signalDisplay,
    mapSignal,
    onCanvasListChange,
  } = props;

  const [allCanvas, setAllCanvas] = useState([]);

  const [canvasRef, canvasDraw] = useCanvas();
  const [canvasLaserRef, canvasLaserDraw] = useCanvas();
  const [canvasLocalNavRef, canvasLocalNavDraw] = useCanvas();
  const [canvasGlobalNavRef, canvasGlobalNavDraw] = useCanvas();
  const [canvasSignalRef, canvasSignalDraw] = useCanvas();

  useEffect(() => {
    const canvas = document.getElementById(`map-canvas-${id}`);
    const laserCanvas = document.getElementById(`mini-canvas-laser-${id}`);
    const localNavCanvas = document.getElementById(`mini-canvas-local-${id}`);
    const globalNavCanvas = document.getElementById(`mini-canvas-global-${id}`);
    const heatmapCanvas = document.getElementById(`heatmap-canvas-${id}`);
    const canvasArray = [canvas, laserCanvas, localNavCanvas, globalNavCanvas];

    // add canvas to the list of canvases to allow the download of the map
    onCanvasListChange([`map-canvas-${id}`, `mini-canvas-laser-${id}`, `mini-canvas-local-${id}`, `mini-canvas-global-${id}`, `heatmap-canvas-${id}`])

    setAllCanvas(canvasArray);
    canvasArray.forEach((cv) => {
      if (cv) {
        cv.width = width;
        cv.height = height;
      }
    });
    heatmapCanvas.width = width;
    heatmapCanvas.height = height;
  }, [width, height]);

  useEffect(() => {
    allCanvas.forEach((cv) => {
      cv.getContext('2d').clearRect(0, 0, cv.width, cv.height);
    });
  }, [layersDisplay]);

  useEffect(() => {
    let cv = document.getElementById(`heatmap-canvas-${id}`);
    cv.getContext('2d').clearRect(0, 0, cv.width, cv.height);
  }, [signalDisplay]);

  useEffect(() => {
    if (mapSignal && signalDisplay) {
      canvasSignalDraw(drawHeatmap, [mapSignal]);
    }
  }, [mapSignal]);

  useEffect(() => {
    if (info.img && canvasRef?.current && navInfo.pose) {
      handleLayers();
    }
  }, [info, navInfo]);

  const handleLayers = () => {
    layersDisplay.forEach((layer) => {
      if (layer === 'robot')
        canvasDraw(drawRobotPose, [
          navInfo.pose,
          info,
          isFollowingRobot,
          `map-canvas-${id}`,
        ]);
      else if (layer === 'lidar')
        canvasLaserDraw(drawLaserHits, [navInfo.laser_hits, info]);
      else if (layer === 'navigation_path') {
        canvasLocalNavDraw(drawPath, [navInfo.local_nav_points, info, 'green']);
        canvasGlobalNavDraw(drawPath, [
          navInfo.global_nav_points,
          info,
          'blue',
        ]);
      }
    });
  };

  return (
    <>
      <Suspense
        fallback={
          <LinearProgress sx={{ width: '98%', mx: 'auto' }} color="secondary" />
        }
      >
        <canvas
          id={`map-canvas-${id}`}
          ref={canvasRef}
          style={{
            borderRadius: '5px',
            outline: 'none',
            width: 'auto',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
          }}
        />
        <canvas
          id={`mini-canvas-laser-${id}`}
          ref={canvasLaserRef}
          style={{
            borderRadius: '5px',
            outline: 'none',
            width: 'auto',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
          }}
        />
        <canvas
          id={`mini-canvas-local-${id}`}
          ref={canvasLocalNavRef}
          style={{
            borderRadius: '5px',
            outline: 'none',
            width: 'auto',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
          }}
        />
        <canvas
          id={`mini-canvas-global-${id}`}
          ref={canvasGlobalNavRef}
          style={{
            borderRadius: '5px',
            outline: 'none',
            width: 'auto',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
          }}
        />
        <canvas
          id={`heatmap-canvas-${id}`}
          ref={canvasSignalRef}
          style={{
            borderRadius: '5px',
            outline: 'none',
            width: 'auto',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
          }}
        />
      </Suspense>
    </>
  );
}
