import { connect } from 'react-redux';
import RobotStatusTransferList from '../../../../components/alert/robotConfiguration/RobotStatusTransferList';


import {
    getRobotsStatus,
    updateRobotsStatus
} from 'core/slices/robotConfiguration';

import {
    robotsStatus,
    loadingRobotsStatus,
    updatingRobotStatus
} from 'core/selectors';


export default connect(
    state => ({
        robotsStatus: robotsStatus(state),
        loadingRobotsStatus: loadingRobotsStatus(state),
        updatingRobotStatus: updatingRobotStatus(state)
    }),
    {
        getRobotsStatus,
        updateRobotsStatus
    }
)(RobotStatusTransferList);
