import { FormControl, ListSubheader, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function CustomSelector(props) {
  const { singleOptions, multipleOptions, selectedValues, setSelectedValues } =
    props;

  const { t } = useTranslation();

  const [uniqueOptions, setUniqueOptions] = useState([]);

  useEffect(() => {
    const options = singleOptions?.reduce((options, currentOption) => [...options, ...(Object.values(currentOption.items).map(row => row.id))], []);
    setUniqueOptions(options);
  }, [singleOptions]);

  const handleSelectionChange = (event) => {
    const { value } = event.target;
    setSelectedValues(value);
  };

  const isConnectivityOptionDisabled = (id) => {
    return (
      !selectedValues.includes(id) &&
      uniqueOptions.some((item) => selectedValues.includes(item))
    );
  };

  return (
    <FormControl sx={{ maxWidth: '10em' }}>
      <Select
        labelId="select-label"
        value={selectedValues}
        onChange={handleSelectionChange}
        displayEmpty
        multiple
        size='small'
        renderValue={(selected) => {
          return <em>Layers</em>;
        }}
      >
        {multipleOptions.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}

        <ListSubheader>{t(singleOptions[0].title)}</ListSubheader>
        {singleOptions?.map((option, index) => option.items.map((item) => (
          <MenuItem key={item.id} value={item.id} disabled={isConnectivityOptionDisabled(item.id)}>
            {t(item.name)}
          </MenuItem>
        ))
        )}

        {/* {singleOptions?.map((option, index) => (
          <CustomMenuItem key={`customMenuItem-${index}`} title={t(option.title)} items={option.items} isConnectivityOptionDisabled={isConnectivityOptionDisabled} t={t} />
        ))} */}

      </Select>
    </FormControl>
  );
}

const CustomMenuItem = forwardRef((props, ref) => (
  <div ref={ref}>
    <ListSubheader>{props.title}</ListSubheader>
    {props.items.map((item) => (
      <MenuItem key={item.id} value={item.id} disabled={props.isConnectivityOptionDisabled(item.id)}>
        {props.t(item.name)}
      </MenuItem>
    ))}
  </div>
));
