import React from 'react';

import { Box, Divider, Typography } from '@mui/material';
import magnifyingGlass from '../../assets/icons/magnifyingGlass.png';

export default function NotFound(props) {
  const { title, content, sx, imgProps = {}, children } = props;
  // card with widget info
  return (
    <Box sx={{ p: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', ...sx }}>
      <Box sx={{
        width: 'fit-content',
        backgroundColor: '#f0f0f0',
        borderRadius: '100%',
        padding: '3em',
      }}>
        <img src={imgProps.src ?? magnifyingGlass} alt="magnifying glass" style={{ width: '10em', height: 'auto', opacity: '0.3', ...imgProps.style }} />
      </Box>

      <Typography sx={{ pl: 3, pt: 3 }} variant='h6'><b>{title}</b></Typography>
      <Typography sx={{ pl: 3, pt: 0 }} variant='subtitle2'>{content}</Typography>

      {children && <Divider sx={{ width: '75%', my: 2 }} />}
      {children}
    </Box>
  );
}
