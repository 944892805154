import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

// Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Styled components
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const drawerWidth = 250;

const styles = {
  itemButton: {
    minHeight: 48,
    px: 2.5,
  },
  itemIcon: {
    minWidth: 0,
    justifyContent: 'center',
  },
  icon: {
    color: 'white',
  },
  text: {
    color: 'white',
    fontSize: 14,
    fontWeight: 400,
  },
  divider: {
    background: 'white',
    marginTop: 8,
    marginBottom: 8,
  },
};

/* Component SideMenu

  Side menu of the navbar, allows you to navigate to the other views of overseer

  Props:
    viewIcons: Object of icon names for the side menu
    endpoints: Object endpoint of the views in the new overseer
*/
class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewIcons: {
        Admin: 'manage_accounts',
        Pipeline: 'table_chart',
        Service: 'account_tree',
        Monitor: 'desktop_windows',
        Layouts: 'grid_view',
        Parameters: 'smart_toy',
        Scheduler: 'calendar_view_week',
        Alerts: 'admin_panel_settings',
        'Metrics-Dashboard': 'bar_chart',
      },
      endpoints: {
        Pipeline: '/pipeline_processing',
      },
    };
  }

  navigateToView(path) {
    const { navigate } = this.props;
    if (path.includes('http')) {
      window.location.replace(path);
    } else {
      //this.navigate(path);
      navigate(path);
    }
  }

  render() {
    const {
      open,
      handleDrawer,
      DrawerHeader,
      navigationOptions,
      navigationLoading,
      t,
    } = this.props;
    const { viewIcons } = this.state;

    const emptyOptions = navigationOptions.length === 0;

    return (
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: '#1a242d',
          },
        }}
      >
        {/* Menu close button */}
        <DrawerHeader>
          <IconButton onClick={handleDrawer}>
            {!open ? (
              <ChevronRightIcon style={styles.icon} />
            ) : (
              <ChevronLeftIcon style={styles.icon} />
            )}
          </IconButton>
        </DrawerHeader>

        <Divider style={{ background: open ? 'white' : 'inherit' }} />

        {!navigationLoading && !emptyOptions && (
          <List>
            {navigationOptions
              .filter((option) => option.path !== null)
              .map((element) => (
                <ListItem
                  key={element.description}
                  disablePadding
                  sx={{ display: 'block' }}
                >
                  <ListItemButton
                    onClick={() => this.navigateToView(element.path)}
                    sx={[
                      styles.itemButton,
                      { justifyContent: open ? 'flex-end' : 'center' },
                    ]}
                  >
                    <ListItemIcon
                      sx={[styles.itemIcon, { mr: open ? 3 : 'auto' }]}
                    >
                      <Icon style={styles.icon}>
                        {viewIcons[element.description]}
                      </Icon>
                    </ListItemIcon>
                    <ListItemText
                      primary={t(
                        `overseer_app.navbar.${element.description}`,
                        element.description
                      )}
                      disableTypography
                      sx={[
                        styles.text,
                        { opacity: open ? 1 : 0, color: '#FFFF' },
                      ]}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        )}
      </Drawer>
    );
  }
}

// Wrap and export
export default withTranslation()((props) => {
  const navigate = useNavigate();
  return <SideMenu {...props} navigate={navigate} />;
});
