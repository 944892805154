import { useEffect, useState } from 'react';

import LayoutMap from './LayoutMap';
import { Skeleton } from '@mui/material';

export default function RobotLayouts(props) {
  const {
    store,
    floor,
    width = 600,
    robotLayout,
    getRobotLayout,
    bucketMapLayout,
    layersDisplay = ['aisles'],
    getBucketMapLayout,
    isLoadingRobotLayout,
    isLoadingBucketMapLayout,

    highlightedBoxes = [],
    onChangeAisles = () => { },
    onChangeZones = () => { },
  } = props;

  const [mapMetadata, setMapMetadata] = useState({});
  const [layout, setLayout] = useState(null);
  const [mapImg, setMapImg] = useState(null);

  // Hooks
  useEffect(() => {
    if (store && floor) {
      getRobotLayout({ store, floor });
      getBucketMapLayout({ store, floor });
    }
  }, [store, floor]);

  useEffect(() => {
    if (robotLayout) {
      setLayout(robotLayout.layout);
      setMapMetadata({
        ...mapMetadata,
        ...robotLayout.metadata,
        map_url: robotLayout.map_url,
      });
      handleLayoutOptions(robotLayout?.layout?.capture ?? {}, onChangeAisles);
      handleLayoutOptions(robotLayout?.layout?.zones ?? {}, onChangeZones);
    }
  }, [robotLayout]);

  useEffect(() => {
    if (bucketMapLayout) {
      setMapImg(bucketMapLayout.map_img);
      setMapMetadata({
        ...mapMetadata,
        height: bucketMapLayout.height,
        width: bucketMapLayout.width,
      })
    }
  }, [bucketMapLayout]);


  // Handlers
  const handleLayoutOptions = (objectOption, onChange) => {
    const options = Object.entries(objectOption).map(([key, value]) => {
      return {
        id: key,
        name: key,
        ...value,
      }
    });
    onChange(options);
  }

  return (
    <>
      {isLoadingRobotLayout || isLoadingBucketMapLayout ? (
        <Skeleton variant="rectangular" width={width} height={600} />
      ) : (
        <LayoutMap
          layout={layout}
          mapImg={mapImg}
          width={width}
          layersDisplay={layersDisplay}
          mapMetadata={mapMetadata}
          highlightedBoxes={highlightedBoxes}
        />
      )}
    </>
  );
}
