import { useTranslation } from 'react-i18next';
import { LinearProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';


const StyledTextField = styled(props => {
  return (<TextField {...props} />)
})(({ theme }) => {
  return ({
    "& .MuiInputLabel-root": {
      color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)',
    },
    "& .MuiFilledInput-root": {
      background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)',
      height: 'auto',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.17)',
    },
    '&.Mui-focused': {
      backgroundColor: 'rgba(255, 255, 255, 0.17)',
    },
  })
});


export default function Selector(props) {
  const {
    labelInput = 'General.label_selecter',
    options = [],
    optionLabel = 'name',
    isLoading,
    fullWidth = false,
    selectedElement,
    setSelectedElement,
    disabled,
    disableClearable = false,
    optionsDisabled = null,
    defaultValue = null,  // There was a warning because the defualt value (eg.: null, [], '', etc.) didn't match any of the selections
    variant = 'filled',
    error = false,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <Autocomplete
        fullWidth={fullWidth}
        disableClearable={disableClearable}
        value={selectedElement}
        onChange={(_, newValue) => {
          setSelectedElement(newValue);
        }}
        isOptionEqualToValue={(option, value) => value?.[optionLabel] === defaultValue ? true : option?.[optionLabel] === value?.[optionLabel]}
        id="controllable-selecter"
        options={options ? options : []}
        getOptionLabel={(option) => option ? option?.[optionLabel] : ''}
        renderInput={(params) => <StyledTextField {...params} variant={variant} label={t(labelInput)} error={error} />}
        disabled={disabled}
        getOptionDisabled={optionsDisabled}
      />
      {!isLoading && <Box sx={{ position: 'relative', width: '100%', height: '4px', visibility: 'hidden' }} />}
      {isLoading && <LinearProgress sx={{ width: '100%', mx: 'auto', top: '-5px' }} color="secondary" />}
    </>
  );
}