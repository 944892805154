import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Divider,
  Modal,
  CircularProgress,
  Stack,
  Chip,
} from "@mui/material";
import LineGraph from "../../LineGraph";
import { useParams } from "react-router-dom";
import Revisions from "../../../../../redux/containers/pipelineMonitoring/Revisions";

import ServiceAlert from "../../../ServiceAlert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  zIndex: 100,
};

function MetricsInfo(props) {
  const {
    edge,
    getTasksDepth,
    tasksDepth,
    loadingTasksDepth,
    getTasksCount,
    tasksCount,
    loadingTasksCount,
    navigationOptions,
  } = props;

  const { session, date } = useParams();
  const [timeFrame, setTimeFrame] = useState(2);
  const [selectedGraph, setSelectedGraph] = useState(false);
  const [newPolicy, setNewPolicy] = useState(false);

  useEffect(() => {
    getTasksDepth({
      metrics_type: "cloud_tasks_depth",
      hours: timeFrame,
      is_vertex: edge.data.child.is_vertex,
      service_name: edge.data.child.cloud_tasks_queue,
      project_id: edge.data.child.queue_project_id,
    });

    getTasksCount({
      metrics_type: "cloud_tasks_count",
      hours: timeFrame,
      is_vertex: edge.data.child.is_vertex,
      service_name: edge.data.child.cloud_tasks_queue,
      project_id: edge.data.child.queue_project_id,
    });
  }, [timeFrame, newPolicy]);

  if (!tasksDepth && !tasksCount) {
    return (
      <Box>
        <Typography
          sx={{
            margin: 1,
            color: "white",
            fontWeight: "bold",
            fontSize: 24,
            marginX: 3,
          }}
        >
          {edge.data.child.cloud_tasks_queue}
        </Typography>

        <Typography
          sx={{
            color: "lightgrey",
            fontSize: 16,
            marginX: 3,
            marginBottom: 1,
          }}
        >
          {edge.data.child.display_name}
          {edge.data.child.description
            ? ` - ${edge.data.child.description}`
            : null}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 1,
            paddingY: 2,
          }}
        >
          <Typography
            sx={{
              color: "#7c909b",
              fontSize: 18,
              fontWidth: "bold",
            }}
          >
            No metrics available for this service...
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      {edge.data.alerts?.map((alertInfo) => {
        return (
          <Box sx={{ marginX: 1 }}>
            <ServiceAlert alertInfo={alertInfo} />
          </Box>
        );
      })}
      <Typography
        sx={{
          margin: 1,
          color: "white",
          fontWeight: "bold",
          fontSize: 24,
          marginX: 3,
        }}
      >
        {edge.data.child.cloud_tasks_queue}
      </Typography>

      <Typography
        sx={{ color: "lightgrey", fontSize: 16, marginX: 3, marginBottom: 1 }}
      >
        {edge.data.child.display_name}
        {edge.data.child.description
          ? ` - ${edge.data.child.description}`
          : null}
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={1}
        sx={{ marginRight: 2 }}
      >
        <Chip
          label="2 hours"
          variant={timeFrame === 2 ? "filled" : "outlined"}
          color="secondary"
          size="small"
          onClick={() => setTimeFrame(2)}
        />
        <Chip
          label="6 hours"
          variant={timeFrame === 6 ? "filled" : "outlined"}
          color="secondary"
          size="small"
          onClick={() => setTimeFrame(6)}
        />
        <Chip
          label="12 hours"
          variant={timeFrame === 12 ? "filled" : "outlined"}
          size="small"
          color="secondary"
          onClick={() => setTimeFrame(12)}
        />
        <Chip
          label="1 day"
          variant={timeFrame === 24 ? "filled" : "outlined"}
          size="small"
          color="secondary"
          onClick={() => setTimeFrame(24)}
        />
        <Chip
          label="7 days"
          variant={timeFrame === 7 * 24 ? "filled" : "outlined"}
          size="small"
          color="secondary"
          onClick={() => setTimeFrame(7 * 24)}
        />
      </Stack>
      <Modal
        style={{ backdropFilter: "blur(5px)" }}
        open={selectedGraph !== false}
        onClose={() => setSelectedGraph(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontWeight: "bold" }}
          >
            {selectedGraph ? selectedGraph.title : null}
          </Typography>
          <Box sx={{ width: "800px", height: "500px" }}>
            <LineGraph
              data={selectedGraph.data}
              size="lg"
              graphType={selectedGraph.type}
            />
          </Box>
        </Box>
      </Modal>
      <Box sx={{ margin: 2, backgroundColor: "#e9e9f1", borderRadius: 1 }}>
        <Revisions
          data={tasksDepth}
          loadingData={loadingTasksDepth}
          setSelectedGraph={setSelectedGraph}
          title="Tasks Queue Depth"
          selectedRevision={"Cloud Tasks"}
          graphType={"numerical"}
          newPolicy={newPolicy}
          setNewPolicy={setNewPolicy}
          navOptions={navigationOptions}
          metric={{
            project_id: edge.data.child.queue_project_id,
            resource_type: "cloud_tasks_queue",
            metric_type: "cloudtasks.googleapis.com/queue/depth",
            resource_label_key: "queue_id",
            resource_label_value: edge.data.child.cloud_tasks_queue,
          }}
        />
        <Divider sx={{ borderBottomWidth: 2 }} />
        <Revisions
          data={tasksCount}
          loadingData={loadingTasksCount}
          setSelectedGraph={setSelectedGraph}
          title="Tasks Attempt Count"
          selectedRevision={"Cloud Tasks"}
          graphType={"numerical"}
          newPolicy={newPolicy}
          setNewPolicy={setNewPolicy}
          navOptions={navigationOptions}
          metric={{
            project_id: edge.data.child.queue_project_id,
            resource_type: "cloud_tasks_queue",
            metric_type: "cloudtasks.googleapis.com/queue/task_attempt_count",
            resource_label_key: "queue_id",
            resource_label_value: edge.data.child.cloud_tasks_queue,
          }}
        />
      </Box>
    </Box>
  );
}

export default MetricsInfo;
