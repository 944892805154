import { connect } from 'react-redux';

import SideMenu from '../../components/navbar/SideMenu';
import { navigationOptions, navigationLoading } from 'core/selectors';

export default connect(
  state => ({
    navigationOptions: navigationOptions(state),
    navigationLoading: navigationLoading(state),
  }),
  {},
)(SideMenu);
