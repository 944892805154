import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

//Icons
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

// Styled components
const ColorButton = styled(Button)(({ theme }) => ({
  color: 'primary',
  height: theme.mixins.toolbar.minHeight,
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#1a242d',
    borderRadius: 3,
    marginTop: theme.spacing(1),
    minWidth: 300,
    color: theme.palette.mode === 'light' ? 'white' : 'black',
  },
}));

const styles = {
  icon: {
    color: 'white',
  },
  text: {
    fontSize: 14,
    marginLeft: 12,
  },
};

/* Component MyMenu

  Consist of a menu with the folowing features:
  - Profile
  - Edit information
  - Change language
  - Change Light/Dark mode
  - Logout

  Props:
    open: A booleano define whether the menu is open
    anchorEl: An HTML element, or a function that returns one. It's used to set the position of the menu.
*/
class MyMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      name: props.userInfo.name,
      picture: props.userInfo.picture,
      tenant: props.userInfo.firebase.tenant,
      REACT_APP_AUTH_BASE_URL: process.env.REACT_APP_AUTH_BASE_URL,
      openModal: false,
      actualShiftId: null,
    };
  }

  handleOpenMenu = (event) => {
    this.setState({ open: true });
    this.setAnchorEl(event.currentTarget);
  };

  handleCloseMenu = () => {
    this.setState({ open: false });
  };

  setAnchorEl = (event) => {
    this.setState({ anchorEl: event });
  };

  handleProfileNavigation = () => {
    window.location.href = `${this.state.REACT_APP_AUTH_BASE_URL}/profile?tenant=${this.state.tenant}&redirect=${window.location.href}`;
  };

  openConfirmation = () => {
    this.setState({ openModal: true });
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };

  handleWorkshift = () => {
    let action = this.props.workshiftActive ? 'end' : 'start';
    let event_ids =
      action === 'end' && this.props.eventsList.length > 0
        ? this.props.eventsList.map((event) => event.robot_event_id)
        : [];
    this.props.postWorkshift(this.props.detailedUserInfo.id, action, event_ids);
    if (action === 'start') this.props.setActiveInShift(true);
    this.handleClose();
    this.handleCloseMenu();
  };

  handleChange = (event) => {
    this.props.setActiveInShift(event.target.checked);
    this.props.postMonitorActivity(
      this.props.detailedUserInfo.id,
      this.props.eventsList.length > 0
        ? this.props.eventsList.map((event) => event.robot_event_id)
        : [],
      this.props.actualShiftId,
      event.target.checked ? 'active' : 'inactive'
    );
    this.props.postMonitorStatus(
      this.props.detailedUserInfo.id,
      event.target.checked
    );
    this.props.getEvents();
  };

  handleLogOut = () => {
    if (this.props.workshiftActive) {
      let events_id =
        this.props.eventsList.length > 0
          ? this.props.eventsList.map((event) => event.robot_event_id)
          : [];
      this.props.postWorkshift(
        this.props.detailedUserInfo.id,
        'end',
        events_id
      );
      this.props.postMonitorActivity(
        this.props.detailedUserInfo.id,
        this.props.eventsList.length > 0
          ? this.props.eventsList.map((event) => event.robot_event_id)
          : [],
        this.props.actualShiftId,
        'inactive'
      );
    }
    this.props.logOut();
  };

  render() {
    const { open, anchorEl } = this.state;
    const { t } = this.props;

    return (
      <Grid>
        <ColorButton onClick={this.handleOpenMenu}>
          <Avatar
            src={this.state.picture}
            imgProps={{ referrerPolicy: 'no-referrer' }}
          />
        </ColorButton>

        <StyledMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={this.handleCloseMenu}>
            <PersonIcon style={styles.icon} />
            <Typography style={styles.text}>{this.state.name}</Typography>
          </MenuItem>

          <MenuItem onClick={this.handleProfileNavigation}>
            <EditIcon style={styles.icon} />
            <Typography style={styles.text}>
              {t('General.edit_profile', 'Edit Profile')}
            </Typography>
          </MenuItem>

          {this.props.handleFeaturePermission() && (
            <>
              <Divider variant="middle" style={{ background: 'white' }} />
              <MenuItem onClick={this.openConfirmation}>
                <IconComponent
                  iconName="stopwatch-outline"
                  style={{ fontSize: 24 }}
                />
                <Typography style={styles.text}>
                  {this.props.workshiftActive
                    ? t('overseer_app.monitoring.end_shift', 'End shift')
                    : t('overseer_app.monitoring.start_shift', 'Start shift')}
                </Typography>
              </MenuItem>
              {this.props.workshiftActive && (
                <MenuItem>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.props.activeInShift}
                        onChange={this.handleChange}
                        size="small"
                        color="secondary"
                        sx={{ marginLeft: 1 }}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: 14 }}>
                        {t('overseer_app.monitoring.active', 'Active')}
                      </Typography>
                    }
                  />
                </MenuItem>
              )}
            </>
          )}

          <Divider variant="middle" style={{ background: 'white' }} />

          {/* 
          <MenuItem onClick={this.handleCloseMenu}>
            <LanguageIcon style={styles.icon} />
            <Typography style={styles.text}>Cambiar idioma</Typography>
          </MenuItem>

          <MenuItem onClick={this.handleCloseMenu}>
            <DarkModeOutlinedIcon style={styles.icon} />
            <Typography style={styles.text}>Cambiar apariencia</Typography>
          </MenuItem>
          */}

          <MenuItem onClick={() => this.handleLogOut()}>
            <LogoutIcon style={styles.icon} />
            <Typography style={styles.text}>
              {t('General.log_out', 'Log Out')}
            </Typography>
          </MenuItem>
        </StyledMenu>
        <Dialog
          open={this.state.openModal}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('overseer_app.monitoring.shift', 'Workshift')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.workshiftActive
                ? t(
                    'overseer_app.monitoring.finish_confirm',
                    'Do you want to finish the workshift?'
                  )
                : t(
                    'overseer_app.monitoring.start_confirm',
                    'Do you want to start the workshift?'
                  )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>No</Button>
            <Button onClick={() => this.handleWorkshift()} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default withTranslation()(MyMenu);
