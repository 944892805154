import { Fragment, useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material';
import { Legend } from '@zippeditoolsjs/dashboards';
import { useTranslation } from 'react-i18next';
import ScatterChartComponent from '../tools/ScatterPlot';
import TimelineInfoWidget from './TimelineInfoWidget';
import TimeSlider from '../tools/TimeSlider';
import { format } from 'date-fns';
import MapWidget from './MapWidget';
import { convertToUserTimezone, getUserTimezone } from '../tools/utils';
import theme from '../../styleSheet';

function TimelineWidget(props) {
  const {
    store,
    robot,
    mqttNavInfo,
    storeMapInfo,
    minHeight,
    isLoading,
    storeInfo,
    getMapSignal,
    timelineTrace,
    getTimelineTrace,
    isTest = false,
    mapSignal,
    isLoadingMapSignalInfo
  } = props;
  const { t } = useTranslation();

  const HEIGHT_Y_LABEL_BLOCK = 48;
  const EXP_BACKOFF_INCREMENT = 0.3;
  const BEFORE_MINUTES = 60;
  const FORWARD_MINUTES = 60;
  const [beforeMinutes, setBeforeMinutes] = useState({ total: BEFORE_MINUTES, expBackoff: 0 });
  const [forwardMinutes, setForwardMinutes] = useState({ total: FORWARD_MINUTES, expBackoff: 0 });
  const [onDotValueChange, setOnDotValueChange] = useState(undefined);
  const [marksTime, setMarksTime] = useState([{ value: 0, label: format((new Date() - 1 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd HH:mm:ss') }, { value: 100, label: format(new Date(), 'yyyy-MM-dd HH:mm:ss') }]);
  const [chartData, setChartData] = useState([]);
  const [scatterInfo, setScatterInfo] = useState([]);
  const [linesInfo, setLinesInfo] = useState([]);
  const [timeLineData, setTimeLineData] = useState(timelineTrace);
  const [AreaIntervals, setAreaIntervals] = useState([]);
  const [heightComposedChart, setHeightComposedChart] = useState(300);
  const timerRef = useRef(null);
  const [valueLegend, setValueLegend] = useState({});
  const [yAxisValueName, setYAxisValueName] = useState({});

  const widthComposedChart = 2500;

  useEffect(() => {
    if (robot && store) {
      setChartData([]);
      setTimeLineData([]);
      setValueLegend({});
      getTimelineTrace({ robot_id: `${store}-${robot}` });
    }
  }, [robot, store]);

  useEffect(() => {
    if (!isLoading && timelineTrace?.alerts) {
      const newChartData = timelineTrace?.alerts.map(
        (alert) => {
          return {
            ...alert,
            x: convertToUserTimezone(alert.x, getUserTimezone()),
          }
        }) ?? [];
      const newIntervals = timelineTrace?.intervals.map(
        (interval) => {
          return {
            ...interval,
            x1: convertToUserTimezone(interval.x1, getUserTimezone()),
            x2: convertToUserTimezone(interval.x2, getUserTimezone()),
          }
        }
      ) ?? [];
      const yAxisValueName = timelineTrace?.y_axis_value_name ?? {};

      setValueLegend({});
      setYAxisValueName(yAxisValueName);
      setTimeLineData(timelineTrace);
      setForwardMinutes({ total: FORWARD_MINUTES, expBackoff: 0 });
      setBeforeMinutes({ total: BEFORE_MINUTES, expBackoff: 0 });
      setChartData(newChartData);
      setScatterInfo(timelineTrace?.scatter_info ?? []);
      setLinesInfo(timelineTrace?.lines_info ?? []);
      setAreaIntervals(newIntervals);
      handleMarksTime(newChartData);

      const newHeight = Object.keys(yAxisValueName).length * HEIGHT_Y_LABEL_BLOCK;
      setHeightComposedChart(newHeight > 200 ? newHeight : 200);
    }
  }, [isLoading, timelineTrace, onDotValueChange]);

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);


  // handle
  const handleWidth = (defaultWidth = '85em') => {
    const width = window.innerWidth;
    return width ? `${width * 0.93}px` : defaultWidth;
  }

  const handleTimelineTimeout = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    const timer = setTimeout(() => {
      getTimelineTrace({ robot_id: `${store}-${robot}`, before_minutes: beforeMinutes.total, forward_minutes: forwardMinutes.total })
    }, 550);
    timerRef.current = timer;
  }

  const handleArrowClick = (isForward) => {
    const waitingRequestData = chartData;
    const N_DATES = 4;
    if (isForward && forwardMinutes.expBackoff === 0) {
      if (waitingRequestData?.length) {
        const lastDate = new Date(waitingRequestData.at(-1)?.x);
        const newLastDate = new Date(lastDate.getTime() + (forwardMinutes.total * 10000));
        const newDates = Array.from({ length: N_DATES }, (_, i) => {
          return { x: format(new Date(newLastDate.getTime() - i * (forwardMinutes.total / N_DATES * 15 * 1000)), 'yyyy-MM-dd HH:mm:ss') }
        })

        const newData = waitingRequestData.concat(newDates);
        const newIntervals = AreaIntervals.concat([{ x1: format(lastDate, 'yyyy-MM-dd HH:mm:ss'), x2: null, fill: '#6b7280' }]);

        setChartData(newData);
        setAreaIntervals(newIntervals);
        handleMarksTime(newData);
      } else {
        const newMarks = [
          {
            value: 0,
            label: marksTime[0]?.label,
          },
          {
            value: 100,
            label: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          }
        ];
        setMarksTime(newMarks);
      }
      setForwardMinutes({
        total: forwardMinutes.total + FORWARD_MINUTES * forwardMinutes.expBackoff,
        expBackoff: forwardMinutes.expBackoff + EXP_BACKOFF_INCREMENT,
      });

    } else if (beforeMinutes.total < 3 * 24 * 60) {
      const totalBefore = beforeMinutes.total + BEFORE_MINUTES * beforeMinutes.expBackoff;
      if (waitingRequestData?.length) {
        const firstDate = new Date(waitingRequestData[0]?.x);
        const newFirstDate = new Date(firstDate.getTime() - ((totalBefore - beforeMinutes.total - N_DATES + 1) * 60000));
        const newDates = Array.from({ length: N_DATES }, (_, i) => {
          return { x: format(new Date(newFirstDate.getTime() + i * ((totalBefore - beforeMinutes.total - N_DATES + 1) / N_DATES * 60000)), 'yyyy-MM-dd HH:mm:ss') }
        })

        const newData = newDates.concat(waitingRequestData);
        const newIntervals = [{ x1: format(newFirstDate, 'yyyy-MM-dd HH:mm:ss'), x2: format(firstDate, 'yyyy-MM-dd HH:mm:ss'), fill: '#6b7280' }].concat(AreaIntervals);

        setChartData(newData);
        setAreaIntervals(newIntervals);
        handleMarksTime(newData);
      } else {
        const newMarks = [
          {
            value: 0,
            label: format(new Date(new Date() - totalBefore * 60000), 'yyyy-MM-dd HH:mm:ss'),
          },
          {
            value: 100,
            label: marksTime[1]?.label,
          }
        ];
        setMarksTime(newMarks);
      }
      setBeforeMinutes({
        total: totalBefore,
        expBackoff: beforeMinutes.expBackoff + EXP_BACKOFF_INCREMENT,
      });
    }
    handleTimelineTimeout();
  }

  const handleMarksTime = (newData) => {
    let newMarksTime = [];
    if (newData?.length) {
      const dates = newData.reduce((acc, inc, index) => {
        const yearMonthDay = inc?.x?.split(' ')[0];
        if (!acc.get(yearMonthDay)) {
          acc.set(yearMonthDay, index);
        }
        return acc
      }, new Map());

      const max = newData.at(-1).x.split(' ').at(-1);
      const min = newData[0].x.split(' ').splice(0)[0];

      const totalData = newData.length;
      newMarksTime = [
        {
          value: 0,
          label: min,
        },
      ]
      newMarksTime = newMarksTime.concat(Array.from(dates)
        .map(row => {
          return {
            label: row[0],
            value: Math.round(100 * row[1] / totalData),
          }
        })
        .slice(1,)
      );
      newMarksTime.push(
        {
          value: 100,
          label: max,
        }
      );
    }

    setMarksTime(newMarksTime);
  }

  const filterYAxisValueName = (yAxisValueName, categoryKey, compareMethod = '!==') => {
    let newFilteredYAxisValueName;
    if (compareMethod === '!==') {
      newFilteredYAxisValueName = Object.entries(yAxisValueName).filter(([key, value]) => value.key !== categoryKey)
    } else if (compareMethod === '===') {
      newFilteredYAxisValueName = Object.entries(yAxisValueName).filter(([key, value]) => value.key === categoryKey);
    } else if (compareMethod === 'startsWith') {
      newFilteredYAxisValueName = Object.entries(yAxisValueName).filter(([key, value]) => value.key.startsWith(categoryKey));
    } else if (compareMethod === '!startsWith') {
      newFilteredYAxisValueName = Object.entries(yAxisValueName).filter(([key, value]) => !value.key.startsWith(categoryKey));
    }
    return newFilteredYAxisValueName.reduce((acc, [key, value]) => { acc[key] = value; return acc }, {})
  }

  const handleLegendClick = ({ categoryKey, chartType }) => () => {
    setValueLegend({ ...valueLegend, [categoryKey]: !valueLegend[categoryKey] });
    let newFilteredYAxisValueName;
    let compareMethod = '===';
    if (valueLegend[categoryKey]) {
      if (chartType === 'line') {
        compareMethod = 'startsWith';
        const newTimeLineData = timeLineData?.lines_info?.filter(row => row.lineKey.startsWith(categoryKey)) ?? [];
        setLinesInfo(linesInfo.concat(newTimeLineData));
      } else if (chartType === 'scatter') {
        const newTimeLineData = timeLineData?.scatter_info?.filter(row => row.scatterKey === categoryKey) ?? [];
        setScatterInfo(scatterInfo.concat(newTimeLineData));
      } else if (chartType === 'area') {
        const newIntervals = timelineTrace?.intervals?.filter(row => row.areaKey === categoryKey).map(
          (interval) => {
            return {
              ...interval,
              x1: convertToUserTimezone(interval.x1, getUserTimezone()),
              x2: convertToUserTimezone(interval.x2, getUserTimezone()),
            }
          }
        ) ?? [];
        setAreaIntervals(AreaIntervals.concat(newIntervals));
      }
      newFilteredYAxisValueName = {
        ...yAxisValueName,
        ...filterYAxisValueName(timeLineData?.y_axis_value_name ?? {}, categoryKey, compareMethod)
      }
    } else {
      compareMethod = '!==';
      if (chartType === 'line') {
        compareMethod = '!startsWith';
        setLinesInfo(linesInfo.filter(row => !row.lineKey.startsWith(categoryKey)));
      } else if (chartType === 'scatter') {
        setScatterInfo(scatterInfo.filter(row => row.scatterKey !== categoryKey));
      } else if (chartType === 'area') {
        setAreaIntervals(AreaIntervals.filter(row => row.areaKey !== categoryKey));
      }
      newFilteredYAxisValueName = filterYAxisValueName(yAxisValueName, categoryKey, compareMethod);
    }

    const newHeight = Object.keys(newFilteredYAxisValueName).length * HEIGHT_Y_LABEL_BLOCK;
    setHeightComposedChart(newHeight > 200 ? newHeight : 200);
    setYAxisValueName(newFilteredYAxisValueName);
  }

  return (
    <>
      {(isLoading && !chartData) ?
        <Box
          variant="rectangular"
          minHeight={250}
          height={'100%'}
          sx={{
            m: 2,
            backgroundColor: '#FFF',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <Typography><CircularProgress /> Loading Timeline...</Typography>
        </Box>
        : (
          <Grid container columnSpacing={2} rowSpacing={1} sx={{ maxWidth: handleWidth() }}>
            <Grid item xs={12}>
              {isLoading && <LinearProgress color='secondary' sx={{ width: '100%' }} />}
            </Grid>

            <Grid item xs={12} sx={{ overflowX: 'auto' }}>
              {
                timelineTrace?.legend &&
                <Card sx={{
                  my: 1, pb: 1,
                  width: '100%',
                  borderRadius: 1,
                  backgroundColor: '#FFF',
                  display: 'flex', justifyContent: 'center', textAlign: 'center',
                  bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                  color: (theme) => theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                }}
                >
                  {
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1, overflowX: 'auto' }}>
                      {
                        timelineTrace.legend.categories.map((category, index) => {
                          const chartType = timelineTrace.legend?.chartTypes?.[index];
                          const categoryKey = timelineTrace.legend?.keys?.[index];
                          const chartColor = timelineTrace.legend?.colors?.[index];
                          const hexColor = theme.palette[chartColor]?.main ?? chartColor;
                          return (
                            <Fragment key={index}>
                              <Button
                                variant="text"
                                size='small'
                                sx={{
                                  ml: 1,
                                  backgroundColor: valueLegend[categoryKey] ? "#DDDDDD66" : "",
                                  display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', textTransform: 'none'
                                }}
                                onClick={handleLegendClick({ categoryKey, chartType })}
                              >
                                <svg style={{ height: chartType === 'line' ? 'auto' : 'revert-layer', width: chartType === 'line' ? '14px' : 'auto' }} fill={`${hexColor}DD`} class="flex-none text-teal-500 h-2 mr-1.5" viewBox="0 0 8 8">
                                  {
                                    chartType === 'line' ?
                                      <>
                                        <circle cx="4" cy="4" r="2" style={{ stroke: hexColor, strokeWidth: 1, fill: "none" }} />
                                        <line x1="0" y1="4" x2="8" y2="4" style={{ stroke: hexColor, strokeWidth: 1, strokeLinecap: 'round' }} />
                                      </>
                                      : chartType === 'area' ?
                                        <rect x="0" y="0" width="8" height="8"></rect>
                                        : chartType === 'scatter' ?
                                          <circle cx="4" cy="4" r="4"></circle>
                                          : <circle cx="4" cy="4" r="4"></circle>
                                  }
                                </svg>
                                <Typography
                                  className='whitespace-nowrap truncate text-tremor-default text-tremor-content dark:text-dark-tremor-content'
                                  variant='caption'>{category}</Typography>
                              </Button>
                            </Fragment>
                          )
                        })
                      }
                    </Box>
                  }
                </Card>
              }
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ overflowX: 'auto', p: 2, mb: 2 }}>
                <ScatterChartComponent
                  points={chartData}
                  widthComposedChart={widthComposedChart}
                  heightComposedChart={heightComposedChart}
                  referenceAreas={AreaIntervals}
                  styleColors={timelineTrace?.style_colors ?? {}}
                  linesInfo={linesInfo}
                  scatterInfo={scatterInfo}
                  onValueChange={(v) => setOnDotValueChange(v)}
                  yAxisValueName={yAxisValueName}
                />
                <TimeSlider
                  disabled={!isTest || isLoading}
                  disableButtons={isLoading}
                  sx={{ width: chartData?.length ? `${widthComposedChart - 5}px` : 'auto' }}
                  marks={marksTime}
                  onclick={handleArrowClick}
                  value={(value) => { return typeof value == 'number' ? value : value?.split(' ')[0] }}
                  leftButtonTooltip={{ title: t('overseer_app.general.backward_until_', 'Backward until 3 days') }}
                  rightButtonTooltip={{ title: t('overseer_app.general.forward', 'Forward') }}
                />
              </Card>
            </Grid>
            <Grid item xs={8}>
              {/* <WidgetTest title='Map Location' content='In development' /> */}
              <MapWidget
                id="timeline-view"
                title={t(`overseer_app.widget.map`, 'Map')}
                storeInfo={storeInfo}
                mqttNavInfo={mqttNavInfo}
                getMapSignal={getMapSignal}
                mapSignal={mapSignal}
                storeMapInfo={storeMapInfo}
                minHeight={minHeight}
                isLoadingMapSignalInfo={isLoadingMapSignalInfo}
              />
            </Grid>
            <Grid item xs={4}>
              <TimelineInfoWidget selectedDotInfo={onDotValueChange} />
            </Grid>
          </Grid >
        )
      }
    </>
  );
}

export default TimelineWidget;
