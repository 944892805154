import { useEffect, useState, useContext } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Grid,
  Autocomplete,
  TextField,
  LinearProgress,
  Divider,
  Typography,
  IconButton,
  Stack,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SchedulerTable from './SchedulerTable';
import MultiSelector from '../tools/MultiSelector';
import NotAllowedView from '../tools/NotAllowed';
import { checkPermision } from '../Utils';
import dayjs from 'dayjs';
import { formatDate } from './Utils';
import { SchedulerViewContext } from '../../context/SchedulerView';

const currentDateStyle = {
  textAlign: 'center',
  backgroundColor: '#fff',
  fontWeight: 'bold',
  fontSize: '1.2rem',
};

function Scheduler(props) {
  const {
    //List with data
    chainsList,
    robotsListBaldur,
    sessionsList,
    newSessionsResponse,
    navOptions,
    updateSessionResponse,
    deleteSessionsResponse,
    //Booleans
    isLoadingChains,
    isLoadingRobotListBaldur,
    isLoadingSessions,
    isLoadingNewSessions,
    isLoadingSessionUpdate,
    isLoadingDeleteSessions,
    //Methods
    getChains,
    getRobotsBaldur,
    getSessionsScheduler,
    postNewSessions,
    postSessionUpdates,
    deleteSessions,
  } = props;

  const viewId = 'Scheduler';
  const [currentDate, setCurrentDate] = useState(null);
  const [currentDay, setCurrentDay] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(0);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));

  const [selectedRobots, setSelectedRobots] = useState(null);
  const [selectedRobObjects, setSelectedRobObjects] = useState([]);

  const [dataRobots, setDataRobots] = useState([]);
  const [useLocalTime, setUseLocalTime] = useState(false);

  const [userHoursDiff, setUserHoursDiff] = useState(null);

  const {
    selectedChain,
    setSelectedChain,
  } = useContext(SchedulerViewContext);

  useEffect(() => {
    if (sessionsList) {
      setDataRobots(sessionsList);
    }
  }, [sessionsList]);

  useEffect(() => {
    if (chainsList && chainsList.length === 0) {
      getChains();
    }
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const now = new Date();
    const userLocalTime = new Date(
      now.toLocaleDateString('en-US', { timeZone: userTimezone })
    );
    const offsetHours = userLocalTime.getTimezoneOffset() / 60;
    setUserHoursDiff(offsetHours * -1);
  }, [chainsList, getChains]);

  useEffect(() => {
    if (selectedRobots) {
      let selectedRobotsObject = robotsListBaldur.filter((robot) =>
        selectedRobots.includes(robot.robot_uuid)
      );
      setSelectedRobObjects(selectedRobotsObject);
      if (selectedRobotsObject.length > 0) {
        let updatedDate;
        let nextMonthFormattedDate;
        if (selectedDate == null) {
          [updatedDate, nextMonthFormattedDate] = formatDate(currentDate);
          setSelectedDate(updatedDate);
        } else {
          [updatedDate, nextMonthFormattedDate] = formatDate(
            selectedDate.format('DD/MM/YYYY')
          );
        }
        handleUserTimezone(updatedDate, nextMonthFormattedDate);
      }
    }
  }, [selectedRobots, useLocalTime]);

  useEffect(() => {
    let [updatedDate, nextMonthFormattedDate] = formatDate(
      selectedDate.format('DD/MM/YYYY')
    );
    setFrom(updatedDate);
    setTo(nextMonthFormattedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (selectedChain && selectedChain.length > 0) {
      getRobotsBaldur(selectedChain);
    }
  }, [selectedChain, getRobotsBaldur]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timerID);
    };
  }, []);

  const tick = () => {
    var date = new Date();
    var dateString = date.toLocaleDateString('en-GB');
    const dateParts = dateString.split('/');
    const day = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const year = parseInt(dateParts[2]);
    const formattedDate = new Date(year, month - 1, day).toLocaleDateString(
      'en-GB'
    );
    setCurrentDay(day);
    setCurrentMonth(month);
    setCurrentYear(year)
    setCurrentDate(formattedDate);
  };

  const handleChangeUseTime = (event) => {
    setUseLocalTime(event.target.checked);
  };

  const handleUserTimezone = (from, to) => {
    if (useLocalTime)
      getSessionsScheduler(selectedRobots, from, to, userHoursDiff);
    else getSessionsScheduler(selectedRobots, from, to);
  };

  const handleGetSessionsScheduler = () => {
    handleUserTimezone(from, to);
  };

  const handleDateChange = (date) => {
    if (date.$y) {
      const year = date.$y ? date.$y : date.$d.getFullYear();
      const month = date.$M || date.$M === 0 ? date.$M : date.$d.getMonth();
      const from = dayjs(new Date(year, month, 1)).format('YYYY-MM-DD');
      const to = dayjs(new Date(year, month + 1, 1)).format('YYYY-MM-DD');
      setFrom(from);
      setTo(to);
      //Format data and save date picked
      setSelectedDate(date);
      setCurrentWeek(0);
      handleUserTimezone(from, to);
    }
  };

  const handlePreviousWeek = () => {
    setCurrentWeek((prevWeek) => prevWeek - 1);
  };

  const handleNextWeek = () => {
    setCurrentWeek((prevWeek) => prevWeek + 1);
  };

  return (
    <Grid container>
      {navOptions.length === 0 ? (
        <LinearProgress
          sx={{ width: '100%', mx: 'auto', top: '-5px' }}
          color="secondary"
        />
      ) : (
        <>
          {checkPermision(navOptions, viewId) ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Autocomplete
                        fullWidth
                        multiple
                        disableCloseOnSelect
                        options={
                          chainsList
                            ? chainsList.map((chain) => chain.name)
                            : chainsList
                        }
                        onChange={(e, value) => setSelectedChain(value)}
                        renderInput={(params) => (
                          <TextField {...params} label="Chains" />
                        )}
                      />
                      {isLoadingChains && (
                        <LinearProgress
                          sx={{ width: '100%', mx: 'auto', top: '-5px' }}
                          color="secondary"
                        />
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <MultiSelector
                        options={robotsListBaldur}
                        inputSelected={selectedRobots}
                        setInputSelectedOptions={setSelectedRobots}
                        inputLabel={'Robots'}
                        isLoading={isLoadingRobotListBaldur}
                        objectName={'robot_code'}
                        objectId={'robot_uuid'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  display={'flex'}
                  justifyContent={'end'}
                  alignItems={'center'}
                >
                  {/* Icons buttons and Date today */}
                  <Typography sx={currentDateStyle}>{currentDate}</Typography>
                  <IconButton
                    onClick={handlePreviousWeek}
                    disabled={currentWeek === 0}
                    size="large"
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                  <IconButton
                    onClick={handleNextWeek}
                    disabled={
                      currentWeek ===
                      (dataRobots[0]
                        ? dataRobots[0].scheduler.length - 1
                        : 0) || (selectedDate ? false : true)
                    }
                    size="large"
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-around'}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        disabled={
                          selectedRobots ? selectedRobots.length === 0 : true
                        }
                        label={'"month" and "year"'}
                        views={['month', 'year']}
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Store Local Time</Typography>
                    <Switch
                      checked={useLocalTime}
                      onChange={handleChangeUseTime}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>Your Local Time</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider orientation="horizontal" fullWidth />
                </Grid>
                <Grid item xs={12} md={12} sx={{ width: '100' }}>
                  {isLoadingSessions ? (
                    <LinearProgress
                      sx={{ width: '100%', mx: 'auto', top: '-5px' }}
                      color="secondary"
                    />
                  ) : (
                    <SchedulerTable
                      //dates
                      currentDay={currentDay}
                      currentMonth={currentMonth}
                      currentWeek={currentWeek}
                      currentYear={currentYear}
                      selectedDate={selectedDate}
                      //list
                      dataRobots={dataRobots}
                      selectedRobots={selectedRobObjects}
                      navOptions={navOptions}
                      sessionsList={sessionsList}
                      robotsFromSelector={selectedRobots}
                      //methods
                      postNewSessions={postNewSessions}
                      postSessionUpdates={postSessionUpdates}
                      handleGetSessionsScheduler={handleGetSessionsScheduler}
                      deleteSessions={deleteSessions}
                      //objects responses
                      updateSessionResponse={updateSessionResponse}
                      newSessionsResponse={newSessionsResponse}
                      deleteSessionsResponse={deleteSessionsResponse}
                      //loadings
                      isLoadingSessionsPost={
                        isLoadingNewSessions || isLoadingSessionUpdate
                      }
                      isLoadingDeleteSessions={isLoadingDeleteSessions}
                      //viewId
                      viewId={viewId}
                      //boolean
                      useLocalTime={useLocalTime}
                    />
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <NotAllowedView />
          )}
        </>
      )}
    </Grid>
  );
}

export default Scheduler;
