import { connect } from 'react-redux';
import Parameters from '../../../components/fleetManagement/Parameters';
import { getChains, getRobots } from 'core/slices/backend';
import { getRobotsBaldur } from 'core/slices/fleetControl';
import { getAllVersionsRobot } from 'core/slices/fleetControl';
import {
  getParameters,
  getRobotParametersValues,
  getRobotParametersByVersion,
  postParameterUpdates,
} from 'core/slices/parameters';
import {
  getChainsList,
  getRobotsList,
  getParametersList,
  getParametersValuesList,
  getRobotParametersByVersionList,
  getRobotListBaldur,
  getVersionsRobot,
  getParametersUpdateResponse,
  isLoadingChains,
  isLoadingRobots,
  isLoadingParameters,
  isLoadingParametersValues,
  isLoadingVersionsRobot,
  isLoadingRobotListBaldur,
  isLoadingParametersUpdate,
  navigationOptions,
} from 'core/selectors';

export default connect(
  (state) => ({
    chainsList: getChainsList(state),
    parameters: getParametersList(state),
    parametersValuesList: getParametersValuesList(state),
    robotsList: getRobotsList(state),
    robotsListBaldur: getRobotListBaldur(state),
    robotVersions: getVersionsRobot(state),
    parametersRobotByVersion: getRobotParametersByVersionList(state),
    paramsUpdateResponse: getParametersUpdateResponse(state),
    isLoadingChains: isLoadingChains(state),
    isLoadingRobots: isLoadingRobots(state),
    isLoadingParameters: isLoadingParameters(state),
    isLoadingRobotVersions: isLoadingVersionsRobot(state),
    isLoadingParametersValues: isLoadingParametersValues(state),
    isLoadingVersionsRobot: isLoadingVersionsRobot(state),
    isLoadingRobotListBaldur: isLoadingRobotListBaldur(state),
    isLoadingParametersUpdate: isLoadingParametersUpdate(state),
    navOptions: navigationOptions(state),
  }),
  {
    getChains,
    getRobots,
    getParameters,
    getRobotParametersValues,
    getAllVersionsRobot,
    getRobotsBaldur,
    getRobotParametersByVersion,
    postParameterUpdates,
  }
)(Parameters);
