import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import Search from './redux/containers/search/Search';
import PipelineMonitoring from './redux/containers/pipelineMonitoring/PipelineMonitoring';
import SessionView from './redux/containers/pipelineMonitoring/SessionView';
import Parameters from './redux/containers/fleetManagement/Parameters';
import Scheduler from './redux/containers/fleetManagement/Scheduler';
import RobotView from './redux/containers/robotView/RobotView';
import Layout from './components/navbar/Layout';
import AlertsTabs from './redux/containers/alerts/AlertsTabs';

// instantiate services and wire up dependencies
import createStore from 'core/store';

import HTTPService from 'core/services/http';
import DataSource from 'core/services/dataSource';
import AuthSource from 'core/services/authSource';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useTranslation, withTranslation } from 'react-i18next';

// zippedi tools js imports
import { ReduxLoginWeb as PrivateRoute } from '@zippeditoolsjs/web';
import { lightTheme, darkTheme } from '@zippeditoolsjs/web';

import SnackAlert from './redux/containers/tools/SnackAlert';
import { RobotViewProvider } from './context/RobotView';

import theme from './styleSheet';
import { DashboardViewProvider } from './context/DashboardView';
import DashboardView from './redux/containers/home/DashboardView';
import { SchedulerViewProvider } from './context/SchedulerView';

const {
  REACT_APP_API_BASE_URL,
  REACT_APP_AUTH_BASE_URL,
  REACT_APP_BASE_URL,
  REACT_APP_AUTH_BACKEND_URL,
} = process.env;

const http = new HTTPService();

const dataSource = new DataSource({
  baseUrl: REACT_APP_API_BASE_URL,
  http,
});

const authSource = new AuthSource({
  baseUrl: REACT_APP_AUTH_BACKEND_URL,
  http,
});

const store = createStore({
  authSource,
  dataSource,
});

const App = () => {
  const [themeName, setThemeName] = useState('light');
  const isDarkTheme = themeName === 'dark';
  const toggleTheme = () => setThemeName(isDarkTheme ? 'light' : 'dark');

  const { i18n } = useTranslation();
  const toggleTranslation = (lang) => i18n.changeLanguage(lang);

  useEffect(() => {
    // TODO: remove this line when we the option to change language is available
    i18n.changeLanguage('en');
  }, []);

  return (
    <Provider store={store}>
      {/* <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}> */}
      <ThemeProvider theme={theme}>
        <SnackAlert />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <PrivateRoute
            appUrlLogin={REACT_APP_AUTH_BASE_URL}
            tenattype={'int'}
            appBaseUrl={REACT_APP_BASE_URL}
          >
            <Router>
              <Layout
                isDarkTheme={isDarkTheme}
                toggleTheme={toggleTheme}
                toggleTranslation={toggleTranslation}
              >
                <Routes>
                  <Route exact path="/" element={<PipelineMonitoring />} />
                  <Route exact path="/search" element={<Search />} />
                  <Route
                    exact
                    path="/pipeline_processing"
                    element={<PipelineMonitoring />}
                  />
                  <Route
                    exact
                    path="/pipeline_processing/:session/:date"
                    element={<SessionView />}
                  />
                  <Route exact path="/parameters" element={<Parameters />} />
                  <Route exact path="/scheduler" element={
                    <SchedulerViewProvider>
                      <Scheduler />
                    </SchedulerViewProvider>
                  }
                  />
                  <Route
                    exact
                    path="/robot/:store"
                    element={
                      <RobotViewProvider>
                        <RobotView />
                      </RobotViewProvider>
                    }
                  />
                  <Route
                    exact
                    path="/robot/:store/:robot"
                    element={
                      <RobotViewProvider>
                        <RobotView />
                      </RobotViewProvider>
                    }
                  />
                  <Route exact path="/alerts" element={<AlertsTabs />}></Route>
                  <Route
                    exact
                    path="/dashboard"
                    element={
                      <DashboardViewProvider>
                        <DashboardView />
                      </DashboardViewProvider>
                    }
                  ></Route>
                </Routes>
              </Layout>
            </Router>
          </PrivateRoute>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default withTranslation()(App);
