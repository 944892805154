import { CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react'

export default function CircularLoader(props) {
    const { message = null, size = '4em', color = 'secondary', messageColor = 'inherit' } = props;

    return (
        <Grid item xs={3} sm={3} sx={{ textAlign: 'center' }}>
            <CircularProgress size={size} color={color} />
            <Typography variant='h6' color={messageColor}>{message}</Typography>
        </Grid>
    );
}
