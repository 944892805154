import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Grid,
  LinearProgress,
  Skeleton,
  TextField,
} from '@mui/material';

export default function MonitorMetricSelector(props) {
  const {
    isLoadingMetrics,
    isLoadingChains,
    timeRangeList,
    isLoadingTimeRange,
    monitorsData,
    size,
    selectedTimeRange,
    selectedMetric,
    setSelectedMonitor,
    setSelectedTimeRange,
    setSelectedMetric,
    getMonitorMetrics,
    onlyPersonalMetrics,
  } = props;

  const [metricsOptions, setMetricsOptions] = useState([]);
  const [monitorsOptions, setMonitorsOptions] = useState([]);

  useEffect(() => {
    if (monitorsData?.all_monitors) {
      setMonitorsOptions(
        monitorsData.all_monitors.map((item) => {
          return { label: item.username, user_id: item.user_id };
        })
      );
    }
    if (monitorsData) {
      setMetricsOptions(
        Object.keys(monitorsData)
          .filter((item) => item !== 'all_monitors')
          .map((item) => {
            return {
              label: item
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
              name: item,
            };
          })
      );
    }
  }, [monitorsData]);

  useEffect(() => {
    if (metricsOptions.length && !selectedMetric) {
      setSelectedMetric(metricsOptions[0].name);
    }
  }, [metricsOptions]);

  const handleTimeRangeChange = (event, value) => {
    const selectedValue = value ? value.value : null;
    setSelectedTimeRange(selectedValue);
    getMonitorMetrics(selectedValue, onlyPersonalMetrics);
  };

  const handleMetricChange = (event, value) => {
    const selectedValue = value ? value.name : null;
    setSelectedMetric(selectedValue);
  };

  return (
    <Grid container item xs={size} spacing={2} paddingX={2}>
      {metricsOptions.length > 0 ? (
        <>
          <Grid item xs={4}>
            <Autocomplete
              fullWidth
              defaultValue={
                selectedTimeRange
                  ? timeRangeList.find(
                      (option) => option.value === selectedTimeRange
                    )
                  : null
              }
              options={timeRangeList}
              getOptionLabel={(option) => option?.name}
              onChange={handleTimeRangeChange}
              renderInput={(params) => (
                <TextField {...params} label="Time range" />
              )}
            />
            {isLoadingTimeRange && (
              <LinearProgress
                sx={{ width: '100%', mx: 'auto', top: '-5px' }}
                color="secondary"
              />
            )}
          </Grid>
          {metricsOptions?.length > 0 && (
            <Grid item xs={4}>
              <Autocomplete
                fullWidth
                defaultValue={
                  selectedMetric
                    ? metricsOptions.find(
                        (option) => option.name === selectedMetric
                      )
                    : metricsOptions[0]
                }
                options={metricsOptions}
                getOptionLabel={(option) => option?.label}
                onChange={handleMetricChange}
                renderInput={(params) => (
                  <TextField {...params} label="Metrics" />
                )}
              />
              {isLoadingMetrics && (
                <LinearProgress
                  sx={{ width: '100%', mx: 'auto', top: '-5px' }}
                  color="secondary"
                />
              )}
            </Grid>
          )}
          {monitorsOptions?.length > 0 && (
            <Grid item xs={4}>
              <Autocomplete
                fullWidth
                options={monitorsOptions}
                onChange={(e, value) => setSelectedMonitor(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Monitor" />
                )}
              />
              {isLoadingChains && (
                <LinearProgress
                  sx={{ width: '100%', mx: 'auto', top: '-5px' }}
                  color="secondary"
                />
              )}
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={40} width="100%"></Skeleton>
        </Grid>
      )}
    </Grid>
  );
}
