import { IconButton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useEffect, useState } from 'react';

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

const minDistance = 10;

export default function TimeSlider(props) {
  const {
    marks = [],
    sx = {},
    disabled = false,
    disableButtons = false,
    size = "small",
    maxValue = 100,
    minValue = 0,
    valueLabelDisplay = "off",
    valueText = (value) => value,
    initialValue = [0, 100],
    sizeButton = "medium",
    onchange = () => { },
    onclick = (isForward) => { },

    leftButtonTooltip = {
      title: 'Before'
    },
    rightButtonTooltip = {
      title: 'After',
    }
  } = props;

  const [value, setValue] = useState(initialValue);

  useEffect(() => { }, [marks]);

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      const newArrayValue = [Math.min(newValue[0], value[1] - minDistance), value[1]];
      setValue(newArrayValue);
      onchange(newArrayValue);
    } else {
      const newArrayValue = [value[0], Math.max(newValue[1], value[0] + minDistance)];
      setValue(newArrayValue);
      onchange(newArrayValue);
    }

  };

  const handleClick = (isForward) => () => {
    onclick(isForward);
  }

  return (
    <Box
      sx={{
        gap: 1,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        ...sx
      }}
    >
      <Tooltip placement='top' {...leftButtonTooltip}>
        <IconButton aria-label='before' size={sizeButton} disabled={disableButtons} sx={{ mr: 2 }} onClick={handleClick(false)}>
          <ArrowBackIosRoundedIcon />
        </IconButton>
      </Tooltip>

      <Slider
        disableSwap
        size={size}
        marks={marks}
        value={value}
        max={maxValue}
        min={minValue}
        disabled={disabled}
        onChange={handleChange}
        getAriaValueText={valueText}
        onClose
        valueLabelDisplay={valueLabelDisplay}
        getAriaLabel={() => 'Minimum distance'}
      />

      <Tooltip placement='top' {...rightButtonTooltip}>
        <IconButton aria-label='after' size={sizeButton} disabled={disableButtons} sx={{ ml: 2 }} onClick={handleClick(true)}>
          <ArrowForwardIosRoundedIcon />
        </IconButton>
      </Tooltip>

    </Box>
  );
}