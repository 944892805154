import React, { useEffect, useState } from "react";

import Policy from "../../../../redux/containers/pipelineMonitoring/Policy";

import {
  Typography,
  Box,
  Modal,
  CircularProgress,
  TextField,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Divider,
} from "@mui/material";
import LineGraph from "../LineGraph";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { checkViewOnlyPermision } from "../../Utils";

function Revisions(props) {
  const {
    data,
    loadingData,
    setSelectedGraph,
    title,
    selectedRevision,
    graphType,
    addAlertPolicy,
    metric,
    setNewPolicy,
    navOptions,
  } = props;

  const [policies, setPolicies] = useState([]);
  const [name, setName] = useState("");
  const [newThreshold, setNewThreshold] = useState(1);

  useEffect(() => {
    setPolicies(data.policies);
  }, [data.policies]);

  const addPolicy = async () => {
    const response = await addAlertPolicy({
      project_id: metric.project_id,
      policy_data: {
        display_name: name,
        resource_type: metric.resource_type,
        metric_type: metric.metric_type,
        resource_label_key: metric.resource_label_key,
        resource_label_value: metric.resource_label_value,
        threshold_value:
          graphType === "percentage" ? newThreshold / 100 : newThreshold,
        alert_duration_time: "60s",
        notification_channel_display_name:
          process.env.REACT_APP_MBA_NOTIFICATION_CHANNEL_DISPLAY_NAME,
      },
    });
    if (response.display_name) {
      setNewPolicy(response);
    }
    setName("");
    setNewThreshold(1);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#e9e9f1",
        padding: 1,
        borderRadius: 1,
      }}
    >
      <Typography sx={{ fontWeight: "bold", marginY: 1 }}>{title}</Typography>
      <Box
        sx={{ width: "450px", height: "300px" }}
        onClick={() =>
          setSelectedGraph({
            title: title,
            data: data.metrics,
            type: graphType,
          })
        }
      >
        {loadingData ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 1,
              paddingY: 2,
            }}
          >
            <CircularProgress />
            <Typography
              sx={{ color: "#29373f", fontSize: 18, fontWidth: "bold" }}
            >
              Loading metrics...
            </Typography>
          </Box>
        ) : (
          <>
            <LineGraph
              data={
                selectedRevision !== "Vertex AI" &&
                selectedRevision !== "Cloud Tasks" &&
                selectedRevision !== "Root"
                  ? Array.isArray(data.metrics)
                    ? data.metrics.filter(
                        (line) => line.revision_name === selectedRevision
                      )
                    : []
                  : Array.isArray(data.metrics)
                  ? data.metrics
                  : []
              }
              size="sm"
              graphType={graphType}
              policies={policies}
            />
          </>
        )}
      </Box>

      {loadingData ? null : (
        <Box sx={{ border: "1px solid #29373f", padding: 1, borderRadius: 2 }}>
          <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
            Alert Policies
          </Typography>

          <Box sx={{ marginLeft: 2 }}>
            {data.metrics && policies?.length > 0 ? (
              policies?.map((policy, idx) => {
                return (
                  <Box key={idx}>
                    <Policy
                      policy={policy}
                      metric={metric}
                      setNewPolicy={setNewPolicy}
                      graphType={graphType}
                      navOptions={navOptions}
                    />
                    <Divider />
                  </Box>
                );
              })
            ) : (
              <Typography sx={{ fontSize: "14px" }}>
                No alert policies configurated
              </Typography>
            )}

            <TextField
              sx={{ marginTop: 3, marginBottom: 1, height: "60px" }}
              type="text"
              fullWidth
              disabled={checkViewOnlyPermision(navOptions, "Pipeline")}
              variant="standard"
              helperText={name.includes(" ") ? "spaces not allowed" : null}
              placeholder="New Policy name"
              error={name.includes(" ")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              InputProps={{
                style: { paddingBottom: 10 },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ display: "flex", alignItems: "center", gap: 2 }}
                  >
                    <TextField
                      size="small"
                      variant="standard"
                      sx={{ width: "60px" }}
                      disabled={checkViewOnlyPermision(navOptions, "Pipeline")}
                      value={newThreshold}
                      onChange={(e) =>
                        setNewThreshold(parseInt(e.target.value))
                      }
                      InputProps={{
                        endAdornment:
                          graphType === "percentage" ? (
                            <InputAdornment position="end">%</InputAdornment>
                          ) : null,
                      }}
                      inputProps={{
                        step: 1,
                        min: 1,
                        max: graphType === "percentage" ? 100 : 10000,
                        type: "number",
                      }}
                    />
                    <IconButton
                      disabled={
                        name === "" ||
                        newThreshold < 1 ||
                        name.includes(" ") ||
                        checkViewOnlyPermision(navOptions, "Pipeline")
                      }
                      onClick={addPolicy}
                      color="secondary"
                    >
                      {<AddCircleIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Revisions;
