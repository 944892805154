import {
  getParametersValuesList,
  getVersionsRobot,
  isLoadingParametersValues,
  isLoadingVersionsRobot,
} from 'core/selectors';
import { getAllVersionsRobot } from 'core/slices/fleetControl';
import { getRobotParametersByVersion } from 'core/slices/parameters';
import { connect } from 'react-redux';
import RobotParametersWidget from '../../../components/widgets/RobotParametersWidget';

export default connect(
  (state) => ({
    robotVersions: getVersionsRobot(state),
    parametersValuesList: getParametersValuesList(state),
    isLoadingRobotVersions: isLoadingVersionsRobot(state),
    isLoadingParametersValues: isLoadingParametersValues(state),
  }),
  {
    getAllVersionsRobot,
    getRobotParametersByVersion,
  }
)(RobotParametersWidget);
