import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ExtendTimeModal(props) {
  const { open, setOpen, robotCode, takeRobot } = props;
  const [minutes, setMinutes] = useState(null);

  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  const handleClick = () => {
    takeRobot(robotCode, minutes, null);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('overseer_app.widget.extend_control', 'Extend control over robot')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            'overseer_app.widget.control_extend',
            'Enter the number of minutes to have control of the robot.'
          )}
        </DialogContentText>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={1}
          mt={2}
        >
          <Grid item xs={6}>
            <TextField
              id="outlined-number"
              label="Minutes"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={minutes}
              onChange={(event) => setMinutes(event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={minutes === null}
              variant="contained"
              fullWidth
              onClick={handleClick}
            >
              {t('overseer_app.widget.extend', 'Extend')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
