import { Tooltip, Typography, IconButton, CircularProgress } from "@mui/material";
import React from "react";
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';

const defaultColors = ["#007f5f", "#2b9348", "#55a630", "#80b918", "#aacc00", "#bfd200", "#d4d700", "#dddf00", "#eeef20", "#ffff3f"];

const MultiProgressBar = ({ colors = defaultColors, progresses, progressNames, height, reportBlobPath, handleGoReport, navigationReportSentTimestamp, isLoadingSignedBlob = false, maxValue = 100 }) => {

    const Parentdiv = {
        height: height,
        width: '100%',
        backgroundColor: 'whitesmoke',
        display: 'flex',
        alignItems: 'horizontal',
        border: '1px solid black',
        margin: "5px 0px"
    };

    const Childdiv = {
        height: '100%',
        textAlign: 'center'
    };

    const getColor = (index) => {
        const colorIndex = Math.floor(index * colors.length / progresses.length);
        return colors[colorIndex]
    };

    const getProgressWidth = (index) => {
        if (index === progresses.length - 1) {
            return Math.min(progresses[index], maxValue) * 100 / maxValue;
        }
        const value1 = Math.min(progresses[index], maxValue)
        const value2 = Math.min(progresses[index + 1], maxValue)
        return (value1 - value2) * 100 / maxValue;

    }

    return (
        <>
            <Tooltip title={
                <div style={{ textAlign: 'right' }}>
                    <Typography>Planned Aisles: {maxValue}</Typography>
                    {progressNames.map((progressName, index) => {
                        return (
                            <Typography key={`${progressName}-tooltip`}>{progressName}: {progresses[index]}</Typography>)
                    })}
                </div>
            }>

                <div style={Parentdiv}>
                    {progresses.slice().reverse().map((progress, index) => { //slice so the array doesn't mutate
                        return (
                            <div key={index} style={{ ...Childdiv, width: `${getProgressWidth(progresses.length - 1 - index)}%`, backgroundColor: getColor(index) }} />
                        )
                    })}
                </div>
            </Tooltip>
            {!isLoadingSignedBlob && navigationReportSentTimestamp && navigationReportSentTimestamp !== '-' ?

                <Tooltip title={<Typography>Navigation Report Sent: {navigationReportSentTimestamp}</Typography>}>
                    <IconButton sx={{ pr: '4px', pt: '4px', pb: '0px', pl: '4px', maxHeight: '32px', mt: '2px' }} onClick={handleGoReport}>
                        <Typography sx={{ alignSelf: 'center', color: 'rgba(0, 0, 0, 0.54)' }}>
                            <MarkEmailReadOutlinedIcon />
                        </Typography>
                    </IconButton>
                </Tooltip>
                :
                isLoadingSignedBlob && navigationReportSentTimestamp ?
                    <Typography sx={{ alignSelf: 'center', color: 'rgba(0, 0, 0, 0.54)' }}>
                        <CircularProgress sx={{ color: '#7c909b', ml: 1 }} size={20} />
                    </Typography>
                    :
                    null
            }
        </>

    )
}

export default MultiProgressBar;
