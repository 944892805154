import React from "react";
import { Grid, Typography } from "@mui/material";
import MultiProgressBar from "./MultiProgressBar";


export function CellDrawer(props) {

    const { progressValues, processNames, maxValue } = props

    return (
            progressValues.map((progressValue, index) => {
                return (
                    <div key={`${processNames[index]}-drawer`}>
                        <Grid container direction={'row'} spacing={2} justifyContent={'flex-start'} alignItems={'center'}>
                            <Grid item xs={2}><Typography textAlign={'right'}>{processNames[index]}</Typography></Grid>
                            <Grid item xs={9}><MultiProgressBar
                                height={10}
                                maxValue={maxValue}
                                progresses={[progressValue]}
                                progressNames={[processNames[index]]} /></Grid>
                            <Grid item xs={1}><Typography>{progressValue}/{maxValue}</Typography></Grid>
                        </Grid>
                    </div>
                )
            })
    )
}