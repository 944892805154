import { createReducer } from './utils';

import {
  GET_SERVICE_METADATA,
  GET_SERVICE_METADATA_FAILURE,
  GET_SERVICE_METADATA_SUCCESS,
  UPDATE_SERVICE_METADATA,
  UPDATE_SERVICE_METADATA_SUCCESS,
  UPDATE_SERVICE_METADATA_FAILURE,
  UPDATE_SERVICE_TRAFFIC,
  UPDATE_SERVICE_TRAFFIC_SUCCESS,
  UPDATE_SERVICE_TRAFFIC_FAILURE,
  GET_TASKS_METADATA,
  GET_TASKS_METADATA_SUCCESS,
  GET_TASKS_METADATA_FAILURE,
} from './constants';

const defaultState = {
  loadingServiceMetadata: false,
  serviceMetadata: {},
  serviceMetadataErrorMessage: null,

  loadingTasksMetadata: false,
  tasksMetadata: {},
  tasksMetadataErrorMessage: null,
};

export const reducer = createReducer(defaultState, {
  [GET_SERVICE_METADATA]: handleServiceMetadata,
  [GET_SERVICE_METADATA_SUCCESS]: handleServiceMetadataSuccess,
  [GET_SERVICE_METADATA_FAILURE]: handleServiceMetadataFailure,

  [UPDATE_SERVICE_METADATA]: handleServiceMetadata,
  [UPDATE_SERVICE_METADATA_SUCCESS]: handleServiceMetadataSuccess,
  [UPDATE_SERVICE_METADATA_FAILURE]: handleServiceMetadataFailure,

  [UPDATE_SERVICE_TRAFFIC]: handleServiceMetadata,
  [UPDATE_SERVICE_TRAFFIC_SUCCESS]: handleServiceMetadataSuccess,
  [UPDATE_SERVICE_TRAFFIC_FAILURE]: handleServiceMetadataFailure,

  [GET_TASKS_METADATA]: handleTasksMetadata,
  [GET_TASKS_METADATA_SUCCESS]: handleTasksMetadataSuccess,
  [GET_TASKS_METADATA_FAILURE]: handleTasksMetadataFailure,
});

function handleServiceMetadataSuccess(state, { payload: { serviceMetadata } }) {
  return {
    ...state,
    serviceMetadata,
    loadingServiceMetadata: false,
    serviceMetadataErrorMessage: null,
  };
}

function handleServiceMetadataFailure(state, { payload: { error } }) {
  return {
    ...state,
    serviceMetadata: null,
    loadingServiceMetadata: false,
    serviceMetadataErrorMessage: error,
  };
}

function handleServiceMetadata(state) {
  return {
    ...state,
    loadingServiceMetadata: true,
    serviceMetadataErrorMessage: null,
  };
}

function handleTasksMetadataSuccess(state, { payload: { tasksMetadata } }) {
  return {
    ...state,
    tasksMetadata,
    loadingTasksMetadata: false,
    tasksMetadataErrorMessage: null,
  };
}

function handleTasksMetadataFailure(state, { payload: { error } }) {
  return {
    ...state,
    tasksMetadata: {},
    loadingTasksMetadata: false,
    tasksMetadataErrorMessage: error,
  };
}

function handleTasksMetadata(state) {
  return {
    ...state,
    loadingTasksMetadata: true,
    tasksMetadataErrorMessage: null,
  };
}

export function getServiceMetadata({ service_name, project_id }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_SERVICE_METADATA });
    try {
      const serviceMetadata = await dataSource.getServiceMetadata(
        service_name,
        project_id,
      );

      dispatch({
        type: GET_SERVICE_METADATA_SUCCESS,
        payload: { serviceMetadata },
      });
      return serviceMetadata;
    } catch (error) {
      dispatch({
        type: GET_SERVICE_METADATA_FAILURE,
        payload: { error },
      });
    }
  };
}

export function updateServiceMetadata({
  service_name,
  project_id,
  changes,
  revision_id,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: UPDATE_SERVICE_METADATA });
    try {
      const serviceMetadata = await dataSource.postServiceMetadata(
        service_name,
        project_id,
        changes,
        revision_id,
      );
      dispatch({
        type: UPDATE_SERVICE_METADATA_SUCCESS,
        payload: { serviceMetadata },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SERVICE_METADATA_FAILURE,
        payload: { error },
      });
    }
  };
}

export function updateServiceTraffic({ service_name, project_id, traffic }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: UPDATE_SERVICE_TRAFFIC });
    try {
      const serviceMetadata = await dataSource.updateServiceTraffic(
        service_name,
        project_id,
        traffic,
      );

      dispatch({
        type: UPDATE_SERVICE_TRAFFIC_SUCCESS,
        payload: { serviceMetadata },
      });
      return serviceMetadata;
    } catch (error) {
      dispatch({
        type: UPDATE_SERVICE_TRAFFIC_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getVertexMetadata({ process_id, chain }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_SERVICE_METADATA });
    try {
      const serviceMetadata = await dataSource.getVertexMetadata(
        process_id,
        chain,
      );

      dispatch({
        type: GET_SERVICE_METADATA_SUCCESS,
        payload: { serviceMetadata },
      });
      return serviceMetadata;
    } catch (error) {
      dispatch({
        type: GET_SERVICE_METADATA_FAILURE,
        payload: { error },
      });
    }
  };
}

export function updateVertexMetadata({ process_id, chain, model_id, changes }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: UPDATE_SERVICE_METADATA });
    try {
      const serviceMetadata = await dataSource.updateVertexMetadata(
        process_id,
        chain,
        model_id,
        changes,
      );
      dispatch({
        type: UPDATE_SERVICE_METADATA_SUCCESS,
        payload: { serviceMetadata },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SERVICE_METADATA_FAILURE,
        payload: { error },
      });
    }
  };
}

export function updateVertexTraffic({ process_id, chain, traffic }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: UPDATE_SERVICE_TRAFFIC });
    try {
      const serviceMetadata = await dataSource.updateVertexTraffic(
        process_id,
        chain,
        traffic,
      );

      dispatch({
        type: UPDATE_SERVICE_TRAFFIC_SUCCESS,
        payload: { serviceMetadata },
      });
      return serviceMetadata;
    } catch (error) {
      dispatch({
        type: UPDATE_SERVICE_TRAFFIC_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getTasksMetadata({ service_name, project_id, task_name }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_TASKS_METADATA });
    try {
      const tasksMetadata = await dataSource.getTasksMetadata(
        service_name,
        project_id,
        task_name,
      );

      dispatch({
        type: GET_TASKS_METADATA_SUCCESS,
        payload: { tasksMetadata },
      });
      return tasksMetadata;
    } catch (error) {
      dispatch({
        type: GET_TASKS_METADATA_FAILURE,
        payload: { error },
      });
    }
  };
}

export function updateTasksMetadata({
  service_name,
  project_id,
  task_name,
  changes,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_TASKS_METADATA });
    try {
      const serviceMetadata = await dataSource.updateTasksMetadata(
        service_name,
        project_id,
        task_name,
        changes,
      );
      dispatch({
        type: GET_TASKS_METADATA_SUCCESS,
        payload: { serviceMetadata },
      });
      return serviceMetadata;
    } catch (error) {
      dispatch({
        type: GET_TASKS_METADATA_FAILURE,
        payload: { error },
      });
    }
  };
}
