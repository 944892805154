import { createReducer } from './utils';

const GET_SERVICE_STATISTICS = 'GET_SERVICE_STATISTICS';
const GET_SERVICE_STATISTICS_SUCCESS = 'GET_SERVICE_STATISTICS_SUCCESS';
const GET_SERVICE_STATISTICS_FAILURE = 'GET_SERVICE_STATISTICS_FAILURE';

const GET_SERVICE_LOGS = 'GET_SERVICE_LOGS';
const GET_SERVICE_LOGS_FAILURE = 'GET_SERVICE_LOGS_FAILURE';
const GET_SERVICE_LOGS_SUCCESS = 'GET_SERVICE_LOGS_SUCCESS';

const GET_ALERTS = 'GET_ALERTS';
const GET_ALERTS_FAILURE = 'GET_ALERTS_FAILURE';
const GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS';

const defaultState = {
  loadingServiceStatus: false,
  serviceStatusErrorMessage: null,

  loadingServiceStatistics: false,
  serviceStatistics: {},
  serviceStatisticsErrorMessage: null,

  loadingServiceLogs: false,
  serviceLogs: {},
  serviceLogsErrorMessage: null,

  loadingAlerts: false,
  alerts: null,
  alertsErrorMessage: null,
};

export const reducer = createReducer(defaultState, {
  [GET_SERVICE_STATISTICS]: handleServiceStatistics,
  [GET_SERVICE_STATISTICS_FAILURE]: handleServiceStatisticsFailure,
  [GET_SERVICE_STATISTICS_SUCCESS]: handleServiceStatisticsSuccess,

  [GET_SERVICE_LOGS]: handleServiceLogs,
  [GET_SERVICE_LOGS_SUCCESS]: handleServiceLogsSuccess,
  [GET_SERVICE_LOGS_FAILURE]: handleServiceLogsFailure,

  [GET_ALERTS]: handleGetAlerts,
  [GET_ALERTS_SUCCESS]: handleGetAlertsSuccess,
  [GET_ALERTS_FAILURE]: handleGetAlertsFailure,
});

function handleServiceStatisticsSuccess(
  state,
  { payload: { serviceStatistics } }
) {
  return {
    ...state,
    serviceStatistics,
    loadingServiceStatistics: false,
    serviceStatisticsErrorMessage: null,
  };
}

function handleServiceStatisticsFailure(state, { payload: { error } }) {
  return {
    ...state,
    serviceStatistics,
    serviceStatisticsErrorMessage: error,
  };
}

function handleServiceStatistics(state) {
  return {
    ...state,
    loadingServiceStatistics: true,
    serviceStatisticsErrorMessage: null,
  };
}

function handleServiceLogs(state) {
  return {
    ...state,
    loadingServiceLogs: true,
    serviceLogsErrorMessage: null,
  };
}
function handleServiceLogsSuccess(state, { payload: { serviceLogs } }) {
  return {
    ...state,
    serviceLogs,
    loadingServiceLogs: false,
    serviceLogsErrorMessage: null,
  };
}

function handleServiceLogsFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingServiceLogs: false,
    serviceLogsErrorMessage: error,
  };
}

export function getServiceLogs(project_id, service_name, quantity) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_SERVICE_LOGS });
    try {
      const serviceLogs = await dataSource.getServiceLogs({
        project_id,
        service_name,
        quantity,
      });
      dispatch({
        type: GET_SERVICE_LOGS_SUCCESS,
        payload: { serviceLogs },
      });
    } catch (error) {
      dispatch({
        type: GET_SERVICE_LOGS_FAILURE,
        payload: { error },
      });
    }
  };
}

function handleGetAlerts(state) {
  return {
    ...state,
    loadingAlerts: true,
    alertsErrorMessage: null,
  };
}
function handleGetAlertsSuccess(state, { payload: { alerts } }) {
  return {
    ...state,
    alerts,
    loadingAlerts: false,
    alertsErrorMessage: null,
  };
}

function handleGetAlertsFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingAlerts: false,
    alertsErrorMessage: error,
  };
}

export function getAlerts({ store, robot, metric_type }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_ALERTS });
    try {
      const alerts = await dataSource.getAlerts({ store, robot, metric_type });
      dispatch({
        type: GET_ALERTS_SUCCESS,
        payload: { alerts },
      });
    } catch (error) {
      dispatch({
        type: GET_ALERTS_FAILURE,
        payload: { error },
      });
    }
  };
}
