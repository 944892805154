import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Box, Grid } from '@mui/material';
import { connectHost, topicSubscribe, onMessage, disconnectHost } from '../../mqtt/mqttFunctions';

// Componenst
import CreateGroup from '../../redux/containers/alerts/group/CreateGroup';
import Management from '../../redux/containers/alerts/management/Management';
import AlertCreation from './AlertCreation';
import RobotStatusTransferList from '../../redux/containers/alerts/robotConfiguration/RobotStatusTransferList';

const { REACT_APP_MQTT_USER, REACT_APP_MQTT_PASSWORD, REACT_APP_MQTT_HOST } = process.env;

export default function AlertsTabs(props) {
    const { getGroupsData,
        getAllCreatedAlerts,
        getAvailableAlerts,
        getGroupAlertsRelation,
        alertsCreated,
        severities,
        getSeverities,
        getCLIFilters,
        CLIData,
        isLoadingCLIData,
        isLoadingAlertsCreated,
        getChartData,
        dataChart,
        isLoadingChartData,
        CMIData,
        isLoadingCMIData,
        getCMIpolicies
    } = props

    const topicResponse = 'info/+/+/robot_status';
    const mqttOptions = { username: REACT_APP_MQTT_USER, password: REACT_APP_MQTT_PASSWORD };
    const host = REACT_APP_MQTT_HOST;

    const [selectedButton, setSelectedButton] = useState('modify-alert');
    const [client, setClient] = useState(null);
    const [alertRegistry, setAlertRegistry] = useState(null);
    const [subscribeStatus, setSubscribeStatus] = useState(false);
    const [JSONRobotStatus, setJSONRobotStatus] = useState({});
    const [severitiesList, setSeveritiesList] = useState(null);


    useEffect(() => {
        // connect to broker
        let mqttClient = connectHost(host, mqttOptions);
        setClient(mqttClient);
    }, [selectedButton])

    useEffect(() => {
        if (client) {
            topicSubscribe(client, topicResponse, 2, setSubscribeStatus);
        }
    }, [client])

    useEffect(() => {
        if (subscribeStatus) {
            onMessage(client, msgHandler)
        }
    }, [subscribeStatus])

    useEffect(() => {
        if (alertRegistry === null) {
            getAllCreatedAlerts();
            setAlertRegistry(alertsCreated);
        }
    }, [alertsCreated])

    useEffect(() => {
        if (severitiesList === null) {
            getSeverities()
            setSeveritiesList(severities)
        }
    }, [severities])

    const msgHandler = (msg) => {
        let data = JSON.parse(msg['msg']);
        setJSONRobotStatus(data);
        if (JSONRobotStatus) {
            disconnectHost(client)
        }
    }

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
    };


    useEffect(() => {
        getAllCreatedAlerts();
        getGroupsData();
        getAvailableAlerts();
        getGroupAlertsRelation();
    }, []);

    return (
        <Grid sx={{ p: 3 }}>
            <ButtonGroup color="primary" variant="contained" fullWidth>
                <Button
                    onClick={() => handleButtonClick('modify-alert')}
                    style={{
                        backgroundColor:
                            selectedButton === 'modify-alert' ? '#1a242d' : 'white',
                        color:
                            selectedButton === 'modify-alert' ? '#FFF' : '#000',
                        border: "none",
                    }}
                >
                    Create Alert
                </Button>
                <Button
                    onClick={() => handleButtonClick('create-group')}
                    style={{
                        backgroundColor:
                            selectedButton === 'create-group' ? '#1a242d' : 'white',
                        color:
                            selectedButton === 'create-group' ? '#FFF' : '#000',
                        border: "none",
                    }}
                >
                    Create Group
                </Button>
                <Button
                    onClick={() => handleButtonClick('manage-group')}
                    style={{
                        backgroundColor:
                            selectedButton === 'manage-group' ? '#1a242d' : 'white',
                        color:
                            selectedButton === 'manage-group' ? '#FFF' : '#000',
                        border: "none",
                    }}
                >
                    Link Group with Alert
                </Button>
                <Button
                    onClick={() => handleButtonClick('robot-config')}
                    style={{
                        backgroundColor:
                            selectedButton === 'robot-config' ? '#1a242d' : 'white',
                        color:
                            selectedButton === 'robot-config' ? '#FFF' : '#000',
                        border: "none",
                    }}
                >
                    Robot Configuration
                </Button>
            </ButtonGroup>


            {selectedButton && (
                <Box mt={2}>
                    {selectedButton === 'modify-alert' && (
                        <div>
                            {/* Create Alert Component */}
                            <AlertCreation
                                JSONRobotStatus={JSONRobotStatus}
                                alertsCreated={alertsCreated}
                                getCLIFilters={getCLIFilters}
                                isLoadingAlertsCreated={isLoadingAlertsCreated}
                                CLIData={CLIData}
                                isLoadingCLIData={isLoadingCLIData}
                                getChartData={getChartData}
                                dataChart={dataChart}
                                isLoadingChartData={isLoadingChartData}
                                CMIData={CMIData}
                                isLoadingCMIData={isLoadingCMIData}
                                getCMIpolicies={getCMIpolicies}
                            />
                        </div>
                    )}

                    {selectedButton === 'create-group' && (
                        <div>
                            {/* Create Group Component */}
                            <CreateGroup />
                        </div>
                    )}

                    {selectedButton === 'manage-group' && (
                        <div>
                            {/* Link Group with Alert Component */}
                            <Management
                                getAvailableAlerts={getAvailableAlerts}
                                getGroupAlertsRelation={getGroupAlertsRelation}
                            />
                        </div>
                    )}
                    {selectedButton === 'robot-config' && (
                        <div>
                            {/* Robot Configuration Component */}
                            <RobotStatusTransferList />
                        </div>
                    )}

                </Box>
            )}
        </Grid>
    );
};
