import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
} from '@mui/material';

import Selector from '../tools/Selector';
import { titleCase } from '../pipelineMonitoring/Utils';

export default function NewStateDialog(props) {
  const {
    robotState,
    open,
    setOpenState,
    updateRobotState,
    robotCode,
    resetForm,
    setResetForm,
    availableStatus,
    getAvailableRobotStatus,
    loadingNewRobotState,
    robotStateMotives,
  } = props;

  const { t } = useTranslation();

  const [newState, setNewState] = useState(null);
  const [allMotives, setAllMotives] = useState(null);
  const [selectedMotive, setSelectedMotive] = useState('');
  const [otherMotive, setOtherMotive] = useState('');
  const [motive, setMotive] = useState(null);
  const [allStatusFormated, setAllStatusFormated] = useState([]);

  useEffect(() => {
    getAvailableRobotStatus();
  }, []);

  useEffect(() => {
    if (availableStatus) {
      let allStatus = [...availableStatus];
      allStatus.map((item) => (item.description = titleCase(item.status)));
      setAllStatusFormated(allStatus);
    }
  }, [availableStatus]);

  useEffect(() => {
    if (resetForm) {
      setSelectedMotive('');
      setOtherMotive('');
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (robotStateMotives) {
      let motives = [...robotStateMotives];
      motives.push({ description: 'Other', motive: 'other' });
      setAllMotives(motives);
    }
  }, [robotStateMotives]);

  useEffect(() => {
    if (selectedMotive === 'Other' && otherMotive !== '') {
      setMotive(otherMotive);
    } else setMotive(selectedMotive);
  }, [selectedMotive, otherMotive]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newState === null || selectedMotive === '') {
      console.log('Por favor, completa todos los campos requeridos');
      return;
    }
    await updateRobotState(robotCode, newState.status, motive.description);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      onClose={() => setOpenState(false)}
      aria-labelledby="state-dialog-title"
      aria-describedby="state-dialog-description"
    >
      <DialogTitle id="state-dialog-title">
        {t(
          'overseer_app.widget.robot_state_dialog_title',
          'Change the robot state'
        )}
        <Divider />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Selector
                options={allStatusFormated}
                selectedElement={newState}
                setSelectedElement={setNewState}
                labelInput={'New State'}
                optionLabel={'description'}
                optionsDisabled={(option) => option.status === robotState}
              />
            </Grid>
            <Grid item xs={12}>
              <Selector
                options={allMotives}
                selectedElement={selectedMotive}
                setSelectedElement={setSelectedMotive}
                labelInput={'Motive'}
                optionLabel={'description'}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          disabled={loadingNewRobotState}
          color="primary"
        >
          {t('overseer_app.robot_view.send', 'Send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
