import { connect } from "react-redux";

import {
  alerts,
  allAlertPolicies,
  batchesReceived,
  getPPNodesInfo,
  isLoadingPPNodesInfo,
  navigationOptions,
  objectNames,
  processesData,
  totalAisles,
} from "core/selectors";
import {
  getObjectNames,
  getPPNodes,
} from "core/slices/pipelineMonitoring";
import SessionView from "../../../components/pipelineMonitoring/SessionView";

export default connect(
  (state) => ({
    processesData: processesData(state),
    totalAisles: totalAisles(state),
    objectNames: objectNames(state),
    batchesReceived: batchesReceived(state),
    navigationOptions: navigationOptions(state),
    alerts: alerts(state),
    allAlertPolicies: allAlertPolicies(state),
    pipelineProgressNodes: getPPNodesInfo(state),
    isLoadingPPNodesInfo: isLoadingPPNodesInfo(state),
  }),
  {
    getObjectNames,
    getPPNodes,
  }
)(SessionView);
