import React, { useEffect, useState } from 'react';

import {
  Typography,
  Box,
  MenuItem,
  Select,
  Button,
  Slider,
  Input,
} from '@mui/material';
import moment from 'moment';

function RevisionMetadata(props) {
  const { model, trafficSplit, node, chain, updateVertexMetadata } = props;

  const [minReplicaCount, setMinReplicaCount] = useState(
    model.dedicatedResources.minReplicaCount,
  );
  const [maxReplicaCount, setMaxReplicaCount] = useState(
    model.dedicatedResources.maxReplicaCount,
  );

  useEffect(() => {}, [model]);

  const handleUpdateModel = () => {
    updateVertexMetadata({
      process_id: node.process_id,
      chain: chain,
      model_id: model.model,
      changes: {
        minReplicaCount,
        maxReplicaCount,
      },
    });
  };

  return (
    <Box
      sx={{
        margin: 2,
      }}>
      <Box
        sx={{
          marginY: 3,
          backgroundColor: '#e9e9f1',
          padding: 2,
          borderRadius: 1,
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 2,
          }}>
          <Typography>Traffic: {trafficSplit[model.id]}%</Typography>
          <Typography sx={{ color: 'gray' }}>
            {moment(model.createTime).format('MMMM Do YYYY, h:mm:ss')}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            marginY: 2,
          }}>
          <Typography> Model:</Typography>
          <Typography> {model.displayName} </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            marginY: 2,
          }}>
          <Typography> Machine Type:</Typography>
          <Typography sx={{ fontWeight: 'bold' }}>
            {' '}
            {model.dedicatedResources.machineSpec.machineType}{' '}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            marginY: 2,
          }}>
          <Typography> GPU Accelerator Type: </Typography>
          <Typography sx={{ fontWeight: 'bold' }}>
            {' '}
            {model.dedicatedResources.machineSpec.acceleratorType}{' '}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: 3,
            alignItems: 'center',
            marginRight: 2,
            marginY: 2,
            justifyContent: 'space-between',
          }}>
          <Typography>Min Replicas Count:</Typography>
          <Input
            value={minReplicaCount}
            onChange={e => setMinReplicaCount(parseInt(e.target.value))}
            sx={{ width: '60px' }}
            inputProps={{
              step: 1,
              min: 1,
              max: 100,
              type: 'number',
            }}
          />
          <Slider
            sx={{ width: '200px' }}
            value={minReplicaCount}
            min={1}
            max={100}
            onChange={(e, newValue) => setMinReplicaCount(parseInt(newValue))}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: 3,
            alignItems: 'center',
            marginRight: 2,
            marginY: 2,
            justifyContent: 'space-between',
          }}>
          <Typography>Max Replicas Count:</Typography>
          <Input
            value={maxReplicaCount}
            onChange={e => setMaxReplicaCount(parseInt(e.target.value))}
            sx={{ width: '60px' }}
            inputProps={{
              step: 1,
              min: 1,
              max: 100,
              type: 'number',
            }}
          />
          <Slider
            sx={{ width: '200px' }}
            value={maxReplicaCount}
            min={1}
            max={100}
            onChange={(e, newValue) => setMaxReplicaCount(parseInt(newValue))}
          />
        </Box>
        <Button
          variant="contained"
          sx={{ height: '30px', width: '100%' }}
          onClick={() =>
            window.confirm(
              `Are you sure you want to update ${node.service_name} metadata?
              \ Min Replicas Count: ${minReplicaCount} 
              \ Max Replicas Count: ${maxReplicaCount}`,
            )
              ? handleUpdateModel()
              : null
          }>
          Update service
        </Button>
      </Box>
    </Box>
  );
}

export default RevisionMetadata;
