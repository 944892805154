import { connect } from 'react-redux';

import {
  getServicingHistory,
  getChangeMotives,
} from 'core/slices/servicingHistoryWidget';
import {
  getRobotState,
  getRobotStateHistory,
} from 'core/slices/robotMonitoring';
import {
  loadingServicingHistory,
  servicingHistory,
  loadingChangeMotives,
  changeMotives,
  patchChangeReasonsResponse,
  getRobotStateData,
  isLoadingRobotStateData,
  getRobotStateHistoryData,
} from 'core/selectors';

import RobotStateHistoryWidget from '../../../components/widgets/RobotStateHistoryWidget';

export default connect(
  (state) => ({
    servicingHistory: servicingHistory(state),
    loadingServicingHistory: loadingServicingHistory(state),
    changeMotives: changeMotives(state),
    loadingChangeMotives: loadingChangeMotives(state),
    patchChangeReasonsResponse: patchChangeReasonsResponse(state),
    robotState: getRobotStateData(state),
    isLoadingRobotStateData: isLoadingRobotStateData(state),
    robotStateHistory: getRobotStateHistoryData(state),
  }),
  {
    getServicingHistory,
    getChangeMotives,
    getRobotState,
    getRobotStateHistory,
  }
)(RobotStateHistoryWidget);
