import { createReducer } from './utils';
import {
    handleRequest,
    handleAvailable,
    handleUnavailable,
} from '../reducerHandlers';

import {
    ALERTS_REQUEST,
    ALERTS_AVAILABLE,
    ALERTS_UNAVAILABLE,
    SEVERITIES_REQUEST,
    SEVERITIES_AVAILABLE,
    SEVERITIES_UNAVAILABLE,
    DATA_CHART_REQUEST,
    DATA_CHART_AVAILABLE,
    DATA_CHART_UNAVAILABLE,
    CMI_REQUEST,
    CMI_AVAILABLE,
    CMI_UNAVAILABLE,
    SNACK_SET
} from './constants';

const defaultState = {
    alertsCreated: [],
    availableAlerts: [],
    severities: [],
    chartData: [],
    groupAlerts: {},
    cmiData: {},
    isLoadingSeverities: false,
    isLoadingAlertsCreated: false,
    isLoadingAvailableAlerts: false,
    isLoadingGroupAlerts: false,
    isLoadingChartData: false,
    isLoadingCMIData: false,
};

export const reducer = createReducer(defaultState, {
    [ALERTS_REQUEST]: handleRequest,
    [ALERTS_AVAILABLE]: handleAvailable,
    [ALERTS_UNAVAILABLE]: handleUnavailable,
    [SEVERITIES_REQUEST]: handleRequest,
    [SEVERITIES_AVAILABLE]: handleAvailable,
    [SEVERITIES_UNAVAILABLE]: handleUnavailable,
    [DATA_CHART_REQUEST]: handleRequest,
    [DATA_CHART_AVAILABLE]: handleAvailable,
    [DATA_CHART_UNAVAILABLE]: handleUnavailable,
    [CMI_REQUEST]: handleRequest,
    [CMI_AVAILABLE]: handleAvailable,
    [CMI_UNAVAILABLE]: handleUnavailable,
});

export function getAllCreatedAlerts() {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'alertsCreated';
        const loadingName = 'isLoadingAlertsCreated';

        dispatch({
            type: ALERTS_REQUEST,
            payload: { loadingName: loadingName },
        });

        try {
            const response = await dataSource.getAllCreatedAlerts();

            dispatch({
                type: ALERTS_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName,
                },
            });

        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: ALERTS_UNAVAILABLE,
                payload: {
                    keyState: keyState,
                    loadingName: loadingName,
                    defaultState: [],
                },
            });
            const snack = {
                open: true,
                message: 'There was an error getting alerts data.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}


export function getAvailableAlerts() {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'availableAlerts';
        const loadingName = 'isLoadingAvailableAlerts';

        dispatch({
            type: ALERTS_REQUEST,
            payload: { loadingName: loadingName },
        });

        try {
            const response = await dataSource.getAvailableAlerts();
            dispatch({
                type: ALERTS_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName,
                },
            });

        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: ALERTS_UNAVAILABLE,
                payload: {
                    keyState: keyState,
                    loadingName: loadingName,
                    defaultState: [],
                },
            });
            const snack = {
                open: true,
                message: 'There was an error getting available alerts data.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function createNewAlert(alertData) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'isLoadingAlertsCreated';

        dispatch({ type: ALERTS_REQUEST, payload: { loadingName: loadingName } });

        try {
            // Set default parameters
            alertData["slack_msg"] = false
            alertData["language"] = "en"
            await dataSource.createNewAlert(JSON.stringify(alertData));
            const snack = {
                open: true,
                message: 'alert created',
                severity: 'success',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });

        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: ALERTS_UNAVAILABLE,
                payload: { error, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'Error in creating alert',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

export function updateAlert(alertData) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'isLoadingAlertsCreated';

        dispatch({ type: ALERTS_REQUEST, payload: { loadingName: loadingName } });

        try {
            await dataSource.updateAlert(
                alertData.name,
                alertData.title,
                alertData.icon,
                alertData.description,
                alertData.old_name
            );
            const snack = {
                open: true,
                message: 'alert updated',
                severity: 'success',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });

        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: ALERTS_UNAVAILABLE,
                payload: { error, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'Error in updating alert',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

export function deleteAlert(alertName) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'isLoadingAlertsCreated';

        dispatch({ type: ALERTS_REQUEST, payload: { loadingName: loadingName } });

        try {
            await dataSource.deleteAlert(alertName);
            const snack = {
                open: true,
                message: 'alert deleted',
                severity: 'success',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });

        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: ALERTS_UNAVAILABLE,
                payload: { error, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'Error in deleting alert',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

export function getGroupAlertsRelation() {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'groupAlerts';
        const loadingName = 'isLoadingGroupAlerts';

        dispatch({ type: ALERTS_REQUEST, payload: { loadingName: loadingName } });

        try {
            const response = await dataSource.getGroupAlertsRelation();
            dispatch({
                type: ALERTS_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName,
                },
            });

        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: ALERTS_UNAVAILABLE,
                payload: {
                    keyState: keyState,
                    loadingName: loadingName,
                    defaultState: [],
                },
            });
            const snack = {
                open: true,
                message: 'There was an error getting group alerts realtion data.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function addGroupAlertRealtion(alerts_list, group_id) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'isLoadingGroupAlerts';

        dispatch({ type: ALERTS_REQUEST, payload: { loadingName: loadingName } });

        try {
            await dataSource.postGroupAlertsRelation(JSON.stringify(alerts_list), group_id);
            const snack = {
                open: true,
                message: 'relation created',
                severity: 'success',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });

        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: ALERTS_UNAVAILABLE,
                payload: { error, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'Error in creating relation',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

export function deleteGroupAlertRelation(alerts_list) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'isLoadingGroupAlerts';

        dispatch({ type: ALERTS_REQUEST, payload: { loadingName: loadingName } });

        try {
            await dataSource.deleteGroupAlertRelation(JSON.stringify(alerts_list));
            const snack = {
                open: true,
                message: 'relation deleted',
                severity: 'success',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: ALERTS_UNAVAILABLE,
                payload: { error, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'Error in deleting relation',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}


export function getSeverities() {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'severities';
        const loadingName = 'isLoadingSeverities';
        dispatch({
            type: SEVERITIES_REQUEST,
            payload: { loadingName: loadingName },
        });

        try {
            const severitiesResponse = await dataSource.getSeverities();
            dispatch({
                type: SEVERITIES_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: severitiesResponse,
                    loadingName: loadingName,
                },
            });

        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: SEVERITIES_UNAVAILABLE,
                payload: {
                    keyState: keyState,
                    loadingName: loadingName,
                    defaultState: [],
                },
            });
            const snack = {
                open: true,
                message: 'There was an error getting severities data.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getChartData(service_name, project_id, metric_type, delta_time, alignment_period) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'chartData';
        const loadingName = 'isLoadingChartData';
        dispatch({
            type: DATA_CHART_REQUEST,
            payload: { loadingName: loadingName },
        });

        try {
            const chartDataResponse = await dataSource.getDataChart(
                service_name,
                project_id,
                metric_type,
                delta_time,
                alignment_period
            );
            dispatch({
                type: DATA_CHART_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: chartDataResponse,
                    loadingName: loadingName,
                },
            });

        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: DATA_CHART_UNAVAILABLE,
                payload: {
                    keyState: keyState,
                    loadingName: loadingName,
                    defaultState: [],
                },
            });
            const snack = {
                open: true,
                message: 'There was an error getting chart data.',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getCMIpolicies(project_name, policy_name) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'cmiData';
        const loadingName = 'isLoadingCMIData';

        dispatch({
            type: CMI_REQUEST,
            payload: { loadingName: loadingName },
        });


        try {
            const response = await dataSource.getCMIPolicies({
                project_name,
                policy_name
            });
            dispatch({
                type: CMI_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName,
                },
            });

        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: CMI_UNAVAILABLE,
                payload: {
                    keyState: keyState,
                    loadingName: loadingName,
                    defaultState: [],
                },
            });
        }
    };
}