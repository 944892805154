import { connect } from 'react-redux';

import {
  getReasonOptions,
  isLoadingActionReasons,
} from 'core/selectors';

import { getActionReasons } from 'core/slices/robotMonitoring';

import CommandForm from '../../../components/widgets/CommandForm';

export default connect(
  (state) => ({
    actionReasons: getReasonOptions(state),
    isLoadingActionReasons: isLoadingActionReasons(state),
  }),
  {
    getActionReasons,
  }
)(CommandForm);
