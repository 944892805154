import { createReducer } from './utils';
import { GET_ROBOTS_STATUS, GET_ROBOTS_STATUS_SUCCESS, GET_ROBOTS_STATUS_FAILURE, SNACK_SET } from './constants';
import { handleRequest, handleAvailable, handleUnavailable } from '../reducerHandlers'


const defaultState = {
    loadingRobotsStatus: false,
    robotsStatus: null,
    robotsStatusErrorMessage: null,
    updatingRobotStatus: false
}

export const reducer = createReducer(defaultState, {
    [GET_ROBOTS_STATUS]: handleRequest,
    [GET_ROBOTS_STATUS_SUCCESS]: handleAvailable,
    [GET_ROBOTS_STATUS_FAILURE]: handleUnavailable,
});

export function getRobotsStatus() {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'robotsStatus'
        const loadingName = 'loadingRobotsStatus'
        dispatch({ type: GET_ROBOTS_STATUS, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getRobotsStatus();
            dispatch({
                type: GET_ROBOTS_STATUS_SUCCESS,
                payload: { keyState: keyState, data: response, loadingName: loadingName },
            });
        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: GET_ROBOTS_STATUS_FAILURE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
            });
            const snack = {
                open: true,
                message: "There was an error getting Robots Status.",
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function updateRobotsStatus({ status_id, chains }) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'robotsStatus'
        const loadingName = 'updatingRobotStatus'
        dispatch({ type: GET_ROBOTS_STATUS, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.updateRobotsStatus({ status_id, chains });
            dispatch({
                type: GET_ROBOTS_STATUS_SUCCESS,
                payload: { keyState: keyState, data: response, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: "Robots Status updated correctly.",
                severity: 'success',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });

        } catch (error) {
            console.error('error: ', error);
            dispatch({
                type: GET_ROBOTS_STATUS_FAILURE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
            });
            const snack = {
                open: true,
                message: "There was an error updating Robots Status.",
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}
