import { Button, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FootageDialog from './FootageDialog';

import { RobotViewContext } from '../../context/RobotView';
import { topicPublish } from '../../mqtt/mqttFunctions';
import CameraOnDemandDialog from './CameraOnDemandDialog';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

export default function CameraOnDemandWidget(props) {
  const {
    sx,
    store,
    robot,
    topics,
    client,
    userInfo,
    iconName,
    isDisabled,
    speedOptions,
    footageBucket,
    setSnackAlert,
    getSpeedOptions,
    postUserActions,
    getFootageBucket,
    resolutionOptions,
    getResolutionOptions,
    isSpeedOptionsLoading,
    isFootageBucketLoading,
    isResolutionOptionsLoading,
    dialogName,
    buttonTitle,
  } = props;
  const cameraOptions = [{ name: 'Security' }, { name: 'Collector' }];
  const [openedDialog, setOpenedDialog] = useState(null);
  const { t } = useTranslation();

  const { onDemandCamerasOptions, setOnDemandCamerasOptions } =
    useContext(RobotViewContext);

  // Hooks

  useEffect(() => {
    if (store) {
      if (!footageBucket && !isFootageBucketLoading) {
        getFootageBucket();
      }
      if (!isResolutionOptionsLoading) {
        getResolutionOptions();
      }
      if (!isSpeedOptionsLoading) {
        getSpeedOptions();
      }
    }
  }, [store]);

  // Handlers
  const sendFootageRequest = (footageForm) => {
    if (footageForm?.date && userInfo?.email && footageBucket) {
      const dayName = footageForm.date.toLocaleDateString('en', {
        weekday: 'long',
      });
      footageForm['email'] = userInfo.email;
      footageForm['cameras'] = cameraOptions
        .flat(1)
        .map((cameraOption) => cameraOption.name);
      footageForm['bucket'] = footageBucket;
      footageForm['blob'] = `${store}/${format(
        footageForm.date,
        'yyyyMMdd'
      )}-${store}-${dayName}_${format(
        footageForm.start_time,
        'HH:mm'
      )}_${format(footageForm.end_time, 'HH:mm')}_${footageForm.resolution}_${
        footageForm.speed
      }.mp4`;
      footageForm['date'] = format(footageForm.date, 'yyyy-MM-dd');
      footageForm['start_time'] = format(footageForm.start_time, 'HH:mm');
      footageForm['end_time'] = format(footageForm.end_time, 'HH:mm');
      postUserActions(
        'get-footage',
        `${store}-${robot}`,
        JSON.stringify(footageForm)
      );
      topicPublish(client, {
        topic: topics.footage_topic.publishTopic,
        qos: topics.footage_topic.publishQos,
        payload: JSON.stringify(footageForm),
      });
      return true;
    } else if (!userInfo?.email) {
      setSnackAlert({
        open: true,
        message:
          'You must be logged in to request footage, try refreshing the page',
        severity: 'error',
      });
      return true;
    } else if (!footageForm?.date) {
      setSnackAlert({
        open: true,
        message: 'You must select a date to request footage',
        severity: 'error',
      });
      return true;
    } else {
      return false;
    }
  };

  const handleCloseDialog = (dialogName) => (footageForm) => {
    setOpenedDialog(null);

    if (dialogName === 'Collector' || dialogName === 'Security') {
      setOnDemandCamerasOptions({});
    } else if (dialogName === 'Footage' && !('cancelable' in footageForm)) {
      const isFootageSent = sendFootageRequest(footageForm);
      // retry if the footage was not sent
      if (!isFootageSent) {
        setTimeout(() => {
          sendFootageRequest(footageForm);
        }, 1000);
      }
    }
  };

  const handleGetCameras = ({ dialogName, msg = '' }) => {
    const targetTopics = {
      Collector: 'side_cameras',
      Security: 'security_cameras',
    };
    const targetTopic = targetTopics[dialogName];
    if (targetTopic) {
      topicPublish(client, {
        topic: topics[targetTopic].publishTopic,
        qos: topics[targetTopic].publishQos,
        payload: JSON.stringify(msg),
      });
    }
  };

  const handleOpenDialog = (dialogName) => () => {
    setOpenedDialog(dialogName);
    if (dialogName === 'Collector' || dialogName === 'Security') {
      setOnDemandCamerasOptions({});
    }
  };

  return (
    <>
      <Tooltip
        title={t(
          `overseer_app.widget.${dialogName}_tooltip`,
          `Camera ${buttonTitle}`
        )}
        enterDelay={500}
      >
        <Button
          variant="contained"
          fullWidth
          onClick={handleOpenDialog(dialogName)}
          disabled={isDisabled}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...sx,
          }}
          startIcon={
            <IconComponent iconName={iconName} style={{ fontSize: '20px' }} />
          }
        >
          <Typography
            sx={{
              fontSize: 'small',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {buttonTitle}
          </Typography>
        </Button>
      </Tooltip>

      <FootageDialog
        open={openedDialog === 'Footage'}
        handleClose={handleCloseDialog('Footage')}
        resolutionOptions={resolutionOptions}
        cameraOptions={cameraOptions}
        speedOptions={speedOptions}
        isSpeedOptionsLoading={isSpeedOptionsLoading}
        isResolutionOptionsLoading={isResolutionOptionsLoading}
      />
      <CameraOnDemandDialog
        open={!!(openedDialog && openedDialog !== 'Footage')}
        onDemandCameras={onDemandCamerasOptions[openedDialog]}
        handleGetCameras={handleGetCameras}
        dialogName={openedDialog}
        handleClose={handleCloseDialog('Collector')}
        setSnackAlert={setSnackAlert}
      />
    </>
  );
}
