import { connect } from 'react-redux';

import {
  getHardwareComponents,
  getDefaultHardwareComponents,
} from 'core/slices/HardwareComponentsWidget';
import {
  loadingHardwareComponents,
  hardwareComponents,
  defaultHardwareComponents,
  loadingDefaultHardwareComponents,
} from 'core/selectors';

import HardwareComponentsWidget from '../../../components/widgets/HardwareComponentsWidget';

export default connect(
  (state) => ({
    hardwareComponents: hardwareComponents(state),
    loadingHardwareComponents: loadingHardwareComponents(state),
    defaultHardwareComponents: defaultHardwareComponents(state),
    loadingDefaultHardwareComponents: loadingDefaultHardwareComponents(state),
  }),
  {
    getHardwareComponents,
    getDefaultHardwareComponents,
  }
)(HardwareComponentsWidget);
