import { createReducer } from './utils';
import {
  handleRequest,
  handleAvailable,
  handleUnavailable,
} from '../reducerHandlers';

import {
  SNACK_SET,
  EVENTS_REQUEST,
  EVENTS_AVAILABLE,
  EVENTS_UNAVAILABLE,
} from './constants';

const defaultState = {
  eventsList: [],
  activeEventsList: [],
  closedEventsList: [],
  eventStatus: '',
  updatingEventStatus: false,
};

export const reducer = createReducer(defaultState, {
  [EVENTS_REQUEST]: handleRequest,
  [EVENTS_AVAILABLE]: handleAvailable,
  [EVENTS_UNAVAILABLE]: handleUnavailable,
});

export function getEvents(
  all_events = false,
  time_range = 0,
  assigned_to_user = false
) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingEvents';
    let keyState;
    if (all_events) keyState = 'activeEventsList';
    else if (time_range !== 0) keyState = 'closedEventsList';
    else keyState = 'eventsList';
    dispatch({ type: EVENTS_REQUEST, payload: { loadingName } });
    try {
      const eventsResponse = await dataSource.getEventsList(
        all_events,
        time_range,
        assigned_to_user
      );
      const eventsList = eventsResponse ? eventsResponse : [];
      dispatch({
        type: EVENTS_AVAILABLE,
        payload: { keyState, loadingName, data: eventsList },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: EVENTS_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function updateEventStatus(event_id, new_status, is_escalate = null) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'eventStatus';
    const loadingName = 'updatingEventStatus';
    let snack;
    dispatch({
      type: EVENTS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const eventStatus = await dataSource.updateEventStatus(
        event_id,
        new_status,
        is_escalate
      );
      dispatch({
        type: EVENTS_AVAILABLE,
        payload: { keyState, data: eventStatus, loadingName },
      });
      snack = {
        open: true,
        message: eventStatus.msg,
        severity: 'success',
      };
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: EVENTS_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
      snack = {
        open: true,
        message: 'There was an error updating Robots Status.',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}

export function postClickUpTask(
  robotEventId,
  destination,
  comments,
  storeCode,
  storeName,
  chainName,
  robotCode,
  alerts,
  mainAlert,
  robotUrl,
) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'newClickUpTaskResponse';
    const loadingName = 'loadingNewClickUpTask';
    let snack;
    dispatch({
      type: EVENTS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const clickUpResponse = await dataSource.postClickUpTask(
        robotEventId,
        destination,
        comments,
        storeCode,
        storeName,
        chainName,
        robotCode,
        JSON.stringify(alerts),
        JSON.stringify(mainAlert),
        robotUrl,
      );
      dispatch({
        type: EVENTS_AVAILABLE,
        payload: { keyState, data: clickUpResponse, loadingName },
      });
      snack = {
        open: true,
        message: clickUpResponse.msg,
        severity: 'success',
      };
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: EVENTS_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
      snack = {
        open: true,
        message: 'There was an error creating ClickUp task.',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}

export function getClickUpTeams() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingClickUpTeams';
    let keyState = 'clickUpTeamsList';
    dispatch({ type: EVENTS_REQUEST, payload: { loadingName } });
    try {
      const teamsResponse = await dataSource.getClickUpTeamsList();
      const teamsList = teamsResponse ? teamsResponse : [];
      dispatch({
        type: EVENTS_AVAILABLE,
        payload: { keyState, loadingName, data: teamsList },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: EVENTS_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}
