import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { styled } from "@mui/material/styles";

const StyledDialog = styled((props) => <Dialog {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    position: "absolute",
    top: "0%",
  },
}));

const typeMap = {
  addSession: "add",
  addMultiSessions: "add",
  editSession: "add",
  editMultiSessions: "add",
  deleteSession: "delete",
  deleteMultiSessions: "delete",
};

export default function Confirmation(props) {
  const {
    open,
    type,
    handleClose,
    handleNewSessions,
    handleDeleteSession,
    selectedSessions,
    dateCellSelected,
    actualSessionClicked,
  } = props;

  const [typeDialog, setTypeDialog] = React.useState("");

  React.useEffect(() => {
    setTypeDialog(typeMap[type]);
  }, [type]);

  const handleStructDataDelete = () => {
    let sessions = [];
    if (type && type === "deleteMultiSessions") {
      sessions = selectedSessions;
    } else {
      if (actualSessionClicked.id) {
        sessions.push(actualSessionClicked.id);
      }
    }

    handleDeleteSession(sessions, dateCellSelected.date);
  };

  return (
    <div>
      <StyledDialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            textAlign={"center"}
            fontSize={"1.5rem"}
            id="alert-dialog-description"
          >
            {typeDialog === "delete"
              ? `Are you sure what you want to delete? `
              : `Are you sure you want create this new session? `}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              width: "10rem",
              mb: 3,
              mr: 3,
              display: "flex",
              alignItems: "center",
              border: "1px solid ",
            }}
          >
            No, cancel
          </Button>
          <Button
            onClick={() =>
              typeDialog === "delete"
                ? handleStructDataDelete()
                : handleNewSessions()
            }
            autoFocus
            sx={{
              width: "10rem",
              mb: 3,
              mr: 3,
              display: "flex",
              alignItems: "center",
              background: "#1E76E8",
              color: "white",
              "&:hover": {
                background: "#1565C0",
              },
            }}
          >
            {typeDialog === "delete" ? `yes, delete` : `yes, create `}
          </Button>
        </DialogActions>
      </StyledDialog>
    </div>
  );
}
