import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import RevisionMetadata from './RevisionMetadata';
import VertexMetadata from './VertexMetadata';
import { useParams } from 'react-router-dom';

function MetadataInfo(props) {
  const {
    node,
    getServiceMetadata,
    loadingServiceMetadata,
    serviceMetadata,
    updateServiceMetadata,
    getVertexMetadata,
    updateVertexMetadata,
    navigationOptions
  } = props;

  const { session, date } = useParams();
  const [selectedRevision, setSelectedRevision] = useState('');

  useEffect(() => {
    if (
      node.is_vertex === false ||
      node.is_vertex === null ||
      node.is_vertex === undefined
    ) {
      getServiceMetadata({
        service_name: node.service_name,
        project_id: node.project_id,
      });
    } else {
      getVertexMetadata({
        process_id: node.process_id,
        chain: session.split('-')[1].replace(/[^a-zA-Z]+/g, ''),
      });
    }
    return () => {
      setSelectedRevision('');
    };
  }, []);

  if (!serviceMetadata) {
    return (
      <Box>
        <Typography
          sx={{
            margin: 1,
            color: 'white',
            fontWeight: 'bold',
            fontSize: 24,
            marginX: 3,
          }}>
          {node.service_name}
        </Typography>

        <Typography
          sx={{
            color: 'lightgrey',
            fontSize: 16,
            marginX: 3,
            marginBottom: 1,
          }}>
          {node.display_name}
          {node.description ? ` - ${node.description}` : null}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 1,
            paddingY: 2,
          }}>
          <Typography
            sx={{
              color: '#7c909b',
              fontSize: 18,
              fontWidth: 'bold',
            }}>
            No metadata available for this service...
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Typography
        sx={{
          margin: 1,
          color: 'white',
          fontWeight: 'bold',
          fontSize: 24,
          marginX: 3,
        }}>
        {node.service_name}
      </Typography>

      <Typography
        sx={{ color: 'lightgrey', fontSize: 16, marginX: 3, marginBottom: 1 }}>
        {node.display_name}
        {node.description ? ` - ${node.description}` : null}
      </Typography>
      {loadingServiceMetadata ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 1,
            paddingY: 2,
          }}>
          <CircularProgress sx={{ color: '#7c909b' }} />
          <Typography
            sx={{ color: '#7c909b', fontSize: 18, fontWidth: 'bold' }}>
            Loading data...
          </Typography>
          <Typography
            sx={{ color: '#7c909b', fontSize: 12, fontWidth: 'bold' }}>
            This may take a few minutes
          </Typography>
        </Box>
      ) : node.is_vertex === false ||
        node.is_vertex === null ||
        node.is_vertex === undefined ? (
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              borderRadius: 1,
            }}>
            {serviceMetadata?.revisions?.map((revision, idx) => {
              return (
                <Box
                  key={idx}
                  onClick={() => setSelectedRevision(revision)}
                  sx={{
                    color: '#e9e9f1',
                    paddingX: 2,
                    flexGrow: 1,
                    paddingTop: 2,
                    paddingBottom: 1,
                    textAlign: 'center',
                    cursor: 'pointer',
                    fontSize: 14,
                    border: '1px solid #1a252c',
                    backgroundColor:
                      selectedRevision.revision_id === revision.revision_id
                        ? null
                        : '#29373f',
                  }}>
                  <Typography sx={{ fontSize: 14 }}>
                    {revision.revision_id.split('-').slice(-2).join('-')}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: 12,
                      color: revision.metadata.traffic > 0 ? '#46BF82' : null,
                    }}>
                    ({revision.metadata.traffic}%)
                  </Typography>

                  {selectedRevision.revision_id === revision.revision_id ? (
                    <hr
                      style={{
                        width: '70%',
                        color: '#e9e9f1',
                      }}
                    />
                  ) : null}
                </Box>
              );
            })}
          </Box>

          {serviceMetadata?.revisions?.map(rev => {
            if (rev.revision_id === selectedRevision.revision_id) {
              return (
                <RevisionMetadata
                  revision={selectedRevision}
                  updateServiceMetadata={updateServiceMetadata}
                  serviceName={node.service_name}
                  projectId={node.project_id}
                  navigationOptions={navigationOptions}
                />
              );
            }
            return null;
          })}
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: 'flex',
              borderRadius: 1,
              overflow: 'auto',
            }}>
            {serviceMetadata?.deployedModels
              ?.sort((a, b) => new Date(b.createTime) - new Date(a.createTime))
              .map((model, idx) => {
                return (
                  <Box
                    key={idx}
                    onClick={() => setSelectedRevision(model)}
                    sx={{
                      color: '#e9e9f1',
                      paddingX: 2,
                      flexGrow: 1,
                      paddingTop: 2,
                      paddingBottom: 1,
                      textAlign: 'center',
                      cursor: 'pointer',
                      fontSize: 14,
                      border: '1px solid #1a252c',
                      backgroundColor:
                        selectedRevision.id === model.id ? null : '#29373f',
                    }}>
                    <Typography sx={{ fontSize: 14 }}>{model.id}</Typography>

                    <Typography
                      sx={{
                        fontSize: 12,
                        color:
                          serviceMetadata.trafficSplit[model.id] > 0
                            ? '#46BF82'
                            : null,
                      }}>
                      ({serviceMetadata.trafficSplit[model.id]}%)
                    </Typography>

                    {selectedRevision.id === model.id ? (
                      <hr
                        style={{
                          width: '70%',
                          color: '#e9e9f1',
                        }}
                      />
                    ) : null}
                  </Box>
                );
              })}
          </Box>

          {serviceMetadata?.deployedModels?.map(model => {
            if (model.id === selectedRevision.id) {
              return (
                <VertexMetadata
                  model={selectedRevision}
                  trafficSplit={serviceMetadata.trafficSplit}
                  updateVertexMetadata={updateVertexMetadata}
                  node={node}
                  chain={session.split('-')[1].replace(/[^a-zA-Z]+/g, '')}
                />
              );
            }
            return null;
          })}
        </Box>
      )}
    </Box>
  );
}

export default MetadataInfo;
