import React from 'react';
import { Stack, Chip } from '@mui/material';

export default function ChipGroup(props) {
  const {
    disabled,
    timeFrame,
    setTimeFrame,
    optionTimes = [{ label: '1 hours', deltaTime: 1, params: {} }],
    color = 'secondary',
    size = 'small',
    justifyContent = 'center',
  } = props;

  return (
    <Stack
      direction="row"
      justifyContent={justifyContent}
      spacing={1}
      sx={{ marginRight: 2 }}
    >
      {optionTimes.map((optionTime, idx) => (
        <Chip
          key={`ChipGroup-${idx}-${optionTime.label}`}
          label={optionTime.label}
          variant={
            timeFrame.deltaTime === optionTime.deltaTime ? 'filled' : 'outlined'
          }
          sx={{
            backgroundColor: (theme) =>
              timeFrame.deltaTime === optionTime.deltaTime
                ? `${theme.palette[color].main}33`
                : 'transparent',
            color: (theme) => theme.palette[color].main,
            borderColor: (theme) => theme.palette[color].main,
          }}
          size={size}
          disabled={disabled}
          onClick={() =>
            setTimeFrame({
              deltaTime: optionTime.deltaTime,
              ...optionTime.params,
            })
          }
        />
      ))}
    </Stack>
  );
}
