import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import moment from "moment";
import { checkViewOnlyPermision } from "../../../Utils";

function MonitoringActions(props) {
  const { node, finishSession, navOptions } = props;
  const { session, date } = useParams();
  const [loading, setLoading] = useState(false);

  const [finished, setFinished] = useState(false);
  const handleFinishSession = async (object_type) => {
    setLoading(true);

    const res = await finishSession({ session, object_type });
    if (res.message === "success") {
      const hard_finished_sessions = JSON.parse(
        localStorage.getItem("hard_finished_sessions")
      );
      const obj = {
        date: moment(),
        session: session,
        id: node.id,
        object: node.object,
      };
      localStorage.setItem(
        "hard_finished_sessions",
        JSON.stringify([...hard_finished_sessions, obj])
      );
      setFinished(true);
    }

    setLoading(false);
  };
  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{ color: "#e9e9f1", fontWeight: "bold", fontSize: "20px" }}
        >
          Monitoring Actions
        </Typography>
      </Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 1,
            paddingY: 2,
          }}
        >
          <CircularProgress sx={{ color: "#7c909b" }} />
          <Typography
            sx={{ color: "#7c909b", fontSize: 18, fontWidth: "bold" }}
          >
            Finishing Session...
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              margin: 2,
              padding: 0.5,
              backgroundColor: "#e9e9f1",
              borderRadius: 1,
              width: "70%",
            }}
          >
            <Typography sx={{ fontWeight: "bold", marginX: 1 }}>
              {node.object}
            </Typography>
            <Box sx={{ margin: 1 }}>
              <Button
                variant="contained"
                disabled={
                  node.data.backgroundColor !== "white" ||
                  finished ||
                  checkViewOnlyPermision(navOptions, "Pipeline")
                }
                size="small"
                sx={{
                  width: "100%",
                }}
                onClick={() =>
                  window.confirm(
                    `Are you sure you want to finish ${node.object} session?
              `
                  )
                    ? handleFinishSession(node.object)
                    : null
                }
              >
                Finish Session
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default MonitoringActions;
