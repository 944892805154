import { createContext, useState } from 'react';

export const SchedulerViewContext = createContext();

export function SchedulerViewProvider({ children }) {
  const [selectedChain, setSelectedChain] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);

  return (
    <SchedulerViewContext.Provider
      value={{
        selectedChain,
        setSelectedChain,
        selectedStore,
        setSelectedStore,
        selectedFloor,
        setSelectedFloor,
      }}
    >
      {children}
    </SchedulerViewContext.Provider>
  );
}
