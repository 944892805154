import { createReducer } from './utils';
import {
  handleRequest,
  handleAvailable,
  handleUnavailable,
} from '../reducerHandlers';
import {
  TIMELINE_TRACE_REQUEST,
  TIMELINE_TRACE_AVAILABLE,
  TIMELINE_TRACE_UNAVAILABLE,
  SNACK_SET,
} from './constants';

const defaultState = {
  isLoadingTimeline: false,
  timelineTrace: null,
};

export const reducer = createReducer(defaultState, {
  [TIMELINE_TRACE_REQUEST]: handleRequest,
  [TIMELINE_TRACE_AVAILABLE]: handleAvailable,
  [TIMELINE_TRACE_UNAVAILABLE]: handleUnavailable,
});


export function getTimelineTrace(payload) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'timelineTrace';
    const loadingName = 'isLoadingTimeline';
    dispatch({
      type: TIMELINE_TRACE_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getTimelineTrace(payload);
      dispatch({
        type: TIMELINE_TRACE_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: TIMELINE_TRACE_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the timeline trace. Please try again.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
