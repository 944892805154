import React from "react";
import { MenuItem, Divider, Typography } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { StyledMenu } from "./Utils";

function MenuSessionTable(props) {
  const {
    anchorEl,
    open,
    handleClose,
    handleClickOpenNewSession,
    handleClickOpenDeleteSession,
    handleClickOpenAddAisles,
    dateCellSelected,
    sessionSelected,
  } = props;

  return (
    <StyledMenu
      anchorEl={anchorEl}
      id="session-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
    >
      {sessionSelected && Object.keys(sessionSelected).length !== 0 ? (
        <MenuItem
          onClick={() => handleClickOpenNewSession("editSession")}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <ModeEditOutlineOutlinedIcon
            style={{ color: "#1B242B", fontSize: "1.8rem" }}
          />{" "}
          {`Edit ${dateCellSelected.date}`}
        </MenuItem>
      ) : null}
      {sessionSelected && Object.keys(sessionSelected).length !== 0 ? (
        <MenuItem
          onClick={() => handleClickOpenAddAisles()}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <AddBoxIcon style={{ color: "#1B242B", fontSize: "1.8rem" }} />{" "}
          Add/remove aisles
        </MenuItem>
      ) : null}
      <MenuItem
        onClick={() => handleClickOpenNewSession("addSession")}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <AddBoxIcon style={{ color: "#1B242B", fontSize: "1.8rem" }} /> Add
        session
      </MenuItem>
      {sessionSelected && Object.keys(sessionSelected).length !== 0 ?
        <Divider style={{ background: "#D8D8D8" }} />
        : null
      }
      {sessionSelected && Object.keys(sessionSelected).length !== 0 ? (
        <MenuItem
          onClick={() => handleClickOpenDeleteSession("deleteSession")}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <DeleteIcon style={{ color: "#1B242B", fontSize: "1.8rem" }} />
          <Typography style={{ color: "#FF0000" }}>
            {`Delete ${dateCellSelected.date}`}
          </Typography>
        </MenuItem>
      ) : null}
    </StyledMenu>
  );
}

export default MenuSessionTable;
