import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";

import WarningIcon from "@mui/icons-material/Warning";

export default function NodeContent(props) {
  const {
    descriptionLines,
    backgroundColor = "white",
    borderWidth = "0px",
    borderColor = "#EE00EE",
    isVertex,
    id,
    serviceName,
    project,
    root,
    alerts,
  } = props;
  return (
    <Tooltip
      title={
        <div>
          <Typography fontSize={14}>Process ID: {id ?? '-'}</Typography>
          <Typography fontSize={14}>Service: {serviceName ?? '-'}</Typography>
          <Typography fontSize={14}>Project: {project ?? '-'}</Typography>

          <Typography fontSize={14}>
            Type: {root ? "Robot" : isVertex ? "Vertex AI" : "Cloud-Run"}
          </Typography>
          {descriptionLines.map((line, index) => (
            <Typography fontSize={14} key={`${line}-${index}-typography`}>{line}</Typography>
          ))}

          {alerts?.length > 0 ? (
            <Box sx={{ marginY: 1 }}>
              {alerts.map((alertInfo) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      marginLeft: 2,
                    }}
                  >
                    <WarningIcon />
                    <Typography fontSize={14}>
                      {alertInfo.incident.metric.displayName} Error
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          ) : null}
        </div>
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          boxSizing: "border-box",
          height: "100%",
          padding: "5px",
          textAlign: "center",
          backgroundColor: backgroundColor,
          border: `${borderWidth} solid ${borderColor}`,
          borderRadius: "15px",
          cursor: "pointer",
        }}
      >
        {props.children}
      </div>
    </Tooltip>
  );
}
