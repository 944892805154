import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
    TextField
} from '@mui/material';

//Icons
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

const formatColumns = (handleOpenDialog, handleOpenAlert, setSelectedGroup, setNewGroupName) => {
    const columns = [
        {
            field: 'name',
            headerName: "Group Name",
            minWidth: 600,
            align: 'left',
            flex: 1
        },
        {
            field: 'edit',
            headerName: "Edit",
            headerAlign: 'center',
            align: 'center',
            width: 150,
            sortable: false,
            sortingOrder: [null],
            disableColumnMenu: true,
            disableColumnFilter: true,
            renderCell: params => {
                return (
                    <IconButton
                        onClick={() => { handleOpenDialog(); setSelectedGroup(params.row.id, params.row.name); setNewGroupName(params.row.name) }}>
                        <IconComponent iconName={'create-outline'} style={{ fontSize: 24 }} />
                    </IconButton>
                );
            },
        },
        {
            field: 'delete',
            headerName: "Delete",
            headerAlign: 'center',
            align: 'center',
            width: 150,
            sortable: false,
            sortingOrder: [null],
            disableColumnMenu: true,
            disableColumnFilter: true,
            renderCell: params => {
                return (
                    <IconButton
                        onClick={e => {
                            handleOpenAlert(); setSelectedGroup(params.row.id, params.row.name);
                        }}>
                        <IconComponent iconName={'trash'} style={{ fontSize: 24 }} />
                    </IconButton>
                );
            },
        }
    ];

    return columns;
};

export default function GroupTable(props) {
    const { groupsDataList, isLoadingGroupData, getGroupsData, deleteGroup, updateGroup } = props

    const [columns, setColumns] = useState([]);
    const [selectedGoupName, setSelectedGroupName] = useState(null);
    const [selectedGoupId, setSelectedGroupId] = useState(null);
    const [newGroupName, setNewGroupName] = useState("")

    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const [openAlert, setOpenAlert] = useState(false);
    const handleOpenAlert = () => setOpenAlert(true);
    const handleCloseAlert = () => setOpenAlert(false);

    const setSelectedGroup = (group_id, group_name) => {
        setSelectedGroupId(group_id)
        setSelectedGroupName(group_name)
    }

    const deleteGroupHandle = async (selectedGoupId) => {
        await deleteGroup(selectedGoupId)
        getGroupsData()
    }

    const updateGroupHandle = async (selectedGoupId, selectedGoupName) => {
        await updateGroup(selectedGoupId, newGroupName)
        getGroupsData()
    }

    useEffect(() => {
        setColumns(formatColumns(
            handleOpenDialog, handleOpenAlert, setSelectedGroup, setNewGroupName
        ));
    }, []);

    return (
        <Grid item sx={{ minHeight: 350 }}>
            <DataGrid
                getRowId={row => row.id}
                rows={groupsDataList}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                disableColumnSelector={true}
                disableRowSelect={true}
                disableColumnMenu={true}
                loading={isLoadingGroupData}
            />

            {/* Edit group */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle sx={{ fontWeight: 'bold' }}>
                    {"Edit group"}
                </DialogTitle>
                <DialogContent sx={{ width: 400 }}>
                    <TextField autoFocus label="Group name" fullWidth
                        value={newGroupName}
                        sx={{ my: 2 }}
                        onChange={(e) => {
                            setNewGroupName(e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined"
                        sx={{ textTransform: 'none', fontWeight: 'bold', p: 1 }}
                        onClick={handleCloseDialog}
                    >
                        Cancel
                    </Button>
                    <Button variant="contained"
                        sx={{ textTransform: 'none', fontWeight: 'bold', p: 1 }}
                        onClick={() => { updateGroupHandle(selectedGoupId, selectedGoupName); handleCloseDialog() }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete group */}
            <Dialog open={openAlert} onClose={handleCloseAlert}>
                <DialogTitle sx={{ fontWeight: 'bold' }}>
                    {"Delete group?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete group:
                        <Typography sx={{ fontWeight: 'bold', py: 3, textAlign: "center" }}>
                            {selectedGoupName} {selectedGoupId}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined"
                        sx={{ textTransform: 'none', fontWeight: 'bold', p: 1 }}
                        onClick={handleCloseAlert}
                    >
                        Cancel
                    </Button>

                    <Button variant="contained"
                        sx={{ textTransform: 'none', fontWeight: 'bold', p: 1 }}
                        onClick={() => { deleteGroupHandle(selectedGoupId); handleCloseAlert() }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
