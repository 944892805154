import { connect } from "react-redux";

import {
  getRequestCounts,
  getContainerInstances,
  getRequestLatencies,
  getCpuUtilization,
  getMemoryUtilization,
  getRevisions,
  getVertexResponseCount,
  getVertexPredictionCount,
  getVertexReplicasCount,
  getVertexLatencies,
  getVertexCpuUtilization,
  getCustomMetric,
} from "core/slices/metrics";
import {
  requestCounts,
  loadingRequestCounts,
  containerInstances,
  loadingContainerInstances,
  memoryUtilization,
  loadingMemoryUtilization,
  cpuUtilization,
  loadingCpuUtilization,
  requestLatencies,
  loadingRequestLatencies,
  revisions,
  loadingRevisions,
  vertexResponseCount,
  loadingVertexResponseCount,
  vertexPredictionCount,
  loadingVertexPredictionCount,
  vertexReplicasCount,
  loadingVertexReplicasCount,
  vertexCpuUtilization,
  loadingVertexCpuUtilization,
  vertexLatencies,
  loadingVertexLatencies,
  navigationOptions,
  loadingCustomMetric,
  customMetric,
} from "core/selectors";

import MetricsInfo from "../../../../components/pipelineMonitoring/monitoringGraph/InfoDrawer/Node/MetricsInfo";

export default connect(
  (state) => ({
    requestCounts: requestCounts(state),
    loadingRequestCounts: loadingRequestCounts(state),
    containerInstances: containerInstances(state),
    loadingContainerInstances: loadingContainerInstances(state),
    memoryUtilization: memoryUtilization(state),
    loadingMemoryUtilization: loadingMemoryUtilization(state),
    cpuUtilization: cpuUtilization(state),
    loadingCpuUtilization: loadingCpuUtilization(state),
    requestLatencies: requestLatencies(state),
    loadingRequestLatencies: loadingRequestLatencies(state),
    revisions: revisions(state),
    loadingRevisions: loadingRevisions(state),
    vertexResponseCount: vertexResponseCount(state),
    loadingVertexResponseCount: loadingVertexResponseCount(state),
    vertexPredictionCount: vertexPredictionCount(state),
    loadingVertexPredictionCount: loadingVertexPredictionCount(state),
    vertexReplicasCount: vertexReplicasCount(state),
    loadingVertexReplicasCount: loadingVertexReplicasCount(state),
    vertexCpuUtilization: vertexCpuUtilization(state),
    loadingVertexCpuUtilization: loadingVertexCpuUtilization(state),
    vertexLatencies: vertexLatencies(state),
    loadingVertexLatencies: loadingVertexLatencies(state),
    navigationOptions: navigationOptions(state),
  }),
  {
    getRequestCounts,
    getContainerInstances,
    getRequestLatencies,
    getCpuUtilization,
    getMemoryUtilization,
    getRevisions,
    getVertexResponseCount,
    getVertexPredictionCount,
    getVertexReplicasCount,
    getVertexLatencies,
    getVertexCpuUtilization,
  }
)(MetricsInfo);
