import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Divider,
  LinearProgress,
  CircularProgress,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Paper,
  TableBody,
} from '@mui/material';
import { useParams } from 'react-router-dom';

function ProgressInfo(props) {
  const { edge, getTasksProgress, tasksProgress, loadingTasksProgress } = props;
  const { session, date } = useParams();

  const getProgress = () => {
    getTasksProgress({
      store: session.split('-')[1],
      session_id: session,
      queue_name: edge.data.child.cloud_tasks_queue,
      project_id: edge.data.child.queue_project_id,
      service_name: edge.data.child.service_name,
    });
  };
  useEffect(() => {
    getProgress();
  }, [session]);

  if (!tasksProgress && !loadingTasksProgress) {
    return (
      <Box>
        <Typography
          sx={{
            margin: 1,
            color: 'white',
            fontWeight: 'bold',
            fontSize: 24,
            marginX: 3,
          }}>
          {edge.data.child.cloud_tasks_queue}
        </Typography>

        <Typography
          sx={{
            color: 'lightgrey',
            fontSize: 16,
            marginX: 3,
            marginBottom: 1,
          }}>
          {edge.data.child.display_name}
          {edge.data.child.description
            ? ` - ${edge.data.child.description}`
            : null}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 1,
            paddingY: 2,
          }}>
          <Typography
            sx={{
              color: '#7c909b',
              fontSize: 18,
              fontWidth: 'bold',
            }}>
            Tasks progress not available...
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Typography
        sx={{
          margin: 1,
          color: 'white',
          fontWeight: 'bold',
          fontSize: 24,
          marginX: 3,
        }}>
        {edge.data.child.cloud_tasks_queue}
      </Typography>

      <Typography
        sx={{ color: 'lightgrey', fontSize: 16, marginX: 3, marginBottom: 1 }}>
        {edge.data.child.display_name}
        {edge.data.child.description
          ? ` - ${edge.data.child.description}`
          : null}
      </Typography>
      {loadingTasksProgress ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 1,
            paddingY: 2,
          }}>
          <CircularProgress sx={{ color: '#7c909b' }} />
          <Typography
            sx={{ color: '#7c909b', fontSize: 18, fontWidth: 'bold' }}>
            Loading data...
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography
            sx={{
              marginX: 2,
              color: '#e9e9f1',
              fontWeight: 'bold',
              fontSize: 20,
            }}>
            Pending Tasks
          </Typography>
          {tasksProgress?.length > 0 ? (
            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: '#e9e9f1',
                display: 'flex',
                justifyContent: 'center',
                marginX: 1,
                width: 'auto',
                maxHeight: 400,
              }}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Aisle</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Job ID </TableCell>

                    <TableCell sx={{ fontWeight: 'bold' }}>Creation</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Schedule Time
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Retries</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasksProgress.map((task, idx) => (
                    <TableRow
                      key={idx}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <TableCell>{task.body.aisle}</TableCell>
                      <TableCell>{task.body.job_id}</TableCell>
                      <TableCell>{task.create_time}</TableCell>
                      <TableCell>{task.schedule_time}</TableCell>
                      <TableCell>{task.dispatch_count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 1,
                paddingY: 2,
              }}>
              <Typography
                sx={{
                  color: '#7c909b',
                  fontSize: 18,
                  fontWidth: 'bold',
                }}>
                No pending tasks are scheduled...
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default ProgressInfo;
