import { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';

export default function ScheduleForm(props) {


  const handleAction = (canSave) => {
    console.log('Action button clicked');
  }

  return (
    <>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Start date</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Repeats on</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">Finish Date</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2"> Start at: </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2"> End at: </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
          <Button variant='contained' onClick={() => { handleAction(false) }}>Cancel</Button>
          <Button variant='contained' onClick={() => { handleAction(true) }} sx={{ ml: 2 }}>Save</Button>
        </Box>
      </Grid>
    </>
  );
}
