import { connect } from "react-redux";

import {
  getTasksMetadata,
  updateTasksMetadata,
} from "core/slices/serviceMetadata";
import {
  tasksMetadata,
  loadingTasksMetadata,
  navigationOptions,
} from "core/selectors";

import MetadataInfo from "../../../../components/pipelineMonitoring/monitoringGraph/InfoDrawer/Edge/MetadataInfo";

export default connect(
  (state) => ({
    tasksMetadata: tasksMetadata(state),
    loadingTasksMetadata: loadingTasksMetadata(state),
    navigationOptions: navigationOptions(state),
  }),
  {
    getTasksMetadata,
    updateTasksMetadata,
  }
)(MetadataInfo);
