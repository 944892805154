import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { hexToPastel } from './components/utils/commons';

let theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});

theme = createTheme({
  palette: {
    type: 'light',
    primary: theme.palette.augmentColor({
      color: { main: '#1a242d' },
      name: 'primary',
    }),
    secondary: theme.palette.augmentColor({
      color: { main: '#FF0266' },
      name: 'secondary',
    }),
    success: theme.palette.augmentColor({
      color: { main: '#46bf83' },
      name: 'success',
    }),
    info: theme.palette.augmentColor({
      color: { main: '#0070B7' },
      name: 'info',
    }),
    warning: theme.palette.augmentColor({
      color: { main: '#ffa400' },
      name: 'warning',
    }),
    error: theme.palette.augmentColor({
      color: { main: '#fe2d38' },
      name: 'error',
    }),
    slate: theme.palette.augmentColor({
      color: { main: '#64748b' },
      name: 'slate',
    }),
    gray: theme.palette.augmentColor({
      color: { main: '#6b7280' },
      name: 'gray',
    }),
    zinc: theme.palette.augmentColor({
      color: { main: '#71717a' },
      name: 'zinc',
    }),
    neutral: theme.palette.augmentColor({
      color: { main: '#737373' },
      name: 'neutral',
    }),
    stone: theme.palette.augmentColor({
      color: { main: '#78716c' },
      name: 'stone',
    }),
    red: theme.palette.augmentColor({
      color: { main: '#ef4444' },
      name: 'red',
    }),
    orange: theme.palette.augmentColor({
      color: { main: '#f97316' },
      name: 'orange',
    }),
    amber: theme.palette.augmentColor({
      color: { main: '#f59e0b' },
      name: 'amber',
    }),
    yellow: theme.palette.augmentColor({
      color: { main: '#eab308' },
      name: 'yellow',
    }),
    lime: theme.palette.augmentColor({
      color: { main: '#84cc16' },
      name: 'lime',
    }),
    green: theme.palette.augmentColor({
      color: { main: '#22c55e' },
      name: 'green',
    }),
    emerald: theme.palette.augmentColor({
      color: { main: '#10b981' },
      name: 'emerald',
    }),
    teal: theme.palette.augmentColor({
      color: { main: '#14b8a6' },
      name: 'teal',
    }),
    cyan: theme.palette.augmentColor({
      color: { main: '#06b6d4' },
      name: 'cyan',
    }),
    sky: theme.palette.augmentColor({
      color: { main: '#0ea5e9' },
      name: 'sky',
    }),
    blue: theme.palette.augmentColor({
      color: { main: '#3b82f6' },
      name: 'blue',
    }),
    indigo: theme.palette.augmentColor({
      color: { main: '#6366f1' },
      name: 'indigo',
    }),
    violet: theme.palette.augmentColor({
      color: { main: '#8b5cf6' },
      name: 'violet',
    }),
    purple: theme.palette.augmentColor({
      color: { main: '#a855f7' },
      name: 'purple',
    }),
    fuchsia: theme.palette.augmentColor({
      color: { main: '#d946ef' },
      name: 'fuchsia',
    }),
    pink: theme.palette.augmentColor({
      color: { main: '#ec4899' },
      name: 'pink',
    }),
    rose: theme.palette.augmentColor({
      color: { main: '#f43f5e' },
      name: 'rose',
    }),
    pastelYellow: theme.palette.augmentColor({
      color: { main: hexToPastel('#fde047') },
      name: 'pastelYellow',
    }),
    pastelSky: theme.palette.augmentColor({
      color: { main: hexToPastel('#0ea5e9') },
      name: 'pastelSky',
    }),
    pastelEmerald: theme.palette.augmentColor({
      color: { main: hexToPastel('#10b981') },
      name: 'pastelEmerald',
    }),
    pastelViolet: theme.palette.augmentColor({
      color: { main: hexToPastel('#8b5cf6') },
      name: 'pastelViolet',
    }),
  },
});

theme = responsiveFontSizes(theme);

export default theme;
