import React, { Fragment, useEffect, useState } from 'react';

import {
  Box,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Tabs,
  Typography,
  FormControl,
  InputAdornment,
  Tab,
  Input,
} from '@mui/material';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { useTranslation } from 'react-i18next';
import AlertBox from '../tools/AlertBox';
import TabPanel from '../tools/TabPanel';
import { a11yProps } from '../Utils';

export default function RobotParametersWidget(props) {
  const {
    title,
    store,
    robot,
    robotVersions,
    parametersValuesList,
    isLoadingRobotVersions,
    isLoadingParametersValues,
    getAllVersionsRobot,
    getRobotParametersByVersion,
  } = props;
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [filteredParameters, setFilteredParameters] = useState([]);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [robotParameters, setRobotParameters] = useState([]);
  const [robotCurrentVersion, setRobotCurrentVersion] = useState(null);

  // handlers
  const handleValue = (value) => {
    if (typeof value === 'object') {
      if (value?.length) {
        return value.join(', ');
      } else if (value?.length === 0) {
        return '-';
      }
    }
    return value;
  };

  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(debounceTimer);
      const timer = setTimeout(() => {
        func(...args);
      }, delay);
      setDebounceTimer(timer);
    };
  };

  const searchAmongParameters = (targetValue, params) => {
    if (targetValue && params?.length) {
      const filteredParameters = params.filter((param) =>
        param.parameter.toLowerCase().includes(targetValue.toLowerCase())
      );
      setFilteredParameters(filteredParameters);
    } else {
      setFilteredParameters(params);
    }
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    debounce(searchAmongParameters, 100)(event.target.value, robotParameters);
  };

  // hooks

  useEffect(() => {
    if (
      store &&
      robot &&
      JSON.stringify(robotVersions) !== JSON.stringify(robotCurrentVersion)
    ) {
      getAllVersionsRobot({ store, robot, can_return_latest_active: true });
    }
  }, [store, robot]);

  useEffect(() => {
    if (
      !isLoadingRobotVersions &&
      !isLoadingParametersValues &&
      robotVersions &&
      robotVersions.robot_uuid &&
      JSON.stringify(parametersValuesList) !== JSON.stringify(robotParameters)
    ) {
      setRobotCurrentVersion(robotVersions);
      getRobotParametersByVersion(
        robotVersions.robot_uuid,
        robotVersions.version
      );
    }
  }, [robotVersions]);

  useEffect(() => {
    if (parametersValuesList) {
      setRobotParameters(parametersValuesList);
      setFilteredParameters(parametersValuesList);
    }
  }, [parametersValuesList]);

  // card with widget info
  return (
    <Card
      sx={{
        p: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Grid container>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Tabs value={0} aria-label={'robot-parameters-tab-0'}>
            <Tab
              label={title}
              {...a11yProps(0, { textTransform: 'initial' })}
            />
          </Tabs>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              pr: 2,
              alignItems: 'center',
            }}
          >
            <FormControl
              sx={{ ml: 3 }}
              variant="standard"
              disabled={isLoadingParametersValues}
            >
              <Input
                size="small"
                placeholder={t('overseer_app.widget.search', 'Search')}
                id="input-search-robot-params"
                startAdornment={
                  <InputAdornment position="start">
                    <IconComponent iconName="search" color="slate" />
                  </InputAdornment>
                }
                value={searchValue}
                onChange={handleSearchChange}
              />
            </FormControl>
          </Box>
        </Box>
        <Grid xs={12}>
          <TabPanel value={0} index={0}>
            {isLoadingParametersValues || isLoadingRobotVersions ? (
              <Skeleton variant="rectangular" width="100%" height="11em" />
            ) : filteredParameters?.length ? (
              <Box sx={{ overflowY: 'auto', maxHeight: '11em', width: '100%' }}>
                <List dense={true}>
                  {filteredParameters.map((robotParam, index) => (
                    <Fragment key={`robot-parameter-widget-${index}`}>
                      <ListItem>
                        <ListItemText
                          primary={t(
                            `overseer_app.widget.${robotParam.parameter}`,
                            robotParam.parameter
                          )}
                          secondary={
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: 'small',
                                }}
                              >
                                {t(
                                  'overseer_app.widget.current_value',
                                  'Current Value:'
                                )}{' '}
                                {handleValue(
                                  robotParam.values[0]?.current_value ?? '-'
                                )}
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>
                      <Divider />
                    </Fragment>
                  ))}
                </List>
              </Box>
            ) : (
              <AlertBox
                severity="info"
                content={t(
                  'overseer_app.widget.no_parameters',
                  'No parameters available'
                )}
              />
            )}
          </TabPanel>
        </Grid>
      </Grid>
    </Card>
  );
}
