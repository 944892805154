import { createContext, useState } from 'react';

export const DashboardViewContext = createContext();

export function DashboardViewProvider({ children }) {
  const [isSubscribedStatus, setIsSubscribedStatus] = useState(false);
  const [selectedTimeRange, setSelectedTimeRange] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [selectedChain, setSelectedChain] = useState(null);

  return (
    <DashboardViewContext.Provider
      value={{
        isSubscribedStatus,
        setIsSubscribedStatus,
        selectedTimeRange,
        setSelectedTimeRange,
        selectedMetric,
        setSelectedMetric,
        selectedChain,
        setSelectedChain,
      }}
    >
      {children}
    </DashboardViewContext.Provider>
  );
}
