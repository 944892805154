import { React, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import {
  Grid,
  Card,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Skeleton,
  Tooltip,
  Tabs,
  Tab,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TabPanel from '../tools/TabPanel';
import AlertBox from '../tools/AlertBox';
import { a11yProps } from '../Utils';

export default function ScheduleWidget(props) {
  const {
    title,
    store,
    robot,
    getRobotUuid,
    robotUuid,
    robotSessionsList,
    isLoadingRobotSessions,
    getSessionsRobot,
    rawRobotStatus,
  } = props;

  const nDays = 3;

  const [actualRobotSession, setActualRobotSession] = useState('');
  const [actualSessionState, setActualSessionState] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (rawRobotStatus) {
      setActualRobotSession(rawRobotStatus.session_id);
      checkActualSession();
    }
  }, [rawRobotStatus]);

  useEffect(() => {
    if (robotUuid) {
      let today = new Date();
      let next = new Date();
      next.setDate(today.getDate() + nDays);
      today = dayjs(today).format('YYYY-MM-DD');
      let to = dayjs(next).format('YYYY-MM-DD');
      getSessionsRobot(robotUuid, today, to, true);
    }
  }, [robotUuid]);

  useEffect(() => {
    if (store && robot) {
      getRobotUuid(`${store}-${robot}`);
    }
  }, [store, robot]);

  const checkActualSession = () => {
    if (robotSessionsList && Object.keys(robotSessionsList).length) {
      let keys = Object.keys(robotSessionsList).sort();
      let firsts = robotSessionsList[keys[0]];
      firsts.forEach((session) => {
        if (session.session_name === actualRobotSession) {
          setActualSessionState(rawRobotStatus.state);
        }
      });
    }
  };

  const checkSessionState = (session) => {
    let state, icon;
    switch (session.session_name) {
      case actualRobotSession:
        switch (actualSessionState) {
          case 'ST_CAPTURING':
          case 'ST_SENDING_CROPS':
          case 'ST_COMPUTING_STOCKOUT':
          case 'ST_SENDING_STOCKOUT':
          case 'ST_SENDING_REMAININ_CROPS':
          case 'ST_COMPUTING_DATA':
            state = t('overseer_app.widgets.in_progress', 'In progress');
            icon = <RefreshOutlinedIcon sx={{ color: '#11ACBF' }} />;
            break;
          case 'ST_WAITING_NEXT_SESSION':
            state = t('overseer_app.widgets.planned', 'Planned');
            icon = <CheckCircleIcon sx={{ color: '#0E7F19' }} />;
            break;
          case 'ST_CANCEL':
            state = t('overseer_app.widgets.canceled', 'Canceled');
            icon = <CancelOutlinedIcon sx={{ color: '#2a2a2a' }} />;
            break;
          case 'ST_FAILURE':
            state = t('overseer_app.widgets.failure', 'Failure');
            icon = <CancelIcon sx={{ color: '#fc2828' }} />;
            break;
          default:
            state = t('overseer_app.widgets.unknown', 'Unknown');
            icon = <QuestionMarkIcon sx={{ color: '#0E7F19' }} />;
            break;
        }
        break;
      default:
        if (session.active) {
          state = t('overseer_app.widgets.planned', 'Planned');
          icon = <CheckCircleIcon sx={{ color: '#0E7F19' }} />;
        } else {
          state = t('overseer_app.widgets.planned', 'Canceled');
          icon = <CancelOutlinedIcon sx={{ color: '#2a2a2a' }} />;
        }
    }
    return (
      <>
        <ListItemIcon style={{ minWidth: '2rem' }}>{icon}</ListItemIcon>
        <Tooltip
          title={
            <>
              {`Session type:${session.type}`} <br />
              {`Overhead mode: ${session.oh_mode}`}
            </>
          }
        >
          <ListItemText
            primary={session.session_name}
            secondary={`${session.start_local_time} - ${state}`}
          />
        </Tooltip>
      </>
    );
  };

  // card with widget info
  return (
    <Card
      sx={{
        p: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Grid container>
        <Grid xs={12} sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={0}
            aria-label={'robot-schedule-tab-0'}
          >
            <Tab
              label={title}
              {...a11yProps(0, { textTransform: 'initial' })}
            />
          </Tabs>
        </Grid>
        <Grid xs={12}>
          <TabPanel value={0} index={0}>
            {isLoadingRobotSessions ? (
              <Skeleton variant="rectangular" width="100%" height="11em" />
            ) : robotSessionsList && Object.keys(robotSessionsList).length ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{ overflowY: 'auto', maxHeight: '12em' }}
                >
                  <List>
                    {robotSessionsList &&
                      Object.keys(robotSessionsList).map((date, index) => (
                        <>
                          <Typography
                            variant="h8"
                            color="primary"
                            paddingLeft={2}
                          >
                            {date}
                          </Typography>
                          {robotSessionsList[date].map((session) => (
                            <ListItem style={{ paddingTop: 0 }}>
                              {checkSessionState(session)}
                            </ListItem>
                          ))}
                          {index <
                            Object.keys(robotSessionsList).length - 1 && (
                            <Divider />
                          )}
                        </>
                      ))}
                  </List>
                </Grid>
              </Grid>
            ) : (
              <AlertBox
                severity="info"
                sx={{ pt: 1 }}
                content={t(
                  'overseer_app.widget.no_sessions',
                  'No sessions available'
                )}
              />
            )}
          </TabPanel>
        </Grid>
      </Grid>
    </Card>
  );
}
