import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import moment from "moment";

function LogRow(props) {
  const { log } = props;
  const [open, setOpen] = useState(false);
  return (
    <Box sx={{ backgroundColor: "#e9e9f1" }}>
      <Box
        onClick={() => setOpen(!open)}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          margin: 1,
          padding: 1,
          borderRadius: 1,
          cursor: "pointer",
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
          {log.severity}
        </Typography>
        <Typography sx={{ fontSize: 12 }}>
          {moment(log.timestamp).format("D-M-YYYY HH:mm:s:SSS")}
        </Typography>
        <Typography sx={{ fontSize: 12 }}>
          {log.payload.message
            ? log.payload.message
            : `method=${log.payload.method} ${log.payload.data}`}
        </Typography>
      </Box>
      {open ? (
        <Box sx={{ fontSize: "12" }}>
          {JSON.stringify(log.payload, null, 2)}
        </Box>
      ) : null}
    </Box>
  );
}

export default LogRow;
