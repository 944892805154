import { connect } from 'react-redux';
import Management from '../../../../components/alert/management/Management';

import {
    addGroupAlertRealtion,
    deleteGroupAlertRelation
} from 'core/slices/alertManager';

import {
    getGroupData,
    getAvailableAlerts,
    getGroupAlerts,
    isLoadingGroupAlerts
} from 'core/selectors';

export default connect(
    state => ({
        groupsDataList: getGroupData(state),
        availableAlertsList: getAvailableAlerts(state),
        groupAlertsDict: getGroupAlerts(state),
        isLoadingGroupAlerts: isLoadingGroupAlerts(state)
    }),
    {
        addGroupAlertRealtion,
        deleteGroupAlertRelation
    }
)(Management);
