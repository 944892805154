import React from "react";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import NodeMetricsInfo from "../../../../redux/containers/pipelineMonitoring/Node/MetricsInfo";
import NodeMetadataInfo from "../../../../redux/containers/pipelineMonitoring/Node/MetadataInfo";
import NodeProgressInfo from "../../../../redux/containers/pipelineMonitoring/Node/ProgressInfo";
import NodeTrafficInfo from "../../../../redux/containers/pipelineMonitoring/Node/TrafficInfo";
import NodeLogsInfo from "../../../../redux/containers/pipelineMonitoring/Node/LogsInfo";

import EdgeMetricsInfo from "../../../../redux/containers/pipelineMonitoring/Edge/MetricsInfo";
import EdgeMetadataInfo from "../../../../redux/containers/pipelineMonitoring/Edge/MetadataInfo";
import EdgeProgressInfo from "../../../../redux/containers/pipelineMonitoring/Edge/ProgressInfo";

import MonitoringActions from "../../../../redux/containers/pipelineMonitoring/MonitoringActions";
import RootMetricsInfo from "../../../../redux/containers/pipelineMonitoring/Root/MetricsInfo";
import { checkViewOnlyPermision } from "../../Utils";

function NodeInfo(props) {
  const { object, node, edge, navOptions, viewId } = props;
  const [selectedTab, setSelectedTab] = useState("metrics");

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "60px",
        }}
      >
        <Box
          onClick={() => setSelectedTab("metrics")}
          sx={{
            color: "#e9e9f1",
            paddingTop: 3,
            paddingX: 3,
            flexGrow: 1,
            textAlign: "center",
            cursor: "pointer",
            backgroundColor: selectedTab === "metrics" ? null : "#29373f",
            border: "1px solid #1a252c",
          }}
        >
          {object === "root" ? "Cloud" : "Metrics"}
          {selectedTab === "metrics" ? (
            <hr style={{ width: "100%", color: "#e9e9f1" }} />
          ) : null}
        </Box>

        {object === "node" || object === "edge" ? (
          <Box
            onClick={() => setSelectedTab("metadata")}
            sx={{
              color: "#e9e9f1",
              paddingTop: 3,
              paddingX: 3,
              flexGrow: 1,
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: selectedTab === "metadata" ? null : "#29373f",
              border: "1px solid #1a252c",
            }}
          >
            Metadata
            {selectedTab === "metadata" ? (
              <hr style={{ width: "100%", color: "#e9e9f1" }} />
            ) : null}
          </Box>
        ) : null}

        {object === "node" ? (
          <Box
            onClick={() => setSelectedTab("traffic")}
            sx={{
              color: "#e9e9f1",
              paddingTop: 3,
              paddingX: 3,
              flexGrow: 1,
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: selectedTab === "traffic" ? null : "#29373f",
              border: "1px solid #1a252c",
            }}
          >
            Traffic
            {selectedTab === "traffic" ? (
              <hr style={{ width: "100%", color: "#e9e9f1" }} />
            ) : null}
          </Box>
        ) : null}

        {object === "node" || object === "edge" ? (
          <Box
            onClick={() => setSelectedTab("progress")}
            sx={{
              color: "#e9e9f1",
              paddingTop: 3,
              paddingX: 3,
              flexGrow: 1,
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: selectedTab === "progress" ? null : "#29373f",
              border: "1px solid #1a252c",
            }}
          >
            Progress
            {selectedTab === "progress" ? (
              <hr style={{ width: "100%", color: "#e9e9f1" }} />
            ) : null}
          </Box>
        ) : null}
        {object === "node" || object === "edge" ? (
          <Box
            onClick={() => setSelectedTab("logs")}
            sx={{
              color: "#e9e9f1",
              paddingTop: 3,
              paddingX: 3,
              flexGrow: 1,
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: selectedTab === "logs" ? null : "#29373f",
              border: "1px solid #1a252c",
            }}
          >
            Logs
            {selectedTab === "logs" ? (
              <hr style={{ width: "100%", color: "#e9e9f1" }} />
            ) : null}
          </Box>
        ) : null}

        {object === "root" ? (
          <Box
            onClick={() => setSelectedTab("robot")}
            sx={{
              color: "#e9e9f1",
              paddingTop: 3,
              paddingX: 3,
              flexGrow: 1,
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: selectedTab === "robot" ? null : "#29373f",
              border: "1px solid #1a252c",
            }}
          >
            Robot
            {selectedTab === "robot" ? (
              <hr style={{ width: "100%", color: "#e9e9f1" }} />
            ) : null}
          </Box>
        ) : null}
      </Box>

      <Box sx={{ width: "500px" }}>
        {object === "node" ? (
          selectedTab === "metrics" ? (
            <NodeMetricsInfo node={node} />
          ) : selectedTab === "metadata" ? (
            <NodeMetadataInfo node={node} />
          ) : selectedTab === "traffic" ? (
            <NodeTrafficInfo node={node} />
          ) : selectedTab === "logs" ? (
            <NodeLogsInfo node={node} />
          ) : (
            <NodeProgressInfo node={node} />
          )
        ) : object === "edge" ? (
          selectedTab === "metrics" ? (
            <EdgeMetricsInfo edge={edge} />
          ) : selectedTab === "metadata" ? (
            <EdgeMetadataInfo edge={edge} />
          ) : selectedTab === "logs" ? null : (
            <EdgeProgressInfo edge={edge} />
          )
        ) : selectedTab === "metrics" ? (
          <RootMetricsInfo root={node} />
        ) : (
          <MonitoringActions node={node} navOptions={navOptions} />
        )}
      </Box>
    </Box>
  );
}

export default NodeInfo;
