import { createReducer } from './utils';
import {
  handleRequest,
  handleAvailable,
  handleUnavailable,
} from '../reducerHandlers';
import {
  CAMERA_WIDGET_REQUEST,
  CAMERA_WIDGET_AVAILABLE,
  CAMERA_WIDGET_UNAVAILABLE,
  SNACK_SET,
} from './constants';

// Reducer

const defaultState = {
  footageBucket: '',
  speedOptions: [{}],
  resolutionOptions: [{}],
  isSpeedOptionsLoading: false,
  isResolutionOptionsLoading: false,
  isFootageBucketLoading: false,
};

export const reducer = createReducer(defaultState, {
  [CAMERA_WIDGET_REQUEST]: handleRequest,
  [CAMERA_WIDGET_AVAILABLE]: handleAvailable,
  [CAMERA_WIDGET_UNAVAILABLE]: handleUnavailable,
});

// Actions

export function getSpeedOptions() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'speedOptions';
    const loadingName = 'isSpeedOptionsLoading';
    dispatch({
      type: CAMERA_WIDGET_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getSpeedOptions();
      dispatch({
        type: CAMERA_WIDGET_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.speed_options,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: CAMERA_WIDGET_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the camera speed options',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getResolutionOptions() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'resolutionOptions';
    const loadingName = 'isResolutionOptionsLoading';
    dispatch({
      type: CAMERA_WIDGET_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getResolutionOptions();
      dispatch({
        type: CAMERA_WIDGET_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.resolution_options,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: CAMERA_WIDGET_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the camera speed options',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getFootageBucket() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'footageBucket';
    const loadingName = 'isFootageBucketLoading';
    dispatch({
      type: CAMERA_WIDGET_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getFootageBucket();
      dispatch({
        type: CAMERA_WIDGET_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.bucket_name,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: CAMERA_WIDGET_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the camera speed options',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
