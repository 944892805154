export const DeltaTypes = {
  Increase: "increase",
  ModerateIncrease: "moderateIncrease",
  Decrease: "decrease",
  ModerateDecrease: "moderateDecrease",
  Unchanged: "unchanged",
};

export const BaseColors = {
  Slate: "#64748b",
  Gray: "#6b7280",
  Zinc: "#71717a",
  Neutral: "#737373",
  Stone: "#78716c",
  Red: "#ef4444",
  Orange: "#f97316",
  Amber: "#f59e0b",
  Yellow: "#eab308",
  Lime: "#84cc16",
  Green: "#22c55e",
  Emerald: "#10b981",
  Teal: "#14b8a6",
  Cyan: "#06b6d4",
  Sky: "#0ea5e9",
  Blue: "#3b82f6",
  Indigo: "#6366f1",
  Violet: "#8b5cf6",
  Purple: "#a855f7",
  Fuchsia: "#d946ef",
  Pink: "#ec4899",
  Rose: "#f43f5e",
};

export const Sizes = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl",
};

export const HorizontalPositions = {
  Left: "left",
  Right: "right",
};

export const VerticalPositions = {
  Top: "top",
  Bottom: "bottom",
};
