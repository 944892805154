import { Divider, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../tools/Icon';
import CommandForm from '../../redux/containers/widgets/CommandForm';

export default function ConfirmDialog(props) {
  const { open, handleClose, elementObject, isCommand, handleConfirm } = props;

  const { t } = useTranslation();

  const [modalMessage, setModalMessage] = useState('');
  const HAS_FORM = new Set(['return_to_base', 'cancel']);
  const modalForm = useRef({});

  useEffect(() => {
    let message;
    if (isCommand) {
      message = t(
        'overseer_app.widget.command_confirm',
        'You are about to send the command. Do you want to continue?',
        { command: elementObject.command }
      );
    } else {
      message = t(
        'overseer_app.widget.kick_confirm',
        'You are about to kick the monitor/agent. Do you want to continue?'
      );
    }
    setModalMessage(message);
  }, [JSON.stringify(elementObject), isCommand]);

  const handleModalConfirm = () => {
    handleConfirm(modalForm.current)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          variant="h6"
          sx={{ display: 'flex', alignItems: 'baseline' }}
          gutterBottom
        >
          <Icon iconName="warning" color={'warning'} />
          {elementObject.title}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="body" gutterBottom>
            {modalMessage}
          </Typography>
        </DialogContentText>
        <Divider sx={{ my: 1 }} />
        {
          HAS_FORM.has(elementObject.command) && (
            <DialogContentText id="alert-dialog-description">
              <CommandForm
                command={elementObject.command}
                modalForm={modalForm}
              />
            </DialogContentText>
          )
        }
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tooltip title="The action will be canceled">
          <Button onClick={handleClose} variant="outlined">
            No
          </Button>
        </Tooltip>
        <Button
          onClick={handleModalConfirm}
          disabled={HAS_FORM.has(elementObject.command) && !modalForm.current.reason}
          autoFocus
          variant="contained"
          color="secondary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
