import { createReducer } from './utils';
import { OPTIONS_REQUEST, OPTIONS_SUCCESS, OPTIONS_FAILURE } from './constants';

const defaultState = {
  loading: false,
  errorMessage: null,
  options: [],
};

// reducer

export const reducer = createReducer(defaultState, {
  [OPTIONS_REQUEST]: handleOptionsRequest,
  [OPTIONS_SUCCESS]: handleOptionsSuccess,
  [OPTIONS_FAILURE]: handleOptionsFailure,
});

function handleOptionsRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleOptionsSuccess(state, { payload: { options } }) {
  return {
    ...state,
    options,
    loading: false,
    errorMessage: null,
  };
}

function handleOptionsFailure(state, { payload: { error } }) {
  return {
    ...state,
    errorMessage: error,
    loading: false,
    options: {},
  };
}

// actions

export function getNavigationOptions() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: OPTIONS_REQUEST });
    try {
      const options = await dataSource.getNavigationOption();
      dispatch({
        type: OPTIONS_SUCCESS,
        payload: { options },
      });
    } catch (error) {
      dispatch({
        type: OPTIONS_FAILURE,
        payload: { error },
      });
    }
  };
}
