import { connect } from 'react-redux';

import {
  getServiceMetadata,
  updateServiceMetadata,
  getVertexMetadata,
  updateVertexMetadata,
} from 'core/slices/serviceMetadata';
import { loadingServiceMetadata, serviceMetadata, navigationOptions } from 'core/selectors';

import MetadataInfo from '../../../../components/pipelineMonitoring/monitoringGraph/InfoDrawer/Node/MetadataInfo';

export default connect(
  state => ({
    serviceMetadata: serviceMetadata(state),
    loadingServiceMetadata: loadingServiceMetadata(state),
    navigationOptions: navigationOptions(state)
  }),
  {
    getServiceMetadata,
    updateServiceMetadata,
    getVertexMetadata,
    updateVertexMetadata,
  },
)(MetadataInfo);
