import { Box, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AlertBox from '../tools/AlertBox';
import ServiceAlert from './ServiceAlert';

export default function PipelineAlerts(props) {
  const { sx, isLoading, alerts = [], loadingHeight = 350 } = props;
  const { t } = useTranslation();
  
  // card with widget info
  return isLoading ? (
    <Skeleton variant="rectangular" height={loadingHeight} />
  ) : alerts?.length ? (
    <Box sx={{ px: 2, overflowY: 'auto', width: '100%', ...sx }}>
      {alerts
        .filter((alertInfo) => !alertInfo?.incident.metric.labels.session_id)
        .map((alertInfo) => (
          <ServiceAlert alertInfo={alertInfo} />
        ))}
    </Box>
  ) : (
    <AlertBox
      severity="success"
      content={t('overseer_app.robot_view.no_alerts', 'No Alerts')}
      sx={sx}
    />
  );
}
