import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, Typography, Stack, } from '@mui/material'
import { styled } from '@mui/material/styles';

// Components
import TransferList from './TransferList';
import CircularLoader from '../../tools/CircularLoader';

// Icons
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

const styles = {
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        mb: 1
    },
    subtitle: {
        fontSize: '20px',
        fontWeight: 'bold',
        mt: 1,
        mb: 3
    },
}

export default function Management(props) {
    const {
        groupsDataList,
        availableAlertsList,
        groupAlertsDict,
        addGroupAlertRealtion,
        deleteGroupAlertRelation,
        getAvailableAlerts,
        getGroupAlertsRelation,
        isLoadingGroupAlerts
    } = props

    const [selectedGroup, setSelectedGroup] = useState("")
    const [selectedGroupId, setSelectedGroupID] = useState(-1)
    const [groupButons, setGroupButons] = useState(Object.assign({}, ...groupsDataList.map((x) => ({ [x.name]: false }))))
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [newAlerts, setNewAlerts] = useState([]);
    const [removedAlerts, setRemovedAlerts] = useState([]);

    const available_alerts = availableAlertsList.map((data) => data.name)

    const handleSelectedGroup = async (groupName) => {
        setSelectedGroup(groupName);
    };

    const resetAlerts = () => {
        setNewAlerts([])
        setRemovedAlerts([])
    }

    const manageRelationHandle = async () => {
        if (newAlerts.length > 0) {
            await addGroupAlertRealtion(newAlerts, selectedGroupId)
        }
        if (removedAlerts.length > 0) {
            await deleteGroupAlertRelation(removedAlerts, selectedGroupId)
        }
        getAvailableAlerts()
        await getGroupAlertsRelation()
        resetAlerts()
    }

    const handleCancel = () => {
        setLeft(available_alerts)
        setRight(groupAlertsDict[selectedGroup] ? groupAlertsDict[selectedGroup] : [])
        resetAlerts()
    }

    useEffect(() => {
        if (selectedGroup !== "") {
            setLeft(available_alerts)
            setRight(groupAlertsDict[selectedGroup] ? groupAlertsDict[selectedGroup] : [])
            Object.keys(groupButons).forEach(function (index) {
                groupButons[index] = false
            });
            groupButons[selectedGroup] = true
            setGroupButons(groupButons)
            resetAlerts()
        }
    }, [selectedGroup]);

    return (
        <StyledPaper elevation={0} sx={{ p: 3 }}>
            <Grid container>
                <Grid item container xs direction={"column"}>
                    <Grid item xs>
                        <Typography sx={styles.title}>
                            Link Groups and Alerts
                        </Typography>
                    </Grid>

                    <Grid item container spacing={3} direction={"row"}>
                        {/* Group selector */}
                        <Grid item xs={3} sx={{ mr: 1, p: 1 }}>
                            <Typography sx={styles.subtitle}>
                                Group List
                            </Typography>

                            <Stack spacing={1} sx={{ overflow: 'auto', }} >
                                {groupsDataList.map((group, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            variant="outlined"
                                            size="large"
                                            sx={{ textTransform: 'none', p: 1 }}
                                            onClick={() => { handleSelectedGroup(group.name); setSelectedGroupID(group.id) }}
                                            style={{
                                                backgroundColor:
                                                    groupButons[group.name] ? '#1a242d' : 'transparent',
                                                color:
                                                    groupButons[group.name] ? '#FFF' : '#000',
                                            }}
                                        >
                                            {group.name}
                                        </Button>
                                    )
                                })}
                            </Stack>
                        </Grid>

                        <Grid item xs={0.5} />

                        {/* Alerts management */}
                        <Grid item xs>
                            <Typography sx={styles.subtitle}>
                                Manage Alerts
                            </Typography>

                            <Grid sx={{ overflowX: 'auto', overflowY: "hidden", maxWidth: "80vw" }}>
                                {isLoadingGroupAlerts ? (
                                    <Grid
                                        item container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ height: 400 }}
                                    >
                                        <CircularLoader size="2em" />
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            {"Loading..."}
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <TransferList
                                        left={left}
                                        right={right}
                                        newAlerts={newAlerts}
                                        removedAlerts={removedAlerts}
                                        setLeft={setLeft}
                                        setRight={setRight}
                                        setNewAlerts={setNewAlerts}
                                        setRemovedAlerts={setRemovedAlerts}
                                        selectedGroup={selectedGroup}
                                    />
                                )}
                            </Grid>
                        </Grid>

                        {/* Bottom buttons */}
                        <Grid container item spacing={2} justifyContent={"flex-end"} sx={{ pr: "5%" }}>
                            <Grid item>
                                <Button variant="outlined"
                                    sx={{ textTransform: 'none', width: 110, p: 1 }}
                                    disabled={!(newAlerts.length > 0 || removedAlerts.length > 0)}
                                    onClick={() => handleCancel()}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained"
                                    sx={{ textTransform: 'none', width: 110, p: 1 }}
                                    disabled={!(newAlerts.length > 0 || removedAlerts.length > 0)}
                                    startIcon={<IconComponent iconName={'save-outline'} style={{ fontSize: 24 }} />}
                                    onClick={() => manageRelationHandle()}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </StyledPaper>
    )
}

const StyledPaper = styled(props => {
    return <Paper elevation={1} {...props} />;
})(({ theme }) => {
    return {
        width: '100%',
        background:
            theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
        '& .MuiDataGrid-columnHeaders': {
            backgroundColor:
                theme.palette.type === 'light'
                    ? theme.palette.primary.main
                    : theme.palette.paper.main,
            color: 'white',
        },
        '& .MuiDataGrid-cell': {
            background:
                theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
            color: theme.palette.type === 'light' ? 'black' : 'white',
        },
        '& .MuiDataGrid-columnsPanel': {
            background:
                theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
            color: theme.palette.type === 'light' ? 'black' : 'white',
        },
        '& .MuiDataGrid-menuIconButton': {
            opacity: 1,
            color: 'white',
        },
        '& .MuiDataGrid-sortIcon': {
            color: 'white',
        },
    };
});