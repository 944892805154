import React from 'react'
import { Stack, Chip } from "@mui/material";

export default function AlertChips(props) {
    const { disabled, timeFrame, setTimeFrame } = props

    return (
        <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{ marginRight: 2 }}
        >
            <Chip
                label="1 hours"
                variant={timeFrame.delta_time === 1 ? "filled" : "outlined"}
                color="secondary"
                size="medium"
                disabled={disabled}
                onClick={() => setTimeFrame({ delta_time: 1, alignment_period: "300s", custom_period: "120s" })}
            />
            <Chip
                label="3 hours"
                variant={timeFrame.delta_time === 3 ? "filled" : "outlined"}
                color="secondary"
                size="medium"
                disabled={disabled}
                onClick={() => setTimeFrame({ delta_time: 3, alignment_period: "600s", custom_period: "120s" })}
            />
            <Chip
                label="6 hours"
                variant={timeFrame.delta_time === 6 ? "filled" : "outlined"}
                size="medium"
                color="secondary"
                disabled={disabled}
                onClick={() => setTimeFrame({ delta_time: 6, alignment_period: "600s", custom_period: "120s" })}
            />
            <Chip
                label="12 hours"
                variant={timeFrame.delta_time === 12 ? "filled" : "outlined"}
                size="medium"
                color="secondary"
                disabled={disabled}
                onClick={() => setTimeFrame({ delta_time: 12, alignment_period: "3600s", custom_period: "300s" })}
            />
            <Chip
                label="1 days"
                variant={timeFrame.delta_time === 24 ? "filled" : "outlined"}
                size="medium"
                color="secondary"
                disabled={disabled}
                onClick={() => setTimeFrame({ delta_time: 24, alignment_period: "7200s", custom_period: "600s" })}
            />
            <Chip
                label="2 days"
                variant={timeFrame.delta_time === 48 ? "filled" : "outlined"}
                size="medium"
                color="secondary"
                disabled={disabled}
                onClick={() => setTimeFrame({ delta_time: 48, alignment_period: "21600s", custom_period: "1200s" })}
            />
        </Stack>

    )
}
