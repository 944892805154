import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import StarIcon from '@mui/icons-material/Star';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
  tableCellClasses,
  TextField,
  ListItem,
  List,
  ListItemText,
  Typography
} from "@mui/material";

//To add style to header of table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1a242d",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function VersionTable(props) {

  const { 
    data,
    handleTableRowClick,
    handleVersion
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
      setDataDisplay(data)
  }, [data])

  useEffect(() => {
    setDataDisplay(filteredData);
  }, [filteredData]);

  useEffect(() => {
    handleVersion(selectedRow)
  }, [selectedRow])

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, dataDisplay.length - page * rowsPerPage);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    const filtered = data.filter((row) => {
      return (
        row.version
          .toString()
          .includes(event.target.value.toLowerCase()) ||
        row.author
          .toString()
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      );
    });
    setFilteredData(filtered);
    setDataDisplay(filtered);
  };

  const handleTableRowClickChangeColor = (row) => {
    handleTableRowClick(row);
    setSelectedRow(row);
  };

  return (
    <TableContainer component={Paper}>
      <TextField
        label="Search Version"
        value={searchQuery}
        onChange={handleSearchChange}
        variant="outlined"
        fullWidth
        margin="normal"
        style={{ marginTop: 0}}
      />
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{textAlign:'center'}}>Version</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? dataDisplay.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : dataDisplay
          ).map((row, index) => (
            <TableRow key={index} onClick={() => handleTableRowClickChangeColor(row)}
                style={{ cursor: 'pointer',
                    background: selectedRow === row ? '#D3D3D3' : 'transparent',
            }}
            >
              <TableCell style={{ paddingBlock: 0}}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        }}
                >
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', display:"flex", alignItems:"center", paddingBlock: 0.5 }}>
                        <ListItem style={{ display:"flex", alignItems:"center"}}>
                            {
                                row.active ?
                                    <StarIcon sx={{ color: '#00a67f' }} />
                                    :
                                    <StarIcon sx={{ color: '#D3D3D3' }} />
                            }
                            <ListItemText
                                primary={"Version: " + row.version}
                                secondary={
                                    <>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {row.author}
                                        </Typography>
                                        {" - " + row.created_at}
                                    </>}
                            />
                        </ListItem>
                    </List>
                </div>
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={2} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
        component="div"
        count={dataDisplay.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}
