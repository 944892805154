import { Box, Grid } from '@mui/material';
import { Legend, LineChart, Text, Title } from '@zippeditoolsjs/dashboards';

export default function LinearChart(props) {
  const {
    size = 12,
    title,
    subtitle,
    lineData,
    inputIndex,
    inputCategories,
    inputColors = ['teal'],
    textOrientation = 'text-center',
    valueFormatter,
  } = props;

  return (
    <Grid container item xs={size}>
      <Grid item xs={12}>
        <Box className={textOrientation}>
          <Title className="mt-2">{title}</Title>
          <Text className={textOrientation}>{subtitle}</Text>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Legend categories={inputCategories} colors={inputColors} />
        </Box>
      </Grid>
      {lineData?.length ? (
        <LineChart
          className="mt-4 h-80"
          data={lineData}
          index={inputIndex}
          categories={inputCategories}
          colors={inputColors}
          showLegend={false}
          yAxisWidth={48}
          valueFormatter={valueFormatter}
        />
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Title>No data</Title>
        </Box>
      )}
    </Grid>
  );
}
