import React from "react";
import { ResponsiveLine, Line } from "@nivo/line";

function LineGraph({ data, size, graphType, policies, legendSeparation }) {
  return (
    <ResponsiveLine
      data={data}
      markers={policies?.flatMap((policy) =>
        policy.real !== false
          ? [
              {
                axis: "y",
                value:
                  graphType === "percentage"
                    ? policy.threshold_value * 100
                    : policy.threshold_value,
                lineStyle: { stroke: "black", strokeWidth: 1 },
                legend: policy.name,
                legendPosition: "bottom-right",
                textStyle: { fontSize: "10px" },
              },
            ]
          : []
      )}
      margin={{ top: 20, bottom: 80, left: 40 }}
      xScale={{
        type: "time",
        format: "%Y-%m-%d %H:%M:%S",
        useUTC: false,
        precision: "minute",
      }}
      xFormat="time:%Y-%m-%d %H:%M:%S"
      yScale={{
        type: "linear",
        max: graphType === "percentage" ? 100 : "auto",
      }}
      axisBottom={{
        format: "%m/%d %H:%M",
        //tickValues: size === 'sm' ? 'every 3 hours' : 'every 2 hours',
        tickRotation: -90,
      }}
      enablePointLabel={false}
      pointSize={0}
      pointBorderWidth={0}
      colors={{ scheme: "set1" }}
      lineWidth={1}
      pointBorderColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      useMesh={true}
      curve={"linear"}
      legends={[
        {
          anchor: "top-left",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: -20,
          itemWidth: legendSeparation === "sm" ? 35 : 60,
          itemHeight: 20,
          itemsSpacing: legendSeparation === "sm" ? 1 : 4,
          symbolSize: 10,
          symbolShape: "circle",
          itemDirection: "left-to-right",
          itemTextColor: "#777",
        },
      ]}
    />
  );
}

export default LineGraph;
