import React from 'react'
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {
  Grid,
  Paper,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { checkViewOnlyPermision } from '../pipelineMonitoring/Utils';

import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarQuickFilter,
} from '@mui/x-data-grid';

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarQuickFilter sx={{ width: '50%', p: 1 }} />
		</GridToolbarContainer>
	);
}

const formatColumns = (setParamDescription, checkSameValue, handleValueCell, checkIsEditable, checkHighlighted, navOptions, viewId) => {
	const columns = [
		{
			field: 'parameter', headerAlign: 'left', headerName: 'Parameter', width: 300, align: 'left',
			renderCell: params => {
				return (
          <Grid>
            <Tooltip title={`${setParamDescription(params.row.parameter)}`}>
              <Typography sx={{fontSize: 14}}>{params.row.parameter}</Typography>
            </Tooltip>
          </Grid>
				)
			}
		},
		{
			field: 'value', headerAlign: 'left', headerName: 'Value', align: 'left', flex: 1,
			renderCell: params => {
				return (
        <Grid container alignItems={'center'}>
          <Grid item xs sx={{ width: 250, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>
          {checkSameValue(params.row.values) ?
            handleValueCell(params.row.values)
            :
            <Tooltip title={
              <ul style={{paddingLeft: '0.5rem'}}>
                {params.row.values.map(item => <li key={item.uuid_robot}>{item.robot_code}: {item.value}</li>)}
              </ul>
              }>
              <u>Multiple Values</u>
            </Tooltip>
          }
          </Grid>
          <Grid item xs={2} sx={{alignSelf: 'flex-end'}}>
            {checkIsEditable(params.row.parameter) && !checkViewOnlyPermision(navOptions, viewId) ?
              checkHighlighted(params.row)
            : null}
          </Grid>
        </Grid>)
			}
		},
	];
	return columns;
};

const navbarHeight = 64;
const tableHeaderHeight = 112;
const tablePaginationHeight = 52;
const rowHeight = 42;

const calculateRowsPerPage = (window) => {
    const availableHeight = window.innerHeight;
    const calculatedRowsPerPage = Math.floor((availableHeight - tableHeaderHeight - tablePaginationHeight - navbarHeight) / rowHeight);
    return calculatedRowsPerPage;
  };

export default function ParamsTable(props) {
  const {
    data, handleClickModal, parameterDefinition, navOptions,
    viewId, selectedVersion, lastVersion, selectedRobots
  } = props

  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(calculateRowsPerPage(window));

  // FX
  useEffect(() => {
    setRowsPerPage(calculateRowsPerPage(window));
  }, []);

  useEffect(() => {
    setTableData(data);
    setColumns(formatColumns(setParamDescription, checkSameValue, handleValueCell, checkIsEditable, checkHighlighted, navOptions, viewId));
  }, [data])

  // FUNCTIONS 
  const checkSameValue = (values) => {
    // check if all values are the same
    return values.every(val => val.value === values[0].value);
  };

  const checkPreviousValues = (values) => {
    if (values.length === 1 && values[0].current_value && values[0].value && values[0].current_value.toString() !== values[0].value.toString()) return 1;
    else return 0;
  }

  const setParamDescription = (param) => {
    let pm = parameterDefinition.find(item => item.parameter === param)
    if (pm) return pm.description_es
  };

  const checkIsEditable = (param) => {
    let pm = parameterDefinition.find(item => item.parameter === param)
    if (pm) return pm.is_editable
  }

  const handleValueCell = (rowValues) => {
    // get first value
      let first = rowValues[0];
      if (first.value) {
        let valueChanged = first.current_value && first.value !== first.current_value;
        if (typeof first.value === "boolean" || typeof first.value === "object") {
          if (valueChanged) {
            return (
              <span>{first.value.toString()}</span>
            );
          }
        } else {
          if (valueChanged) {
            return (
              <span>{first.value}</span>
            );
          }
        }
        return first.value.toString();
      }
  }

  const sameVersion = () => {
    if (lastVersion === selectedVersion || selectedRobots.length > 1 ) return true
    else return false;
  }

  const checkHighlighted = (row) => {
    let first = row.values[0];
    if (checkPreviousValues(row.values)) {
      return (
        <Tooltip title={`Current value: ${first.current_value}`}>
          <IconButton>
            <HelpOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      )
    }
    else return (
      <IconButton onClick={handleClickModal(row)} disabled={!sameVersion()}>
        <EditIcon />
      </IconButton>
    )
  }

  return (
      <StyledPaper>
        <StyledDataGrid
          rowHeight={40}
					rows={tableData}
          getRowClassName={(params) => checkPreviousValues(params.row.values) ?  'highlighted' : ''}
					columns={columns}
          getRowId={(row) => row.parameter}
					slots={{ toolbar: CustomToolbar }}
					slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
					autoreset={false}
					disableColumnSelector={true}
					disableRowSelect={true}
          initialState={{
						pagination: {
							paginationModel: {
								pageSize: rowsPerPage,
                page: 0
							},
						},
					}}
					pageSizeOptions={[rowsPerPage, 25, 50]}
				/>
      </StyledPaper>
  );
};


const StyledDataGrid = styled(DataGrid)(() => ({
  '& .highlighted': {
    backgroundColor: '#0070b766',
    '&:hover' : {
      backgroundColor: '#0070b766',
    },
    '&.Mui-selected': {
      backgroundColor: '#0070b766',
    }
  }
}));


const StyledPaper = styled(props => {
	return (
		<Paper
			elevation={1}
			{...props}
		/>
	)
}
)(({ theme }) => {
	return ({
		width: '100%',
		background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
		'& .MuiPaper-root': {
			backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.paper.main,
			color: 'white',
		},
		'& .MuiDataGrid-columnHeaders': {
			backgroundColor:
				theme.palette.type === 'light'
					? theme.palette.primary.main
					: theme.palette.paper.main,
			color: 'white',
		},

		'& .MuiDataGrid-menuIconButton': {
			opacity: 1,
			color: 'white',
		},
		'& .MuiDataGrid-sortIcon': {
			color: 'white',
		},
	})
});
