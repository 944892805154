import {
  activeAlerts,
  getLeaveRobotResponse,
  getRobotActionsList,
  getRobotBlockingList,
  getRobotsTakenList,
  getTakeRobotResponse,
  isLoadingLeaveRobot,
  isLoadingRobotActions,
  isLoadingRobotBlocking,
  isLoadingRobotsTaken,
  isLoadingTakeRobot,
  getNewServicingResponse,
  isLoadingNewServicing,
  getChangeMotivesList,
  isLoadingChangeMotives,
  getRobotComponentsList,
  isLoadingRobotComponents,
  getProductsList,
  isLoadingProductsList,
  getBrokerUrl,
  isLoadingBrokerUrl,
  getStoreMapInfo,
  getStoreInfo,
  getIsLoadingStoreInfo,
  userInfo,
  detailedUserInfo,
  getHeatmapSignal,
  getIsLoadingMapSignalInfo,
  getFeaturePermission,
  getHeatMapMappingAlerts,
  getHeatMapMappingAlertsLoading,
  getRobotStateData,
  getAvailableStatusData,
  getRobotStateUpdateResponse,
  isLoadingRobotStateUpdate,
  getAvailableRobotStateMotives,
  getActiveHardwareAlertsList,
} from 'core/selectors';
import { setSnackAlert } from 'core/slices/snackAlert';
import {
  getRobotActions,
  getRobotBlocking,
  getRobotsTaken,
  leaveRobot,
  postUserActions,
  takeRobot,
  topicMessageFeedback,
  getProductsByName,
  getMapSignal,
  getMappingAlerts,
  getRobotState,
  getAvailableRobotStatus,
  updateRobotState,
  getRobotStateMotives,
  postActionReason,
} from 'core/slices/robotMonitoring';
import { getActiveHardwareAlerts } from 'core/slices/hardwareAlertsWidget';
import { connect } from 'react-redux';
import RobotView from '../../../components/robotView/RobotView';
import {
  postNewServicing,
  getChangeMotives,
  getRobotComponents,
} from 'core/slices/robotServicing';
import { getBroker, getStore } from 'core/slices/backend';
import { getActiveAlerts } from 'core/slices/alertsWidget';
import { postNewMap, getStoreMap } from 'core/slices/actionWidget';
import { getUserInfo } from 'core/slices/session';
import { checkFeaturePermission } from 'core/slices/backend';

export default connect(
  (state) => ({
    robotActionsList: getRobotActionsList(state),
    isLoadingRobotActions: isLoadingRobotActions(state),
    robotsTakenList: getRobotsTakenList(state),
    isLoadingRobotsTaken: isLoadingRobotsTaken(state),
    takeRobotResponse: getTakeRobotResponse(state),
    isLoadingTakeRobot: isLoadingTakeRobot(state),
    leaveRobotResponse: getLeaveRobotResponse(state),
    isLoadingLeaveRobot: isLoadingLeaveRobot(state),
    robotBlocking: getRobotBlockingList(state),
    isLoadingRobotBlocking: isLoadingRobotBlocking(state),
    newServicingResponse: getNewServicingResponse(state),
    isLoadingNewServicing: isLoadingNewServicing(state),
    changeMotivesList: getChangeMotivesList(state),
    isLoadingChangeMotives: isLoadingChangeMotives(state),
    robotComponentsList: getRobotComponentsList(state),
    isLoadingRobotComponents: isLoadingRobotComponents(state),
    productsList: getProductsList(state),
    isLoadingProductsList: isLoadingProductsList(state),
    brokerUrl: getBrokerUrl(state),
    isLoadingBrokerUrl: isLoadingBrokerUrl(state),
    storeMapInfo: getStoreMapInfo(state),
    storeInfo: getStoreInfo(state),
    isLoadingStoreInfo: getIsLoadingStoreInfo(state),
    userInfo: userInfo(state),
    detailedUserInfo: detailedUserInfo(state),
    activeAlerts: activeAlerts(state),
    mapSignal: getHeatmapSignal(state),
    isLoadingMapSignalInfo: getIsLoadingMapSignalInfo(state),
    featurePermission: getFeaturePermission(state),
    getHeatMapMappingAlerts: getHeatMapMappingAlerts(state),
    getHeatMapMappingAlertsLoading: getHeatMapMappingAlertsLoading(state),
    robotStateInfo: getRobotStateData(state),
    availableStatus: getAvailableStatusData(state),
    robotStateUpdateResponse: getRobotStateUpdateResponse(state),
    loadingNewRobotState: isLoadingRobotStateUpdate(state),
    robotStateMotives: getAvailableRobotStateMotives(state),
    hardwareActiveAlerts: getActiveHardwareAlertsList(state),
  }),
  {
    getRobotActions,
    topicMessageFeedback,
    postUserActions,
    getRobotsTaken,
    takeRobot,
    leaveRobot,
    getRobotBlocking,
    postNewServicing,
    getChangeMotives,
    getRobotComponents,
    setSnackAlert,
    getProductsByName,
    getBroker,
    getActiveAlerts,
    postNewMap,
    getStoreMap,
    getStore,
    getUserInfo,
    getMapSignal,
    getMappingAlerts,
    checkFeaturePermission,
    getRobotState,
    getAvailableRobotStatus,
    updateRobotState,
    getRobotStateMotives,
    getActiveHardwareAlerts,
    postActionReason,
  }
)(RobotView);
