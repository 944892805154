import React, { useState } from 'react';
import { Grid } from '@mui/material'
import { ResponsiveBar } from '@nivo/bar'

export default function BarChart(props) {

    const {
        data,
        keys
    } = props;

    // make sure parent container have a defined height when using
    // responsive component, otherwise height will be 0 and
    // no chart will be rendered.
    // website examples showcase many properties,
    // you'll often use just a few of them.
    return (
        <Grid sx={{ width: "1000px", height: "600px" }}>
            <ResponsiveBar
                data={data}
                keys={keys}
                indexBy="time"
                margin={{ top: 50, right: 100, bottom: 50, left: 60 }}
                padding={0.2}
                colors={{ scheme: 'set3' }}
                theme={{
                    legends: { text: { fontSize: 16 } },
                    axis: { legend: { text: { fontSize: 20 } } }
                }}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Timestamp',
                    legendPosition: 'middle',
                    legendOffset: 40
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Count',
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                label={e => e.id}
                labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 3]]
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top',
                        direction: 'row',
                        translateX: 0,
                        translateY: -50,
                        itemsSpacing: 2,
                        itemWidth: 120,
                        itemHeight: 30,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 30,
                        effects: [
                            {
                                on: 'hover',
                                style: { itemOpacity: 1 }
                            }
                        ]
                    }
                ]}
                ariaLabel="bar chart"
            />
        </Grid>
    )
}