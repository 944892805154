import { connect } from "react-redux";

import { editAlertPolicy, deleteAlertPolicy } from "core/slices/alertPolicies";

import {} from "core/selectors";

import Policy from "../../../components/pipelineMonitoring/monitoringGraph/InfoDrawer/Policy";

export default connect((state) => ({}), {
  editAlertPolicy,
  deleteAlertPolicy,
})(Policy);
