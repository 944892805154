import React, { useState } from "react";
import {
  Typography,
  Box,
  IconButton,
  Input,
  Tooltip,
  TextField,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import { checkViewOnlyPermision } from "../../Utils";

function Policy(props) {
  const {
    policy,
    metric,
    setNewPolicy,
    editAlertPolicy,
    deleteAlertPolicy,
    graphType,
    navOptions,
  } = props;

  const [threshold, setThreshold] = useState();
  const editPolicy = async () => {
    const response = await editAlertPolicy({
      project_id: metric.project_id,
      policy_data: {
        display_name: policy.name,
        resource_type: metric.resource_type,
        metric_type: metric.metric_type,
        resource_label_key: metric.resource_label_key,
        resource_label_value: metric.resource_label_value,
        threshold_value:
          graphType === "percentage" ? threshold / 100 : threshold,
        alert_duration_time: "60s",
        notification_channel_display_name:
          process.env.REACT_APP_MBA_NOTIFICATION_CHANNEL_DISPLAY_NAME,
      },
      policy_name: policy.name,
    });
    if (response.display_name) {
      setNewPolicy(response);
    }
  };

  const deletePolicy = async () => {
    const response = await deleteAlertPolicy({
      project_id: metric.project_id,
      policy_name: policy.name,
    });
    if (response.state) {
      setNewPolicy(response);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginY: 1,
        width: "100%",
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "14px", width: "200px" }}>
          {policy.name}
        </Typography>
      </Box>
      <TextField
        size="small"
        variant="outlined"
        disabled={checkViewOnlyPermision(navOptions, "Pipeline")}
        value={
          threshold
            ? threshold
            : graphType === "percentage"
            ? policy.threshold_value * 100
            : policy.threshold_value
        }
        onChange={(e) => setThreshold(parseInt(e.target.value))}
        InputProps={{
          endAdornment:
            graphType === "percentage" ? (
              <InputAdornment position="end">%</InputAdornment>
            ) : null,
        }}
        inputProps={{
          step: 1,
          min: 1,
          max: graphType === "percentage" ? 100 : 10000,
          type: "number",
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title="Save">
          <IconButton
            color="info"
            onClick={editPolicy}
            disabled={checkViewOnlyPermision(navOptions, "Pipeline")}
          >
            <AddAlertIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            color="error"
            onClick={deletePolicy}
            disabled={checkViewOnlyPermision(navOptions, "Pipeline")}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default Policy;
