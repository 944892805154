import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';

import {
  Box,
  Stack,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  Modal,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@mui/material';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import Battery50Icon from '@mui/icons-material/Battery50';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import { useTranslation } from 'react-i18next';
import { transformPixels2Robot } from '../Utils';
import StoreCanvas from '../tools/StoreCanvas';
import { RobotViewContext } from '../../context/RobotView';
import CamWidget from '../widgets/CamWidget';
import MapWidget from '../widgets/MapWidget';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '52%',
  transform: 'translate(-50%, -50%)',
  width: '65%',
  maxHeight: '98vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
  p: 2,
};

export default function RelocateWidget(props) {
  const {
    client,
    topics,
    robotCode,
    storeMapInfo,
    isStoreMapInfoLoading,
    isLoadingRobotCameras,
    mqttNavInfo,
    frontalImage,
    postUserActions,
    publishMessageCommand,
    canPublish,
    iHaveRobotTaken,
    mqttMessage,
    floorImage,
    productsList,
    isLoadingProductsList,
    getProductsByName,
    isRelocateOpen,
    setIsRelocateOpen,
    rawRobotStatus,
  } = props;

  // Context
  const { setIsLinesLoading, virtualBorders, setVirtualBorders } =
    useContext(RobotViewContext);

  // States
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [isFollowingRobot, setFollowingRobot] = useState(true);
  const [showFrontalImage, setShowFrontalImage] = useState(false);
  const [createNavPath, setCreateNavPath] = useState(false);
  const [showRelatedButtons, setShowRelatedButtons] = useState(false);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [pathPoints, setPathPoints] = useState([]);
  const [cleanCanvas, setCleanCanvas] = useState({});
  const [isRelocating, setIsRelocating] = useState(false);
  const [isAutonomousMapping, setIsAutonomousMapping] = useState(false);
  const [relocatingAngle, setRelocatingAngle] = useState(0);
  const [relocatingPose, setRelocatingPose] = useState([]);
  const [arrowClicked, setArrowClicked] = useState({
    up: false,
    down: false,
    left: false,
    right: false,
  });
  const [manualDistance, setManualDistance] = useState(null);
  const [manualAngle, setManualAngle] = useState(null);
  const manualMovement = useRef({ distance: manualDistance, angle: manualAngle });
  const [productName, setProductName] = useState('');
  const [numResults, setNumResults] = useState(null);
  const [results, setResults] = useState([]);
  const [batteryVoltage, setBatteryVoltage] = useState(null);
  const [batteryStatus, setBatteryStatus] = useState(null);
  const [chargeState, setChargeState] = useState(null);
  const [mapHeight, setMapHeight] = useState('20rem');

  const [autonomousMappingForm, setAutonomousMappingForm] = useState({});
  const [virtualBordersTemp, setVirtualBordersTemp] = useState(virtualBorders);

  const handleFollowingRobotChange = (event) => {
    setFollowingRobot(event.target.checked);
  };

  const handleFrontalImageChange = (event) => {
    setShowFrontalImage(event.target.checked);
  };

  const handleListItemClick = (index) => (event) => {
    setSelectedIndex(index);
    setShowRelatedButtons(true);
    if (index === 3) {
      setIsRelocating(true);
      setCreateNavPath(false);
      setFollowingRobot(false);
      setIsAutonomousMapping(false);
      setRelocatingAngle(mqttNavInfo.pose[2]);
    } else if (index === 2) {
      setCreateNavPath(true);
      setIsRelocating(false);
      setIsAutonomousMapping(false);
    } else if (index === 4) {
      setCreateNavPath(false);
      setIsRelocating(false);
      setFollowingRobot(false);
      setIsAutonomousMapping(true);
    } else {
      setCreateNavPath(false);
      setIsRelocating(false);
      setIsAutonomousMapping(false);
    }
  };

  const handleSearch = () => {
    setResults(null);
    getProductsByName(productName, robotCode.split('-')[0], numResults);
  };

  const handleClear = () => {
    setProductName('');
    setNumResults(null);
    setResults(null);
  };

  const handleVirtualBorder = (send) => (event) => {
    if (send) {
      autonomousMappingForm.newVirtualBorders.forEach((newBorderLineForm) => {
        client.publish(
          topics.autonomous_mapping.publishTopic,
          JSON.stringify(newBorderLineForm),
          { qos: topics.autonomous_mapping.publishQos }
        );
      });

      autonomousMappingForm.deletedVirtualBorders.forEach(
        (deletedVirtualBorder) => {
          client.publish(
            topics.autonomous_mapping.publishTopic,
            JSON.stringify(deletedVirtualBorder),
            { qos: topics.autonomous_mapping.publishQos }
          );
        }
      );
      setIsAutonomousMapping(false);
      client.publish(
        topics.autonomous_mapping.publishTopic,
        JSON.stringify({ cmd: 'list' }),
        { qos: topics.autonomous_mapping.publishQos }
      );
      setIsLinesLoading(true);
      setTimeout(() => {
        setIsLinesLoading(false);
        setVirtualBordersTemp([]);
        setVirtualBorders([]);
      }, 5000);
      setVirtualBordersTemp(virtualBorders);
    } else {
      setVirtualBorders(virtualBordersTemp);
      setIsAutonomousMapping(false);
    }
  };

  const handleKeyboardArrows = (evt) => {
    // Only run if manual mode is activated, and it is an arrow key that was pressed
    if (tabIndex === 0 && evt.key.startsWith('Arrow')) {
      evt.preventDefault();
      switch (evt.key) {
        case 'ArrowUp':
          clickedArrow('up');
          break;
        case 'ArrowDown':
          clickedArrow('down');
          break;
        case 'ArrowLeft':
          clickedArrow('left');
          break;
        case 'ArrowRight':
          clickedArrow('right');
          break;

        default:
          break;
      }
    }
  };

  const batteryIcon = () => {
    let icon;
    if (batteryStatus === 1) {
      icon = (
        <BatteryChargingFullIcon fontSize="large" sx={{ color: '#0E7F19' }} />
      );
    } else if (batteryStatus === 2) {
      icon = <BatteryAlertIcon fontSize="large" color="error" />;
    } else {
      if (chargeState <= 0.2) {
        icon = <Battery20Icon fontSize="large" color="error" />;
      } else if (chargeState > 0.8) {
        icon = <BatteryFullIcon fontSize="large" sx={{ color: '#0E7F19' }} />;
      } else {
        icon = <Battery50Icon fontSize="large" color="warning" />;
      }
    }
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {icon}
        {chargeState && chargeState < 1 ? (
          <Typography
            sx={{ fontSize: 12, fontWeight: 600, color: 'grey' }}
          >{`(${chargeState * 100}%)`}</Typography>
        ) : null}
      </Box>
    );
  };

  useEffect(() => {
    if (tabIndex === 0 && isRelocateOpen) {
      window.addEventListener('keydown', handleKeyboardArrows);
    } else {
      handleRemoveListener();
    }
    return () => {
      handleRemoveListener();
    };
  }, [tabIndex, isRelocateOpen]);

  useEffect(() => {
    if (productsList) setResults(productsList);
  }, [productsList]);

  useEffect(() => {
    if (mqttMessage) {
      if (mqttMessage.command === 'nav_poses') {
        setWaitingResponse(false);
        cleanPath('canvas-custom-path');
      } else if (mqttMessage.command === 'relocate') {
        setWaitingResponse(false);
      }
    }
  }, [mqttMessage]);

  useEffect(() => {
    if (rawRobotStatus) {
      setBatteryStatus(rawRobotStatus?.battery_status);
      setBatteryVoltage(rawRobotStatus?.battery);
      setChargeState(rawRobotStatus?.charge_state);
    }
  }, [rawRobotStatus]);

  const { t } = useTranslation();

  const handleRemoveListener = () => {
    try {
      window.removeEventListener('keydown', handleKeyboardArrows);
    } catch (error) { }
  };

  const handleClose = () => {
    setTabIndex(0);
    setIsAutonomousMapping(false);
    setIsRelocateOpen(false);
    setIsRelocating(false);
    setRelocatingPose(mqttNavInfo.pose);
    setRelocatingAngle(mqttNavInfo.pose[2]);
    setShowRelatedButtons(false);
    setFollowingRobot(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const sendPath = (points) => {
    if (canPublish) {
      let rosPoints = [];
      points.forEach((element) => {
        rosPoints.push(
          transformPixels2Robot(
            element.x,
            element.y,
            storeMapInfo.origin,
            storeMapInfo.resolution,
            storeMapInfo.height
          )
        );
      });
      let posesObject = { poses: rosPoints };
      postUserActions('send-path', robotCode, posesObject);
      let commandObject = { name: 'nav-poses', command: 'nav_poses' };
      publishMessageCommand(commandObject, posesObject);
      setWaitingResponse(true);
    }
  };

  const cleanPath = (canvasId) => {
    setCleanCanvas({
      canvas: canvasId,
      canvasList: [canvasId],
    });
  };

  const cleanCanvasList = (canvasList) => {
    setCleanCanvas({
      canvasList: canvasList,
    });
  };

  const relocate = (newPose) => {
    if (canPublish) {
      let extraInfo = {
        pose: mqttNavInfo && mqttNavInfo.pose ? mqttNavInfo.pose : [],
        new_pose: newPose,
      };
      postUserActions('relocate', robotCode, extraInfo);
      let msgObj = {
        command: 'initial_pose',
        pose: {
          x: newPose[0],
          y: newPose[1],
          ang: newPose[2],
        },
      };
      let commandObject = { command: 'initial_pose', name: 'relocate' };
      publishMessageCommand(commandObject, msgObj);
    }
  };

  const moveRobot = (args) => {
    let distance = Number.parseFloat(args[1] ?? 0);
    let angleRads = Number.parseFloat(args[2] ?? 0);

    let extraInfo = {
      distance: distance,
      angle: angleRads,
    };

    postUserActions('move-robot', robotCode, extraInfo);

    let commandObject = { command: 'move', name: 'move-robot' };
    let moveValues = {
      linear: distance || 0,
      angular: angleRads || 0,
    };
    let msgObj = { command: 'move', move_values: moveValues };
    publishMessageCommand(commandObject, msgObj);
  };

  const getRelatedButtons = (selectedIndex) => {
    switch (selectedIndex) {
      case 2:
        return [
          {
            label: 'Send path',
            translationKey: 'overseer_app.widget.send_path',
            disabled: waitingResponse,
            action: () => {
              sendPath(pathPoints);
            },
          },
          {
            label: 'Clear path',
            translationKey: 'overseer_app.widget.clean_path',
            disabled: waitingResponse,
            action: () => {
              setPathPoints([]);
              cleanPath('canvas-custom-path');
            },
          },
          {
            label: 'Cancel',
            translationKey: 'overseer_app.widget.cancel',
            disabled: waitingResponse,
            action: () => {
              setShowRelatedButtons(false);
              setCreateNavPath(false);
              cleanPath('canvas-custom-path');
            },
          },
        ];
      case 3:
        return [
          {
            label: 'Send new location',
            translationKey: 'overseer_app.widget.send_location',
            action: () => {
              relocate(relocatingPose);
            },
          },
          {
            label: 'Cancel',
            translationKey: 'overseer_app.widget.cancel',
            action: () => {
              setShowRelatedButtons(false);
              cleanCanvasList(['responsive-canvas', 'canvas-laser']);
              setIsRelocating(false);
              setRelocatingAngle(mqttNavInfo.pose[2]);
              setRelocatingPose(mqttNavInfo.pose);
            },
          },
          {
            label: 'Angle',
            translationKey: 'overseer_app.widget.rad_angle',
            type: 'field',
          },
        ];
      default:
        return [];
    }
  };

  const clickedArrow = (arrow) => {
    let auxObj = { ...arrowClicked };
    auxObj[arrow] = true;
    setArrowClicked(auxObj);
    const { distance: manulDistanceRef, angle: manualAngleRef } = manualMovement.current;
    switch (arrow) {
      case 'up':
        moveRobot([arrow, manulDistanceRef, 0]);
        break;
      case 'down':
        moveRobot([arrow, -manulDistanceRef, 0]);
        break;
      case 'left':
        moveRobot([arrow, 0, manualAngleRef]);
        break;
      case 'right':
        moveRobot([arrow, 0, -manualAngleRef]);
        break;
      default:
        console.log('ERROR: non recognized arrow clicked');
        break;
    }
  };

  const handleManualMovement = (type, value) => {
    if (type === 'distance') {
      manualMovement.current.distance = value
      setManualDistance(value)
    } else if (type === 'angle') {
      setManualAngle(value);
      manualMovement.current.angle = value
    }
  };

  const relatedButtons = showRelatedButtons
    ? getRelatedButtons(selectedIndex)
    : [];

  // Hooks

  return (
    <>
      <Modal
        open={isRelocateOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={t('overseer_app.widget.move_robot', 'Move robot')}
                {...a11yProps(0)}
              />
              <Tab
                label={t(
                  'overseer_app.widget.manually_relocate',
                  'Manually Relocate'
                )}
                {...a11yProps(1)}
              />
              <Tab
                label={t(
                  'overseer_app.widget.search_products',
                  'Search products'
                )}
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <TabPanel value={tabIndex} index={0}>
            {isLoadingRobotCameras && (
              <LinearProgress
                sx={{ mb: 2, mx: 'auto', borderRadius: '15px' }}
                color="secondary"
              />
            )}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CamWidget name={'Frontal'} image={frontalImage} />
              </Grid>
              <Grid item xs={6}>
                <MapWidget
                  id="move-robot"
                  title={t(`overseer_app.widget.map`, 'Map')}
                  mqttNavInfo={mqttNavInfo}
                  storeMapInfo={storeMapInfo}
                  isFollowingRobot={isFollowingRobot}
                  handleFollowingRobotChange={handleFollowingRobotChange}
                  minHeight={mapHeight}
                />
              </Grid>
              <Grid item xs={6}>
                <CamWidget name={'Floor'} image={floorImage} />
              </Grid>
              <Grid item spacing={2} xs={4} disabled={true}>
                <Stack spacing={1}>
                  <TextField
                    value={manualDistance}
                    inputProps={{ step: 0.01 }}
                    onChange={(e) => handleManualMovement('distance', e.target.value)}
                    label={`${t(
                      'overseer_app.widget.distance',
                      'Distance'
                    )} (m)`}
                    type="number"
                    variant="outlined"
                  />
                  <TextField
                    value={manualAngle}
                    onChange={(e) => handleManualMovement('angle', e.target.value)}
                    label={`${t('overseer_app.widget.angle', 'Angle')} (rad)`}
                    type="number"
                    variant="outlined"
                  />
                </Stack>
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                  <Grid item xs={12} container justifyContent="center">
                    <Button
                      variant="contained"
                      onClick={() => clickedArrow('up')}
                      disabled={!iHaveRobotTaken}
                    >
                      &#8593;
                    </Button>
                  </Grid>
                  <Grid item xs={4} container justifyContent="center">
                    <Button
                      variant="contained"
                      onClick={() => clickedArrow('left')}
                      disabled={!iHaveRobotTaken}
                    >
                      &#8592;
                    </Button>
                  </Grid>
                  <Grid item xs={4} container justifyContent="center">
                    <Button
                      variant="contained"
                      onClick={() => clickedArrow('down')}
                      disabled={!iHaveRobotTaken}
                    >
                      &#8595;
                    </Button>
                  </Grid>
                  <Grid item xs={4} container justifyContent="center">
                    <Button
                      variant="contained"
                      onClick={() => clickedArrow('right')}
                      disabled={!iHaveRobotTaken}
                    >
                      &#8594;
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Box>{batteryIcon()}</Box>
                  <Box>
                    <Typography
                      sx={{ fontSize: 15, fontWeight: 700, color: 'black' }}
                    >
                      {t(`overseer_app.widget.battery_status`, 'Battery')}
                    </Typography>
                    {batteryVoltage && (
                      <Typography
                        sx={{ fontSize: 15, fontWeight: 700, color: 'grey' }}
                      >
                        {`${batteryVoltage} V`}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <Grid container>
              <Grid item xs={3}>
                {!isAutonomousMapping ? (
                  relatedButtons.length === 0 && (
                    <Stack spacing={1} marginY={2} paddingRight={1}>
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                        onClick={handleListItemClick(2)}
                        disabled={!iHaveRobotTaken}
                      >
                        {t(
                          'overseer_app.widget.create_nav_path',
                          'Create navigation path'
                        )}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                        onClick={handleListItemClick(3)}
                        disabled={!iHaveRobotTaken}
                      >
                        {t(
                          'overseer_app.widget.start_reloc',
                          'Start relocalization'
                        )}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                        onClick={handleListItemClick(4)}
                        disabled={!iHaveRobotTaken}
                      >
                        {t(
                          'overseer_app.widget.add_virtual_border',
                          'Add virtual border'
                        )}
                      </Button>
                    </Stack>
                  )
                ) : (
                  <Stack spacing={4} marginY={2} paddingRight={1}>
                    <Button
                      variant="contained"
                      sx={{ textTransform: 'none' }}
                      onClick={handleVirtualBorder(true)}
                    >
                      {t('overseer_app.general.Send', 'Send')}
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ textTransform: 'none' }}
                      onClick={handleVirtualBorder(false)}
                    >
                      {t('overseer_app.general.Cancel', 'Cancel')}
                    </Button>
                  </Stack>
                )}
                {relatedButtons.length !== 0 && (
                  <Stack spacing={1} marginY={2}>
                    {relatedButtons.map((button, index) => (
                      <>
                        {button.type === 'field' ? (
                          <TextField
                            id="outlined-number"
                            type="number"
                            variant="standard"
                            value={relocatingAngle}
                            inputProps={{ step: 0.1 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            helperText={t('overseer_app.widget.rad_angle')}
                            onChange={(event) =>
                              setRelocatingAngle(parseFloat(event.target.value))
                            }
                          />
                        ) : (
                          <Button
                            variant="contained"
                            onClick={button.action}
                            disabled={button.disabled}
                            sx={{ textTransform: 'none' }}
                          >
                            {t(button.translationKey, button.label)}
                          </Button>
                        )}
                      </>
                    ))}
                  </Stack>
                )}
                <Divider />
                {/* following robot checkbox */}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isFollowingRobot}
                        onChange={handleFollowingRobotChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={t(
                      'overseer_app.widget.follow_robot_map',
                      'Follow robot'
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showFrontalImage}
                        onChange={handleFrontalImageChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={t(
                      'overseer_app.widget.show_frontal_img',
                      'Frontal image'
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs
                sx={{ overflow: 'auto', maxHeight: '30em' }}
                style={{
                  WebkitOverflowScrolling: 'touch !important',
                  height: 'max-content',
                  minHeight: '400px',
                  maxHeight: '30em',
                  width: '100%',
                  minWidth: '450px',
                  marginTop: '1em',
                  marginLeft: '0.1em',
                  position: 'relative',
                }}
              >
                {/* grey box */}
                {isStoreMapInfoLoading ? (
                  <Skeleton
                    variant="rectangular"
                    width={'auto'}
                    height={300}
                    sx={{ borderRadius: '5px', minHeight: '20em', ml: 1 }}
                  />
                ) : storeMapInfo && storeMapInfo.img ? (
                  <>
                    {showFrontalImage && (
                      <div
                        style={{
                          position: 'sticky',
                          top: 0,
                          left: 0,
                          width: '50%',
                          zIndex: 8,
                        }}
                      >
                        <img
                          alt="frontal"
                          style={{ 'border-radius': '5px', maxWidth: '30vh' }}
                          src={`data:image/jpeg;base64,${frontalImage.img}`}
                        />
                      </div>
                    )}
                    <img
                      src={`data:image/jpeg;base64,${storeMapInfo.img}`}
                      style={{ zIndex: 1, position: 'absolute', top: 0 }}
                      alt="map"
                    />
                    <StoreCanvas
                      info={storeMapInfo}
                      width={storeMapInfo.width}
                      height={storeMapInfo.height}
                      navInfo={mqttNavInfo}
                      isFollowingRobot={isFollowingRobot}
                      createNavPath={createNavPath}
                      waitingResponse={waitingResponse}
                      pathPoints={pathPoints}
                      setPathPoints={setPathPoints}
                      cleanPath={cleanPath}
                      cleanCanvas={cleanCanvas}
                      setCleanCanvas={setCleanCanvas}
                      isRelocating={isRelocating}
                      isAutonomousMapping={isAutonomousMapping}
                      relocatingAngle={relocatingAngle}
                      setRelocatingAngle={setRelocatingAngle}
                      relocatingPose={relocatingPose}
                      setRelocatingPose={setRelocatingPose}
                      setAutonomousMappingForm={setAutonomousMappingForm}
                      isRelocateOpen={isRelocateOpen}
                    />
                  </>
                ) : null}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <Grid container>
              <TextField
                label={t('overseer_app.widget.product_name', 'Product name')}
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <TextField
                label={t(
                  'overseer_app.widget.number_results',
                  'Number of results'
                )}
                value={numResults}
                onChange={(e) => setNumResults(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
              />
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                >
                  {t('overseer_app.widget.search', 'Search')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClear}
                >
                  {t('overseer_app.widget.clean', 'Clear')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                {productsList && results && (
                  <TableContainer
                    component={Paper}
                    style={{ marginTop: '20px' }}
                    sx={{ maxHeight: 400 }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {t(
                              'overseer_app.widget.description',
                              'Description'
                            )}
                          </TableCell>
                          <TableCell>
                            {t('overseer_app.widget.aisle', 'Aisle')}
                          </TableCell>
                          <TableCell>
                            {t('overseer_app.widget.linear', 'Linear')}
                          </TableCell>
                          <TableCell>
                            {t('overseer_app.widget.depth', 'Depth')}
                          </TableCell>
                          <TableCell>
                            {t('overseer_app.widget.height', 'Height')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {results.map((result, index) => (
                          <TableRow key={index}>
                            <TableCell>{result.description}</TableCell>
                            <TableCell>
                              {`${result.aisle}-${result.orient}${result.aisle_part ? `-${result.aisle_part}` : ''}`}
                            </TableCell>
                            <TableCell>{result.location_x}</TableCell>
                            <TableCell>{result.location_y}</TableCell>
                            <TableCell>{result.location_z}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {isLoadingProductsList ? (
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <CircularProgress />
                    <p>
                      {t(
                        'overseer_app.widget.loading_results',
                        'Loading Results'
                      )}
                      ...
                    </p>
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </Modal>
    </>
  );
}
