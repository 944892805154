import { connect } from 'react-redux';
import {
  getWorkshiftInfo,
  logOut,
  postWorkshift,
  postMonitorActivity,
  postMonitorStatus,
} from 'core/slices/session';
import {
  userInfo,
  detailedUserInfo,
  getWorkshiftResponse,
  isloadingPostWorkshift,
} from 'core/selectors';
import MyMenu from '../../components/navbar/MyMenu';

export default connect(
  (state) => ({
    userInfo: userInfo(state),
    detailedUserInfo: detailedUserInfo(state),
    workshiftResponse: getWorkshiftResponse(state),
    isloadingPostWorkshift: isloadingPostWorkshift(state),
  }),
  {
    logOut,
    getWorkshiftInfo,
    postWorkshift,
    postMonitorActivity,
    postMonitorStatus,
  }
)(MyMenu);
