import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import {
  Bold,
  Legend,
  LineChart,
  Text,
  Title,
  Tracker,
} from '@zippeditoolsjs/dashboards';
import { useEffect, useState } from 'react';
import ChipGroup from './ChipGroup';
import Icon from './Icon';

const handleIconNameDefault = (tracker) => {
  const colorIconMap = {
    emerald: 'checkmark-circle',
    rose: 'close-circle',
    yellow: 'alert-circle',
    gray: 'help-circle',
    '': 'help-circle',
    undefined: 'help-circle',
  };
  return colorIconMap[tracker?.color];
};

export default function TrackerCard(props) {
  const {
    sx,
    isLoading,
    filterVariant = 'chip',
    timeFrame,
    setTimeFrame,
    title = 'Cycles',
    leftText = 'Trackers',
    rightTest = 'Proyects with trackers',
    subtitle = 'Analysis of the behavior of the cycles',
    trackerData,
    handleIconName = handleIconNameDefault,
  } = props;

  const [trackers, setTrackers] = useState([]);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  useEffect(() => {
    const trackerLength = trackerData?.length;
    if (trackerLength) {
      const minTrack = 30;
      let newTrackers = [...trackerData];
      if (trackerLength < minTrack) {
        for (let i = 0; i < minTrack - trackerLength; i++) {
          const voidTrack = { color: '', tooltip: '' };
          if (i < (minTrack - trackerLength - 1) / 2) {
            newTrackers = [voidTrack, ...newTrackers];
          } else {
            newTrackers.push(voidTrack);
          }
        }
      }
      setTrackers(newTrackers);
    }
  }, [trackerData]);

  return (
    <Card sx={{ mt: 1, ...sx }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Icon
            iconName={handleIconName(trackerData?.[0])}
            color={trackerData?.[0].color || 'slate'}
            tooltip={trackerData?.[0].tooltip || 'No data'}
          />
          <Title>{title}</Title>
          <Text className="text-center">{subtitle}</Text>
        </Box>

        <Divider sx={{ my: 2 }} />
        {filterVariant === 'chip' ? (
          <ChipGroup
            disabled={isLoading}
            timeFrame={timeFrame}
            setTimeFrame={setTimeFrame}
            optionTimes={[
              { label: '1 hours', deltaTime: 1 },
              { label: '3 hours', deltaTime: 3 },
              { label: '6 hours', deltaTime: 6 },
              { label: '12 hours', deltaTime: 12 },
              { label: '1 day', deltaTime: 24 },
            ]}
            color="info"
            size="small"
          />
        ) : null}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box justifyContent="start" className="space-x-2">
            <Text>
              <Bold>{leftText}</Bold>
            </Text>
          </Box>
          <Text>{rightTest}</Text>
        </Box>
        {trackers?.length ? (
          <Tracker data={trackers} className="mt-0" />
        ) : (
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            No data
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
