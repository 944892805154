import { createReducer } from './utils';
import {
  handleRequest,
  handleAvailable,
  handleUnavailable,
} from '../reducerHandlers';
import {
  METRICS_REQUEST,
  METRICS_AVAILABLE,
  METRICS_UNAVAILABLE,
  SNACK_SET,
} from './constants';

const defaultState = {
  specificMetricData: {},
  monitorMetricsData: {},
  isLoadingMonitorMetricsData: false,
  isLoadingNavigationStats: false,
  navigationStats: {},
};

export const reducer = createReducer(defaultState, {
  [METRICS_REQUEST]: handleRequest,
  [METRICS_AVAILABLE]: handleAvailable,
  [METRICS_UNAVAILABLE]: handleUnavailable,
});

export function getMetricsOptions() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'metricOptionsList';
    const loadingName = 'isLoadingMetricsOptions';
    dispatch({ type: METRICS_REQUEST, payload: { loadingName } });
    try {
      const metricsResponse = await dataSource.getMetricsOptionsList();
      const response = metricsResponse ? metricsResponse : [];
      dispatch({
        type: METRICS_AVAILABLE,
        payload: { keyState, loadingName, data: response },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: METRICS_UNAVAILABLE,
        payload: { keyState, loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: 'There was an error getting metrics data. Please try again.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getSpecificMetric(payload) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'specificMetricData';
    const loadingName = 'isLoadingMetricData';
    dispatch({ type: METRICS_REQUEST, payload: { loadingName } });
    try {
      const assignationResponse = await dataSource.getSpecificMetricData(
        payload
      );
      const assignationData = assignationResponse ? assignationResponse : [];
      dispatch({
        type: METRICS_AVAILABLE,
        payload: { keyState, loadingName, data: assignationData },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: METRICS_UNAVAILABLE,
        payload: { keyState, loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: 'There was an error getting metric data. Please try again.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getRealTimeMetrics() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'realTimeData';
    const loadingName = 'isLoadingRealTimeData';
    dispatch({ type: METRICS_REQUEST, payload: { loadingName } });
    try {
      const realTimeResponse = await dataSource.getRealTimeData();
      const realTimeData = realTimeResponse ? realTimeResponse : {};
      dispatch({
        type: METRICS_AVAILABLE,
        payload: { keyState, loadingName, data: realTimeData },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: METRICS_UNAVAILABLE,
        payload: { keyState, loadingName, defaultState: {} },
      });
      const snack = {
        open: true,
        message: 'There was an error getting real time data. Please try again.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getMonitorMetrics(time_range = 24, personal_info = false) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'monitorMetricsData';
    const loadingName = 'isLoadingMonitorMetricsData';
    dispatch({ type: METRICS_REQUEST, payload: { loadingName } });
    try {
      const monitorMetricsResponse = await dataSource.getMonitorMetricsData(
        time_range,
        personal_info
      );
      const monitorMetricsData = monitorMetricsResponse
        ? monitorMetricsResponse
        : {};
      dispatch({
        type: METRICS_AVAILABLE,
        payload: { keyState, loadingName, data: monitorMetricsData },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: METRICS_UNAVAILABLE,
        payload: { keyState, loadingName, defaultState: {} },
      });
      const snack = {
        open: true,
        message:
          'There was an error getting monitor metrics data. Please try again.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getNavigationStats(planned_for, navigation_status = null) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'navigationStats'
    const loadingName = 'isLoadingNavigationStats'
    dispatch({ type: METRICS_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.getNavigationStats(planned_for, navigation_status);
      dispatch({
        type: METRICS_AVAILABLE,
        payload: { keyState: keyState, data: response, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: METRICS_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error getting the navigation stats.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}