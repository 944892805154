import {
  Box,
  Breadcrumbs,
  Chip,
  CircularProgress,
  Drawer,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SHOWN_OBJECT_IDS } from './Constants';
import { formatDate, prettyName } from './Utils';
import InfoDrawer from './monitoringGraph/InfoDrawer/Drawer';
import MonitoringGraph from './monitoringGraph/MonitoringGraph';


export default function SessionView(props) {
  const {
    processesData,
    totalAisles,
    objectNames,
    batchesReceived,
    getObjectNames,
    alerts,
    allAlertPolicies,
    getPPNodes,
    pipelineProgressNodes,
    isLoadingPPNodesInfo,
    navigationOptions,
  } = props;

  const viewId = 'Pipeline-actions';
  const [selectedObjectIds, setSelectedObjectIds] = useState([]);
  const { session, date } = useParams();
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  useEffect(() => {
    getObjectNames();
  }, [getObjectNames]);

  useEffect(() => {
    let interval60;
    let intervalDelay;
    if (!isLoadingPPNodesInfo && session && date) {
      interval60 = setInterval(() => {
        const canRefresh = date === formatDate(new Date());
        if (!isLoadingPPNodesInfo && canRefresh) {
          handleGetPPNodes(session, { canRefresh });
        }
      }, 3 * 60 * 1000);

      intervalDelay = setTimeout(() => {
        handleGetPPNodes(session)
      }, 100);
    }

    return () => {
      clearInterval(interval60);
      clearInterval(intervalDelay);
    }
  }, [session, date]);

  useEffect(() => {
    if (handleCanShowFlowChart() && pipelineProgressNodes) {
      const nodesEdges = selectedObjectIds.reduce((acc, objectId) => {
        let nodes = pipelineProgressNodes?.[objectId].nodes ?? [];
        let edges = pipelineProgressNodes?.[objectId].edges ?? [];

        // filter edges by objectIds
        edges = edges.filter(edge => {
          return selectedObjectIds.includes(edge.data.child.object_id);
        });

        return {
          nodes: [...acc.nodes, ...nodes],
          edges: [...acc.edges, ...edges],
        }
      }, {
        nodes: [],
        edges: [],
      });

      const ppNodes = nodesEdges?.nodes ?? [];
      const ppEdges = nodesEdges?.edges ?? [];

      setNodes(ppNodes);
      setEdges(ppEdges);
      setTimeout(() => {
        setNodes([...ppNodes]);
        setEdges([...ppEdges]);
      }, 50);
    }
  }, [selectedObjectIds, isLoadingPPNodesInfo, pipelineProgressNodes]);


  // Handlers
  const handleGetPPNodes = (session, { canRefresh = false } = {}) => {
    let hard_finished_sessions = localStorage.getItem('hard_finished_sessions') ?? '[]';
    if (hard_finished_sessions !== '[]') {
      hard_finished_sessions = JSON.stringify(JSON.parse(hard_finished_sessions)?.filter((session) => moment().diff(session.date, 'days') <= 3));
    }
    localStorage.setItem('hard_finished_sessions', hard_finished_sessions);

    const ppNodesForm = canRefresh ? {
      date: date,
      alerts: JSON.stringify(alerts ?? []),
      session: session,
      total_aisles: JSON.stringify(totalAisles),
      processes_data: JSON.stringify(processesData),
      batches_received: JSON.stringify(batchesReceived),
      all_alert_policies: JSON.stringify(allAlertPolicies),
      hard_finished_sessions: hard_finished_sessions,
      chain_code: session.split('-')[1].replace(/[^a-zA-Z]+/g, ''),
      store: session.split('-')[1],
    } : {
      date: date,
      alerts: JSON.stringify(alerts ?? []),
      session: session,
      chain_code: session.split('-')[1].replace(/[^a-zA-Z]+/g, ''),
      store: session.split('-')[1],
    };
    getPPNodes(ppNodesForm);
  }

  const handleRefreshNodes = () => {
    setSelectedObjectIds([...selectedObjectIds]);
  }

  const onNodeClick = (processId, node) => {
    if (node.root) {
      setSelectedObject('root');
      setSelectedNode(node);
    } else {
      setSelectedObject('node');
      setSelectedNode(node);
    }
  };

  const onEdgeClick = (edge) => {
    setSelectedObject('edge');
    setSelectedEdge(edge);
  };

  const toggleSelectedObjectId = (objectId) => {
    const newSelectedObjectIds = new Set(selectedObjectIds);
    if (selectedObjectIds.includes(objectId)) {
      newSelectedObjectIds.delete(objectId);
    } else {
      newSelectedObjectIds.add(objectId);
    }

    setSelectedObjectIds(Array.from(newSelectedObjectIds));
  };

  const handleWidth = () => {
    const windowInnerWidth = window.innerWidth;
    return `calc(${windowInnerWidth}px - 20em)`;
  }

  const handleCanShowFlowChart = () => {
    return !isLoadingPPNodesInfo || Object.values(pipelineProgressNodes ?? {}).length > 0;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href="/#/pipeline_processing"
            >
              Pipeline Monitoring
            </Link>
            <Typography color="text.primary"> {session} </Typography>
          </Breadcrumbs>
        </Box>
      </Grid>
      {!handleCanShowFlowChart() ? (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          width="100%"
          padding="0"
          spacing="10px"
          flexDirection={'column'}
          sx={{
            borderRadius: '15px',
            background: '#f7f6fa',
            pb: 2,
            px: 2,
            display: 'block',
            boxShadow: 1,
            width: handleWidth(),
          }}
        >
          <Grid item xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              my: 1,
              pb: 1.5,
            }}
          >
            <Box sx={{ display: 'flex' }}>
              {SHOWN_OBJECT_IDS.map((objectId) => (
                <Chip
                  variant={
                    selectedObjectIds.includes(objectId)
                      ? 'filled'
                      : 'outlined'
                  }
                  style={{
                    marginRight: '4px',
                  }}
                  key={objectId}
                  label={prettyName(objectNames[objectId])}
                  color={'primary'}
                  onClick={() => {
                    toggleSelectedObjectId(objectId);
                  }}
                />
              ))}
              <Chip
                label="Refresh"
                sx={{ ml: 1 }}
                onClick={handleRefreshNodes}
              />
            </Box>
            {selectedObjectIds?.length === 0 ? (
              <Typography
                sx={{ marginLeft: 1, marginTop: '3px', fontSize: '12px' }}
              >
                Select any of the above chips to display de pipeline graph
              </Typography>
            ) : null}
          </Grid>

          {nodes?.length ?
            <Grid
              item
              xs={12}
              sx={{
                display: 'block',
                overflow: 'auto',
                borderRadius: '7px',
                padding: '0 !important',
                border: '1px solid #cacadd57',
              }}
            >
              <MonitoringGraph
                nodes={nodes}
                edges={edges}
                onNodeClick={onNodeClick}
                onEdgeClick={onEdgeClick}
              />
              <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: '#1A252C',
                    maxWidth: '500px',
                    minWidth: '500px',
                  },
                }}
                anchor={'right'}
                open={selectedNode || selectedEdge ? true : false}
                onClose={() => {
                  setSelectedObject(undefined);
                  setSelectedNode(undefined);
                  setSelectedEdge(undefined);
                }}
              >
                {' '}
                {selectedNode || selectedEdge ? (
                  <InfoDrawer
                    object={selectedObject}
                    node={selectedNode}
                    edge={selectedEdge}
                    navOptions={navigationOptions}
                    viewId={viewId}
                  />
                ) : null}{' '}
              </Drawer>{' '}
            </Grid>
            :
            <Grid
              item
              xs={12}
              sx={{
                mx: '1em',
                overflow: 'auto',
                display: 'block',
                minHeight: '10em',
                borderRadius: '7px',
                border: '1px solid #cacadd57',
              }}
            >
            </Grid>
          }
        </Grid>
      )}
    </Grid>
  );
}
