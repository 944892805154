import React, { useEffect, useState } from 'react';

import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Badge,
  Box,
  Breadcrumbs,
  Checkbox,
  FormControlLabel,
  Modal,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DebounceInput } from 'react-debounce-input';
import {
  getAllChainsFromStoreData
} from './Utils';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { format } from 'date-fns';
import PipelineMonitoringTable from '../../redux/containers/pipelineMonitoring/PipelineMonitoringTable';
import PipelineAlerts from './PipelineAlerts';
import SessionAlert from './SessionAlert';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: '600px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  zIndex: 100,
  borderRadius: 2,
  overflow: 'auto',
  outline: 0,
};

export default function PipelineMonitoring(props) {
  const [selectedDate, setSelectedDate] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );
  const [filterText, setFilterText] = useState('');
  const [selectedChains, setSelectedChains] = useState([]);
  const {
    storeData,
    getJobsCompleted,
    loadingMonitoringData,
    getAllMetadata,
    navigationData,
    getAlerts,
    alerts,
    getBatchesByAisle,
    loadingNavigationData,
  } = props;

  const [sessionAlertsOpen, setSessionAlertsOpen] = useState(false);
  const [serviceAlertsOpen, setServiceAlertsOpen] = useState(false);

  const [showTestSessions, setShowTestSessions] = useState(false);
  const [updateInterval, setUpdateInterval] = useState(null);

  //For updating data every minute
  useEffect(() => {
    if (selectedDate >= format(new Date(), 'yyyy-MM-dd')) {
      clearInterval(updateInterval)
      const interval = setInterval(() => {
        if (!loadingMonitoringData) {
          getJobsCompleted({
            date: selectedDate,
            loading: false,
          });
          getBatchesByAisle({
            date: selectedDate,
          });
        }
      }, 60000);
      setUpdateInterval(interval);
    }
    return () => clearInterval(updateInterval);
  }, [selectedDate, loadingMonitoringData, getJobsCompleted]);

  // For getting data on load
  useEffect(() => {
    getJobsCompleted({
      date: selectedDate,
      loading: true,
    });
    getBatchesByAisle({
      date: selectedDate,
    });
  }, [selectedDate, getJobsCompleted]);

  //For getting metadata on load
  useEffect(() => {
    getAllMetadata({ chain_code: null });
  }, [getAllMetadata]);

  useEffect(() => {
    getAlerts({});
  }, []);

  return (
    <Box>
      {serviceAlertsOpen || sessionAlertsOpen ? (
        <Modal
          style={{ backdropFilter: 'blur(5px)' }}
          open={serviceAlertsOpen || sessionAlertsOpen}
          onClose={() => {
            setServiceAlertsOpen(false);
            setSessionAlertsOpen(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 'bold' }}
            >
              {serviceAlertsOpen ? 'Service Alerts' : 'Session Alerts'}
            </Typography>

            {serviceAlertsOpen ? (
              <PipelineAlerts alerts={alerts} />
            ) : (
              <Box>
                {alerts
                  .filter(
                    (alertInfo) =>
                      alertInfo?.incident.metric?.labels.session_id &&
                      navigationData
                        ?.map((session) => session?.session)
                        ?.includes(alertInfo.incident.metric.labels.session_id)
                  )
                  .map((alertInfo) => (
                    <SessionAlert alertInfo={alertInfo} date={selectedDate} />
                  ))}
              </Box>
            )}
          </Box>
        </Modal>
      ) : null}

      <Box>
        <Box sx={{ marginX: 5, marginY: 1 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="text.primary">Pipeline Monitoring</Typography>
          </Breadcrumbs>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            py: 2,
            borderRadius: '3px',
            boxShadow: 1,
            width: 'auto',
            background: '#FFF',
            marginX: 4,
          }}
        >
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']} sx={{ paddingTop: 0 }}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => <TextField {...params} />}
                  value={dayjs(selectedDate)}
                  onChange={(newDate) => {
                    setSelectedDate(newDate.format('YYYY-MM-DD'));
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
          <Box>
            <DebounceInput
              id="sessionFilter"
              label="Search"
              variant="outlined"
              value={filterText}
              onChange={(event) => {
                setFilterText(event.target.value);
              }}
              debounceTimeout={300}
              element={TextField}
            />
          </Box>
          <Box sx={{ width: '250px' }}>
            <Autocomplete
              multiple
              id="chain-filter"
              options={getAllChainsFromStoreData(storeData)}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              value={selectedChains}
              onChange={(event, newSelectedChains) => {
                setSelectedChains(newSelectedChains);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Chain"
                  placeholder="Select Chain"
                />
              )}
            />
          </Box>
          <Box>
            <FormControlLabel
              value="end"
              control={
                <Switch
                  color="primary"
                  value={showTestSessions}
                  onChange={(e) => {
                    setShowTestSessions(!showTestSessions);
                  }}
                />
              }
              label="Show Test Sessions"
              labelPlacement="end"
            />
          </Box>

          <Box>
            {alerts &&
              alerts?.filter((alertInfo) => !alertInfo.incident.metric?.labels.session_id)
                ?.length > 0 ? (
              <Badge
                badgeContent={
                  alerts?.filter(
                    (alertInfo) => !alertInfo.incident.metric?.labels.session_id
                  )?.length
                }
                color="error"
              >
                <Alert
                  sx={{
                    width: '100%',
                    height: '55px',
                  }}
                  severity="error"
                  action={
                    <MoreHorizIcon
                      onClick={() => setServiceAlertsOpen(!serviceAlertsOpen)}
                      sx={{ cursor: 'pointer' }}
                    />
                  }
                >
                  <Typography
                    sx={{ fontWeight: 'bold', fontSize: 14, paddingX: 1 }}
                  >
                    Pipeline Alerts
                  </Typography>
                </Alert>
              </Badge>
            ) : null}
          </Box>

          <Box>
            {alerts &&
              !loadingNavigationData &&
              alerts.filter(
                (alertInfo) =>
                  alertInfo?.incident.metric.labels.session_id &&
                  navigationData
                    ?.map((session) => session?.session)
                    ?.includes(alertInfo?.incident.metric.labels.session_id)
              ).length > 0 ? (
              <Badge
                badgeContent={
                  alerts.filter(
                    (alertInfo) =>
                      alertInfo?.incident.metric.labels.session_id &&
                      navigationData
                        ?.map((session) => session?.session)
                        ?.includes(alertInfo?.incident.metric.labels.session_id)
                  ).length
                }
                color="warning"
              >
                <Alert
                  sx={{
                    width: '100%',
                    height: '55px',
                  }}
                  severity="warning"
                  action={
                    <MoreHorizIcon
                      onClick={() => setSessionAlertsOpen(!sessionAlertsOpen)}
                      sx={{ cursor: 'pointer' }}
                    />
                  }
                >
                  <Typography
                    sx={{ fontWeight: 'bold', fontSize: 14, paddingX: 1 }}
                  >
                    Session Alerts
                  </Typography>
                </Alert>
              </Badge>
            ) : null}
          </Box>
        </Box>
        <Box sx={{ marginX: 4, marginY: 3 }}>
          <PipelineMonitoringTable
            cardElevation={0}
            canShowNavigationTitle={true}
            filterText={filterText}
            selectedDate={selectedDate}
            selectedChains={selectedChains}
            showTestSessions={showTestSessions}
            sxCard={{
              border: '1px solid #E0E0E0',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
