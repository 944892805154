import {
  Alert,
  Badge,
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const AdditionalButtonsWrapper = styled(Grid)({
  position: 'absolute',
  top: '50%',
  left: '52%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1,
});

const ControlButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1,
  boxShadow: theme.shadows[3],
}));

export default function RobotBlocking(props) {
  const {
    controlledBy,
    isLoadingTakeRobot,
    setOpenKick,
    takeSelectedRobot,
    getRobotsTaken,
    minutesLeft,
    minutesKickLeft,

    featurePermission,
    checkFeaturePermission,
  } = props;

  const { t } = useTranslation();
  const [canKick, setCanKick] = useState(false);
  const [canKickNoMinutesLeft, setCanKickNoMinutesLeft] = useState(false);
  const KICK_USER_ROBOT = 'kick_user_robot';
  const KICK_NO_MINUTES_LEFT = 'kick_no_minutes_left';

  useEffect(() => {
    checkFeaturePermission(KICK_USER_ROBOT);
    checkFeaturePermission(KICK_NO_MINUTES_LEFT);
  }, []);

  useEffect(() => {
    if (KICK_USER_ROBOT in featurePermission) {
      setCanKick(!!featurePermission[KICK_USER_ROBOT]);
    }
    if (KICK_NO_MINUTES_LEFT in featurePermission) {
      setCanKickNoMinutesLeft(!!featurePermission[KICK_NO_MINUTES_LEFT]);
    }
  }, [featurePermission]);

  const handleTakeRobot = () => {
    takeSelectedRobot();
    getRobotsTaken();
  };

  return (
    <AdditionalButtonsWrapper container spacing={2} item xs={12}>
      {controlledBy ? (
        <>
          <Grid item xs={12}>
            <Badge
              badgeContent={
                <Tooltip
                  title={`${t(
                    'overseer_app.widget.minutes_timeout',
                    'Minutes until timeout'
                  )}: ${minutesLeft}`}
                >
                  <Typography style={{ fontSize: '0.8rem' }}>
                    {minutesLeft}
                  </Typography>
                </Tooltip>
              }
              invisible={minutesLeft === 0}
              color="primary"
              style={{ width: '100%' }}
            >
              <Button
                variant="contained"
                fullWidth
                disabled={isLoadingTakeRobot}
              >
                {t('overseer_app.widgets.controlled_by', 'Controlled by')}:{' '}
                <br />
                {controlledBy}
              </Button>
            </Badge>
          </Grid>
          {(canKick ||
            (canKickNoMinutesLeft &&
              minutesKickLeft === 0)) && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={isLoadingTakeRobot}
                  onClick={() => setOpenKick(true)}
                  style={{ backgroundColor: '#DA433B' }}
                  startIcon={
                    <IconComponent
                      iconName="game-controller-outline"
                      style={{ fontSize: '20px' }}
                    />
                  }
                >
                  {t(
                    'overseer_app.widgets.kick_control',
                    'Kick and take control'
                  )}
                </Button>
              </Grid>
            )}
          {minutesKickLeft > 0 && (
            <Grid item xs={12}>
              <Box>
                <Alert severity="warning">
                  <Typography>{`${t(
                    'overseer_app.widget.minutes_kick_timeout',
                    'Minutes left to kick user'
                  )}: ${minutesKickLeft}`}</Typography>
                </Alert>
              </Box>
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={12}>
          <ControlButton
            variant="contained"
            disabled={isLoadingTakeRobot}
            onClick={handleTakeRobot}
            startIcon={
              <IconComponent
                iconName="game-controller-outline"
                style={{ fontSize: '20px' }}
              />
            }
          >
            <Tooltip
              title={t('overseer_app.widget.take_control_tooltip')}
              enterDelay={500}
            >
              <Typography
                sx={{
                  fontSize: 'small',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {t('overseer_app.wigdet.take_robot', 'Take Control')}
              </Typography>
            </Tooltip>
          </ControlButton>
        </Grid>
      )}
    </AdditionalButtonsWrapper>
  );
}
