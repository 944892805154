import { createReducer } from './utils';
import {
  GET_SERVICING_HISTORY,
  GET_SERVICING_HISTORY_SUCCESS,
  GET_SERVICING_HISTORY_FAILURE,
  GET_CHANGE_MOTIVES,
  GET_CHANGE_MOTIVES_SUCCESS,
  GET_CHANGE_MOTIVES_FAILURE,
  PATCH_CHANGE_REASONS,
  PATCH_CHANGE_REASONS_SUCCESS,
  PATCH_CHANGE_REASONS_FAILURE,
  SNACK_SET,
} from './constants';

const defaultState = {
  loadingServicingHistory: false,
  servicingHistory: [],
  servicingHistoryErrorMessage: null,

  loadingChangeMotives: false,
  changeMotives: [],
  changeMotivesErrorMessage: null,

  loadingPatchChangeReasons: false,
  patchChangeReasonsResponse: {},
  patchChangeReasonsErrorMessage: null,
};

export const reducer = createReducer(defaultState, {
  [GET_SERVICING_HISTORY]: handleGetServicingHistory,
  [GET_SERVICING_HISTORY_SUCCESS]: handleGetServicingHistorySuccess,
  [GET_SERVICING_HISTORY_FAILURE]: handleGetServicingHistoryFailure,

  [GET_CHANGE_MOTIVES]: handleGetChangeMotives,
  [GET_CHANGE_MOTIVES_SUCCESS]: handleGetChangeMotivesSuccess,
  [GET_CHANGE_MOTIVES_FAILURE]: handleGetChangeMotivesFailure,

  [PATCH_CHANGE_REASONS]: handlePatchChangeReasons,
  [PATCH_CHANGE_REASONS_SUCCESS]: handlePatchChangeReasonsSuccess,
  [PATCH_CHANGE_REASONS_FAILURE]: handlePatchChangeReasonsFailure,
});

function handleGetServicingHistory(state) {
  return {
    ...state,
    loadingServicingHistory: true,
    servicingHistoryErrorMessage: null,
  };
}

function handleGetServicingHistorySuccess(
  state,
  { payload: { servicingHistory } }
) {
  return {
    ...state,
    servicingHistory,
    loadingServicingHistory: false,
    servicingHistoryErrorMessage: null,
  };
}

function handleGetServicingHistoryFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingServicingHistory: false,
    servicingHistoryErrorMessage: error,
  };
}

export function getServicingHistory({ robot_id }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_SERVICING_HISTORY });
    try {
      const servicingHistory = await dataSource.getServicingHistory({
        robot_id,
      });

      dispatch({
        type: GET_SERVICING_HISTORY_SUCCESS,
        payload: { servicingHistory },
      });
    } catch (error) {
      dispatch({
        type: GET_SERVICING_HISTORY_FAILURE,
        payload: { error },
      });
    }
  };
}

function handleGetChangeMotives(state) {
  return {
    ...state,
    loadingChangeMotives: true,
    changeMotivesErrorMessage: null,
  };
}

function handleGetChangeMotivesSuccess(state, { payload: { changeMotives } }) {
  return {
    ...state,
    changeMotives,
    loadingChangeMotives: false,
    changeMotivesErrorMessage: null,
  };
}

function handleGetChangeMotivesFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingChangeMotives: false,
    changeMotivesErrorMessage: error,
  };
}

export function getChangeMotives() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_CHANGE_MOTIVES });
    try {
      const changeMotives = await dataSource.getChangeMotives();

      dispatch({
        type: GET_CHANGE_MOTIVES_SUCCESS,
        payload: { changeMotives },
      });
    } catch (error) {
      dispatch({
        type: GET_CHANGE_MOTIVES_FAILURE,
        payload: { error },
      });
    }
  };
}

function handlePatchChangeReasons(state) {
  return {
    ...state,
    loadingPatchChangeReasons: true,
  };
}

function handlePatchChangeReasonsSuccess(
  state,
  { payload: { patchChangeReasonsResponse } }
) {
  return {
    ...state,
    loadingPatchChangeReasons: false,
    patchChangeReasonsResponse,
  };
}

function handlePatchChangeReasonsFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingPatchChangeReasons: false,
    patchChangeReasonsErrorMessage: error,
  };
}

export function patchChangeReasons({
  servicing_id,
  change_motives,
  change_reasons,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    let snack;
    dispatch({ type: PATCH_CHANGE_REASONS });
    try {
      const patchChangeReasonsResponse = await dataSource.patchChangeReasons({
        servicing_id,
        change_motives,
        change_reasons,
      });
      dispatch({
        type: PATCH_CHANGE_REASONS_SUCCESS,
        payload: { patchChangeReasonsResponse },
      });
      snack = {
        open: true,
        message: 'Change Motive and Reasons updated successfully',
        severity: 'success',
      };
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: PATCH_CHANGE_REASONS_FAILURE,
        payload: { error },
      });
      snack = {
        open: true,
        message:
          'There was a problem with update of the change reasons or motives',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}
