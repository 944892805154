import { createReducer } from "./utils";
import {
  GET_ALL_ALERT_POLICIES,
  GET_ALL_ALERT_POLICIES_FAILURE,
  GET_ALL_ALERT_POLICIES_SUCCESS,
} from "./constants";

const defaultState = {
  loadingAllAlertPolicies: false,
  allAlertPolicies: [],
  allAlertPoliciesErrorMessage: null,
};

export const reducer = createReducer(defaultState, {
  [GET_ALL_ALERT_POLICIES]: handleGetAllAlertPolicies,
  [GET_ALL_ALERT_POLICIES_SUCCESS]: handleGetAllAlertPoliciesSuccess,
  [GET_ALL_ALERT_POLICIES_FAILURE]: handleGetAllAlertPoliciesFailure,
});

function handleGetAllAlertPolicies(state) {
  return {
    ...state,
    loadingAllAlertPolicies: true,
    allAlertPoliciesErrorMessage: null,
  };
}

function handleGetAllAlertPoliciesSuccess(
  state,
  { payload: { allAlertPolicies } }
) {
  return {
    ...state,
    allAlertPolicies,
    loadingAllAlertPolicies: false,
    allAlertPoliciesErrorMessage: null,
  };
}

function handleGetAllAlertPoliciesFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingAllAlertPolicies: false,
    allAlertPoliciesErrorMessage: error,
  };
}

export function getAllAlertPolicies() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_ALL_ALERT_POLICIES });
    try {
      const allAlertPolicies = await dataSource.getAllPolicies();

      dispatch({
        type: GET_ALL_ALERT_POLICIES_SUCCESS,
        payload: { allAlertPolicies },
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_ALERT_POLICIES_FAILURE,
        payload: { error },
      });
    }
  };
}

export function addAlertPolicy({ project_id, policy_data }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      const newAlertPolicy = await dataSource.addAlertPolicy({
        project_id,
        policy_data,
      });

      return newAlertPolicy;
    } catch (error) {
      return error;
    }
  };
}

export function deleteAlertPolicy({ project_id, policy_name }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      const response = await dataSource.deleteAlertPolicy({
        project_id,
        policy_name,
      });

      return response;
    } catch (error) {
      return error;
    }
  };
}

export function editAlertPolicy({ project_id, policy_data, policy_name }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      const newAlertPolicy = await dataSource.editAlertPolicy({
        project_id,
        policy_data,
        policy_name,
      });

      return newAlertPolicy;
    } catch (error) {
      return error;
    }
  };
}
