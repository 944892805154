import { createReducer } from './utils';
import {
  handleRequest,
  handleAvailable,
  handleUnavailable,
} from '../reducerHandlers';
import {
  SNACK_SET,
  ROBOT_SERVICING_REQUEST,
  ROBOT_SERVICING_AVAILABLE,
  ROBOT_SERVICING_UNAVAILABLE,
} from './constants';

const defaultState = {
  changeMotives: [],
  robotComponents: [],
  loadingNewServicing: false,
  newServicingResponse: {}
};

export const reducer = createReducer(defaultState, {
  [ROBOT_SERVICING_REQUEST]: handleRequest,
  [ROBOT_SERVICING_AVAILABLE]: handleAvailable,
  [ROBOT_SERVICING_UNAVAILABLE]: handleUnavailable,
});


export function getChangeMotives() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingChangeMotives';
    const keyState = 'changeMotives';
    dispatch({ type: ROBOT_SERVICING_REQUEST, payload: { loadingName } });
    try {
      const changeMotivesResponse = await dataSource.getChangeMotives();
      const changeMotives = changeMotivesResponse ? changeMotivesResponse : [];
      dispatch({
        type: ROBOT_SERVICING_AVAILABLE,
        payload: { keyState, loadingName, data: changeMotives },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_SERVICING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getRobotComponents(robot_code) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingRobotComponents';
    const keyState = 'robotComponents';
    dispatch({ type: ROBOT_SERVICING_REQUEST, payload: { loadingName } });
    try {
      const robotComponentsResponse = await dataSource.getRobotComponents(robot_code);
      const robotComponents = robotComponentsResponse ? robotComponentsResponse : [];
      dispatch({
        type: ROBOT_SERVICING_AVAILABLE,
        payload: { keyState, loadingName, data: robotComponents },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_SERVICING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function postNewServicing(component_name, componentId, componentModel, componentSerialNumber, robot_code, date, motive, metadata, comments) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingNewServicing';
    const keyState = 'newServicingResponse';
    let snack;
    dispatch({ type: ROBOT_SERVICING_REQUEST, payload: { loadingName } });
    try {
      const creationResponse = await dataSource.postServicing(component_name, componentId, componentModel, componentSerialNumber, robot_code, date, motive, JSON.stringify(metadata), comments);
      const creation = creationResponse ? creationResponse : {}
      dispatch({
        type: ROBOT_SERVICING_AVAILABLE,
        payload: { data: creation, keyState, loadingName },
      });
      snack = {
        open: true,
        message: "Servicing was created",
        severity: "success",
      };
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_SERVICING_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      snack = {
        open: true,
        message: "There was a problem creating the servicing",
        severity: "error",
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}
