/**
 * Validate if the variable is empty or undefined
 * @param  	{Object} 				variable 		Variable to be validated
 * @param  	{String} 				type 				Type of the variable
 * @return  {Boolean}									  True if the variable is empty or undefined
 */
export function isEmptyOrUndefined(variable, type) {
  let isEmptyOrUndefined = true;
  if (variable) {
    switch (type) {
      case 'array':
        isEmptyOrUndefined = variable.length <= 0;
        break;
      case 'dict':
        isEmptyOrUndefined = Object.keys(variable).length <= 0;
        break;
      case 'id': // An id can be 0
        isEmptyOrUndefined = variable === null || variable === undefined || variable === '';
        break;
      case 'falsy': // To check if the variable is null, undefined, 0, false, NaN, empty string, etc.
        isEmptyOrUndefined = !variable;
        break;
      default:
        break;
    }
  }
  return isEmptyOrUndefined
}