import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';
import AlertBox from '../../tools/AlertBox';
import LineChartCard from '../../tools/LineChartCard';
import TabPanel from '../../tools/TabPanel';
import TrackerCard from '../../tools/TrackerCard';
import { a11yProps } from '../../Utils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  p: 2,
  maxHeight: '90%',
  overflowY: 'auto',
};

const InfoRow = (props) => {
  const { data, name, handleChart, isLoading } = props;
  const [openDetail, setOpenDetail] = useState(false);

  const iconComponentState = (data) => {
    if (data.length > 0) {
      if (data.every((val) => val[1] !== false)) {
        return <CheckCircleIcon sx={{ fontSize: '20px', color: '#0E7F19' }} />;
      } else {
        return <CancelIcon color="error" sx={{ fontSize: '20px' }} />;
      }
    } else return <ErrorIcon color="warning" sx={{ fontSize: '20px' }} />;
  };

  return (
    <Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          p: 2,
        }}
      >
        {data.length === 1 ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              width: '100%',
            }}
          >
            {iconComponentState(data)}
            <Typography>{data[0][0]}</Typography>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <IconButton
                disabled={isLoading}
                aria-label="chart_icon"
                size="small"
                onClick={() =>
                  handleChart({
                    statusKey: data[0][0],
                    statusType: 'boolean',
                  })
                }
              >
                <IconComponent iconName="stats-chart" />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {iconComponentState(data)}
              <Typography>{name}</Typography>
            </Box>
            {openDetail ? (
              <KeyboardArrowDownIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => setOpenDetail(!openDetail)}
              />
            ) : (
              <KeyboardArrowRightIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => setOpenDetail(!openDetail)}
              />
            )}
          </Box>
        )}
      </Box>
      {openDetail ? (
        <Box sx={{ marginLeft: 5 }}>
          {data.map((info, idx) => (
            <Box
              key={idx}
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                p: 1,
              }}
            >
              {typeof info[1] === 'boolean' ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    width: '100%',
                  }}
                >
                  {info[1] ? (
                    <CheckCircleIcon
                      sx={{ fontSize: '20px', color: '#0E7F19' }}
                    />
                  ) : (
                    <CancelIcon color="error" sx={{ fontSize: '20px' }} />
                  )}
                  <Typography>{info[0]}</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <IconButton
                      disabled={isLoading}
                      aria-label="chart_icon"
                      size="small"
                      onClick={() =>
                        handleChart({
                          statusKey: `${info[0]}_${name}`,
                          statusType: 'boolean',
                        })
                      }
                    >
                      <IconComponent iconName="stats-chart" />
                    </IconButton>
                  </Box>
                </Box>
              ) : (
                <>
                  {`${info[0]}: ${info[1]}`}
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <IconButton
                      disabled={isLoading}
                      aria-label="chart_icon"
                      size="small"
                      onClick={() =>
                        handleChart({
                          statusKey: `${info[0]}_${name}`,
                          statusType: 'number',
                        })
                      }
                    >
                      <IconComponent iconName="analytics" />
                    </IconButton>
                  </Box>
                </>
              )}
            </Box>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

function RobotStatusModal(props) {
  const {
    open,
    setOpen,
    data,
    rawData,
    robotCode,
    robotStatusHistory,
    getRobotStatusHistory,
    isLoadingRobotStatusHistory,
  } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();
  const [statusInfo, setStatusInfo] = useState(null);
  const [currentBooleanData, setCurrentBooleanData] = useState(null);
  const [timeFrame, setTimeFrame] = useState({ deltaTime: 1, params: {} });

  useEffect(() => {
    if (statusInfo && robotStatusHistory) {
      const currentBooleanData = robotStatusHistory?.boolean_data?.map(
        (item) => {
          if (item[statusInfo.statusKey]) {
            return {
              ...item[statusInfo.statusKey],
              tooltip: `${item[statusInfo.statusKey]?.tooltip} ${format(
                parseISO(`${item.timestamp}Z`),
                'yyyy-MM-dd HH:mm:ss'
              )}`,
            };
          } else {
            return { color: '', tooltip: '' };
          }
        }
      );
      setCurrentBooleanData(currentBooleanData);
    }
  }, [statusInfo, robotStatusHistory]);

  useEffect(() => {
    if (
      robotCode &&
      !isLoadingRobotStatusHistory &&
      open &&
      timeFrame.deltaTime
    ) {
      getRobotStatusHistory({
        robotCode: robotCode,
        hoursBefore: timeFrame.deltaTime,
      });
    }
  }, [timeFrame.deltaTime, open]);

  const handleChart = (statusInfo) => {
    setStatusInfo({
      statusKey: statusInfo.statusKey,
      statusType: statusInfo.statusType,
    });
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleOnClose = () => {
    setOpen(false);
    setStatusInfo(null);
    setCurrentBooleanData(null);
    setTimeFrame({ deltaTime: 1, params: {} });
  };

  return (
    <Modal open={open} onClose={handleOnClose}>
      <Box sx={style}>
        <Typography fontSize={20} marginLeft={1} fontWeight={'bold'}>
          {t('overseer_app.widget.robot_status', 'Robot Status')}
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label={'Principal'} {...a11yProps(0, {})} />
            <Tab label={'All Info'} {...a11yProps(1, {})} />
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={0}>
          <Grid container>
            <Grid item xs={12} sx={{ overflowY: 'auto', maxHeight: 320 }}>
              {data ? (
                data.map((info, idx) => (
                  <InfoRow
                    isLoading={isLoadingRobotStatusHistory}
                    data={info['data']}
                    name={info['name']}
                    key={idx}
                    handleChart={handleChart}
                  />
                ))
              ) : (
                <Box>
                  <Typography sx={{ m: 1 }}>No info available...</Typography>
                </Box>
              )}
            </Grid>
            <Divider sx={{ mt: 2, width: '100%' }} />
            <Grid
              item
              xs={12}
              sx={{
                overflowY: 'auto',
                minHeight: statusInfo?.statusType === 'boolean' ? 230 : 300,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {isLoadingRobotStatusHistory ? (
                <AlertBox
                  severity="info"
                  sx={{ mt: 2 }}
                  content={
                    <LinearProgress
                      color="inherit"
                      sx={{ mt: 1, borderRadius: '5px', minWidth: '28em' }}
                    />
                  }
                />
              ) : statusInfo && robotStatusHistory ? (
                statusInfo.statusType === 'boolean' ? (
                  <TrackerCard
                    sx={{ width: '100%', boxShadow: 0, mt: 0 }}
                    title={t(
                      'overseer_app.widget.historic_robot_status',
                      'Historic Robot Status'
                    )}
                    subtitle={`${statusInfo.statusKey} Status History from Cloud Logging`}
                    leftText={t(
                      `overseer_app.widget.${statusInfo.statusKey}`,
                      statusInfo.statusKey
                    )}
                    rightTest="Uptime"
                    trackerData={currentBooleanData}
                    isLoading={isLoadingRobotStatusHistory}
                    timeFrame={timeFrame}
                    setTimeFrame={setTimeFrame}
                  />
                ) : (
                  <LineChartCard
                    sx={{ width: '100%', boxShadow: 0, mt: 0 }}
                    title={t(
                      'overseer_app.widget.historic_robot_status',
                      'Historic Robot Status'
                    )}
                    subtitle="Robot Status History from Cloud Logging"
                    inputIndex={'timestamp'}
                    inputCategories={[statusInfo.statusKey]}
                    lineData={robotStatusHistory.numeric_data}
                    isLoading={isLoadingRobotStatusHistory}
                    timeFrame={timeFrame}
                    setTimeFrame={setTimeFrame}
                  />
                )
              ) : robotStatusHistory?.boolean_data?.length ? (
                <AlertBox
                  severity="info"
                  sx={{ mt: 2 }}
                  content={t(
                    'overseer_app.widget.select_chart_data',
                    'Select a status to see the chart data'
                  )}
                />
              ) : (
                <AlertBox
                  severity="warning"
                  sx={{ mt: 2 }}
                  content={t(
                    'overseer_app.widget.no_chart_data',
                    'No chart data'
                  )}
                />
              )}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <Grid container>
            <Grid item xs={12} sx={{ overflowY: 'auto', maxHeight: 300 }}>
              {/* <JSONTree data={rawData} invertTheme={false} /> */}\
              <ReactJson
                src={rawData}
                sortKeys
                collapsed={1}
                displayDataTypes={false}
                displayObjectSize={false}
                name={false}
                quotesOnKeys={false}
                style={{ fontSize: '12px' }}
                iconStyle="circle"
              />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Modal>
  );
}

export default RobotStatusModal;
