import { Box, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RobotLayouts from '../../../redux/containers/layouts/RobotLayouts';
import MultiSelector from '../../tools/MultiSelector';

export default function ScanningForm(props) {
  const {
    inputSelectedAisles = [],
    inputSelectedZones = [],
  } = props;
  const [ohMode, setOhMode] = useState('OFF');
  const [selectedLayer, setSelectedLayer] = useState('aisles');
  const [selectedAisles, setSelectedAisles] = useState(inputSelectedAisles);
  const [aisleOptions, setAisleOptions] = useState([]);

  const [selectedZones, setSelectedZones] = useState(inputSelectedZones);
  const [zonesOptions, setZonesOptions] = useState([]);

  const { t } = useTranslation();
  const OH_OPTIONS = [
    { value: 'ON', label: 'ON' },
    { value: 'OFF', label: 'OFF' },
    { value: 'PRIORITY', label: 'PRIORITY' },
  ];
  const LAYOUTS_LAYERS = [
    { name: 'zones', label: 'Zones' },
    { name: 'aisles', label: 'Aisles' },]

  // Hooks

  // Handlers
  const handleOptions = (targetList, setOptions) => {
    // TODO: Add the field isInThePlan to aisles by session info
    let targetSorted = targetList.sort((a, b) => {
      if (a.excluded && !b.excluded) return 1;
      if (!a.excluded && b.excluded) return -1;
      if (a.isInThePlan && !b.isInThePlan) return -1;
      if (!a.isInThePlan && b.isInThePlan) return 1;
      return 0;
    });
    targetSorted = targetSorted.map((aisle) => {
      return {
        ...aisle,
        secondary: aisle.excluded ? 'Aisle excluded by layout' : aisle.client_name ? `Client name: ${aisle.client_name}` : '',
      }
    });
    setOptions(targetSorted);
  }

  const handleChangeSelectScanMode = (event) => {
    setOhMode(event.target.value);
  };

  const handleSelectedAisles = (aisleList) => {
    const aisles = aisleOptions.filter((aisle) => aisleList.includes(aisle.id));
    setSelectedAisles(aisles);
  }

  const handleSelectedZones = (zoneList) => {
    const zones = zonesOptions.filter((zone) => zoneList.includes(zone.id));
    setSelectedZones(zones);
  }

  const handleLayers = (event) => {
    const newLayer = event.target.value;
    if (newLayer !== 'aisles') {
      setSelectedAisles([]);
    } else if (newLayer !== 'zones') {
      setSelectedZones([]);
    }
    setSelectedLayer(newLayer);
  };

  return (
    <>
      <Grid container sx={{ mt: 3, height: '100%' }} columnSpacing={8}>
        <Grid item xs={'auto'} sx={{ maxHeight: 'calc(400px - 1.2em)', overflow: 'auto' }}>
          <RobotLayouts
            onChangeAisles={(aisles) => handleOptions(aisles, setAisleOptions)}
            onChangeZones={(zones) => handleOptions(zones, setZonesOptions)}
            layersDisplay={[selectedLayer]}
            highlightedBoxes={selectedLayer === 'aisles' ? selectedAisles : selectedZones}
          />
        </Grid>
        <Grid item xs sx={{ mt: 1 }}>
          <Grid container rowSpacing={4}>
            {/* OH mode */}
            <Grid item xs={12}>
              <FormControl sx={{ minWidth: '35%' }}
              >
                <InputLabel id="oh_mode-select-label">{t('overseer_app.scheduler.OH_Mode', 'OH Mode')}</InputLabel>
                <Select
                  labelId="oh_mode-select-label"
                  id="oh_mode-select-label"
                  value={ohMode}
                  label="OH Mode"
                  onChange={handleChangeSelectScanMode}
                >
                  {
                    OH_OPTIONS.map((item) => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {/* LAYERS */}
            <Grid item xs={12}>
              <FormControl>
                <Typography variant="subtitle2">Layers</Typography>
                <RadioGroup row value={selectedLayer} onChange={handleLayers}>
                  {LAYOUTS_LAYERS.map((item) => (
                    <FormControlLabel
                      value={item.name}
                      control={<Radio />}
                      label={item.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            {/* AISLES */}
            <Grid item xs={selectedLayer === 'aisles' ? 6 : 0} sx={{ display: selectedLayer === 'aisles' ? 'block' : 'none' }}>
              {
                !aisleOptions?.[0]?.name ?
                  <Box sx={{ border: 'solid 1px #CCC', borderRadius: '3px', p: 1, width: '35%' }}>
                    <Typography variant="caption" sx={{ fontSize: 'small', color: '#888' }}>No aisles available</Typography>
                  </Box>
                  :
                  <>
                    <MultiSelector
                      id='scanning-aisles'
                      options={aisleOptions}
                      isDisabled={aisleOptions.length === 0}
                      inputSelected={selectedAisles.map((aisle) => aisle.name)}
                      setInputSelectedOptions={handleSelectedAisles}
                      inputLabel={'Aisles'}
                      objectName={'name'}
                      objectId={'id'}
                      maxLabelLength={6}
                    />
                    <Typography variant="caption" sx={{ fontSize: 'small', color: '#888' }}>*Only activated aisles in layout available for selection</Typography>
                  </>
              }
            </Grid>
            {/* ZONES */}
            <Grid item xs={selectedLayer === 'zones' ? 6 : 0} sx={{ display: selectedLayer === 'zones' ? 'block' : 'none' }}>
              {
                !zonesOptions?.[0]?.name ?
                  <Box sx={{ border: 'solid 1px #CCC', borderRadius: '3px', p: 1, width: '35%' }}>
                    <Typography variant="caption" sx={{ fontSize: 'small', color: '#888' }}>No zones available</Typography>
                  </Box>
                  :
                  <>
                    <MultiSelector
                      id='scanning-zones'
                      options={zonesOptions}
                      isDisabled={zonesOptions.length === 0}
                      inputSelected={selectedZones.map((zone) => zone.name)}
                      setInputSelectedOptions={handleSelectedZones}
                      inputLabel={'Zones'}
                      objectName={'name'}
                      objectId={'id'}
                      maxLabelLength={6}
                    />
                    <Typography variant="caption" sx={{ fontSize: 'small', color: '#888' }}>*Only activated zones in layout available for selection</Typography>
                  </>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ with: '80%!important', mt: 1 }} />
        </Grid>
      </Grid>
    </>
  );
}
