import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import {
    Box,
    Button,
    Card,
    CardHeader,
    Checkbox,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material';
import Selector from '../../tools/Selector';

const statusOptions = [{ name: 'active' }, { name: 'unimplemented' }, { name: 'retired' }, { name: 'servicing' }]

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export default function RobotStatusTransferList(props) {
    const {
        getRobotsStatus,
        robotsStatus,
        loadingRobotsStatus,
        updateRobotsStatus,
        updatingRobotStatus
    } = props;
    const { t } = useTranslation();
    const [checked, setChecked] = useState([]);
    const [right, setRight] = useState([])
    const [left, setLeft] = useState([])
    const [rightStatus, setRightStatus] = useState({ name: 'unimplemented' })
    const [leftStatus, setLeftStatus] = useState({ name: 'active' })

    const [openSublistLeft, setOpenSublistLeft] = useState([]);
    const [openSublistRight, setOpenSublistRight] = useState([]);
    const [inputValueLeft, setInputValueLeft] = useState('');
    const [inputValueRight, setInputValueRight] = useState('');

    // Lifecycle methods

    useEffect(() => {
        if (left) {
            setOpenSublistLeft(left.map(() => false));
        }
    }, [left]);

    useEffect(() => {
        if (right) {
            setOpenSublistRight(right.map(() => false));
        }
    }, [right]);

    useEffect(() => {
        getRobotsStatus()
    }, []);

    useEffect(() => {
        if (robotsStatus) {
            let filteredItemsLeft = robotsStatus?.filter(chain => chain.robots.length > 0);
            filteredItemsLeft = filteredItemsLeft?.map(chain => {
                const filteredRobotsLeft = chain?.robots?.filter(robot => {
                    return (robot.status_id === leftStatus.name);
                })
                return {
                    ...chain,
                    robots: filteredRobotsLeft,
                }
            })

            setRight([])

            setLeft(filteredItemsLeft)
        }
    }, [robotsStatus, leftStatus, rightStatus])

    // Methods
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    // Toggle all robots in the list
    const handleToggleAll = (items) => {
        const newChecked = [...checked];
        const isAllChecked = checkChains(items);
        items.forEach(item => {
            if (isAllChecked) {
                item?.robots?.forEach(robot => {
                    const currentIndex = newChecked.indexOf(robot);
                    if (currentIndex !== -1) {
                        newChecked.splice(currentIndex, 1);
                    }
                })
            } else {
                item?.robots?.forEach(robot => {
                    const currentIndex = newChecked.indexOf(robot);
                    if (currentIndex === -1) {
                        newChecked.push(robot);
                    }
                })
            }
        })
        setChecked(newChecked);
    };

    // Check/uncheck all robots in all chains (add/remove all robots from the list 'checked')
    const handleChainToggle = (value) => {
        let newChecked = [...checked];
        const isAllChecked = checkRobots(value);
        if (isAllChecked) {
            value?.robots?.forEach(robot => {
                const currentIndex = newChecked.indexOf(robot);
                if (currentIndex !== -1) {
                    newChecked.splice(currentIndex, 1);
                }
            })
        } else {
            value?.robots?.forEach(robot => {
                const currentIndex = newChecked.indexOf(robot);
                if (currentIndex === -1) {
                    newChecked.push(robot);
                }
            })
        }
        setChecked(newChecked);
    }

    // Check if all robots are checked in all chains
    const checkChains = (items) => {
        const isAllChecked = items?.map(chain => {
            return checkRobots(chain);
        });
        if (isAllChecked.includes(false) || isAllChecked.length === 0) {
            return false;
        }
        return true;
    }

    // Check if all robots are checked in the chain, i.e. the store is in the list 'checked'
    const checkRobots = (value) => {
        const isAllChecked = value?.robots?.every(robot => checked.includes(robot));
        return isAllChecked;
    }

    // Send items to the right list
    const handleCheckedRight = () => {
        const newLeft = []
        const newRight = right

        left.forEach(chain => {
            const robotsToAdd = []
            const robotsToLeave = []
            // Differentiate between robots that are checked and robots that are not
            chain.robots.forEach(robot => {
                if (checked.includes(robot)) {
                    const newRobot = {
                        chain: chain.chain,
                        chain_id: robot.chain_id,
                        code: robot.code,
                        name: robot.name,
                        store_code: robot.store_code,
                        status_id: rightStatus // ACA VA EL ESTADO NUEVO CREO
                    }
                    robotsToAdd.push(newRobot);
                } else {
                    robotsToLeave.push(robot);
                }
            });
            if (robotsToAdd.length > 0) {
                // Get the index of the chain in the right list (if it exists)
                let rightIndex = -1
                newRight.forEach((rightChain, index) => {
                    if (rightChain.chain === chain.chain) {
                        rightIndex = index;
                    }
                });
                // If the chain is already in the right list, add the robots to the existing chain
                if (rightIndex != null && rightIndex !== -1) {
                    const newChain = {
                        ...chain,
                        robots: robotsToAdd.concat(newRight[rightIndex].robots),
                    }
                    newRight[rightIndex] = newChain;
                } else {
                    newRight.push({
                        ...chain,
                        robots: robotsToAdd,
                    });
                }
            }
            if (robotsToLeave.length > 0) {
                newLeft.push({
                    ...chain,
                    robots: robotsToLeave,
                });
            }
        });

        setRight(newRight);
        setLeft(newLeft);
        setChecked([]);
    };

    // Send items to the left list
    const handleCheckedLeft = () => {
        const newRight = []
        const newLeft = left

        right.forEach(chain => {
            const robotsToAdd = []
            const robotsToLeave = []
            // Differentiate between stores that are checked and stores that are not
            chain.robots.forEach(robot => {
                if (checked.includes(robot)) {
                    const newRobot = {
                        chain: chain.chain,
                        chain_id: robot.chain_id,
                        code: robot.code,
                        name: robot.name,
                        store_code: robot.store_code,
                        status_id: leftStatus
                    }
                    robotsToAdd.push(newRobot);
                } else {
                    robotsToLeave.push(robot);
                }
            });
            if (robotsToAdd.length > 0) {
                // Get the index of the chain in the left list (if it exists)
                let leftIndex = -1
                newLeft.forEach((leftChain, index) => {
                    if (leftChain.chain === chain.chain) {
                        leftIndex = index;
                    }
                });
                // If the chain is already in the left list, add the stores to the existing chain
                if (leftIndex != null && leftIndex !== -1) {
                    const newChain = {
                        ...chain,
                        robots: robotsToAdd.concat(newLeft[leftIndex].robots),
                    }
                    newLeft[leftIndex] = newChain;
                } else {
                    newLeft.push({
                        ...chain,
                        robots: robotsToAdd,
                    });
                }
            }
            if (robotsToLeave.length > 0) {
                newRight.push({
                    ...chain,
                    robots: robotsToLeave,
                });
            }
        });

        setLeft(newLeft);
        setRight(newRight);
        setChecked([]);
    };

    // Open and close sublists
    const handleOpenSublist = (e, index, openSublist, setOpenSublist) => {
        e.stopPropagation();
        const newOpenSublists = [...openSublist];
        newOpenSublists[index] = !openSublist[index];
        setOpenSublist(newOpenSublists);
    }

    // Intersect 'left' and 'checked', where left is the list of chains and checked is the list of stores
    const leftChecked = () => {
        const leftCheckedList = []
        left?.forEach(chain => {
            const checkedRobots = intersection(checked, chain?.robots);
            if (checkedRobots?.length > 0) {
                leftCheckedList.push(...checkedRobots);
            }
        });
        return leftCheckedList
    }

    // Intersect 'right' and 'checked', where right is the list of chains and checked is the list of stores
    const rightChecked = () => {
        const rightCheckedList = []
        right?.forEach(chain => {
            const checkedRobots = intersection(checked, chain?.robots);
            if (checkedRobots?.length > 0) {
                rightCheckedList.push(...checkedRobots);
            }
        });
        return rightCheckedList
    }

    const customList = (items, status, openSublist, setOpenSublist, inputValue, setInputValue) => {
        const numberOfRobots = items?.reduce((acc, chain) => {
            return acc + chain?.robots?.length;
        }, 0);
        const numberOfCheckedRobots = items?.reduce((acc, chain) => {
            return acc + chain?.robots?.filter(robot => checked.includes(robot))?.length;
        }, 0);
        // Filter the stores in items based on inputValue
        let filteredItems = items?.filter(chain => chain.robots.length > 0);
        filteredItems = filteredItems?.map(chain => {
            const filteredRobots = chain?.robots?.filter(robot => {
                return ((robot?.name?.toLowerCase().includes(inputValue?.toLowerCase()) ||
                    robot?.code?.toLowerCase().includes(inputValue?.toLowerCase()) ||
                    robot?.chain?.toLowerCase().includes(inputValue?.toLowerCase())));
            })
            return {
                ...chain,
                robots: filteredRobots,
            }
        })


        return (
            <Card>
                {/* Select all checkbox and display number of selected items by list */}
                <CardHeader
                    sx={{ px: 2, py: 1 }}
                    avatar={
                        <Checkbox
                            onClick={() => handleToggleAll(filteredItems)}
                            checked={checkChains(filteredItems)}
                            disabled={filteredItems.length === 0}
                            inputProps={{
                                'aria-label': 'all items selected',
                            }}
                        />
                    }
                    subheader={`${numberOfCheckedRobots}/${numberOfRobots} ${t('cws_app.general.stores_selected', 'stores selected')}`}
                />
                <Divider />
                {/* Search bar */}
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <TextField
                        variant='standard'
                        id='search'
                        label={t('cws_app.general.search', 'Search')}
                        value={inputValue}
                        onChange={(event) => {
                            setInputValue(event.target.value);
                        }}
                        sx={{ mt: '0.5em', width: '90%' }}
                    />

                </Box>

                <List
                    sx={{
                        width: 400,
                        height: 230,
                        bgcolor: 'background.paper',
                        overflow: 'auto',
                    }}
                    dense
                    component="div"
                    role="list"
                >
                    {/* Chains loop */}
                    {filteredItems.map((value, index) => {
                        if (value?.robots?.length === 0) return null; // If the chain has no stores, don't display it
                        const labelId = `transfer-list-all-item-${value['chain_country_id']}-label`;
                        return (
                            <Box key={Math.random()}>
                                <ListItemButton
                                    key={labelId}
                                    role="listitem"
                                    onClick={() => handleChainToggle(value)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={checkRobots(value)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={`${value['chain']}`} />
                                    <IconButton
                                        aria-label="expand"
                                        onClick={(e) => handleOpenSublist(e, index, openSublist, setOpenSublist)}
                                    >
                                        <IconComponent
                                            iconName={openSublist[index] ? 'chevron-up' : 'chevron-down'}
                                            style={{ fontSize: '20px' }}
                                        />
                                    </IconButton>
                                </ListItemButton>
                                {/* Stores loop */}
                                {openSublist[index] &&
                                    value?.robots.map((robot) => {
                                        const labelId = `transfer-list-all-item-${robot['code']}-label`;
                                        return (
                                            <ListItemButton
                                                key={robot['code']}
                                                role="listitem"
                                                onClick={handleToggle(robot)}
                                                sx={{ marginLeft: '3em' }}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={checked.indexOf(robot) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={`[${robot['code']}]: ${robot['name']}`} />
                                            </ListItemButton>
                                        )
                                    })
                                }
                            </Box>
                        );
                    })}
                </List>
            </Card>
        )
    };

    const sendNewStatus = () => {
        updateRobotsStatus({ status_id: rightStatus.name, chains: JSON.stringify(right) })
    }

    if (loadingRobotsStatus || !robotsStatus) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', my: 10, gap: 1 }}>
                <CircularProgress />
                <Typography>Loading...</Typography>

            </Box>
        )
    }

    return (
        <Card sx={{ width: '100%', }}>

            <Grid container item justifyContent='center' alignItems='center' gap={5} sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' }, overflowY: 'scroll', p: 2 }}>
                <Grid item>
                    <Grid container direction='column' alignItems='center'>
                        <Selector options={statusOptions} labelInput={'Actual Robot status'} fullWidth selectedElement={leftStatus} setSelectedElement={setLeftStatus} disableClearable />
                        {customList(left, leftStatus, openSublistLeft, setOpenSublistLeft, inputValueLeft, setInputValueLeft)}
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction='column' alignItems='center'>
                        <Button
                            sx={{ m: 0.5 }}
                            variant='outlined'
                            size='small'
                            onClick={handleCheckedRight}
                            disabled={leftChecked().length === 0}
                            aria-label='move selected right'
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{ m: 0.5 }}
                            variant='outlined'
                            size='small'
                            onClick={handleCheckedLeft}
                            disabled={rightChecked().length === 0}
                            aria-label='move selected left'
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction='column' alignItems='center'>
                        <Selector options={statusOptions} labelInput={'New Robot Status'} fullWidth selectedElement={rightStatus} setSelectedElement={setRightStatus} disableClearable />
                        {customList(right, rightStatus, openSublistRight, setOpenSublistRight, inputValueRight, setInputValueRight)}
                    </Grid>
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'right', m: 1 }}>
                {updatingRobotStatus ? <CircularProgress size={30} /> : <Button disabled={right.length === 0} variant='contained' onClick={sendNewStatus}>Apply </Button>}

            </Box>
        </Card>

    );
}
