import WarningIcon from '@mui/icons-material/Warning';
import { Box, Typography } from '@mui/material';
import React, { useCallback, useRef } from 'react';
import { Canvas, Edge, Node } from 'reaflow';
import NodeContent from './NodeContent';

export default function MonitoringGraph(props) {
  const { nodes, edges, clickable = true, onNodeClick, onEdgeClick } = props;

  const canvasRef = useRef(null);

  const calculatePaneWidthAndHeight = useCallback(() => {
    const svg_doc = document.getElementById(
      canvasRef?.current?.svgRef?.current?.id
    );
    if (svg_doc) {
      svg_doc.style.height = '2500px';
      svg_doc.style.width = '130em';
    }
  }, []);

  const handleHeight = () => {
    const windowInnerHeight = window.innerHeight;
    return `calc(${windowInnerHeight}px - 17em)`;
  }

  return (
    <>
      <style>
        {`
        .edge {
          stroke: green;
          stroke-dasharray: 5;
          animation: dashdraw .5s linear infinite;
          stroke-width: 3;
        }

        .edgeStopped {
          stroke: green;
          stroke-width: 3;
        }

        .errorEdge {
          stroke: red;
          stroke-width: 4;
        }

        .noPoliciesEdge {
          stroke: #F9D949;
          stroke-width: 3;
          stroke-dasharray: 5;
          animation: dashdraw .5s linear infinite;
        }

         .noPoliciesEdgeStopped {
          stroke: #F9D949;
          stroke-width: 3;
         
        }

        @keyframes dashdraw {
          0% { stroke-dashoffset: 10; }
        }
      `}
      </style>
      <Canvas
        className='yScrollBarWrapper-grey'
        ref={canvasRef}
        onLayoutChange={() => {
          calculatePaneWidthAndHeight();
        }}
        nodes={nodes}
        edges={edges}
        direction="DOWN"
        defaultPosition="top left"
        width={'100%'}
        height={handleHeight()}
        pannable={true}
        readonly={true}
        arrow={null}
        // Access to edges in session view
        edge={(edge, idx) => (
          <Edge
            className={
              edge.data.alerts?.length > 0
                ? 'errorEdge'
                : edge.data.policiesConfigurated
                  ? edge.data.stopped
                    ? 'edgeStopped'
                    : 'edge'
                  : edge.data.stopped
                    ? 'noPoliciesEdgeStopped'
                    : 'noPoliciesEdge'
            }
            key={idx}
            onClick={(e, data) => onEdgeClick(data)}
          />
        )}
        node={
          <Node style={{ fill: 'white', strokeWidth: '0' }}>
            {(event, idx) => (
              <foreignObject
                key={idx}
                onClick={(e) => {
                  if (clickable) {
                    onNodeClick(event.node.id, event.node);
                  }
                }}
                display={'flex'}
                height={event.height}
                width={event.width}
                x={0}
                y={0}
              >
                <NodeContent
                  height={event.height}
                  alerts={event.node.alerts}
                  root={event.node.root}
                  id={event.node.id}
                  serviceName={event.node.data.serviceName}
                  isVertex={event.node.data.isVertex}
                  project={event.node.data.projectId}
                  descriptionLines={event.node.data.descriptionLines}
                  backgroundColor={
                    event.node.alerts?.length > 0
                      ? '#FA5F55'
                      : event.node.policiesConfigurated || event.node.root
                        ? event.node.data.backgroundColor
                        : ' #F9D949'
                  }
                  borderColor={
                    event.node.data.borderColor
                      ? event.node.data.borderColor
                      : 'black'
                  }
                  borderWidth={
                    event.node.data.borderWidth
                      ? event.node.data.borderWidth
                      : '1px'
                  }
                >
                  {event.node.alerts?.length > 0 ? (
                    <Box>
                      <WarningIcon sx={{ color: 'white' }} />
                    </Box>
                  ) : null}

                  <Typography fontSize={14}>
                    {event.node.data.displayName}
                  </Typography>
                  <Typography fontSize={14}>
                    {event.node.data.progress}
                  </Typography>
                </NodeContent>
              </foreignObject>
            )}
          </Node>
        }
      />
    </>
  );
}
