import { connect } from 'react-redux';

import { patchChangeReasons } from 'core/slices/servicingHistoryWidget';
import { loadingPatchChangeReasons } from 'core/selectors';

import ChangeReasonsModal from '../../../../components/widgets/Components/ChangeReasonsModal';

export default connect(
	(state) => ({
		loadingPatchChangeReasons: loadingPatchChangeReasons(state),
	}),
	{
		patchChangeReasons,
	}
)(ChangeReasonsModal);
