import { useEffect, useState } from 'react';
import { Container, Divider, Tooltip, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Counter(props) {
  const { data } = props;

  const [activeMonitors, setActiveMonitors] = useState('-');
  const [unattendedRobots, setunattendedRobots] = useState('-');
  const [assignedMonitors, setAssignedMonitors] = useState('-');
  const [unassignedMonitors, setUnassignedMonitors] = useState('-');

  useEffect(() => {
    if (data) {
      setActiveMonitors(data?.active_monitors?.length);
      setunattendedRobots(data?.unattended_robots?.length);
      setAssignedMonitors(data?.assigned_monitors?.length);
      setUnassignedMonitors(data?.unassigned_monitors?.length);
    }
  }, [data]);

  const { t } = useTranslation();

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            {t('overseer_app.metrics.unattended_robots', 'Unattended Robots')}
          </Typography>
          <Typography variant="h4" align="center">
            {unattendedRobots}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            {t('overseer_app.metrics.active_monitors', 'Active Monitors')}
          </Typography>
          <Typography variant="h4" align="center">
            {activeMonitors}
          </Typography>
        </Grid>
        <Tooltip
          title={t(
            'overseer_app.widget.assigned_monitors',
            'Assigned monitors'
          )}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h6" align="center" style={{ color: 'green' }}>
              {t('overseer_app.metrics.assigned', 'Assigned')}
            </Typography>
            <Typography variant="h4" align="center" style={{ color: 'green' }}>
              {assignedMonitors}
            </Typography>
          </Grid>
        </Tooltip>
        <Tooltip
          title={t(
            'overseer_app.widget.unassigned_monitors',
            'Unassigned monitors'
          )}
        >
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              align="center"
              color={'#BE8F15'}
              style={{ color: 'warning' }}
            >
              {t('overseer_app.metrics.unassigned', 'Unassigned')}
            </Typography>
            <Typography
              variant="h4"
              align="center"
              style={{ color: '#BE8F15' }}
            >
              {unassignedMonitors}
            </Typography>
          </Grid>
        </Tooltip>
      </Grid>
    </Container>
  );
}
