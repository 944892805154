import { connect } from 'react-redux';
import RobotLayouts from '../../../components/layouts/RobotLayouts';

import {
  getRobotLayout,
  getBucketMapLayout,
} from 'core/slices/layouts';

import {
  getLocalRobotLayout,
  isLoadingRobotLayout,
  getLocalBucketMapLayout,
  isLoadingBucketMapLayout,
} from 'core/selectors';


export default connect(
  state => ({
    robotLayout: getLocalRobotLayout(state),
    isLoadingRobotLayout: isLoadingRobotLayout(state),
    bucketMapLayout: getLocalBucketMapLayout(state),
    isLoadingBucketMapLayout: isLoadingBucketMapLayout(state),
  }),
  {
    getRobotLayout,
    getBucketMapLayout,
  }
)(RobotLayouts);
