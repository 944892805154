import * as React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Badge, Box, ButtonBase, Grid, IconButton, Tooltip, Typography, useTheme, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import NewSessionDialog from "./Dialogs/NewSessionDialog";
import dayjs from "dayjs";
import MenuSessionTable from "./MenuSessionTable";
import MenuMultiSessionTable from "./MenuMultiSessionTable";
import DeleteSession from "./Dialogs/DeleteSession";
import AddAisles from "./Dialogs/AddAisles";
import { checkViewOnlyPermision } from "../pipelineMonitoring/Utils";
import Confirmation from "./Dialogs/Confirmation";
import { IconComponent } from "@zippeditoolsjs/zippedi-icons";
import { isEmptyOrUndefined } from "core/utils/validators";

import NotFound from '../notFound/NotFound';
import RobotSvg from '../../assets/icons/robot.svg';
import AddPlanForm from './forms/AddPlanForm';

const blueCircle = {
  display: "inline-block",
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  backgroundColor: "#1E76E8",
  color: "white",
  textAlign: "center",
  lineHeight: "30px",
};

function determineOrdinalPosition(number) {
  const cases = {
    1: number + "st",
    2: number + "nd",
    3: number + "rd",
  };
  var lastDigit = number % 10;
  var secondLastDigit = Math.floor(number / 10) % 10;

  if (secondLastDigit === 1) {
    return number + "th";
  }

  if (cases[lastDigit]) {
    return cases[number];
  } else {
    return number + "th";
  }
}

const columns = [
  { id: "store", label: "Store", minWidth: 170 },
  { id: "timeZone", label: "TimeZone", minWidth: 170 },
  { id: "storeName", label: "Store Name", minWidth: 170 },
];

export default function SchedulerTable(props) {
  const {
    currentDay,
    currentWeek,
    currentMonth,
    currentYear,
    selectedDate,
    postNewSessions,
    isLoadingSessionsPost,
    newSessionsResponse,
    selectedRobots,
    navOptions,
    viewId,
    dataRobots,
    handleGetSessionsScheduler,
    postSessionUpdates,
    updateSessionResponse,
    deleteSessions,
    deleteSessionsResponse,
    isLoadingDeleteSessions,
    useLocalTime,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  //table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //to access to the data of the table
  const [dateCellSelected, setDateCellSelected] = useState({
    date: "",
    day: "",
  });
  const [cellSelected, setCellSelected] = useState(null);
  const [sessionSelected, setSessionSelected] = useState(null);
  const [actualRowClicked, setActualRowClicked] = useState(null);
  const [actualSessionClicked, setActualSessionClicked] = useState(null);
  //To open menus from table
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSession, setAnchorElSession] = useState(null);
  //dialogs
  const [dialogType, setDialogType] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openNewSession, setOpenNewSession] = useState(false);
  const [openDeleteSession, setOpenDeleteSession] = useState(false);
  const [openAddAisles, setOpenAddAisles] = useState(false);
  //data
  const [selectedSessions, setSelectedSessions] = useState([]);

  //To open menus from table
  const openSession = Boolean(anchorElSession);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (newSessionsResponse) {
      if (
        newSessionsResponse.message &&
        newSessionsResponse.message === "Success"
      ) {
        handleCloseNewSession();
      }
    }
  }, [newSessionsResponse]);

  useEffect(() => {
    if (updateSessionResponse) {
      if (
        updateSessionResponse.message &&
        updateSessionResponse.message === "Success"
      ) {
        handleCloseNewSession();
      }
    }
  }, [updateSessionResponse]);

  useEffect(() => {
    if (deleteSessionsResponse) {
      if (
        deleteSessionsResponse.message &&
        deleteSessionsResponse.message === "Success"
      ) {
        handleCloseDeleteSession();
      }
    }
  }, [deleteSessionsResponse]);

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleClickOpenNewSession = (type) => {
    setOpenNewSession(true);
    setDialogType(type);
  };

  const handleCloseNewSession = () => {
    setOpenNewSession(false);
    setOpenAddAisles(false);
  };

  const handleDeleteSession = async (sessions_id, date) => {
    setOpenConfirmation(false);
    await deleteSessions(sessions_id, date);
    handleGetSessionsScheduler();
  };

  const handlePostNewSessions = async (
    robots_uuid,
    session_name,
    oh_mode,
    start_local_time,
    aisles,
    active,
    start_date,
    session_type,
    week_days
  ) => {
    await postNewSessions(
      robots_uuid,
      session_name,
      oh_mode,
      start_local_time,
      aisles,
      active,
      start_date,
      session_type,
      week_days
    );
    handleGetSessionsScheduler();
  };

  const handleUpdateSessions = async (
    session_ids,
    oh_mode,
    start_local_time,
    start_date,
    aisles,
    week_days
  ) => {
    await postSessionUpdates(
      session_ids,
      oh_mode,
      start_local_time,
      start_date,
      aisles,
      week_days
    );
    handleGetSessionsScheduler();
  };

  const handleClickOpenDeleteSession = (type) => {
    setOpenDeleteSession(true);
    setDialogType(type);
  };

  const handleCloseDeleteSession = () => {
    setOpenDeleteSession(false);
  };

  const handleClickOpenAddAisles = () => {
    setOpenAddAisles(true);
  };

  const handleCloseAddAisles = () => {
    setOpenAddAisles(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickCell = (event, index, column, row = null) => {
    setCellSelected(index);
    let day = column.day_of_month;
    let month = selectedDate.$M;
    let year = selectedDate.$y;
    const date = dayjs(new Date(year, month, day)).format("YYYY-MM-DD");
    setDateCellSelected({ date: date, day: column.day_of_week, index: index });
  };

  const handleClickSessions = (event, indexSession, column, dayNumber) => {
    if (!checkViewOnlyPermision(navOptions, viewId))
      setAnchorEl(event.currentTarget);

    getSessionIds(indexSession, dayNumber);
  };

  const handleClickSession = (event, index, session, row = null) => {
    if (!checkViewOnlyPermision(navOptions, viewId)) {
      setAnchorElSession(event.currentTarget);
      setSessionSelected(session);
      setActualRowClicked(row);
      setActualSessionClicked(session);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSession(null);
  };

  const getSessionIds = (indexSession, dayNumber) => {
    let sessionIds = [];
    dataRobots.forEach((element) => {
      let aux =
        element.scheduler[currentWeek][dayNumber].sessions[indexSession];
      if (aux.id) sessionIds.push(aux.id);
    });
    setSelectedSessions(sessionIds);
  };

  const badgeTooltipContent = (session) => {
    const { oh_mode } = session;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>{t('overseer_app.general.Details', 'Details')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle2'>{`${t('overseer_app.scheduler.OH_Mode', 'OH Mode')}: ${oh_mode}`}</Typography>
        </Grid>
      </Grid>
    );
  };


  return dataRobots.length === 0 ? (
    <NotFound
      sx={{ pt: 3 }}
      title={'Make a selection to see content'}
      content={'Select Chain and Robot to see scheduled sessions'}
    />
  ) : !selectedRobots?.length ? (
    <NotFound
      sx={{ pt: 3 }}
      title={'Navigation plan not found'}
      content={'We couldn’t find any scheduled plan for selected chain and robots'}
      imgProps={{
        src: RobotSvg,
      }}
    >
      <AddPlanForm />
    </NotFound>
  ) : (
    <Paper sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 600, maxWidth: 1500 }}>
        <Table>
          {/* Table headers*/}
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                {
                  "Current week: " + (currentWeek + 1) // The week is 0 based
                }
              </TableCell>
              {/* Days of the week */}
              {dataRobots[0].scheduler[currentWeek].map((day, index) => {
                // If the day is the current day, it will be highlighted in blue
                const isToday = !isEmptyOrUndefined(day, 'dict') && day.day_of_month === currentDay && currentMonth === selectedDate.$M + 1; // The month is 0 based
                const sx = isToday ? { ...blueCircle } : {};
                return (
                  <TableCell key={day?.date} align={"center"} colSpan={2}>
                    <Box sx={sx}>
                      {Object.keys(day).length !== 0 ? day.day_of_month : " "}
                    </Box>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                {" "}
              </TableCell>
              {/* Names of the days of the week */}
              {dataRobots[0].scheduler[currentWeek].map((weekDay, index) => (
                <TableCell
                  key={weekDay?.day_of_week}
                  align={"center"}
                  colSpan={2}
                  style={{ color: "#FFFFFF", background: "#1a242d", top: 0 }}
                >
                  {Object.keys(weekDay).length !== 0
                    ? weekDay.day_of_week
                    : " "}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {/* 2nd headers (store, timezone, store name) */}
              {columns.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{
                    top: 57,
                    background: "#1a242d",
                    color: "#FFF",
                    width: "1rem",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
              {/* Position of the sessions (1st, 2nd, 3rd, etc.) */}
              {dataRobots[0].scheduler[currentWeek].map((dayWeek, index) =>
                Object.keys(dayWeek).length !== 0 ? (
                  <TableCell
                    key={`${dayWeek?.day_of_month}-${dayWeek?.day_of_week}`}
                    align={"justify"}
                    // maxWidth={100}
                    m={"auto"}
                    style={{
                      top: 57,
                      textAlign: "center",
                      border: "solid 1px",
                      borderColor: "#1a242d",
                      paddingBlock: 0,
                    }}
                    onClick={(event) => handleClickCell(event, index, dayWeek)}
                    colSpan={2}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {dayWeek.sessions.map((session, index_session) => (
                        <div
                          style={{
                            margin: "1rem",
                            width: "2rem",
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                            paddingBlock: 0,
                          }}
                          onClick={(event) =>
                            (dayWeek.day_of_month >= currentDay &&
                              selectedDate.$M + 1 === currentMonth) ||
                              selectedDate.$M + 1 > currentMonth
                              ? handleClickSessions(
                                event,
                                index_session,
                                session,
                                index
                              )
                              : null
                          }
                        >
                          {determineOrdinalPosition(index_session + 1)}
                        </div>
                      ))}
                    </div>
                  </TableCell>
                ) : (
                  <TableCell
                    key={index}
                    colSpan={2}
                    style={{
                      top: 57,
                      textAlign: "center",
                      border: "solid 1px",
                      borderColor: "#1a242d",
                      paddingBlock: 0,
                    }}
                  >
                    {" - "}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          {/* Table body */}
          <TableBody>
            {dataRobots
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow tabIndex={-1} key={row.robot_uuid}>
                    {/* Session info (store, timezone, store name) */}
                    <TableCell
                      align="center"
                      style={{
                        top: 57,
                        background: "#1a242d",
                        color: "#FFF",
                        width: "1rem",
                      }}
                    >
                      {row.store}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        top: 57,
                        background: "#1a242d",
                        color: "#FFF",
                        width: "1rem",
                      }}
                    >
                      {row.timezone}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        top: 57,
                        background: "#1a242d",
                        color: "#FFF",
                        width: "1rem",
                      }}
                    >
                      {row.store_name}
                    </TableCell>
                    {/* Sessions */}
                    {row.scheduler[currentWeek].map((dayWeek, index) =>
                      isEmptyOrUndefined(dayWeek?.sessions, 'array') ?
                        <TableCell
                          key={index}
                          colSpan={2}
                          style={{
                            top: 57,
                            textAlign: "center",
                            border: "solid 1px",
                            borderColor: "#1a242d",
                            paddingBlock: 0,
                          }}
                        >
                          {" - "}
                        </TableCell>
                        :
                        <TableCell
                          key={index}
                          align={"center"}
                          style={{
                            border: "solid 1px",
                            borderColor: "#1a242d",
                          }}
                          onClick={(event) =>
                            handleClickCell(event, index, dayWeek, row)
                          }
                          colSpan={2}
                          sx={{
                            "&:hover": {
                              backgroundColor: "#D9D9D9",
                            },
                          }}
                        >
                          <Grid container spacing={4} justifyContent='center'>
                            {dayWeek?.sessions?.map((session, index_session) => {
                              const isEditable = (dayWeek.day_of_month >= currentDay &&
                                selectedDate.$M + 1 === currentMonth &&
                                selectedDate.$y === currentYear) ||
                                (selectedDate.$M + 1 > currentMonth && selectedDate.$y === currentYear) ||
                                selectedDate.$y > currentYear;
                              return (
                                <Grid item key={index_session}>
                                  {isEmptyOrUndefined(session, 'dict') ?
                                    isEditable ?
                                      <IconButton onClick={(event) => handleClickSession(event, index_session, session, row)}>
                                        <IconComponent
                                          iconName='add'
                                          style={{ fontSize: '18px' }}
                                        />
                                      </IconButton>
                                      :
                                      '-'
                                    :
                                    <Badge
                                      badgeContent={
                                        <Tooltip title={badgeTooltipContent(session)}>
                                          <ButtonBase style={{ color: theme.palette.grey['500'], cursor: 'default' }}>
                                            <IconComponent
                                              iconName='information-circle'
                                              style={{ fontSize: '18px' }}
                                            />
                                          </ButtonBase>
                                        </Tooltip>
                                      }
                                      invisible={isEmptyOrUndefined(session.oh_mode, 'falsy')}
                                      sx={{ alignItems: 'center' }}
                                    >
                                      <ButtonBase
                                        onClick={(event) => handleClickSession(event, index_session, session, row)}
                                        disabled={!isEditable}
                                        sx={{ color: isEditable ? theme.palette.info.main : '' }}
                                      >
                                        <Typography>{session.start_local_time ? session.start_local_time : "-"}{" "}</Typography>
                                      </ButtonBase>
                                    </Badge>
                                  }
                                </Grid>
                              )
                            })}
                          </Grid>
                        </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={dataRobots.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* Open if click all sessions */}
      <MenuMultiSessionTable
        anchorEl={anchorEl}
        open={open}
        dialogType={dialogType}
        dateCellSelected={dateCellSelected}
        selectedDate={selectedDate}
        selectedSessions={selectedSessions}
        handleClickOpenNewSession={handleClickOpenNewSession}
        handleClose={handleClose}
        handleClickOpenDeleteSession={handleClickOpenDeleteSession}
      />
      {/* Open if click only 1 session*/}
      <MenuSessionTable
        anchorEl={anchorElSession}
        open={openSession}
        dialogType={dialogType}
        dateCellSelected={dateCellSelected}
        selectedDate={selectedDate}
        sessionSelected={sessionSelected}
        handleClose={handleClose}
        handleClickOpenNewSession={handleClickOpenNewSession}
        handleClickOpenDeleteSession={handleClickOpenDeleteSession}
        handleClickOpenAddAisles={handleClickOpenAddAisles}
      />
      <NewSessionDialog
        handleClose={handleCloseNewSession}
        handleNewSessions={handlePostNewSessions}
        handleOpenConfirmation={handleOpenConfirmation}
        handleCloseConfirmation={handleCloseConfirmation}
        actualRowClicked={actualRowClicked}
        dateCellSelected={dateCellSelected}
        open={openNewSession}
        currentDay={currentDay}
        currentWeek={currentWeek}
        currentMonth={currentMonth}
        selectedDate={selectedDate}
        sessionSelected={sessionSelected}
        dialogType={dialogType}
        isLoading={isLoadingSessionsPost}
        newSessionsResponse={newSessionsResponse}
        selectedRobots={selectedRobots}
        handleUpdateSessions={handleUpdateSessions}
        selectedSessions={selectedSessions}
      />
      <DeleteSession
        handleClose={handleCloseDeleteSession}
        handleOpenConfirmation={handleOpenConfirmation}
        handleCloseConfirmation={handleCloseConfirmation}
        selectedRobots={selectedRobots}
        cellSelected={cellSelected}
        dateCellSelected={dateCellSelected}
        open={openDeleteSession}
        currentDay={currentDay}
        currentWeek={currentWeek}
        currentMonth={currentMonth}
        sessionSelected={sessionSelected}
        dialogType={dialogType}
        isLoading={isLoadingDeleteSessions}
      />
      <AddAisles
        handleClose={handleCloseAddAisles}
        handleUpdateSessions={handleUpdateSessions}
        dateCellSelected={dateCellSelected}
        open={openAddAisles}
        currentDay={currentDay}
        currentWeek={currentWeek}
        currentMonth={currentMonth}
        sessionSelected={sessionSelected}
        isLoading={isLoadingSessionsPost}
        selectedDate={selectedDate}
      />
      <Confirmation
        open={openConfirmation}
        type={dialogType}
        selectedSessions={selectedSessions}
        dateCellSelected={dateCellSelected}
        actualSessionClicked={actualSessionClicked}
        handleClose={handleCloseConfirmation}
        handleDeleteSession={handleDeleteSession}
        handleNewSessions={handlePostNewSessions}
      />
    </Paper >
  );
}
