import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';


export default function LineChart(props) {
  const {
    data,
    CLIData,
  } = props;

  // make sure parent container have a defined height when using
  // responsive component, otherwise height will be 0 and
  // no chart will be rendered.
  // website examples showcase many properties,
  // you'll often use just a few of them.
  return (
    <Grid sx={{ width: '1000px', height: '600px' }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        theme={{
          legends: { text: { fontSize: 16 } },
          axis: { legend: { text: { fontSize: 20 } } }
        }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        axisBottom={{
          tickSize: 4,
          tickPadding: 5,
          tickRotation: 35,
          legend: 'Timestamp',
          legendOffset: 40,
          legendPosition: 'middle',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: CLIData.valueType === '' ? "Time [min]" : 'Battery [V]',
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        enableGridX={false}
        colors={{ scheme: 'paired' }}
        lineWidth={2}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: 'top',
            direction: 'column',
            justify: false,
            translateX: 0,
            translateY: -40,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 20,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />

    </Grid>
  );
}
