import { Box, Divider, Grid, LinearProgress, Stack, Switch, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Selector from '../tools/Selector';

import { pink } from '@mui/material/colors';
import { alpha, styled } from '@mui/material/styles';

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600],
  },
}));

export default function CommandForm(props) {
  const {
    command,
    modalForm,
    actionReasons,
    getActionReasons,
    isLoadingActionReasons, } = props;
  const [selectedReason, setSelectedReason] = useState(null);
  const [isZippediProblem, setIsZippediProblem] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    modalForm.current = {}
    return () => {
      modalForm.current = {}
    }
  }, []);

  useEffect(() => {
    getActionReasons({ command });
  }
    , [command]);

  useEffect(() => {
    if (selectedReason?.name) {
      modalForm.current = {
        reason: selectedReason.name,
        store_imposed: !isZippediProblem,
        command,
      };
    }
  }, [selectedReason, isZippediProblem]);

  const handleSwitch = (event) => {
    setIsZippediProblem(event.target.checked);
  };

  return (
    <>
      <Box sx={{ p: 1 }}>
        <Grid container spacing={1} sx={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={4} sx={{ paddingTop: '0px !important' }}>
            <Typography gutterBottom sx={{
              color: 'rgba(0, 0, 0, 0.87)',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 400,
              fontSize: '13px',
              lineHeight: '1.4375em',
              letterSpacing: '0.00938em',
            }}>
              {t('overseer_app.widget.root_cause', 'Root Cause')}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>
                {t('overseer_app.widget.Client', 'Client')}
              </Typography>
              <PinkSwitch size="small" inputProps={{ 'aria-label': 'mui switch' }} checked={isZippediProblem} onChange={handleSwitch} />
              <Typography>Zippedi</Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Selector
              fullWidth
              error={!selectedReason}
              disabled={isLoadingActionReasons}
              selectedElement={selectedReason}
              setSelectedElement={setSelectedReason}
              disableClearable
              labelInput={t(
                'overseer_app.widget.select_reason',
                'Select a reason for this action *'
              )}
              options={actionReasons}
              variant={'standard'}
            />
            {isLoadingActionReasons && <LinearProgress size={20} />}
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ mt: 1 }} />
    </>
  );
}
